import {HeaderAnt, Footer } from 'components';
import { Index } from './components/Index';
export const Login = () => {
  return (<>
    <HeaderAnt/>
    <section style={{ minHeight: "85dvh",alignContent:'center', alignItems:'center' }} >
      <Index /> 
      </section>
    <Footer />
  </>)
};
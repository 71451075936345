import React, { useState } from 'react';
import { ModalSimples } from '@components';
import { Avatar } from 'antd';
import { useUser } from '@context/components/UserProvider';
import ViewFotoPerfil from '@pages/Private/Usuarios/Cadastro/components/usuario/components/ViewFotoPerfil';
import MdCameraAltMD from '@components/icons/components/MdCameraAltMD';
export const ImagemPerfilUsuario = () => {
    const { user } = useUser()
    const [showModal, setShowModal] = useState(false);
    const handleOpenModal = () => {
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };
    return (<>
        <div className='text-center col-md-12 justify-content-center' style={{ position: "relative", display: "inline-block" }}>
            <Avatar className="d-block rounded-circle w-50 mx-auto mt-2 image-fluid" style={{
                maxHeight: '200px',
                minHeight: '200px',
                maxWidth: '200px',
                minWidth: '200px',
            }} src={user?.images} alt="Imagem do Paciente" onClick={handleOpenModal} />
            <MdCameraAltMD className=" holder icone-branco position-absolute h-25" onClick={handleOpenModal} style={{
                zIndex: '1', top: '95%', left: '50%', transform: 'translate(-50%, -50%)',
            }} />
        </div>
        {showModal && (
            <ModalSimples
                showModal={showModal}
                handleClose={handleCloseModal}
            >
                <ViewFotoPerfil />
            </ModalSimples>
        )}
    </>);
};
export default ImagemPerfilUsuario;
import { post_user_login_renew } from "@pages/Public/Login/components/services/post_user_login_renew";
const { apiToken } = require("services");
  export const handleActiveProfissional = async ({
    profissional,
    user,
    setAuthenticatedProfissional,
    updateProfessionalData,
    clearProfessionalData,
    updateProfissional}) => {
  try {
    const response = await apiToken().put(`put_professional/${profissional.id}`, {
      state: "active"  // Enviando o estado corretamente no corpo da requisição
    });
    if (response.status === 200) {
      await post_user_login_renew({
        user,
        setAuthenticatedProfissional,
        updateProfessionalData,
        clearProfessionalData,
        updateProfissional
      })
    } else {
      console.error('Erro ao ativar o cadastro profissional. Resposta:', response);
      alert('Erro ao ativar o cadastro profissional. Status não esperado.');
    }
  } catch (error) {
    console.error('Erro ao ativar o cadastro profissional:', error);
    alert('Erro ao ativar o cadastro profissional. Consulte o console para mais detalhes.');
  }
};
import React, { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ServicosAlert } from './ServicosAlert';
import { Button, Carousel, Input } from 'antd';
import "pages/Public/Produtos/components/comprar/styles/promotion.css";
import { MdBookMD, RemoveMarkdown, TitleL3 } from "components";
import FormattedValue from 'functions/FormattedValue';
import ProfessionalCardServices from './ProfessionalCardServices';
export const ServicesCard = ({ product_id, product_code_id, products_name, image, description, value, services_type, services_code_id, company_unit_id, onAddToCart, professional, }) => {
  const [quantidade, setQuantidade] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  const carouselRef = useRef(null);
  const handleAddToCart = () => {
    const servicesToAdd = {
      product_id,
      products_name,
      image,
      product_code_id,
      description,
      value,
      services_type,
      services_code_id,
      company_unit_id,
      quantity: quantidade,
      table: 'service'
    };
    onAddToCart(servicesToAdd);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };
  const handleComprarAgora = () => {
    const servicesToAdd = {
      product_id,
      products_name,
      image,
      product_code_id,
      description,
      value,
      services_type,
      services_code_id,
      company_unit_id,
      quantity: quantidade,
      table: 'service'
    };
    onAddToCart(servicesToAdd);
    const urlCarrinho = '/carrinho';
    window.location.href = urlCarrinho + search;
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  const location = useLocation();
  const { search } = location;
  const handleChangeQuantidade = (event) => {
    const value = event.target.value;
    // Se o campo estiver vazio, define quantidade como 0
    if (value === '') {
      setQuantidade(0);
    } else if (/^\d+$/.test(value)) {
      // Apenas atualiza quantidade se o valor for um número válido
      setQuantidade(Number(value));
    }
  };
  const parseimages = (images) => {
    try {
      const parsedimages = JSON.parse(images);
      return Array.isArray(parsedimages) ? parsedimages : [];
    } catch (e) {
      return [];
    }
  };
  const images = parseimages(image);
  return (<>
    <div className="col-md-3 mt-1 py-1 card_box"
      style={{
        padding: '5px',
        cursor: "pointer",
      }}>
   <header>
    <span className="promo-label">Promoção</span>
  </header>  <span className="center-square"></span>
      <div
        className='rounded border'
        style={{
          padding: '0px'
        }}>
        {images.length > 0 ? (
          <div style={{ position: 'relative' }}>
            <Button
              style={{
                position: 'absolute',
                top: '50%',
                left: '0',
                transform: 'translateY(-50%)',
                zIndex: 1,
                borderRadius: ' 0 50%  50% 0',
                padding:16
              }}
              onClick={() => carouselRef.current.prev()}
            >
              ◀
            </Button>
            <Carousel ref={carouselRef}>
              {images.map((img) => (
                <div key={img.id}>
                  <a href={`${location.pathname.includes('usuario') ? `../../../usuario/servicos/${product_id}${search}` : `/servicos/${product_id}${search}`}`}>
                    <img
                      className="w-100 rounded image-fluid"
                      style={{ minHeight: "270px", maxHeight: "270px", borderRadius: '20px 20px 0px  0px' }}
                      src={img.url}
                      alt="Produtos imagem"
                    />
                  </a>
                </div>
              ))}
            </Carousel>
            <Button
              style={{
                position: 'absolute',
                top: '50%',
                right: '0',
                transform: 'translateY(-50%)',
                zIndex: 1,
                borderRadius: ' 50% 0 0  50%',
                padding:16
              }}
              onClick={() => carouselRef.current.next()}
            >
              ▶
            </Button>
          </div>
        ) : (
          <a href={`${location.pathname.includes('usuario') ? `../../../usuario/servicos/${product_id}${search}` : `/servicos/${product_id}${search}`}`}>
            <img
              className="w-100 rounded image-fluid"
              style={{ minHeight: '270px', maxHeight: '270px' }}
              src={image}
              alt="Produtos imagem"
            />
          </a>
        )}
        <div
        style={{
          padding: '5px'
        }}>
        <a href={`${location.pathname.includes('usuario') ? `../../../usuario/servicos/${product_id}${search}` : `/servicos/${product_id}${search}`}`}>
          <TitleL3 className=' mx-auto  justify-content-center align-items-center text-justify '
            style={{
              minHeight: "45px",
              padding: "0px",
              fontWeight: 700,
              overflow: "hidden",
              marginBottom: "0px",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              marginTop:'4px',
              lineHeight: "1", // Reduz o espaçamento entre as linhas
              WebkitBoxOrient: "vertical",
            }}>{products_name}</TitleL3>
        </a>
        <a className='text-dark' style={{ margin: '0px', }} href={`/servicos/${product_id}${search}`}>
          {description && <p className='text-justify text-primary' style={{ marginBottom: '0px', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', minHeight: "42px", WebkitLineClamp: 1, WebkitBoxOrient: 'vertical' }}>
            <RemoveMarkdown  description={description} /></p>}
        </a>
        <div className="d-flex align-items-center w-100">
          <div className="flex-grow-1" style={{ maxWidth: '33.33%', paddingRight: '15px' }}>
            <strong className="font-weight-bold">
              <FormattedValue value={value} />
            </strong>
            <br />
            <label htmlFor={`quantidade-input-${product_id}`}>Quantidade</label>
          </div>
          <div className="flex-grow-1" style={{ maxWidth: '66.66%', }}>
            <ProfessionalCardServices professional={professional} />
          </div>
        </div>
        <div className='text-center d-flex'>
          <div className='d-flex align-items-center justify-content-center w-100'>
            <Button style={{ visibility: quantidade > 0 ? 'visible' : 'hidden' }} onClick={() => setQuantidade(prevQuantidade => Math.max(prevQuantidade - 1, 0))}>-</Button>
            <Input
              className='text-center'
              style={{ width: '100%', borderRadius: '20px', }}
              id={`quantidade-input-${product_id}`}
              value={quantidade}
              onChange={handleChangeQuantidade}
              minLength={0}
              min={0}
              inputMode="numeric"
            />
            <Button onClick={() => setQuantidade(prevQuantidade => prevQuantidade + 1)}>+</Button>
          </div>
        </div>
        <div className='d-flex mt-1  mx-auto justify-content-center'>
          <button className='btn btn-primary col-md-6 btn-rounded '
            onClick={() => handleAddToCart(quantidade)}>
            Adicionar ao Carrinho  <i className="fa text-white flip-horizontal">🛒</i>
          </button>
          &nbsp;  &nbsp;  &nbsp;
          <button className='btn btn-success col-md-6 btn-rounded no-drag button-with-triangles' onClick={() => handleComprarAgora(quantidade)}>Comprar Agora 👆 </button>
        </div>
        <div className='text-center mt-2'>
        </div>
        <div className="button text-center d-flex mx-auto justify-content-center">
          <div className="button__text text-center">
            <Link className='text-primary font-weight-bold mt-2 ' to={`/servicos/${product_id}${search}`}>Mais info  &nbsp;<MdBookMD /> </Link>
          </div>
          <div className="button__wrapper">
            <div className="button__arrow"></div>
            <div className="button__border-circle"></div>
            <div className="button__mask-circle">
              <div className="button__small-circle"></div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div >
    <ServicosAlert show={showAlert}
      onHide={handleCloseAlert}
      products_name={products_name}
      services_type={services_type}
      value={value}
      quantity={quantidade}
    />
  </>);
};
export default ServicesCard;
import MdSearchMD from "@components/icons/components/MdSearchMD";
import { Input } from "antd";
export function InputPadraoSearch(props) {
    return (
        <>
            <div className={`generate-container w-100`}>
                <div className="input-container">
                    <Input {...props} />
                    <MdSearchMD
                        onClick={props.onClick}
                        className="generate-button input-filled fa fa-search  flip-horizontal text-dark"
                        aria-label="Botão de busca"
                        title="Buscar"
                    />
                </div>
            </div>
        </>
    );
}
export default InputPadraoSearch;
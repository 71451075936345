import React, { useState, useContext } from 'react';
import { ButtonAPI, MaisInfoMenosInfo } from '@components';
import { useProfisional } from 'context';
import { AuthContext } from 'guard';
import { apiToken } from 'services';
import AreasConhecimentoCNPQ from './AreasConhecimentoCNPQ';
import { CBO } from './CBO';
import CadastrarPhotoProfissional from './CadastrarPhotoProfissional';
import NomeProfissionalInfo from '../info/NomeProfissionalInfo';
import CadastroAddress from '@pages/Public/Cadastrar/components/components/CadastroAddress';
import useLocalizacao from '@pages/Public/Cadastrar/components/components/useLocalizacao';
import SearchAreas from './SearchAreas';
import { InputPadrao } from '@components/Input';
import { post_user_login_renew } from '@pages/Public/Login/components/services/post_user_login_renew';
import { useProfessionalData } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@context/components/UserProvider';
export const CadastrarProfissionalMain = () => {
  const [professionalName, setProfessionalName] = useState('');
  const {
    setLoading,
    loading,
    setAuthenticatedProfissional,
    authenticatedProfisional
  } = useContext(AuthContext);
  const { user } = useUser();
  const { updateProfissional } = useProfisional()
  const { updateProfessionalData, clearProfessionalData } = useProfessionalData(updateProfissional)
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = {
        professional_name: professionalName,
        user_id: user.id       
      };
      await apiToken().post(`post_professional/${user.id}`, formData);
      alert('Cadastro adicionado com sucesso.');
      await post_user_login_renew({
        id: user.id,
        setAuthenticatedProfissional,
        updateProfessionalData,
        clearProfessionalData,
        updateProfissional
      })
    } catch (error) {
      console.error('Erro ao adicionar o cadastro:', error);
      alert('Erro em salvar os dados');
    } finally {
      setLoading(false);
    }
  };
  const handleNameChange = (e) => {
    const inputName = e.target.value;
    setProfessionalName(inputName);
  };
  return (<>
    <div className='col-md-3'>
      <InputPadrao
        id="input_name_profissional_autonomo"
        title={`Nome profissional`}
        type="text"
        value={professionalName}
        onChange={handleNameChange}
        required
      />
      <MaisInfoMenosInfo text={<NomeProfissionalInfo />}></MaisInfoMenosInfo>
    </div>
    <CBO />
    {!authenticatedProfisional &&
      <div className='py-2'>
        <ButtonAPI type="submit" disabled={loading} onClick={handleSubmit}>
          {loading ? 'Enviando...' : `Cadastrar`}
        </ButtonAPI>
      </div>
    }
  </>);
}
export default CadastrarProfissionalMain;
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // Importar o hook useLocation
import { FooterUser, BreadcrumbAnt } from "components";
import { PlanosPrecosServicos, GerenciamentoAvancado, SolucaoIAAvancadaServicos, ApresentacaoServicos } from "@pages/Public/Servicos/components/vender";
export const Index = () => {
    const location = useLocation(); // Pega o objeto location da URL
    const [currentSection, setCurrentSection] = useState(location.hash || "#apresentacaoServicos");
    useEffect(() => {
        // Monitorando a mudança de location (pathname, search ou hash)
        setCurrentSection(location.hash || "#apresentacaoServicos");  // Atualiza o estado com o novo hash ou padrão
    }, [location]);  // O efeito é executado sempre que a localização mudar
    return (
        <>
            <BreadcrumbAnt icon={'user fa'} text={'Serviços'} />
            {currentSection === "#apresentacaoServicos" && <ApresentacaoServicos />}
            {currentSection === "#PlanosPrecosServicos" && <PlanosPrecosServicos />}
            {currentSection === "#SolucaoGerencimamentoAvancado" && <GerenciamentoAvancado />}
            {currentSection === "#SolucaoIAAvancadaServicos" && <SolucaoIAAvancadaServicos />}
            <FooterUser />
        </>
    );
};
import React, { useState, useEffect, useContext } from 'react';
import { List } from 'antd';
import PaymentDetails from './PaymentDetails';
import { fetchOrders } from '../../services/get_cart_payments_res'; // Importa a função da API para buscar pedidos
import MockOrderButton from './mockDataOrder';
import { useUser } from '@context/components/UserProvider';
import { AuthContext } from '@guard/index';
import OrderTracker from './OrderTracker';
const CartOrder = () => {
  const [orders, setOrders] = useState([]);
  const { authenticated } = useContext(AuthContext);
  const { user } = useUser()
  useEffect(() => {
    const getOrders = async () => {
      if (authenticated) {
        const fetchedOrders = await fetchOrders(user);
        setOrders(fetchedOrders);
      }
    };
    getOrders();
  }, [user, authenticated]);
  return (
    <>
      {process.env.NODE_ENV === 'development' && <MockOrderButton setOrders={setOrders} />}
      <List
        itemLayout="vertical"
        dataSource={orders}
        split={false}
        renderItem={order => (
          <List.Item>
            <PaymentDetails data={order} />
          </List.Item>
        )}
      />
      <OrderTracker/>
    </>
  );
};
export default CartOrder;
import React, { useState, useRef } from 'react';
import { Checkbox, Divider, Form, Input, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import {
    ButtonAPI,
    MaisInfoMenosInfo,
    TermoDeParcerias,
    TitleL1,
    TitleL2
} from '@components';
import {
    BreveDescricaoInputInfo,
    ContatoInputInfo,
    NivelDeMaturidadeInputInfo,
    NomeProprietarioInputInfo,
    PretensaoProjetoInputInfo,
    TituloDoProjetoInputInfo
} from '../info/CadastroParceriasInputInfo';
import { api } from 'services';
const { Option } = Select;
const isFormValid = (formData) => {
    const {
        TITULO,
        TRL,
        DESCRICAO,
        NEGOCIO,
        NOME,
        CONTATO,
        TERMOS,
    } = formData;
    return (
        TITULO !== '' &&
        TRL !== '' &&
        DESCRICAO !== '' &&
        NEGOCIO !== '' &&
        NOME !== '' &&
        CONTATO !== '' &&
        TERMOS
    );
};
export const CadastroParcerias = () => {
    const [formData, setFormData] = useState({
        user_id: '',
        professional_id: '',
        company_id: '',
        TITULO: '',
        TRL: '',
        DESCRICAO: '',
        NEGOCIO: '',
        NOME: '',
        CONTATO: '',
        TERMOS: false,
    });
    const formRefs = {
        TITULO: useRef(null),
        TRL: useRef(null),
        DESCRICAO: useRef(null),
        NEGOCIO: useRef(null),
        NOME: useRef(null),
        CONTATO: useRef(null),
        TERMOS: useRef(null),
    };
    const limparFormulario = () => {
        setFormData({
            user_id: '',
            professional_id: '',
            company_id: '',
            TITULO: '',
            TRL: '',
            DESCRICAO: '',
            NEGOCIO: '',
            NOME: '',
            CONTATO: '',
            TERMOS: false,
        });
    };
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const fieldValue = type === 'checkbox' ? checked : value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: fieldValue,
        }));
    };
    const handleSelectChange = (name, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    const handleGoToFirstEmptyField = () => {
        const firstEmptyField = findFirstEmptyField();
        if (firstEmptyField) {
            const element = formRefs[firstEmptyField].current;
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
                element.focus();
            }
        }
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        handleGoToFirstEmptyField();
        if (!isFormValid(formData)) {
            alert('Por favor preencha os campos necessários');
            return;
        }
        try {
            const response = await api.post(`post_partnerships`, formData);
            limparFormulario();
            alert(response.data.message);
        } catch (error) {
            console.error(error);
            alert("Erro ao cadastrar projeto");
        }
    };
    const findFirstEmptyField = () => {
        if (!formData.TITULO) return 'TITULO';
        if (!formData.TRL) return 'TRL';
        if (!formData.DESCRICAO) return 'DESCRICAO';
        if (!formData.NEGOCIO) return 'NEGOCIO';
        if (!formData.NOME) return 'NOME';
        if (!formData.CONTATO) return 'CONTATO';
        if (!formData.TERMOS) return 'TERMOS';
        return null;
    };
    return (<>
        <section style={{ minHeight: '95dvh' }}>
            <div className='row'>
                <div className='col-md-6'>
                    <div className="col-md-12 mx-auto" style={{ padding: '0px' }}>
                        <div className="text-center justify-content-center col-md-12 w-100 mx-auto">
                            <TitleL1>Cadastro de Projetos</TitleL1>
                        </div>
                        <h5 className="text-dark text-justify mb-5">
                            <strong>Convite Aberto ao Público [ Fluxo Contínuo ]:</strong> A THOT I.A. CORP.
                            é uma empresa especializada no desenvolvimento de projetos em parceria com clientes externos. Se você tem uma ideia ou projeto em mente,
                            estamos aqui para ajudar a transformá-lo em realidade. Para dar o primeiro passo, preencha o formulário simplificado abaixo,
                            e nossa equipe entrará em contato para discutir suas necessidades e objetivos
                            <br />   <br />
                        Lembre-se do nosso <b>FIT</b> para ter maiores taxas de sucesso: Empresa especializada na consultoria e no desenvolvimento de
                        softwares e equipamentos com Inteligência Artificial e Realidade Mista Integrada.
                        Nossa atuação abrange áreas como:
                            <ul className='mt-3'>
                                <p >  <strong className='mt-3'>  Consultoria e desenvolvimento:</strong>
                                    <li>✅ IA (Inteligência Artificial)</li>
                                    <li>✅ RM (Realidade Mista )</li>
                                    <li>✅ IoT (Internet das Coisas)</li>
                                    <li>✅ Web Dev.</li>
                                    <li>✅ E afins</li>
                                </p >
                            </ul>
                        </h5>
                    </div>
                </div>
                <div className='col-md-6 text-center d-flex  flex-wrap justify-content-center mx-auto align-items-center'>
                    <p className='text-center col-md-12'>
                        <img
                            src={require('../assets/CadastroParcerias.png')}
                            alt="Decole seus projetos"
                            className="d-block image-fluid mx-auto col-md-12"
                            style={{ maxHeight: '280px', maxWidth: '480px',  }}
                        />
                        <small className='font-weight-bold'><b>Projetos de alta qualidade com metodologia cientifica aplicada !</b></small>
                    </p>
                </div>
            </div>
            <Form
                name="Cadastro-regional-form"
                className='border bg-light py-2 rounded'
                initialValues={{ remember: true }}
                layout="vertical"
                onFinish={handleSubmit}
            >
                <TitleL2>Dados do Projeto</TitleL2>
                <Divider />
                <div className='row'>
                    <div className='col-md-6'>
                        <Form.Item label="Titulo">
                            <Form.Item name="TITULO" rules={[{ required: true, message: 'Por favor, insira o título do projeto!' }]}>
                                <Input ref={formRefs.TITULO} value={formData.TITULO} onChange={handleChange} />
                            </Form.Item>
                        </Form.Item>
                        <MaisInfoMenosInfo text={<><TituloDoProjetoInputInfo /></>} />
                    </div>
                    <div className='col-md-6'>
                        <Form.Item label="Nível de Maturidade Tecnológica - TRL" name="TRL" rules={[{ required: true, message: 'Por favor, insira o nível TRL do projeto!' }]}>
                            <Select ref={formRefs.TRL} value={formData.TRL} onChange={(value) => handleSelectChange('TRL', value)} className="text-center" placeholder='Selecione Nível de Maturidade'>
                                <Option value="">Selecione</Option>
                                <Option value="IDEIA">Idéia</Option>
                                <Option value="PROTÓTIPO">Protótipo</Option>
                                <Option value="MVP">MVP</Option>
                            </Select>
                        </Form.Item>
                        <MaisInfoMenosInfo text={<><NivelDeMaturidadeInputInfo /></>} />
                    </div>
                    <div className='col-md-6'>
                        <Form.Item label="Breve Descrição" name="DESCRICAO" rules={[{ required: true, message: 'Por favor, insira a descrição do projeto!' }]}>
                            <TextArea ref={formRefs.DESCRICAO} id="BreveDescricao" type="text" rows="1" data-ls-module="charCounter" maxLength="10000" value={formData.DESCRICAO} onChange={handleChange} className='text-center col-md-12 border border-dark' />
                        </Form.Item>
                        <MaisInfoMenosInfo text={<><BreveDescricaoInputInfo /></>} />
                    </div>
                    <div className='col-md-6'>
                        <Form.Item label="Pretenção com Projeto" name="NEGOCIO" rules={[{ required: true, message: 'Por favor, insira sua pretenção com o projeto!' }]}>
                            <Select ref={formRefs.NEGOCIO} value={formData.NEGOCIO} onChange={(value) => handleSelectChange('NEGOCIO', value)} required className="text-center">
                                <Option value="">Selecione</Option>
                                <Option value="GRATUITO">Concessão total e gratuita de direitos autorais</Option>
                                <Option value="CONCESSÃO PARCIAL DEV">Concessão parcial gratuita dos direitos autorais pelo desenvolvimento</Option>
                                <Option value="VENDA TOTAL MVP">Venda total de direitos autorais MVP</Option>
                                <Option value="VENDA PARCIAL MVP">Venda parcial de direitos autorais MVP</Option>
                            </Select>
                        </Form.Item> <MaisInfoMenosInfo text={<><PretensaoProjetoInputInfo /></>} />
                    </div>
                    <div className='col-md-6'>
                        <Form.Item label="Nome do proprietário" name="NOME" rules={[{ required: true, message: 'Por favor, insira o nome do proprietário!' }]}>
                            <Input ref={formRefs.NOME} value={formData.NOME} onChange={handleChange} />
                        </Form.Item>     <MaisInfoMenosInfo text={<><NomeProprietarioInputInfo /></>} />
                    </div>
                    <div className='col-md-6'>
                        <Form.Item label="Contato" name="CONTATO" rules={[{ required: true, message: 'Por favor, insira o contato!' }]}>
                            <Input ref={formRefs.CONTATO} value={formData.CONTATO} onChange={handleChange} />
                        </Form.Item>     <MaisInfoMenosInfo text={<><ContatoInputInfo /></>} />
                    </div>
                    <div className='text-center col-md-12'>
                        <TitleL2>Termos</TitleL2>
                        <h5 className='text-justify mr-2 ml-2'>
                            <b>Leia atentamente os termos e condições da THOT I.A. CORP. antes de enviar o esboço do seu
                                projeto. Ao aceitar e enviar sobre-entendemos que o depositante leu atentamente e
                                aceitou todas as normas e regras do ecossistema independente do tamanho do arquivo
                                'tanto de palavras' contida nos termos.
                            </b>
                        </h5>
                        <TermoDeParcerias />
                    </div>
                    <div className='col-md-12 text-center'>
                        <Form.Item name="TERMOS" valuePropName="checked" rules={[{ required: true, message: 'Por favor, aceite os termos!' }]}>
                            <Checkbox ref={formRefs.TERMOS} checked={formData.TERMOS} onChange={handleChange}>
                                <h5>Eu aceito os termos e condições</h5>
                            </Checkbox>
                        </Form.Item>
                    </div>
                    <div className='col-md-12 text-center'>
                        <ButtonAPI type="submit">Enviar</ButtonAPI>
                    </div>
                </div>
            </Form>
        </section >
    </>
    );
};
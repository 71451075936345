import React, { useState } from 'react';
import { Pagination } from 'antd';
import { ButtonCadastrar, InputPadraoSearch } from 'components';
import { ReconhecimentosCard } from './ReconhecimentosCard';
import { MdWarningMD } from '@components/icons';
import { Link } from 'react-router-dom';
export const ReconhecimentosList = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(6);
    const reconhecimentosEmpresariais = [
        {
            name: 'CATALISA ICT',
            title: 'pesquisa da luz é umas das 1.000 melhores pesquisas do Brasil - CTL0891',
            imageSource: require('../assets/Catalisa.png'),
            href: 'https://www.sebrae.com.br/Sebrae/Portal%20Sebrae/Catalisa%20Principal/Lista%20de%20selecionados%20catalisa%20ict%20-%201000%20vf.pdf',
            href_dois: '../../sobre#historia'
        },
        {
            name: 'IDEIAZ',
            title: 'Os equipamentos são umas das 264 melhores pesquisas do Brasil',
            imageSource: require('../assets/Inovativa.png'),
            href: 'https://anprotec.org.br/site/2021/09/programa-ideiaz-divulga-projetos-selecionados-no-segundo-ciclo/',
            href_dois: '../../parcerias#Pesquisadores'
        },
        {
            name: 'Centelha - MT',
            title: 'O equipamento de monitorar pesquisas com roedores é a 17º melhor pesquisa do estado de MT',
            imageSource: require('../assets/CentelhaMT.png'),
            href: 'https://programacentelha.com.br/wp-content/uploads/2022/07/Lista-Preliminar-de-Aprovadas-da-Fase-1-2.pdf',
            href_dois: '../../parcerias#Pesquisadores#equipamentosPesquisasAnimais'
        },
        {
            name: 'Inova - MT',
            title: 'A empresa possui requisitos de gestão da inovação para ISO 56002',
            imageSource: require('../assets/InovaMT.png'),
            href: 'https://programacentelha.com.br/wp-content/uploads/2022/07/Lista-Preliminar-de-Aprovadas-da-Fase-1-2.pdf',
            href_dois: '../../servicos#apresentacaoServicos#SolucaoIAAvancadaServicos',
        },
        {
            name: 'FECITI',
            title: 'Análise Orofacial com IA e RM 2º melhor projeto- Feira de Ciência, Tecnologia e Inovação de Rondonópolis',
            imageSource: require('../assets/FECITI.png'),
            href: 'https://www.rondonopolis.mt.gov.br/noticias/prefeitura-encerra-a-6a-edicao-da-feciti-com-entrega-de-premios-aos-participantes/#:~:text=A%20categoria%20VI%2C%20destinada%20ao,R%24%203%20mil%20mais%20medalhas.',
            href_dois: '../../servicos#apresentacaoServicos#SolucaoIAAvancadaServicos',
        }
    ];
    const filteredReconhecimentosEmpresariais = reconhecimentosEmpresariais.filter((member) =>
        member.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredReconhecimentosEmpresariais.slice(indexOfFirstItem, indexOfLastItem);
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const totalPages = Math.ceil(filteredReconhecimentosEmpresariais.length / itemsPerPage);
    return (<>
        <div className='col-md-12  mt-2'>
            <InputPadraoSearch
                type="text"
                placeholder="Pesquisar premiação por nome..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="row align-items-center">
                {currentItems.map((member, index) => (
                    <ReconhecimentosCard
                        key={index}
                        name={member.name}
                        title={member.title}
                        imageSource={member.imageSource}
                        href={member.href}
                        href_dois={member.href_dois}
                    />
                ))}
                <div className='col-md-3 text-justify'>
                    <small>
                        <MdWarningMD color={'red'} />  Reclame aqui caso em algum momento você nos ajudou e mesmo assim sua empresa, instituição, programa está sem o devido mérito !?
                        <Link className='text-primary font-weight-bold' to={'https://api.whatsapp.com/send?phone=5566996675486&text=Estou%20entrando%20em%20contato%20para%20'} target={'_blank'} rel={'noopener noreferrer'}> Clique aqui</Link>
                    </small>
                </div>
            </div>
            {totalPages > 1 && (
                <div className="pagination text-center mx-auto justify-content-center py-4">
                    <Pagination
                        current={currentPage}
                        pageSize={itemsPerPage}
                        total={filteredReconhecimentosEmpresariais.length}
                        onChange={handlePageChange}
                    />
                </div>
            )}
            <ButtonCadastrar />
        </div>
    </>);
};
export default ReconhecimentosList;
import React, { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import { Link } from "react-router-dom";
import './styles/modal.css'
export function MaisInfoMenosInfo({ text, destacar }) {
  const [openModal, setOpenModal] = useState(false);
  const [highlight, setHighlight] = useState(false);
  useEffect(() => {
    if (destacar) {
      setHighlight(true);
      const timer = setTimeout(() => {
        setHighlight(false);
      }, 8000);
      return () => clearTimeout(timer);
    } else {
      setHighlight(false);
    }
  }, [destacar]);
  return (
    <div className="text-center font-weight-bold ml-2 mr-2 ">
      <Link
        className={`text-${highlight ? "danger" : "primary"} ${highlight ? "highlighted" : ""
          }`}
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          e.preventDefault();
          setOpenModal(true);
        }}
      >
        🔎
        Mais info
      </Link>
      <Modal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={[
          <Button key="fechar" className="bg-danger" onClick={() => setOpenModal(false)}>
            Fechar
          </Button>
        ]}    
        centered
        width="90vw" // Definindo 90% da largura da tela
        className="custom-modal"
        style={{ paddingTop: '4%', padding: '0px' }}
      >
        <h2>🔎</h2>
        <div className="col-md-12" style={{ overflowX: "hidden", padding: '0px' }} // Impede o scroll horizontal diretamente no Modal
        >{text}</div>
      </Modal>
    </div>
  );
}
export default MaisInfoMenosInfo;
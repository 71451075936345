import { UpdateCadastroForm } from './UpdateCadastroForm';
import { ViewFotoPerfil } from './ViewFotoPerfil';
import { FooterUser, HeaderAnt, SiderUserMain, BreadcrumbAnt } from '@components';
export const ViewCadastroUser = () => {
    return (<>
        <HeaderAnt />
        <div className="d-flex mt-3">
            <SiderUserMain />
            <div className="w-100">
                <BreadcrumbAnt icon={'user fa'} text={'Cadastro'} />
                <ViewFotoPerfil />
                <UpdateCadastroForm />
                <FooterUser />
            </div>
        </div>
    </>)
}
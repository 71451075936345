import logo from './logo.png';  // Usando import para carregar a imagem
export const IconThot = () => {
  return (<>
    <img
      src={logo}
      preview={false ? undefined : undefined}
      style={{ minHeight: '36px', maxHeight: '36px', minWidth: '36px', maxWidth: '36px', paddingBottom:'2px' }}
      alt='Logo da empresa'
    />
  </>);
};
export default IconThot;
import React, { useState, useRef, useEffect } from 'react';
import { Select } from 'antd';
import { MdMenuMD, MdSearchMD } from '@components/icons';
export const MaisOpcoesBusca = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const buttonRef = useRef(null);
    const [screenY, setScreenY] = useState(window.innerWidth); // Inicialize com a altura atual da tela
    useEffect(() => {
        const handleResize = () => {
            setScreenY(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    return (<>
        <div className={`mt-3 collapse  mx-auto ${isExpanded ? 'show' : ''}`} >
            <div className="row">
                <div className="col-md-3 ">
                    <div className="form-group">
                        <small>Ano</small>
                        <Select
                            className=" text-center w-100"
                            required="required">
                            <option value='' selected=""></option>
                            <option value="1">SIM</option>
                            <option value="2">NÃO</option>
                        </Select></div>
                </div>
                <div className="col-md-3 ">
                    <div className="form-group">
                        <small>Area</small>
                        <Select
                            className=" text-center w-100"
                            required="required">
                            <option value='' selected=""></option>
                            <option value="1">SIM</option>
                            <option value="2">NÃO</option>
                        </Select>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <small>Especialidade</small>
                        <Select
                            className=" text-center w-100"
                            required="required">
                            <option value='' selected=""></option>
                            <option value="1">SIM</option>
                            <option value="2">NÃO</option>
                        </Select></div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <small>Reino</small>
                        <Select
                            className=" text-center w-100"
                            required="required">
                            <option value='' selected=""></option>
                            <option value="1">SIM</option>
                            <option value="2">NÃO</option>
                        </Select></div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <small>Espécie</small>
                        <Select
                            className=" text-center w-100"
                            required="required">
                            <option value='' selected=""></option>
                            <option value="1">SIM</option>
                            <option value="2">NÃO</option>
                        </Select></div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <small>Substância</small>
                        <Select
                            className=" text-center w-100"
                            required="required">
                            <option value='' selected=""></option>
                            <option value="1">SIM</option>
                            <option value="2">NÃO</option>
                        </Select></div>
                </div>
                <div className="col-md-3 ">
                    <div className="form-group">
                        <small>Empresa</small>
                        <Select
                            className=" text-center w-100"
                            required="required">
                            <option value='' selected=""></option>
                            <option value="1">SIM</option>
                            <option value="2">NÃO</option>
                        </Select>
                    </div>
                </div>
                <div className="col-md-3 ">
                    <div className="form-group">
                        <small>Pesquisador</small>
                        <Select
                            className=" text-center w-100"
                            required="required">
                            <option value='' selected=""></option>
                            <option value="1">SIM</option>
                            <option value="2">NÃO</option>
                        </Select></div>
                </div>
                <div className="col-md-3 ">
                    <div className="form-group">
                        <small>ID</small>
                        <Select
                            className=" text-center w-100"
                            required="required">
                            <option value='' selected=""></option>
                            <option value="1">SIM</option>
                            <option value="2">NÃO</option>
                        </Select>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-12" style={{ padding: '10px' }}>
            <div className='d-flex flex-wrap '>
                <div className={`input-group-append h-100 w-100 col-md-6`} style={{ padding: '0px', alignItems: 'center' }}>
                    <small className={`font-weight-bold ${isExpanded ? "text-danger " : 'text-primary'}`}
                        onClick={toggleExpand}
                        style={{ cursor: "pointer", border: 'none', background: 'none', padding: 0, margin: 0, fontFamily: ' Arial, Helvetica' }}                             >
                        <MdMenuMD />     {`${isExpanded ? 'menos opções de filtros' : 'mais opções de filtros'}`}<MdSearchMD />
                    </small>
                </div>
                <div className={`col-md-6     font-weight-bold ${screenY > 1058 ? 'text-right' : ''} `} style={{ padding: '0px' }}>
                    <small className={` font-weight-bold`} style={{ fontFamily: ' Arial, Helvetica' }}>Artigos encontrados: <b style={{ fontFamily: ' Arial, Helvetica' }}>{ } </b> </small> </div>
            </div>
        </div>
    </>
    )
}
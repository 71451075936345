import React from 'react';
import { Divider } from 'antd';
import '../styles/EcosystemMenu.css'; // Assumindo que você tem um arquivo CSS para estilos
import {
  MdAddShoppingCartMD,
  MdHomeMD,
  MdRoomServiceMD,
  MdWidgetsMD,
  MdHandshakeMD,
  MdHistoryEduMD,
  MdAccountCircleMD,
  MdOutlinePersonAddAltMD,
  FaResearchgateFA
} from '@components';
export const EcosystemMenu = () => {
  return (
    <div className='EcosystemMenu-links col-md-6 align-items-left text-left'>
      <div className='col-md-12 text-center' style={{ padding: '0px' }}>
        <small>Ecossistema (RSP) - [ I.A. e RM Integradas ]</small>
        <Divider className='' style={{ margin: '0px', borderColor: 'white', borderWidth: '3px', marginBottom: '3px' }} />
      </div>
      <a href='../' className='EcosystemMenu-link'>
        <MdHomeMD className='EcosystemMenu' />
        <span className='text-white'>Home</span>
      </a>
      <a href='../servicos' className='EcosystemMenu-link'>
        <MdRoomServiceMD className='EcosystemMenu' />
        <span className='text-white'>Serviços</span>
      </a>
      <a href='../produtos' className='EcosystemMenu-link'>
        <MdWidgetsMD className='EcosystemMenu' />
        <span className='text-white'>Produtos</span>
      </a>
      <a href='../artigos' className='EcosystemMenu-link'>
        <FaResearchgateFA className='EcosystemMenu' />
        <span className='text-white'>Artigos</span>
      </a>
      <a href='../parcerias' className='EcosystemMenu-link'>
        <MdHandshakeMD className='EcosystemMenu' />
        <span className='text-white'>Parcerias</span>
      </a>
      <a href='../sobre' className='EcosystemMenu-link'>
        <MdHistoryEduMD className='EcosystemMenu' />
        <span className='text-white'>Sobre</span>
      </a>
      <a href='../carrinho' className='EcosystemMenu-link'>
        <MdAddShoppingCartMD className='EcosystemMenu' />
        <span className='text-white'>Carrinho</span>
      </a>
      <a href='../login' className='EcosystemMenu-link'>
        <MdAccountCircleMD className='EcosystemMenu' />
        <span className='text-white'>Login</span>
      </a>
      <a href='../cadastrar' className='EcosystemMenu-link'>
        <MdOutlinePersonAddAltMD className='EcosystemMenu' />
        <span className='text-white'>Cadastrar</span>
      </a>
    </div>
  );
};
export default EcosystemMenu;
import { CardDirecionamento, BreadcrumbAnt, MdAssignmentIndMD, MdWorkMD } from '@components';
import { AuthContext } from '@guard/index';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
export const Index = () => {
    const location = useLocation();
    const { search } = location;
    const { authenticatedProfisional } = useContext(AuthContext);
    return (<>
        <section style={{ minHeight: "95dvh" }}>
            <div className='w-100'>
                <BreadcrumbAnt icon={'user fa'} text={'Cadastro'} />
                <div className="text-center">
                    <div className="container-fluid">
                        <div className="row d-inline-flex justify-content-center w-100">
                            <CardDirecionamento text={'Usuario'} icon={<MdAssignmentIndMD size={50} />} href={`./usuario${search}`} />
                            <CardDirecionamento text={'Profissional'} icon={<MdWorkMD size={50} />} href={!authenticatedProfisional ? `./profissional${search}` : `../../../profissionais/cadastro/${search}`} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}
export default Index;
import { BreadcrumbAnt, FooterUser, HeaderAnt, InputPadraoSearch, SiderUserMain } from "components";
export const ViewAtestadosAtivos = () => {
    return (<>
        <HeaderAnt />
        <div className="d-flex mt-3" style={{ overflowX: 'hidden' }}>
            <SiderUserMain />
            <section className="w-100">
                <BreadcrumbAnt />
                <div className="py-3">
                    <InputPadraoSearch />
                    <div className="text-center">
                        <div className="container-fluid">
                            <div className="row d-inline-flex justify-content-center w-100">
                            </div>
                        </div>
                    </div>
                </div>
                <FooterUser />
            </section>
        </div>
    </>)
}
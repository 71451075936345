import { useContext, useState } from 'react';
import { useProfessionalData } from 'hooks';
import { useLocation } from 'react-router-dom';
import CadastrarPhotoProfissional from '../ProfissionalCadastrar/components/CadastrarPhotoProfissional';
import SearchAreas from '../ProfissionalCadastrar/components/SearchAreas';
import AreasConhecimentoCNPQ from '../ProfissionalCadastrar/components/AreasConhecimentoCNPQ';
import useLocalizacao from '@pages/Public/Cadastrar/components/components/useLocalizacao';
import { ButtonAPI, MaisInfoMenosInfo } from '@components/button';
import apiToken from '@services/apiToken';
import { post_user_login_renew } from '@pages/Public/Login/components/services/post_user_login_renew';
import { useProfisional } from '@context/index';
import { AuthContext } from '@guard/index';
import { useUser } from '@context/components/UserProvider';
import CadastroAddress from '@pages/Public/Cadastrar/components/components/CadastroAddress';
import CBO from '../ProfissionalCadastrar/components/CBO';
import { InputPadrao } from '@components/Input';
import NomeProfissionalInfo from '../ProfissionalCadastrar/info/NomeProfissionalInfo';
import { TitleL1 } from '@components/text';
export const ProfissionalEditar = () => {
  const location = useLocation();
  const { search } = location;
  const [professionalName, setProfessionalName] = useState('');
  const { latitude, setLatitude, longitude, setLongitude, address, getLocalizacao } = useLocalizacao();
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [loading, setLoading] = useState(null);
  const { user } = useUser();
  const { profissional, updateProfissional } = useProfisional()
  const { professionalData, updateProfessionalData, clearProfessionalData } = useProfessionalData()
  const handleAddArea = (area) => {
    setSelectedAreas((prevAreas) => {
      const isAreaSelected = prevAreas.some(
        (prevArea) =>
          prevArea.grande_area === area.grande_area &&
          prevArea.area === area.area &&
          prevArea.sub_area === area.sub_area &&
          prevArea.especialidade === area.especialidade
      );
      if (!isAreaSelected) {
        return [...prevAreas, area];
      }
      return prevAreas;
    });
  };
  const handleRemoveArea = (area) => {
    setSelectedAreas((prevAreas) => prevAreas.filter((prevArea) => prevArea !== area));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = {
        professional_name: professionalName,
        address: address
      };
      await apiToken().put(`put_professional/${profissional.id}`, formData);
      alert('Cadastro editado com sucesso.');
    } catch (error) {
      console.error('Erro ao editar o cadastro:', error);
      alert('Erro em salvar os dados');
    } finally {
      setLoading(false);
    }
  };
  const handleNameChange = (e) => {
    const inputName = e.target.value;
    setProfessionalName(inputName);
  };
  return (<>
    <CadastrarPhotoProfissional />
    <div className="mx-auto">
      <div className="form-group bg-light">
        <TitleL1>Dados Profissional</TitleL1>
        <div className='col-md-12 mb-2'>
          <div className='row'>
            <div className='col-md-3'>
              <InputPadrao
                id="input_name_profissional_autonomo"
                title={`Nome profissional`}
                type="text"
                value={professionalName}
                onChange={handleNameChange}
                required
              />
              <MaisInfoMenosInfo text={<NomeProfissionalInfo />}></MaisInfoMenosInfo>
            </div>
            <CBO />
          </div>
        </div>
        <AreasConhecimentoCNPQ onAddArea={handleAddArea} onRemoveArea={handleRemoveArea} selectedAreas={selectedAreas} />
        {
          selectedAreas.length > 0 ? (
            <div className='col-md-12 bg-light rounded text-center'>
              <small>Áreas CNPQ Selecionadas:</small>
              <div className='col-md-12'>
                {selectedAreas.length > 0 ? (<>
                  <div className='py-2 '>
                    {selectedAreas.map((area, index) => (
                      <div className='mt-2  py-2 border border-dark  rounded bg-warning' key={index}>
                        <p>
                          <small>Grande Área:</small> {area.grande_area}
                        </p>
                        {area.area && (
                          <p>
                            <small>Área:</small> {area.area}
                          </p>)}
                        {area.sub_area && (
                          <p>
                            <small>Sub-Área:</small> {area.sub_area}
                          </p>)}
                        {area.especialidade && (
                          <p>
                            <small>Especialidade:</small> {area.especialidade}
                          </p>)}
                        <button style={{ border: '0px', background: "#ffc107 " }} onClick={() => handleRemoveArea(area)}>
                          <i className='fa fa-trash text-danger' > &nbsp; Excluir</i>
                        </button>
                      </div>
                    ))}
                  </div>
                </>) : (
                  <p className='bg-warning'>Nenhuma área selecionada.</p>
                )}
              </div>
            </div>
          ) : (
            null
          )
        }
        <SearchAreas id={user.id} profissional={profissional.id} />
        <CadastroAddress latitude={latitude} setLatitude={setLatitude}
          longitude={longitude} setLongitude={setLongitude} getLocalizacao={getLocalizacao} />
        <ButtonAPI type="submit" disabled={loading} onClick={handleSubmit}>
          {loading ? 'Enviando...' : `${profissional.id ? `Salvar edição` : `Cadastrar info.`}`}
        </ButtonAPI>
      </div>
    </div>
  </>)
}
export default ProfissionalEditar;
import React from 'react';
export const ResolucaoParceriasInfo = (props) => {
  return (
    <div>
      <h5 className='font-weight-bold'>Resolução de Parcerias</h5>
      <p>
        A "Resolução de Parcerias" é um painel que oferece uma visão geral das parcerias cadastradas na plataforma.
        Esse painel é uma ferramenta importante para gerenciar e acompanhar o progresso das parcerias estabelecidas.
        Aqui estão alguns detalhes sobre o painel:
      </p>
      <h5 className='font-weight-bold'>Parcerias Cadastradas {props.parceriasCadastradas} Cadastradas</h5>
      <p className='mt-2'>
        <strong>Número Total:</strong> Existem atualmente {props.parceriasCadastradas} parcerias cadastradas na plataforma.
      </p>
      <p>Essas parcerias representam acordos ou colaborações estabelecidas com outras entidades ou empresas
      </p>
      <h5 className='font-weight-bold'>FeedBack {props.feedBack} FeedBack</h5>
      <p className='mt-2'>
        <strong>Número de Feedbacks: {props.feedBack} </strong> Respostas emitidas para os pretendentes
      </p>
      <p>    O feedback é essencial para avaliar o desempenho e a eficácia das entradas de parcerias
      </p>
      <h5 className='font-weight-bold'>Em Desenvolvimento  {props.EmDesenvolvimento} Em Desenvolvimento</h5>
      <p className='mt-2'>
        <strong>Parcerias em Desenvolvimento:{props.EmDesenvolvimento}</strong>   </p><p>
        Parcerias fechadas e sendo desenvolvidas, no qual essa empresa é co-responsável pelo desenvolvimento
      </p>
      <h5 className='font-weight-bold'>Resolvidas {props.Resolvidas} Resolvidas</h5>
      <p className='mt-2'>
        <strong>Parcerias Resolvidas:</strong>{props.Resolvidas} parcerias marcada como resolvida até o momento.   </p>
      <p>
        Parcerias resolvidas geralmente indicam que os objetivos da parceria foram atingidos ou que a colaboração chegou ao seu desenvolvimento inicial planejado
      </p>
      <h5 className='font-weight-bold'>Média de Dias para Feedback de Parcerias Cadastradas {props.mediaDiasResposta} Dias para Feedback</h5>
      <p className='mt-2'>
        <strong>Tempo Médio para Feedback:</strong> O tempo médio para receber feedback em parcerias cadastradas é atualmente de {props.mediaDiasResposta} dias
      </p>
      <p className='py-5'>
        Este painel fornece uma visão geral útil para acompanhar e avaliar o status das parcerias cadastradas, garantindo que elas sejam eficazes e atendam aos objetivos estabelecidos no compliance de inovação da empresa
      </p>
    </div>
  );
};
export default ResolucaoParceriasInfo;
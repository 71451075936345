import React, { useState, useEffect } from 'react';
export function ViewFacePointTable({
  landmarks,
  modifiedLandmarks,
  pontoDestacado,
  setPontoDestacado,
  currentType,
  modifiedLandmarksType,
  setModifiedLandmarks
}) {
  const [originalLandmarks, setOriginalLandmarks] = useState([]);
  useEffect(() => {
    const currentOriginal =
      landmarks?.[currentType]?.[modifiedLandmarksType]?.landmarks ||
      landmarks?.[currentType]?.[modifiedLandmarksType] ||
      [];
    setOriginalLandmarks(currentOriginal);
  }, [landmarks, currentType, modifiedLandmarksType]);
  const currentModified =
    modifiedLandmarks?.[currentType]?.[modifiedLandmarksType]?.landmarks ||
    modifiedLandmarks?.[currentType]?.[modifiedLandmarksType] ||
    [];
  const hasUN =
    currentModified.length > 0 &&
    Object.prototype.hasOwnProperty.call(currentModified[0], 'UN');
  const hasLandmarksColumn = modifiedLandmarksType?.endsWith("landmarks");
  const handleInputChange = (index, field, value) => {
    const updatedLandmarks = [...currentModified];
    updatedLandmarks[index] = {
      ...updatedLandmarks[index],
      [field]: parseFloat(value) || 0, // Converte para número
    };
    setModifiedLandmarks(prev => ({
      ...prev,
      [currentType]: {
        ...prev[currentType],
        [modifiedLandmarksType]: hasLandmarksColumn ? { landmarks: updatedLandmarks } : updatedLandmarks
      }
    }));
  };
  const handleDeleteRow = (index) => {
    const updatedLandmarks = currentModified.filter((_, i) => i !== index);
    setModifiedLandmarks(prev => ({
      ...prev,
      [currentType]: {
        ...prev[currentType],
        [modifiedLandmarksType]: hasLandmarksColumn ? { landmarks: updatedLandmarks } : updatedLandmarks
      }
    }));
  };
  return (
    <>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={thStyle}>Ponto</th>
            <th style={thStyle}>X (Original)</th>
            <th style={thStyle}>Y (Original)</th>
            <th style={thStyle}>Z (Original)</th>
            {hasUN && <th style={thStyle}>UN (Original)</th>}
            <th style={thStyle}>Diferença</th>
            <th style={thStyle}>X (Atual)</th>
            <th style={thStyle}>Y (Atual)</th>
            <th style={thStyle}>Z (Atual)</th>
            {hasUN && <th style={thStyle}>UN (Atual)</th>}
            {hasUN && <th style={thStyle}>Ações</th>}
          </tr>
        </thead>
        <tbody>
          {currentModified.map((landmark, index) => {
            const originalLandmark = originalLandmarks[index] || {};
            console.log(`Ponto ${index}: originalLandmark:`, originalLandmark); // Debug
            const formatValue = (val) =>
              val !== undefined ? val.toFixed(2) : 'N/A';
            const originalX = originalLandmark.x !== undefined ? originalLandmark.x.toFixed(2) : 'N/A';
            const originalY = originalLandmark.y !== undefined ? originalLandmark.y.toFixed(2) : 'N/A';
            const originalZ = originalLandmark.z !== undefined ? originalLandmark.z.toFixed(2) : 'N/A';
            const originalUN = formatValue(originalLandmark.UN);
            const diferenca =
              landmark.x !== originalLandmark.x ||
              landmark.y !== originalLandmark.y ||
              landmark.z !== originalLandmark.z ||
              landmark.UN !== originalLandmark.UN
                ? 'Alterado'
                : 'Nenhuma';
            return (
              <tr
                key={index}
                style={{
                  cursor: 'pointer',
                  backgroundColor:
                    pontoDestacado === index ? '#f8d7da' : 'transparent',
                }}
                onClick={() => setPontoDestacado(pontoDestacado === index ? null : index)}
              >
                <td style={tdStyle}>{index + 1}</td>
                <td style={tdStyle}>{originalX}</td>
                <td style={tdStyle}>{originalY}</td>
                <td style={tdStyle}>{originalZ}</td>
                {hasUN && <td style={tdStyle}>{originalUN}</td>}
                <td style={{ ...tdStyle, color: diferenca === 'Alterado' ? 'red' : 'black' }}>
                  {diferenca}
                </td>
                <td style={tdStyle}>
                  <input
                    type="number"
                    value={landmark.x}
                    onChange={(e) => handleInputChange(index, "x", e.target.value)}
                    style={inputStyle}
                  />
                </td>
                <td style={tdStyle}>
                  <input
                    type="number"
                    value={landmark.y}
                    onChange={(e) => handleInputChange(index, "y", e.target.value)}
                    style={inputStyle}
                  />
                </td>
                <td style={tdStyle}>
                  <input
                    type="number"
                    value={landmark.z}
                    onChange={(e) => handleInputChange(index, "z", e.target.value)}
                    style={inputStyle}
                  />
                </td>
                {hasUN && (
                  <td style={tdStyle}>
                    <input
                      type="number"
                      value={landmark.UN}
                      onChange={(e) => handleInputChange(index, "UN", e.target.value)}
                      style={inputStyle}
                    />
                  </td>
                )}
                {hasUN && (
                  <td style={tdStyle}>
                    <button onClick={() => handleDeleteRow(index)} style={buttonStyle}>
                      Excluir
                    </button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
const thStyle = {
  padding: '8px',
  textAlign: 'left',
  border: '1px solid #ddd',
};
const tdStyle = {
  padding: '8px',
  border: '1px solid #ddd',
  color: 'black',
};
const inputStyle = {
  width: '80px',
  padding: '4px',
  border: '1px solid #ddd',
  textAlign: 'center'
};
const buttonStyle = {
  backgroundColor: 'red',
  color: 'white',
  border: 'none',
  padding: '5px 10px',
  cursor: 'pointer',
  borderRadius: '4px'
};
export default ViewFacePointTable;
import React from 'react';
import BuscarEquipamentos from './components/layout/BuscarEquipamentos';
import CadastraEquipamentos from './components/layout/CadastraEquipamentos';
import { FooterProfissional,HeaderAnt, NavProfissionalMain } from '../../../../components';
export const Equipamentos = () => {
    return (<>
   <HeaderAnt/>
        <div className="d-flex mt-3">
            <NavProfissionalMain />
            <div className="w-100">
                <CadastraEquipamentos />
                <BuscarEquipamentos />
                <FooterProfissional />
            </div>
        </div>
    </>);
}
import { Image } from 'antd';
import { MaisInfoMenosInfo } from '@components';
import {
  handleNavigateCadastrarProfissional,
  handleUpdateProfissionalController,
  handleDeletedProfessional
} from 'controllers/functions';
import MdKeyMD from '@components/icons/components/MdKeyMD';
import { MdDeleteMD, MdEditMD } from '@components/icons';
import { useUser } from '@context/components/UserProvider';
import { useProfessionalData } from '@hooks/useProfessionalData';
import { useContext } from 'react';
import { AuthContext } from '@guard/index';
import { useNavigate } from 'react-router-dom';
import { handleNavigateUpdateProfessional } from '../functions/handleNavigateUpdateProfessional';
export const UpdateProfissionalController = ({ professionalData, updateProfissional, updateProfessionalData }) => {
  const { user } = useUser();
  const {
    setAuthenticatedProfissional
  } = useContext(AuthContext);
  const { clearProfessionalData, } = useProfessionalData();
  const navigate = useNavigate();
  if (professionalData) {
    return (
      <div className='col-md-3'>
        {professionalData.professionalData.filter((item) => item !== undefined).map((item) => (
          <div className='bg-light d-flex rounded' key={item.id}>
            <div className='container'>
              <small className='font-weight-bold  mt-2'><b>Cadastro Profissional</b></small>     <hr style={{marginTop:'0px'}} />
              <div className="row">
                <div className="bg-light d-flex flex-column rounded">
                  <div>
                    <Image
                      height={50}
                      width={50}
                      className='rounded' src={item.images} alt="Professional Image" preview={false} /><br />
                    <small className='font-weight-bold'>Nome Profissional:<small>  {item.professional_name ? item.professional_name : "Nome não disponível"} </small></small><br />
                    <button style={{ border: '0px' }} onClick={() => {
                      handleUpdateProfissionalController(item, updateProfissional);
                    }}>
                      <MdKeyMD
                        color={JSON.stringify(item) === localStorage.getItem('profissional') ? 'green' : 'black'}
                        className="mr-2 ml-2"
                      />
                    </button>   <button style={{ border: '0px' }} onClick={() => handleNavigateUpdateProfessional({ item, updateProfissional, navigate })}> <MdEditMD className='text-warning mr-2 ml-2' /></button>
                    <button style={{ border: '0px' }} onClick={() => handleDeletedProfessional({
                      item: item.id,
                      user,
                      setAuthenticatedProfissional,
                      updateProfessionalData,
                      clearProfessionalData,
                      updateProfissional
                    })}><MdDeleteMD className=' text-danger mr-2 ml-2' /></button>
                    <MaisInfoMenosInfo text={<>
                      <small className='font-weight-bold'>Classificação Brasileira de Ocupações - CBO</small><br />
                      <div >
                        {/* {Object.entries(professionalCBOData).map(([key, value]) => (
                            <div className='mt-1 bg-white rounded py-2' key={key}>
                              <small className='font-weight-bold ml-1 text-dark'>Descrição: </small><small>{value}</small><br />
                              <small className='font-weight-bold ml-1 text-dark'>Código CBO: </small><small>{key}</small>
                            </div>
                          ))} */}
                      </div>
                      <small className='font-weight-bold'>Informações Gerais</small><br />
                      {item.user_id && <small><strong>Identificação Única de Usuário:</strong> {item.user_id}</small>}<br />
                      {item.id && <small><strong>Identificação Única de Profissional:</strong> {item.id}</small>}<br />
                      {item.address_id && <small><strong>Identificação Única Endereço:</strong> {item.address_id}</small>}<br />
                      {item.created_at && <small><strong>Data de criação:</strong> {item.created_at}</small>}<br />
                      {item.state && <small><strong>Estado do cadastro: </strong>{item.state}</small>}<br />
                    </>} />
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
        ))
        }
      </div >
    );
  } else {
    return <>
      <div className='col-md-12 mx-auto d-flex justify-content-center py-3'>
        <button className='btn btn-primary' onClick={handleNavigateCadastrarProfissional}>Cadastrar Profissional</button>
      </div>
      <p>Nenhum profissional ativo encontrado.
      </p>;
    </>
  }
};
export default UpdateProfissionalController;
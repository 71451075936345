import { BreadcrumbAnt, CardDirecionamento } from '@components'
import { MdCalendarMonthMD, MdCloudMD, MdShoppingBasketMD } from '@components/icons'
export const Index = () => {
    return (<>
        <div className='w-100'>
            <BreadcrumbAnt text={'Meus Exames'} icon={'flask fa'} />
            <div className="py-3">
                <div className="text-center">
                    <div className="container-fluid">
                        <div className="row d-inline-flex justify-content-center w-100">
                            <CardDirecionamento text={'Marcar'} icon={< MdShoppingBasketMD size={50} />} href={"../usuario/servicos/comprar?comprar=consultas"} />
                            <CardDirecionamento text={'Marcados'} icon={< MdCalendarMonthMD size={50} />} href={'./marcados'} />
                            <CardDirecionamento text={'Finalizados'} icon={< MdCloudMD size={50} />} href={'./finalizados'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SearchBar from '@pages/Public/Search/components/layout/SearchBar';
import { IconThot, MdAccountCircleMD, MdOutlinePersonAddAltMD, MdAddShoppingCartMD } from 'components';
import { MdAssignmentIndMD } from '@components/icons';
import { useUser } from '@context/components/UserProvider';
import { Avatar } from 'antd';
import { AuthContext } from '@guard/index';
export const HeaderAnt = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isFixed, setIsFixed] = useState(false);
    const [navHeight, setNavHeight] = useState(0);
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
    const location = useLocation();
    const { search } = location;
    const { authenticated } = useContext(AuthContext)
    const { user } = useUser();
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    let total = 0;
    cart.forEach((item) => {
        total += parseInt(item.quantity);
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 0) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    useEffect(() => {
        const handleWindowResize = () => {
            setScreenWidth(window.innerWidth);
            setIsFixed(false);
        };
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, [screenWidth]);
    useEffect(() => {
        const nav = document.querySelector('.navbar');
        const height = nav ? nav.getBoundingClientRect().height : 0;
        setNavHeight(height);
    }, [screenWidth]);
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const isScrollingUp = prevScrollPos > currentScrollPos;
            setIsFixed(isScrollingUp);
            setPrevScrollPos(currentScrollPos);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);
    const navClasses = `navbar navbar-expand-md navbar-dark bg-primary justify-content-center align-items-center text-white w-100 ${isFixed && !location.pathname.includes('profissionais/ecommerce') ? 'fixed-top' : ''}`;
    const collapseItens = () => {
        setIsNavbarCollapsed((prevIsCollapsed) => !prevIsCollapsed);
    };
    const clicarNoId = useCallback((hash) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const elementoClicado = document.getElementById('navbar18');
        elementoClicado.classList.remove('show');
        if (hash) {
            const id = hash.substring(1);
            const element = document.getElementById(id);
            if (element) {
                element.click();
            }
        }
    }, []);
    useEffect(() => {
        if (location.hash) {
            setIsNavbarCollapsed(true);
            clicarNoId(location.hash);
        }
    }, [location, clicarNoId]);
    return (<>
        <div id='evitaPulo'
            className={!location.pathname.includes('profissionais/ecommerce') ? '' : 'collapse'}
            style={{ height: isFixed ? `${navHeight}px` : 0, }}></div>
        <nav className={navClasses} style={{ minHeight: "5dvh" }}>
            <div
                className={`justify-content-between w-100 ${screenWidth <= 1200 ? `  ${isNavbarCollapsed ? 'd-flex' : 'row'}` : 'col-md-12 '}   justify-content-center align-items-center`}>
                <button
                    className="navbar-toggler navbar-toggler-right  mr-1"
                    id='collapseNavBar' type="button"
                    style={{ padding: '10px', border: 'none' }}
                    data-toggle="collapse"
                    aria-label="Abrir menu de navegação"
                    data-target="#navbar18"
                    onClick={collapseItens}
                >
                    <span className="navbar-toggler-icon text-white" style={{ border: 'none' }}></span>
                </button>
                <div className="collapse navbar-collapse  col-md-12" id="navbar18" style={{ padding: '0px' }} >
                    <Link className="navbar-brand d-none d-md-block   text-white" aria-label="Home" to={`../${search} `} style={{ cursor: 'pointer' }}>
                        <i className='text-white py-1 bg-white rounded' style={{ boxShadow: location.pathname === '/' && " 0px 0px 4px 2px rgb(255, 255, 255, 0.3)" }} ><IconThot /> </i>
                    </Link>
                    <div className={` ${screenWidth <= 1200 ? 'collapse' : '  col-md-4 '}`} style={{ opacity: '1', padding: "0px" }}>
                        <div className="container justify-content-center align-items-center d-flex mx-auto" style={{ padding: '0px' }}>
                            <div className=" w-100 text-center">
                                <SearchBar />
                            </div>
                        </div>
                    </div>
                    <ul className="navbar-nav " style={{ padding: '0px', marginBottom: 0, marginTop: -20 }}>
                        <li className="nav-item mt-4">
                            <Link className={`nav-link ${screenWidth <= 1200 ? (location.pathname === '/' ? 'font-weight-bold text-white' : '') : 'collapse'}`}
                                to={'../' + search}
                            >
                                Início
                            </Link>
                        </li>
                        <li className="nav-item dropdown">
                            <button className={` nav-link dropdown-toggle ${location.pathname.includes('servicos') ? ' font-weight-bold  text-white' : ''}`}
                                style={{ background: 'none', border: 'none', textShadow: location.pathname.includes('servicos') ? ' 0.5px 0 0 currentColor, -0.5px 0 0 currentColor' : '' }}
                                id="navbarDropdownServicos"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                Serviços
                            </button>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownServicos">
                                <Link
                                    className={`dropdown-item text-dark ${(location.pathname.includes('/servicos') && location.hash === '#servicos') || (location.pathname.includes('/servicos/comprar')) ? ' font-weight-bold ' : ''}`}
                                    style={{ background: 'none' }}
                                    to={`${user ? '../../usuario/servicos/comprar' + search : '../servicos#servicos' + search}`}
                                    onClick={() => clicarNoId(location.hash)}
                                >Comprar</Link>
                                <Link
                                    className={`dropdown-item text-dark ${(location.pathname.includes('/servicos') && location.hash === '#apresentacaoServicos') || (location.pathname.includes('/servicos/vender')) ? ' font-weight-bold ' : ''}`}
                                    style={{ background: 'none' }}
                                    to={`${user ? '../../usuario/servicos/vender' + search : '../servicos#apresentacaoServicos' + search}`}
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    onClick={() => clicarNoId(location.hash)}
                                >
                                    Vender
                                </Link>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <button
                                className={`nav-link dropdown-toggle ${location.pathname.includes('produtos') ? ' font-weight-bold  text-white' : ''}`}
                                id="navbarDropdownProdutos"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={{ background: 'none', border: 'none', fontWeight: 'initial', textShadow: location.pathname.includes('produtos') ? ' 0.5px 0 0 currentColor, -0.5px 0 0 currentColor' : '' }}>
                                Produtos
                            </button>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownProdutos">
                                <Link
                                    className={`dropdown-item text-dark ${(location.pathname.includes('/produtos') && location.hash === '#produtos') || (location.pathname.includes('/produtos/comprar')) ? ' font-weight-bold ' : ''}`}
                                    style={{ background: 'none' }}
                                    to={`${user ? '../../usuario/produtos/comprar' + search : '../produtos#produtos' + search}`}
                                    onClick={() => clicarNoId(location.hash)}
                                >Comprar</Link>
                                <Link
                                    className={`dropdown-item text-dark ${(location.pathname.includes('/produtos') && location.hash === '#apresentacao') || (location.pathname.includes('/produtos/vender')) ? ' font-weight-bold ' : ''}`}
                                    style={{ background: 'none' }}
                                    to={`${user ? '../../usuario/produtos/vender' + search : '../produtos#apresentacao' + search}`}
                                    onClick={() => clicarNoId(location.hash)}
                                >
                                    Vender
                                </Link>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <button
                                className={`nav-link dropdown-toggle ${location.pathname.includes('artigos') ? ' font-weight-bold  text-white' : ''}`}
                                style={{ background: 'none', border: 'none', textShadow: location.pathname.includes('artigos') ? ' 0.5px 0 0 currentColor, -0.5px 0 0 currentColor' : '' }}
                                id="navbarDropdownArtigos"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Artigos
                            </button>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownArtigos">
                                <Link
                                    className={`dropdown-item text-dark ${(location.pathname === '/artigos' && location.hash === '#publicArtigos') || (location.pathname === '/artigos' && location.hash === '') ? ' font-weight-bold ' : ''}`}
                                    style={{ background: 'none' }}
                                    to={`${user ? '../../artigos#publicArtigos' + search : '../artigos#publicArtigos' + search}`}
                                    onClick={() => clicarNoId(location.hash)}>Comprar</Link>
                                <Link
                                    className={`dropdown-item text-dark ${(location.pathname === '/artigos' && location.hash === '#SobrePublicacoesInfo') ? ' font-weight-bold ' : ''}`}
                                    to={`${user ? '../../artigos#SobrePublicacoesInfo' + search : '../artigos#SobrePublicacoesInfo' + search}`}
                                    onClick={() => clicarNoId(location.hash)}
                                    style={{ background: 'none' }}
                                >
                                    Politica de Publicação </Link>
                                <Link
                                    className={`dropdown-item text-dark ${(location.pathname === '/artigos' && location.hash === '#ArticleSubmissionRequirements') ? ' font-weight-bold ' : ''}`}
                                    style={{ background: 'none' }}
                                    to={`${user ? '../../artigos#ArticleSubmissionRequirements' + search : '../artigos#ArticleSubmissionRequirements' + search}`}
                                    onClick={() => clicarNoId(location.hash)}
                                >
                                    Como Publicar</Link>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <button
                                className={`nav-link dropdown-toggle ${location.pathname.includes('parcerias') ? ' font-weight-bold  text-white' : ''}`}
                                style={{ background: 'none', border: 'none', textShadow: location.pathname.includes('parcerias') ? ' 0.5px 0 0 currentColor, -0.5px 0 0 currentColor' : '' }}
                                id="navbarDropdownparcerias"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                Parcerias
                            </button>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdownparcerias">
                                <Link onClick={() => clicarNoId(location.hash)}
                                    className={`dropdown-item text-dark ${(location.pathname === '/parcerias' && location.hash === '#apresentacaoParcerias') || (location.pathname === '/parcerias' && location.hash === '') ? ' font-weight-bold ' : ''}`}
                                    style={{ background: 'none' }}
                                    to={`${user ? '../../parcerias#apresentacaoParcerias' + search : '../parcerias#apresentacaoParcerias' + search}`}>
                                    Apresentação
                                </Link>
                                <Link
                                    onClick={() => clicarNoId(location.hash)}
                                    className={`dropdown-item text-dark ${(location.pathname === '/parcerias' && location.hash === '#representacao') ? ' font-weight-bold ' : ''}`}
                                    style={{ background: 'none' }} to={`${user ? '../../parcerias#representacao' + search : '../parcerias#representacao' + search}`}>
                                    Representação Comercial </Link>
                                <Link
                                    onClick={() => clicarNoId(location.hash)}
                                    className={`dropdown-item text-dark ${(location.pathname === '/parcerias' && location.hash === '#SoftwareHouse') ? ' font-weight-bold ' : ''}`}
                                    style={{ background: 'none' }} to={`${user ? '../../parcerias#SoftwareHouse' + search : '../parcerias#SoftwareHouse' + search}`}>
                                    Software House</Link>
                                <Link
                                    onClick={() => clicarNoId(location.hash)}
                                    className={`dropdown-item text-dark ${(location.pathname === '/parcerias' && location.hash === '#Pesquisadores') ? ' font-weight-bold ' : ''}`}
                                    style={{ background: 'none' }} to={`${user ? '../../parcerias#Pesquisadores' + search : '../parcerias#Pesquisadores' + search}`}>
                                    Pesquisadores</Link>
                                <Link
                                    onClick={() => clicarNoId(location.hash)}
                                    className={`dropdown-item text-dark ${(location.pathname === '/parcerias' && location.hash === '#fornecedores') ? ' font-weight-bold ' : ''}`}
                                    style={{ background: 'none' }} to={`${user ? '../../parcerias#fornecedores' + search : '../parcerias#fornecedores' + search}`}>
                                    Fornecedores</Link>
                                <Link onClick={() => clicarNoId(location.hash)}
                                    className={`dropdown-item text-dark ${(location.pathname === '/parcerias' && location.hash === '#ColaboreConosco') ? ' font-weight-bold ' : ''}`}
                                    style={{ background: 'none' }} to={`${user ? '../../parcerias#ColaboreConosco' + search : '../parcerias#ColaboreConosco' + search}`}>
                                    Colabore Conosco</Link>
                            </div>
                        </li>
                        <li className="nav-item">
                            <Link
                                className={`nav-link ${location.pathname === '/sobre' ? 'nav-link font-weight-bold text-white' : 'nav-link'}`}
                                style={{ textShadow: location.pathname.includes('sobre') ? ' 0.5px 0 0 currentColor, -0.5px 0 0 currentColor' : '' }}
                                to={'../sobre' + search}
                            >Sobre</Link>
                        </li>
                    </ul>
                    <ul className="navbar-nav ml-auto" style={{ marginBottom: 0, marginTop: -20 }} >
                        <li className="nav-item ">
                            <Link
                                className={`nav-link ${location.pathname === '/carrinho' || location.pathname === '/usuario/carrinho' ? 'nav-link font-weight-bold text-white' : 'nav-link'} ${screenWidth <= 1200 && 'mt-3'}`}
                                to={user ? `../usuario/carrinho${search}` : `../carrinho${search}`}
                                style={{ textShadow: location.pathname.includes('carrinho') ? ' 0.5px 0 0 currentColor, -0.5px 0 0 currentColor' : '' }}
                            >
                                {total > 0 && <span className="text-white" style={{ background: 'green', padding: '5px', borderRadius: '50%' }} id="QntItensCarrinho"> {total}
                                </span>}
                                <MdAddShoppingCartMD /> Carrinho
                            </Link>
                        </li>
                        <li className="nav-item d-flex">
                            <Link
                                className={`nav-link ${!user ? ' ' : 'd-flex'} ${location.pathname === '/login' ? 'nav-link font-weight-bold text-white' : 'nav-link'}`}
                                to={user ? `../usuario${search}` : `../login${search}`}
                                style={{ textShadow: location.pathname.includes('login') ? ' 0.5px 0 0 currentColor, -0.5px 0 0 currentColor' : '' }}
                            >
                                {user &&
                                    <Avatar
                                        src={user.images && user.images.length > 0 ? user.images : null}  // User's image or null for default avatar
                                        alt="Imagem do Usuário"
                                        style={{
                                            width: '24px',
                                            height: '24px',
                                            borderRadius: '50%',
                                        }}
                                    >
                                        {/* Default text or icon if no image is provided */}
                                        {user.images ? null : <MdAccountCircleMD />}  {/* Show 'A' for Avatar if no image */}
                                    </Avatar>
                                }
                                {!authenticated && <MdAccountCircleMD />}
                                <span
                                    style={{ textShadow: location.pathname === '/usuario' ? ' 0.5px 0 0 currentColor, -0.5px 0 0 currentColor' : '' }}
                                    className={` ${location.pathname === `/usuario` ? ' font-weight-bold text-white' : ''}`}>
                                    {user ? " Usuario" : ' Entrar'}
                                </span>{screenWidth <= 1200 ? <>&emsp;</> : null}
                            </Link>
                            <Link
                                className={`nav-link ${location.pathname === `/cadastrar` || location.pathname.includes('/usuario/cadastro') ? ' font-weight-bold text-white' : 'nav-link'}`}
                                to={user ? `../usuario/cadastro${search}` : `../cadastrar${search}`}
                                style={{ textShadow: location.pathname.includes('cadastrar') || location.pathname.includes('/usuario/cadastro') ? ' 0.5px 0 0 currentColor, -0.5px 0 0 currentColor' : '' }}
                            >
                                {!authenticated ? (<MdOutlinePersonAddAltMD />) :
                                    (<MdAssignmentIndMD />)
                                }
                                {authenticated ? " Cadastro" : ' Cadastrar'}
                            </Link>
                        </li>
                    </ul>
                    <form className={` ${screenWidth >= 1200 ? 'collapse' : ' py-1 w-100 collapse show'}`} style={{ opacity: '1' }}>
                        <SearchBar />
                    </form>
                </div>
                {isNavbarCollapsed ? (<>
                    {screenWidth <= 1200 && <SearchBar />}
                    <Link className="navbar-toggler text-white navbar-togglerl-left border-0 collapse show" to={'../carrinho' + search} >
                        <MdAddShoppingCartMD />
                    </Link>
                </>) : null}
                {isNavbarCollapsed ? (<>
                    {screenWidth <= 1200 && <>
                        {!user &&
                            <Link className="navbar-toggler  navbar-togglerl-left border-0 collapse show"
                                to={user ? `../usuario${search}` : `../login${search}`} >
                                <MdAccountCircleMD />
                            </Link>}
                        {user && user.images && (<>
                            <Link to={user.images ? `../usuario${search}` : `../login${search}`} >
                                <img className="d-block rounded-circle "
                                    style={{
                                        maxHeight: '25px',
                                        minHeight: '25px',
                                        maxWidth: '25px',
                                        minWidth: '25px',
                                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.2)"
                                    }}
                                    src={user?.images}
                                    id="FaceFrontalLogin"
                                    alt="Imagem do Usuário"
                                />
                            </Link>
                        </>)}
                    </>}</>) : null}
            </div>
        </nav>
    </>)
}
export default HeaderAnt;
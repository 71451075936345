import FormattedValue from 'functions/FormattedValue';
import ImpostoBR from '../utils/ImpostoBR';
export const ResumoCompra = ({ cart, procedimentosValor, totaisPorTipo, totalValor }) => {
    return (<>
        <div className='col-md-6' >
            <small className='font-weight-bold' >Resumo da compra</small>
            <table className="col-md-12 table-dark table-circle text-center mx-auto justify-content-center align-items-center" id="tabela" style={{ borderRadius: '0.25rem' }}>
                <thead className="col-md-12 " >
                    <tr>
                        <th id="QNT" className="text-white font-weight-bold" >Qnt</th>
                        <th id="ItensCarrinhoDescricao" className="text-white font-weight-bold">Descrição</th>
                        <th id="ItensCarrinhoValor" className="text-white font-weight-bold">Valor</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(totaisPorTipo).map(tipo => (
                        <tr key={tipo}>
                            <td className="text-white font-weight-bold text-center">{totaisPorTipo[tipo].quantity}</td>
                            <td className="text-white ">{tipo}</td>
                            <td className="text-white font-weight-bold"><FormattedValue value={totaisPorTipo[tipo].valorTotal} /></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <small className='text-center'>Valor total dos itens sem frete </small><FormattedValue value={totalValor} />
            <ImpostoBR cart={cart} procedimentosValor={procedimentosValor} totalValor={totalValor} />
        </div>
    </>);
};
export default ResumoCompra;
import React from 'react';
import BtnPulbicCarrinhoVazio from './BtnPulbicCarrinhoVazio';
import { MaisInfoMenosInfo } from '@components';
import CarrinhoVazioInfo from '../../info/CarrinhoVazioInfo';
import {
    MdWarning,
} from "react-icons/md";
export const CarrinhoVazio = () => {
    return (<>
        <div className='text-center mx-auto justify-content-center align-items-center w-100 col-md-12'>
            <div className='col-md-12 d-flex mx-auto justify-content-center align-items-center'>
                <img className="rounded-top mx-auto d-block image-fluid w-100" src={require('../../assets/cart.png')} alt='Carrinho vazio' style={{ maxHeight: '300px', maxWidth: '350px' }} />
            </div>
            <div className='col-md-12'>
                <small> <MdWarning color='yellow' style={{ scale: '2' }} /> Seu carrinho ainda está vazio conheça :</small>
            </div>
        </div>
        <BtnPulbicCarrinhoVazio />
        <div className='col-md-12 text-center mt-1'>
            <MaisInfoMenosInfo text={<CarrinhoVazioInfo />} />
        </div>
    </>);
};
export default CarrinhoVazio;
import React, { useState, useEffect } from "react";
import { BarraDeComandos } from "./components/BarraDeComandos";
import { ImagemPerfilUsuario } from "@components/layout";
import ImagemPerfilProfissional from "./components/ImagemPerflProfissional";
import ImagemPerfilCliente from "./components/ImagemPerfilCliente";
export const SiderHeader = (props) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isCollapsed, setIsCollapsed] = useState(screenWidth <= 767);
    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);
    const handleWindowResize = () => {
        setScreenWidth(window.innerWidth);
        setIsCollapsed(window.innerWidth <= 767);
    };
    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    const toggleSectionVisibility = () => {
        const sections = document.querySelectorAll("section");
        if (screenWidth <= 767) {
            sections.forEach(section => {
                section.style.display = section.style.display === "none" ? "block" : "none";
            });
        }
    };
    const handleButtonClick = () => {
        toggleCollapse();
        toggleSectionVisibility();
    };
    return (<>
        <div
            id="NavPaciente"
            className={`col-md-2 d-print-none ${isCollapsed ? "collapse" : "collapse show"} ${screenWidth >= 767 && 'mr-3'}`}
            style={{ backgroundColor: '#F0F0F0', paddingLeft: '0px', paddingRight: '8px', borderRadius: '0 0.25rem 0.25rem 0' }}
        >
            <ImagemPerfilCliente />
            <ImagemPerfilUsuario />
            <ImagemPerfilProfissional />
            <BarraDeComandos />
            {props.HTML}
            <nav className="navbar navbar-expand-md navbar-dark justify-content-center align-items-center text-white w-100 mb-5" >
                <button
                    className="btn btn-primary text-white floating-button-in"
                    onClick={handleButtonClick}
                >
                    <i id="CtrlBtnCollapseIcone" className={`icon-center fa ${!isCollapsed ? 'fa-angle-double-left' : 'fa-angle-double-right'}`}></i>
                </button>
            </nav>
        </div>
        {isCollapsed &&
            <div className="mr-1">
                <button
                    className="btn  btn-primary text-white floating-button"
                    onClick={handleButtonClick}                    >
                    <i id="CtrlBtnCollapseIcone" className={`icon-center fa ${!isCollapsed ? 'fa-angle-double-left' : 'fa-angle-double-right'}`}></i>
                </button>
            </div>
        }
    </>);
}
export default SiderHeader;
import React from 'react';
export const PerfilCadastroProfissionalInfo = () => {
  return (<>
    <div>
      <h5 className='font-weight-bold'>Apresentação dos Tipos de Perfis de Cadastro Profissional</h5>
      <p>Este documento descreve os diferentes tipos de perfis de cadastro profissional disponíveis em nossos sistemas. Cada perfil corresponde a um papel ou categoria específica, e o usuário pode escolher o perfil que melhor se adequa à sua atuação e necessidades.</p>
      <hr />
      <h5 className='font-weight-bold'>Particular</h5>
      <p>O perfil de cadastro particular é destinado a usuários que desejam criar um cadastro pessoal sem associação a uma instituição ou organização governamental. É adequado para aqueles que buscam utilizar os recursos particulares disponíveis na plataforma.</p>
      <ol className='ml-3'>
        <li className='font-weight-bold'>Clínica</li>
        <p>O perfil de clínica é voltado para clínicas de saúde ou estabelecimento similar. Ele permite a associação do usuário a uma organização de saúde e pode oferecer recursos específicos para a gestão clínica.</p>
        <li className='font-weight-bold'>Profissional Vinculado</li>
        <p>O perfil profissional vinculado é adequado para profissionais que estão vinculados a uma instituição ou empresa, mas não necessariamente uma clínica. Isso pode incluir acadêmicos, pesquisadores ou colaboradores de empresas.</p>
        <li className='font-weight-bold'>Profissional Autônomo</li>
        <p>O perfil de profissional autônomo é destinado a aqueles que exercem sua profissão de forma independente, sem vínculo com instituições. É uma opção para freelancers e empreendedores individuais.</p>
      </ol>
      <hr />
      <h5 className='font-weight-bold'>Governamental</h5>
      <p>O governamental é indicado para profissionais que trabalham em organizações governamentais ou setores públicos. Ele pode oferecer recursos específicos para atender às necessidades desse segmento.</p>
      <ol className='ml-3'>
        <li className='font-weight-bold'>por Localização</li>
        <p>Os perfis por localização são divididos em três níveis: país, estado e cidade. Eles permitem que profissionais indiquem sua localização geográfica, o que pode ser útil para estabelecer conexões locais e parcerias.</p>
        <li className='font-weight-bold'>Instituição</li>
        <p>O de instituição é voltado para organizações, empresas ou instituições de ensino. Ele permite que a instituição crie e gerencie perfis para seus membros e colaboradores.</p>
        <li className='font-weight-bold'>Servidor</li>
        <p>O de servidor é destinado a profissionais que trabalham em servidores ou data centers, lidando com infraestrutura tecnológica. Ele pode oferecer recursos específicos para essa área de atuação.</p>
      </ol>
      <hr />
      <h5 className='font-weight-bold'>Aspirante</h5>
      <p>O de aspirante é para aqueles que estão buscando entrar em uma determinada profissão ou área. É adequado para estudantes e indivíduos que desejam explorar e aprender mais sobre a área de interesse.</p>
      <ol className='ml-3'>
        <li className='font-weight-bold'>Aluno</li>
        <p>O de aluno é voltado para estudantes que estão matriculados em instituições educacionais. Ele permite que os alunos tenham acesso a recursos educacionais e interajam com professores e colegas.</p>
      </ol><br /><br /><br />
      <p>Escolha o que melhor representa sua atuação e necessidades profissionais. Lembre-se de que diferentes perfis podem oferecer recursos específicos para cada área, otimizando sua experiência em nossa plataforma.</p>
    </div>
  </>);
};
export default PerfilCadastroProfissionalInfo;
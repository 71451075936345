import React, { useContext, useState } from 'react';
import { AuthContext } from '@guard';
import { baseURL } from '@services';
import { ReferenciaLocal, BreadcrumbAnt, InputPadraoTitle, ProgressBar } from '@components';
export const CadastraEquipamentos = () => {
    const {user}= useContext(AuthContext);
    const [cadastroSucesso, setCadastroSucesso] = useState(false);
    const [equipamentoInfo, setEquipamentoInfo] = useState({
        user_id: user.id,
        nome: '',
        tipo: '',
        marca: '',
        numeroSerie: '',
    });
    const [progress, setProgress] = useState(0); // Estado para controlar o progresso
    const handleInfoChange = (event) => {
        const { name, value } = event.target;
        setEquipamentoInfo((prevInfo) => ({
            ...prevInfo,
            [name]: value,
        }));
        // Atualizar o progresso a cada campo preenchido
        const filledFields = Object.values(equipamentoInfo).filter((field) => field !== '');
        const newProgress = (filledFields.length / 5) * 100; // 5 campos no total
        setProgress(newProgress);
    };
    const cadastrarEquipamento = async () => {
        try {
            const response = await fetch(`${baseURL}cadastraEquipamentos`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(equipamentoInfo),
            });
            if (!response.ok) {
                throw new Error('Erro ao cadastrar equipamento');
            }
            alert('Equipamento cadastrado com sucesso!');
            setCadastroSucesso(true);
            // Limpar os campos após o cadastro
            setEquipamentoInfo({
                nome: '',
                tipo: '',
                marca: '',
                numeroSerie: '',
            });
            setProgress(0); // Reiniciar o progresso
            window.location.reload();
        } catch (error) {
            console.error('Erro ao cadastrar equipamento:', error);
            alert('Erro ao cadastrar equipamento. Verifique o console para mais informações.');
        }
    };
    return (
        <>
            <BreadcrumbAnt icon={'book fa'} text={'I.A - Texto'} />
            <ReferenciaLocal text={'Cadastro de Equipamento'} />
            <div className='py-5 text-center'>
                {cadastroSucesso && <p className="text-success">Equipamento cadastrado com sucesso!</p>}
                {/* Adicione o componente InputProgressBar aqui e passe o progresso como prop */}
                <ProgressBar progress={progress} />
                <div className="form-group">
                    <InputPadraoTitle title={'Nome do Equipamento'}></InputPadraoTitle>
                    <input
                        className="form-control text-center"
                        type='text'
                        name="nome"
                        value={equipamentoInfo.nome}
                        onChange={handleInfoChange}
                    />
                </div>
                <div className="form-group">
                    <InputPadraoTitle title={'Tipo de Equipamento'}></InputPadraoTitle>
                    <input
                        className="form-control text-center"
                        type='text'
                        name="tipo"
                        value={equipamentoInfo.tipo}
                        onChange={handleInfoChange}
                    />
                </div>
                <div className="form-group">
                    <InputPadraoTitle title={'Marca'}></InputPadraoTitle>
                    <input
                        className="form-control text-center"
                        type='text'
                        name="marca"
                        value={equipamentoInfo.marca}
                        onChange={handleInfoChange}
                    />
                </div>
                <div className="form-group">
                    <InputPadraoTitle title={'Número de Série'}></InputPadraoTitle>
                    <input
                        className="form-control text-center"
                        type='text'
                        name="numeroSerie"
                        value={equipamentoInfo.numeroSerie}
                        onChange={handleInfoChange}
                    />
                </div>
                <button onClick={cadastrarEquipamento} className="btn btn-primary text-center">Cadastrar</button>
            </div>
        </>
    );
}
export default CadastraEquipamentos;
import { MdBookMD } from "@components/icons";
import { Link } from "react-router-dom";
export const CardFragmentIdentifier = (props) => {
    const clicarNoId = () => {
        const elementoClicado = document.getElementById(props.href);
        if (elementoClicado) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            elementoClicado.click();
        }
    };
    return (<>
        <div className="col-md-3 d-flex" style={{padding:'8px'}} onClick={clicarNoId}>
            <div className="col-md-12 mt-3 border rounded bg-white text-center  py-3 no-drag" >
                <Link onClick={clicarNoId}> {props.icon}
                    <h5 className="mt-3 font-weight-bold"><strong>{props.title}</strong></h5>
                    <h5 className="mt-3 text-justify">{props.text} ...</h5>
                    <h3 className="font-weight-bold mt-3 "><MdBookMD/></h3>
                </Link>
            </div>
        </div>
    </>)
}
export default CardFragmentIdentifier;
import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { v4 as uuidv4 } from 'uuid';
import { apiToken } from '@services';
import { ButtonCadastrar, TitleL1,  } from '@components';
import { MdTroubleshoot } from "react-icons/md";
import '../styles/NossosNumeros.css';
export const NossosNumeros = () => {
    const [analyticsData, setAnalyticsData] = useState(null);
    const [totalUniqueIPs, setTotalUniqueIPs] = useState(0);
    const [totalUser, setTotalUser] = useState(0);
    const [totalProfessional, setTotalProfessional] = useState(0);
    const chartRef = useRef(null);
    const bubbleChartRef = useRef(null);
    const chartInstance = useRef(null);
    const bubbleChartInstance = useRef(null);
    useEffect(() => {
        // Fetching data for analytics and totals
        apiToken().get('get_analytics')
            .then(response => {
                setAnalyticsData(response.data);
            })
            .catch(error => {
                console.error('Error fetching analytics data:', error);
            });
        apiToken().get('get_analytics_total_unique_ips')
            .then(response => {
                setTotalUniqueIPs(response.data.total_unique_ips);
            })
            .catch(error => {
                console.error('Error fetching total unique IPs:', error);
            });
        apiToken().get('get_user_total')
            .then(response => {
                setTotalUser(response.data.totalUsers);
            })
            .catch(error => {
                console.error('Error fetching total users:', error);
            });
        apiToken().get('get_professional_total')
            .then(response => {
                setTotalProfessional(response.data.totalProfessional);
            })
            .catch(error => {
                console.error('Error fetching total professionals:', error);
            });
    }, []);
    useEffect(() => {
        const renderCharts = () => {
            if (!analyticsData) return;
            const pagesToShow = [
                '', 'servicos', 'produtos', 'artigos', 'parcerias',
                'sobre', 'carrinho', 'login', 'cadastrar', 'usuario', 'profissionais',
            ];
            const filteredTotalVisits = analyticsData.total_visits
                .filter(data => pagesToShow.includes(data.page_group))
                .sort((a, b) => pagesToShow.indexOf(a.page_group) - pagesToShow.indexOf(b.page_group));
            const filteredPageActions = analyticsData.page_actions.filter(data => pagesToShow.includes(data.page_group));
            const totalVisitsData = filteredTotalVisits.map(data => data.total_visits);
            const pageGroupLabels = filteredTotalVisits.map(data => data.page_group);
            const totalActionsData = pageGroupLabels.map(pageGroup => {
                return filteredPageActions
                    .filter(data => data.page_group === pageGroup && data.type_action !== 'page-load')
                    .reduce((acc, data) => acc + data.total_actions, 0);
            });
            // Bubble Chart Data (Visitas, Ações, Usuários e Profissionais)
            const maxBubbles = 10;  // Limitando o número de bolhas
            const bubbleData = totalVisitsData.slice(0, maxBubbles).map((visit, index) => ({
                x: visit,
                y: totalActionsData[index],
                r: Math.min((totalActionsData[index] / 10) + 5, 25), // Limitar o tamanho das bolhas
                users: totalUser,
                professionals: totalProfessional,
            }));
            // Bubble Chart Data (Visitas, Ações, Usuários e Profissionais)
            const bubbleChartData = {
                datasets: [
                    {
                        label: 'Conversões',
                        data: bubbleData,
                        backgroundColor: '#4CAF50',
                    }
                ]
            };
            // Bar Chart Data (Total de Visitas e Ações)
            const chartData = {
                labels: pageGroupLabels,
                datasets: [
                    {
                        label: 'Total de Visitas',
                        data: totalVisitsData,
                        backgroundColor: '#334B90',
                    },
                    {
                        label: 'Total de Ações',
                        data: totalActionsData,
                        backgroundColor: '#4CAF50',
                    }
                ]
            };
            // Bar Chart Rendering
            const ctx = chartRef.current.getContext('2d');
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
            chartInstance.current = new Chart(ctx, {
                type: 'bar',
                data: chartData,
                options: {
                    scales: {
                        x: { stacked: true },
                        y: { stacked: true }
                    }
                }
            });
            // Bubble Chart Rendering
            const bubbleCtx = bubbleChartRef.current.getContext('2d');
            if (bubbleChartInstance.current) {
                bubbleChartInstance.current.destroy();
            }
            bubbleChartInstance.current = new Chart(bubbleCtx, {
                type: 'bubble',
                data: bubbleChartData,
                options: {
                    responsive: true,
                    scales: {
                        x: {
                            title: { text: 'Data', display: true }
                        },
                        y: {
                            title: { text: 'Conversões', display: true }
                        }
                    },
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function(tooltipItem) {
                                    const dataPoint = tooltipItem.raw;
                                    return `Visitas: ${dataPoint.x}, Ações: ${dataPoint.y}, Tamanho: ${dataPoint.r}, 
                                            Usuários: ${dataPoint.users}, Profissionais: ${dataPoint.professionals}`;
                                }
                            }
                        }
                    }
                }
            });
        };
        renderCharts();
    }, [analyticsData]);
    return (
        <section style={{ minHeight: '95vh' }}>
            <div className="row">
                <div className="col-md-6">
                    <TitleL1 icon={<MdTroubleshoot style={{ scale: '2' }} />}>Nossos Números</TitleL1>
                    <h5 className='text-justify mb-5'>
                        Nossos números são uma representação essencial do desempenho e da atividade interna em nossa plataforma.
                        Eles capturam uma variedade de métricas que nos ajudam a entender melhor como nossa plataforma está sendo
                        utilizada e quais áreas estão prosperando. No entanto, mais do que simples estatísticas, esses números também têm um
                        significado pessoal e individual. Cada usuário, seja um profissional autônomo ou uma empresa estabelecida, pode conferir seus próprios resultados ao entrar na plataforma.
                        Isso oferece uma oportunidade única para os usuários acompanharem seu próprio progresso, identificarem áreas de melhoria e
                        tomarem decisões informadas com base nos dados disponíveis <br /><br />
                        Portanto, nossos números não são apenas uma visão abstrata do desempenho geral da plataforma,
                        mas sim uma ferramenta poderosa para capacitar e informar cada indivíduo que faz parte de nossa comunidade
                    </h5>
                </div>
                <div className="col-md-6">
                    <canvas ref={chartRef} id={`chart-bar-${uuidv4()}`}></canvas>
                    <small className=" mt-1 d-block text-justify">
                       Visitas por páginas - URI  [1] significa Identificador Uniforme de Recursos. É uma string de caracteres usada para identificar um recurso na Internet. Por exemplo, o endereço de um site é uma URI, como <b className='font-weight-bold '>https://www.thotiacorp.com.br</b>.
                        A URI -[1] inclui um caminho adicional após a barra, como <b className='font-weight-bold ' >https://www.thotiacorp.com.br/servicos</b>, que especifica uma página recurso específico dentro do site
                    </small>
                    <canvas ref={bubbleChartRef} id={`chart-bubble-${uuidv4()}`}></canvas>
                </div>
            </div>
            <div className="text-center py-3 mt-2 border" style={{ borderRadius: '33px', position: 'relative' }}>
                <div className="row">
                    <div className="col-md-4 mt-1">
                        <div className="nossos-numeros py-2">
                            <small className="font-weight-bold"><b>IP Único - {totalUniqueIPs}</b></small>
                        </div>
                    </div>
                    <div className="col-md-4 mt-1">
                        <div className="nossos-numeros py-2"><small className="font-weight-bold"><b>Usuários - {totalUser}</b></small></div>
                    </div>
                    <div className="col-md-4 mt-1">
                        <div className="nossos-numeros py-2">
                            <small className="font-weight-bold"><b>Profissionais - {totalProfessional}</b></small>
                        </div>
                    </div>
                </div>
            </div>
            <small className="text-justify mt-1  mr-1 ml-1 d-block  text-dark"><b>Perspectiva Geral: </b>
                Em nosso sistema, exibimos apenas os usuários e profissionais ativos na plataforma. Isso garante que você interaja com aqueles que estão realmente comprometidos e engajados, proporcionando uma experiência mais dinâmica e relevante. Manter um foco nos membros ativos nos permite otimizar os recursos e oferecer suporte mais eficiente, garantindo que as interações e colaborações sejam sempre com indivíduos que estão plenamente envolvidos. Nossa plataforma está projetada para destacar os usuários e profissionais mais dedicados, aqueles que estão constantemente contribuindo e aprimorando suas habilidades. Ao se concentrar nos membros ativos, você tem a certeza de estar conectado com os mais engajados e atualizados participantes da nossa comunidade, o que pode levar a oportunidades de networking, aprendizado e crescimento profissional muito mais significativas.
            </small>
            <ButtonCadastrar />
        </section>
    );
};
export default NossosNumeros;
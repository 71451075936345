import React, { useState, useEffect } from 'react';
import { Layout, Form, Input, Button, List, Tooltip, Image, Card } from 'antd';
import { LikeOutlined, LikeFilled } from '@ant-design/icons';
import { apiToken } from 'services';
import { useUser } from '@context/components/UserProvider';
const { Content, Footer } = Layout;
export const SocialFeed = () => {
  const [messages, setMessages] = useState([]);
  const [content, setContent] = useState('');
  const [replyTo, setReplyTo] = useState(null);
  const [commentContents, setCommentContents] = useState({});
 const { user } = useUser()
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await apiToken().get(`get_social_feed/${user.id}`);
        setMessages(response.data);
      } catch (error) {
        console.error("Failed to fetch messages", error);
      }
    };
    fetchMessages();
  }, [user.id]);
  const sendMessage = async () => {
    try {
      await apiToken().post(`post_social_feed/${user.id}`, {
        content,
        replyTo,
      });
      setContent('');
      setReplyTo(null);
      const response = await apiToken().get(`get_social_feed/${user.id}`);
      setMessages(response.data);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        alert("Conteúdo inapropriado detectado. Por favor, remova palavrões.");
      } else {
        alert("Failed to send message", error);
      }
    }
  };
  const likeMessage = async (id) => {
    try {
      await apiToken().post(`post_social_feed/${id}/like/${user.id}`);
      const response = await apiToken().get(`get_social_feed/${user.id}`);
      setMessages(response.data);
    } catch (error) {
      console.error("Failed to like message", error);
    }
  };
  const addComment = async (id, commentContent) => {
    try {
      await apiToken().post(`post_social_feed/comment/${user.id}/${id}`, { commentContent });
      const response = await apiToken().get(`get_social_feed/${user.id}`);
      setMessages(response.data);
      setCommentContents(prevState => ({ ...prevState, [id]: '' })); // Limpa o campo de comentário após o envio
    } catch (error) {
      console.error("Failed to add comment", error);
    }
  };
  const handleCommentChange = (id, value) => {
    setCommentContents(prevState => ({ ...prevState, [id]: value }));
  };
  return (
    <Layout style={{ background: 'none' }}>
      <Content style={{ padding: '0 0px', flex: 1, background: 'none' }}>
        <List
          style={{ background: 'none' }}
          dataSource={messages}
          renderItem={(msg) => (
            <List.Item key={msg.id} style={{ border: 'none', background: 'none' }}>
              <Card style={{ width: '100%', background: 'none' }}>
                <Card.Meta
                  avatar={<Image style={{ borderRadius: '50%' }} preview={false} height={50} width={50} src={msg.image_user} />}
                  title={msg.sender_username}
                  description={msg.content}
                />
                <div style={{ marginTop: 8 }}>
                  <Tooltip title="Like">
                    <Button
                      type="link"
                      icon={msg.likes > 0 ? <LikeFilled /> : <LikeOutlined />}
                      onClick={() => likeMessage(msg.id)}
                    >
                      {msg.likes} Likes
                    </Button>
                  </Tooltip>
                  <Button type="link" onClick={() => setReplyTo(msg)}>
                    Reply
                  </Button>
                </div>
                <div style={{ marginTop: 8, background: 'none' }}>
                  <Form
                    onFinish={() => {
                      addComment(msg.id, commentContents[msg.id]);
                    }}
                    style={{ background: 'none' }}
                  >
                    <Form.Item>
                      <Input
                        value={commentContents[msg.id] || ''}
                        onChange={(e) => handleCommentChange(msg.id, e.target.value)}
                        placeholder="Add a comment"
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">Comment</Button>
                    </Form.Item>
                  </Form>
                  {msg.comments && msg.comments.length > 0 && (
                    <List
                      style={{ background: 'none' }}
                      dataSource={msg.comments}
                      renderItem={(comment, index) => (
                        <List.Item key={index} style={{ border: 'none', background: 'none' }}>
                          <List.Item.Meta description={comment.content} />
                        </List.Item>
                      )}
                    />
                  )}
                </div>
              </Card>
            </List.Item>
          )}
        />
      </Content>
      <Footer style={{ position: 'sticky', bottom: 0, width: '100%', padding: '10px 20px', background: 'none' }}>
        {replyTo && (
          <div style={{ marginBottom: '10px' }}>
            Replying to: {replyTo.sender_username} - "{replyTo.content}"
            <Button type="link" onClick={() => setReplyTo(null)}>Cancel</Button>
          </div>
        )}
        <Form layout="inline" onFinish={sendMessage} style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Form.Item style={{ flex: 1, marginRight: '10px' }}>
            <Input
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="Postar - [ T-Feed ] ツ"
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Send</Button>
          </Form.Item>
        </Form>
      </Footer>
    </Layout>
  );
};
export default SocialFeed;
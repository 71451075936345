import { post_user_login_renew } from "@pages/Public/Login/components/services/post_user_login_renew";
import { apiToken } from "services";
export const handleDeletedProfessional = async ({item,
  user,
  setAuthenticatedProfissional,
  updateProfessionalData,
  clearProfessionalData,
  updateProfissional}) => {
  if (!item) return; // ❌ Se não tiver item.id, não chama a API
  const confirmMessage = 'Você irá excluir todos os vínculos, sendo necessários reestabelecer novamente. Tem certeza?';
  if (!window.confirm(confirmMessage)) {
    return;
  }
  try {
    const response = await apiToken().put(`put_professional/${item}`, {
      state: "inactive"
    });
    if (response.status === 200) {
      await post_user_login_renew({
        id: user.id,
        setAuthenticatedProfissional,
        updateProfessionalData,
        clearProfessionalData,
        updateProfissional
      })
    }
  } catch (error) {
    console.error("Erro ao atualizar o estado do profissional:", error);
    alert("Erro ao atualizar o estado do profissional. Verifique o console para mais detalhes.");
  }
};
import { FooterProfissional, NavProfissionalMain } from "@components";
import TextIAMain from './components/TextIAMain';
import { BreadcrumbAnt, HeaderAnt } from "@components/layout";
export const TextIA = () => {
    return (<>
        <HeaderAnt />
        <div className="d-flex mt-3" style={{ overflowX: 'hidden' }}>
            <NavProfissionalMain />
            <section className="w-100">
                <div className="w-100">
                    <TextIAMain />
                    <FooterProfissional />
                </div>
            </section>
        </div>
    </>)
};
import { BreadcrumbAnt, CardDirecionamento } from "components";
import { MdAirlineSeatIndividualSuiteMD, MdCloudMD } from "@components/icons";
export const Index = () => {
  return (<>
    <div className="w-100 ">
      <BreadcrumbAnt text={"Minhas Consultas"} icon={"hand-paper-o fa"} />
      <div className="py-3 ">
        <div className="text-center">
          <div className="container-fluid">
            <div className="row d-inline-flex justify-content-center w-100">
              <CardDirecionamento
                text={"Ativos"}
                icon={< MdAirlineSeatIndividualSuiteMD size={50} />}
                href={"./ativos"}
              />
              <CardDirecionamento
                text={"Armazenados"}
                icon={<MdCloudMD size={50} />}
                href={"./armazenados"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
};
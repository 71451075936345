import { Index } from "./Index";
import { FooterUser, HeaderAnt, SiderUserMain } from "components";
export const ProdutosComprarUser = () => {
    return (<>
        <HeaderAnt />
        <div className="d-flex mt-3" style={{ overflowX: 'hidden' }}>
            <SiderUserMain />
            <section className="w-100">
                <Index />
                <FooterUser />
            </section>
        </div>
    </>)
}
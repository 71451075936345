import { Index } from "./Index";
import { FooterProfissional,HeaderAnt, NavProfissionalMain } from "@components";
export const ProfissionaisEditarServicos = () => {
    return(<>
    <HeaderAnt/>
    <div className="d-flex mt-3">
        <NavProfissionalMain />
        <div className="w-100">
            <Index/>            
            <FooterProfissional/>
        </div>        
    </div>
    </>)
}
import { CardDirecionamento, BreadcrumbAnt, MdMonetizationOnMD, MdCloudMD, MdAddCircleOutlineMD, MdEditMD } from 'components';
export const Index = () => {
    return (<>
        <div className='w-100'>
            <BreadcrumbAnt icon={'suitcase fa'} text={'Serviços'} />
            <div className="row d-inline-flex justify-content-center w-100">
                <CardDirecionamento text={'Vender'} icon={<MdMonetizationOnMD size={50} />} href={'../../../profissionais/vendas/?vender=servicos'} />
                <CardDirecionamento text={'Historico'} icon={<MdCloudMD size={50} />} href={'../../../profissionais/servicos/historico'} />
                <CardDirecionamento text={'Cadastrar'} icon={<MdAddCircleOutlineMD size={50} />} href={'../../../profissionais/servicos/cadastrar'} />
                <CardDirecionamento text={'Editar'} icon={<MdEditMD size={50} />} href={'../../../profissionais/servicos/editar'} />
            </div>
        </div>
    </>)
}
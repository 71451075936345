import React, { useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { ConfiguracoesAvisoList } from './ConfiguracoesAvisoList';
import ClassesIAImagem from './ClassesIAImagem';
import TestarConfiguracoesAviso from './TestarConfiguracoesAviso';
import BuscarIA from './BuscarIA';
import { baseURL } from '@services';
import IADiagnoticoInteracao from './InterecaoIAImagem';
import CommentSectionIA from '../../../global/CommentSectionIA';
import { ReferenciaLocal, } from '@components';
import AvisoIA from './AvisoIA';
import VendasIAImagem from './VendasIAImagem';
import IframeIAImagem from './IframeIAImagem';
import ShareAI from '../../../global/ShareAI';
import NomePatologias from './NomePatologias';
export const MainImagemIA = () => {
  const [modules, setModules] = useState([]);
  const [newModuleName, setNewModuleName] = useState('');
  const [centralFolderName, setCentralFolderName] = useState('');
  const [configuracoesAviso, setConfiguracoesAviso] = useState([]);
  const [filtro, setFiltro] = useState('');
  const videoRef = useRef(null);
  const [nameIAText, setNameIAText] = useState('');
  const [capturedImages, setCapturedImages] = useState([]);
  const startCamera = useCallback(async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error('API de captura de mídia não suportada');
      return;
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
      videoRef.current.onloadedmetadata = () => {
        videoRef.current.play();
      };
    } catch (error) {
      console.error('Erro ao ligar a câmera:', error);
    }
  }, [videoRef]);
  const stopCamera = useCallback(() => {
    const stream = videoRef.current?.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  }, [videoRef]);
  const handleAddModule = () => {
    if (newModuleName.trim() === '') {
      return;
    }
    const newModule = {
      name: newModuleName,
      capturedImages: [],
      configuracoes: []
    };
    setModules((prevModules) => [...prevModules, newModule]);
    setNewModuleName('');
  };
  const captureImage = async (moduleName, configuracoes) => {
    const video = videoRef.current;
    if (!video || !video.videoWidth || !video.videoHeight) {
      return null;
    }
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const currentDateTime = new Date();
    const formattedDateTime = currentDateTime.toISOString().replace(/:/g, "_").replace(/\.\d{3}/, ""); // Format the date and time
    const milliseconds = currentDateTime.getMilliseconds();
    const filename = `${moduleName}_${formattedDateTime}_${milliseconds}_image.png`;
    const base64Data = canvas.toDataURL('image/png');
    const file = dataURLtoFile(base64Data, filename);
    setModules((prevModules) =>
      prevModules.map((module) => {
        if (module.name === moduleName) {
          return {
            ...module,
            capturedImages: [...module.capturedImages, { file, previewURL: URL.createObjectURL(file) }],
            configuracoes: configuracoes
          };
        }
        return module;
      })
    );
  };
  const dataURLtoFile = (dataURL, filename) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };
  console.log('modules', modules);  // Verifique a estrutura aqui antes de chamar o alert.
  console.log('capturedImages', capturedImages);  // Verifique a estrutura aqui antes de chamar o alert.
  // console.log(capturedImages);  // Verifique a estrutura aqui antes de chamar o alert.
  // alert(JSON.stringify(capturedImages));
  // Função principal de salvamento
  const saveAllInformation = async () => {
    try {
      const formData = new FormData();
      formData.append('centralFolderName', centralFolderName);
      // Preparando os nomes dos módulos
      const moduleNamesArray = modules.map((module) => module.name);
      formData.append('moduleNames', JSON.stringify(moduleNamesArray));
      // Adicionando imagens dos módulos
      modules.forEach((module) => {
        module.capturedImages.forEach((image) => {
          formData.append('images', image.file);
        });
      });
      Object.keys(capturedImages).forEach((moduleName) => {
        capturedImages[moduleName].forEach((image) => {
          // Verifica se 'image.file' é realmente um objeto File
          if (image.file instanceof File) {
            // Cria o novo nome do arquivo concatenando o nome do módulo e o nome da imagem
            const newFileName = `${moduleName}_${image.file.name}`;
            // Cria um novo File com o novo nome
            const newFile = new File([image.file], newFileName, { type: image.file.type });
            // Adiciona o arquivo modificado ao FormData
            formData.append('images', newFile);
          }
        });
      });
      // Adicionando as imagens capturadas (capturedImages)
      // Enviando os dados para o backend
      const response = await axios.post(`http://localhost:3030/api/save-all-information`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      await treinarIAImagem(); // Treina a IA após o envio bem-sucedido
      alert('Resposta do treinamento da IA:', response.data.message);
    } catch (error) {
      alert('Erro ao enviar informações para o backend:', error);
    }
  };
  const treinarIAImagem = async () => {
    try {
      const response = await axios.post(`${baseURL}treinarIAImagem`, {
        centralPath: centralFolderName,
      });
      alert('Resposta do treinamento da IA:', response.data.message);
    } catch (error) {
      alert('Erro ao treinar a IA:', error);
    }
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleAddModule();
    }
  };
  const [draggedItem, setDraggedItem] = useState(null);
  const handleDragOver = (e) => {
    e.preventDefault(); // Permite o drop
  };
  const handleDragStart = (moduleName, item) => {
    setDraggedItem({ moduleName, item });
  };
  const handleAddImagesToModule = (moduleName, newImages) => {
    setModules((prevModules) =>
      prevModules.map((module) => {
        if (module.name === moduleName) {
          return {
            ...module,
            capturedImages: [...module.capturedImages, ...newImages], // Adiciona as novas imagens
          };
        }
        return module;
      })
    );
  };
  const handleDrop = (moduleName, e) => {
    e.preventDefault();
    if (draggedItem) {
      // Adiciona a imagem arrastada ao módulo
      handleAddImagesToModule(moduleName, [draggedItem]);
      setDraggedItem(null); // Limpa o item arrastado
    }
  };
  return (<>
    <ReferenciaLocal text={'Interagir'} />
    <IADiagnoticoInteracao centralFolderName={centralFolderName} />
    <ReferenciaLocal text={'Procurar e Compartilhar'} />
    <div className="row justify-content-between">
      <div className={`col-md-6 d-flex py-3 `}      >
        <div className='bg-light  w-100'>
          <BuscarIA setCentralFolderName={setCentralFolderName} />
        </div>    </div>
      <div className={`col-md-6  py-3 `}      >
        <div className='bg-light'>
          <ShareAI nameIAText={nameIAText} setNameIAText={setNameIAText} />
        </div></div>
      <div
        className={`col-md-12 py-3 `}
        style={{ cursor: "pointer" }}
      >
        {centralFolderName && <CommentSectionIA />}
      </div>
    </div>
    <ReferenciaLocal text={'Criar e Treinar I.A'} />
    <div className="col-md-12 ">
      <div className="d-flex flex-wrap justify-content-center">
        <div
          className={`col-md-12 mt-3 d-flex`}
          style={{ cursor: "pointer" }}
        >
          <div className='col-md-12 mt-3 mx-auto mx-auto justify-content-center  mb-3 d-flex'>
            <div className="col-md-6 mt-2 border bg bg-light rounded " style={{
              backgroundColor: '#f1f1f1',
              padding: '5px',
              marginLeft: '5px',
              marginRight: "5px",
            }}>
              <div className="col-md-12 mt-2">
                <div className="add-module col-md-12">
                  <div className="generate-container">
                    <div className="input-container mt-3">
                      <input
                        className={`input-field form-control mt-1 form-control-lg text-dark text-center`}
                        type="text"
                        placeholder="Adicionar Classe da I.A."
                        value={newModuleName}
                        onChange={(event) => setNewModuleName(event.target.value)}
                        onKeyDown={handleEnterKeyPress}
                      />
                      <button className={` ${newModuleName && 'success'
                        } generate-button`} onClick={handleAddModule}>
                        <i className={` ${newModuleName && 'input-filled'
                          } fa fa-rocket mt-2`}></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {modules.map((module, index) => (
                <ClassesIAImagem
                  key={index}
                  name={module.name}
                  moduleName={module.name}
                  captureImage={(moduleName) => captureImage(moduleName)}
                  videoRef={videoRef}
                  onDragStart={() => handleDragStart(module.name, module)} // Alterado para passar o módulo inteiro
                  handleAddImagesToModule={() => handleAddImagesToModule(module.name, module.capturedImages)} // Certifique-se de passar as imagens
                  onDragOver={handleDragOver} // Permite o "drag over"
                  onDrop={(e) => handleDrop(module.name, e)} // Permite o "drop"
                  capturedImages={capturedImages}
                  setCapturedImages={setCapturedImages}
                  configuracoes={module.configuracoes}
                  startCamera={startCamera}
                  stopCamera={stopCamera}
                />
              ))}
            </div>
            <div className="col-md-6 mt-2 border bg bg-light  justify-content-center align-items-center rounded" style={{
              backgroundColor: '#f1f1f1',
              padding: '5px',
              marginLeft: '5px',
              marginRight: "5px",
            }}>
              <div className='col-md-12 mt-2 mx-auto text-center justify-content-center align-items-center '>
                <div className="generate-container">
                  <div className="input-container mt-3">
                    <input
                      className={`input-field form-control mt-1 form-control-lg text-dark text-center`}
                      type="text"
                      placeholder="Digite o nome da I.A."
                      value={centralFolderName}
                      onChange={(event) => {
                        setCentralFolderName(event.target.value);
                        setFiltro(event.target.value);
                      }}
                      onKeyDown={handleEnterKeyPress}
                    />
                    <button className={` ${newModuleName && 'success'
                      } generate-button`} onClick={handleAddModule}>
                      <i className={` ${newModuleName && 'input-filled'
                        } fa fa-rocket mt-2`}></i>
                    </button>
                  </div>
                </div>
                <NomePatologias setCentralFolderName={setCentralFolderName} filtro={filtro} setFiltro={setFiltro} />
              </div>
              <div className='col-md-12  text-center py-2'>
                <button className="add-module-button btn btn-primary" onClick={saveAllInformation}>
                  Criar e Treinar a I.A.
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ReferenciaLocal text={'Configurações de aviso'} />
    {!centralFolderName && <AvisoIA />}
    {centralFolderName &&
      <div className="col-md-12 mx-auto py-2 d-flex">
        <div className=" flex-wrap  d-flex">
          <div className="col-md-12 d-flex flex-column align-items-stretch border bg bg-light rounded" style={{
            backgroundColor: '#f1f1f1',
            padding: '5px',
            marginLeft: '5px',
            marginRight: "5px",
          }}>
            <ConfiguracoesAvisoList
              configuracoesAviso={configuracoesAviso}
              setConfiguracoesAviso={setConfiguracoesAviso}
              moduleNames={modules.map((module) => module.name)}
            />
          </div>
          <div className="col-md-12 mt-4 d-flex flex-column align-items-stretch border bg bg-light rounded" style={{
            backgroundColor: '#f1f1f1',
            padding: '5px',
            marginLeft: '5px',
            marginRight: "5px",
          }}>
            <TestarConfiguracoesAviso />
          </div>
        </div>
      </div>
    }
    <ReferenciaLocal text={'Oferte essa página no seu sistema'} />
    <IframeIAImagem centralFolderName={centralFolderName} />
    <ReferenciaLocal text={'Venda de serviços de I.A.'} />
    <VendasIAImagem />
  </>);
};
export default MainImagemIA;
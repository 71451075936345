import { apiToken } from "services";
export const post_user_login_renew = async ({
    id,
    setAuthenticatedProfissional,
    updateProfessionalData,
    clearProfessionalData,
    updateProfissional
}) => {
    try {
        const response = await apiToken().post(`post_user_login_renew/${id}`);
        const { newToken, user_type, professionalData } = response.data;
        localStorage.setItem("token", newToken);
        if (user_type === "profissional") {
            setAuthenticatedProfissional(true)
            updateProfessionalData(professionalData);
            updateProfissional(professionalData.professionalData[0])
        } else {
            clearProfessionalData();
        }
    } catch (error) {
        console.error("Erro no login:", error.response ? error.response.data : error);
        alert("Erro no login");
    }
};
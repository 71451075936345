import React from 'react';
import { ButtonCadastrar, CardVerde, TitleL1 } from 'components';
import {
    MdHistoryEdu,
    MdHandshake,
    MdCheckCircle,
    MdFlag,
    MdVisibility,
    MdArchitecture 
} from "react-icons/md";
import '../styles/TitleL1.css'
import InnovationalConducts from './InnovationalConducts';
import FinancialEssentials from './FinancialEssentials';
import MissionStatement from './MissionStatement';
import VisionCards from './VisionCards';
import { FaResearchgateFA, MdAccountCircleMD, MdAddShoppingCartMD, MdHomeMD, MdOutlinePersonAddAltMD, MdRoomServiceMD, MdWidgetsMD } from '@components/icons';
export const Condutas = () => {
    return (<>
        <section style={{ minHeight: '95dvh' }}>
               <TitleL1 icon={<MdArchitecture  style={{ scale: '2' }} />}>
                          Condutas
                        </TitleL1>
            <InnovationalConducts />
            <div className="row">
                <div style={{
                    padding: '5px',
                }} className="col-md-4  mt-3 align-items-center justify-content-center d-flex">
                    <CardVerde
                        title={'OBJETIVO'}
                        text={'Ser financeiramente essencial'}
                        icon={<MdCheckCircle size={30} color='white' />}
                        icon_one={<MdHomeMD />}
                        icon_one_data_name={'Home'}
                        href_icon_one={'../'}
                        icon_two={<MdRoomServiceMD />}
                        icon_two_data_name={'Serviços'}
                        href_icon_two={'../servicos'}
                        icon_tre={<MdWidgetsMD />}
                        icon_tre_data_name={'Produtos'}
                        href_icon_tre={'../produtos'}
                    />
                </div>
                <div style={{
                    padding: '5px',
                }} className="col-md-4  mt-3 align-items-center justify-content-center d-flex">
                    <CardVerde title={'MISSÃO'} text={'Fazer o bem sempre'}
                        icon={<MdFlag size={30} color='white' />}
                        icon_one={<FaResearchgateFA/>}
                        icon_one_data_name={'Artigos'}
                        href_icon_one={'../artigos'}
                        icon_two={<MdHandshake />}
                        icon_two_data_name={'Parcerias'}
                        href_icon_two={'../parcerias'}
                        icon_tre={<MdHistoryEdu />}
                        icon_tre_data_name={'Sobre'}
                        href_icon_tre={'../sobre'}
                    />
                </div>
                <div style={{
                    padding: '5px',
                }} className="col-md-4  mt-3 align-items-center justify-content-center d-flex">
                    <CardVerde title={'VISÃO'} text={'Aprimoramento contínuo'}
                        icon={<MdVisibility size={30} color='white' />}
                        icon_one={<MdAddShoppingCartMD />}
                        icon_one_data_name={'Carrinho'}
                        href_icon_one={'../carrinho'}
                        icon_two={<MdAccountCircleMD />}
                        icon_two_data_name={'Login'}
                        href_icon_two={'../login'}
                        icon_tre={<MdOutlinePersonAddAltMD />}
                        icon_tre_data_name={'Cadastrar'}
                        href_icon_tre={'../cadastrar'}
                    />
                </div>
            </div>
            <FinancialEssentials />
            <MissionStatement />
            <VisionCards />
            <br /><br /><br />
            <ButtonCadastrar />
        </section>
    </>);
};
export default Condutas;
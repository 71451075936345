import { login } from "@pages/Public/Login/components/functions/login";
import { post_user } from "../services/post_user";
export const cadastrar = async (
  email,
  name,
  password,
  cpf,
  address,
  phone,
  dataNascimento,
  user_type,
  convite_code,
  setUser,
  setAuthenticated,
  setLoading,
  setAuthenticatedProfissional,
  updateProfessionalData,
  clearProfessionalData,
  updateProfissional,
  navigate
) => {
  const response = await post_user(
    email,
    name,
    password,
    cpf,
    address,
    phone,
    dataNascimento,
    user_type,
    convite_code
  );
  if (response.status === 200) {
    await login(
      email,
      password,
      setUser,
      setAuthenticated,
      setLoading,
      setAuthenticatedProfissional,
      updateProfessionalData,
      clearProfessionalData,
      updateProfissional,
      navigate);
  } else {
    console.error('Erro no registro:', response.statusText);
  }
};
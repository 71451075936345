import { useState } from "react";
import { SelectAnt } from "@components/select";
import { MdOutlineCircle } from "react-icons/md";
import { botox_asa_nasal, botox_base_nasal, botox_dorso_nasal, botox_frontal, botox_glabela, botox_oralemento, botox_periorbicular, botox_periorbital, botox_sorriso_gengival } from "../functions";
export const UpdateBotoxSelect = ({ piscaMaisInfo, selectedValuesBotox, setSelectedValuesBotox}) => {
  // Função para atualizar o estado ao mudar o select
  const handleChange = (key, value) => {
    setSelectedValuesBotox((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  return (
    <div className="row text-left">
      <div className="col-md-3">
        <SelectAnt
          title={<>'Frontal' <MdOutlineCircle color='red' /></>}
          options={botox_frontal}
          value={selectedValuesBotox.frontal}
          onChange={(value) => handleChange("frontal", value)}
        />
      </div>
      <div className="col-md-3">
        <SelectAnt
          title={<>'Glabela' <MdOutlineCircle color='red' /></>}
          options={botox_glabela}
          value={selectedValuesBotox.glabela}
          onChange={(value) => handleChange("glabela", value)}
        />
      </div>
      <div className="col-md-3">
        <SelectAnt
          title={<>'Orbicular dos Olhos e Região Infraorbital' <MdOutlineCircle color='red' /></>}
          options={botox_periorbital}
          value={selectedValuesBotox.periorbital}
          onChange={(value) => handleChange("periorbital", value)}
        />
      </div>
      <div className="col-md-3">
        <SelectAnt
          title={<>'Dorso Nasal' <MdOutlineCircle color='red' /></>}
          options={botox_oralemento}
          value={selectedValuesBotox.oralemento}
          onChange={(value) => handleChange("oralemento", value)}
        />
      </div>
      <div className="col-md-3">
        <SelectAnt
          title={<>'Dorso Nasal' <MdOutlineCircle color='red' /></>}
          options={botox_dorso_nasal}
          value={selectedValuesBotox.dorso_nasal}
          onChange={(value) => handleChange("dorso_nasal", value)}
        />
      </div>
      <div className="col-md-3">
        <SelectAnt
          title={<>'Asa Nasal' <MdOutlineCircle color='red' /></>}
          options={botox_asa_nasal}
          value={selectedValuesBotox.asa_nasal}
          onChange={(value) => handleChange("asa_nasal", value)}
        />
      </div>
      <div className="col-md-3">
        <SelectAnt
          title={<>'Base Nasal' <MdOutlineCircle color='red' /></>}
          options={botox_base_nasal}
          value={selectedValuesBotox.base_nasal}
          onChange={(value) => handleChange("base_nasal", value)}
        />
      </div>
      <div className="col-md-3">
        <SelectAnt
          title={<>'Sorriso Gengival' <MdOutlineCircle color='red' /></>}
          options={botox_sorriso_gengival}
          value={selectedValuesBotox.sorriso_gengival}
          onChange={(value) => handleChange("sorriso_gengival", value)}
        />
      </div>
      <div className="col-md-3">
        <SelectAnt
          title={<>'Periorbicular' <MdOutlineCircle color='red' /></>}
          options={botox_periorbicular}
          value={selectedValuesBotox.periorbicular}
          onChange={(value) => handleChange("periorbicular", value)}
        />
      </div>
    </div>
  );
};
import React, { useEffect, useRef, useState } from 'react';
import { TitleL1, TitleL2 } from '@components';
import { Avatar, Button, Carousel, Image, Tooltip } from 'antd';
import fresa from '../assets/fresa.avif';
import imp3 from '../assets/imp33.avif';
import imp33 from '../assets/imp33d.avif';
import img_pesquisadores from '../assets/pesquisadores.jpg';
import img_bio from '@pages/Public/Home/components/assets/images/Termografia/Animal/Rats2.jpeg';
import img_inf from '@pages/Public/Home/components/assets/images/Termografia/Human3.jpg';
import { ButtonCadastrar } from '@components/button';
import { Link } from 'react-router-dom';
export const OportunidadesDeParcerias = () => {
    const carouselRef = useRef(null);
    const images = [
        { id: 1, url: fresa, type: 'image' },
        { id: 2, url: 'https://www.youtube.com/embed/z7s3500Ev8Y?si=A2Yta_VgSGrQLA8E', type: 'video' },
        { id: 3, url: imp33, type: 'image' },
        { id: 4, url: imp3, type: 'image' },
        { id: 5, url: 'https://www.youtube.com/embed/_2hNMPk9FmI?si=GijEZog-PYd9sqFq', type: 'video' },
        { id: 6, url: 'https://www.youtube.com/embed/oKxBgOpLQRQ?si=AtzDJnvGTsqw7yhH', type: 'video' },
    ];
    const [idParaRolagem, setIdParaRolagem] = useState(null);
    useEffect(() => {
        if (idParaRolagem) {
            const elementoClicado = document.getElementById(idParaRolagem);
            if (elementoClicado) {
                console.log(`Rolando para o elemento com ID: ${idParaRolagem}`);
                elementoClicado.click();
                window.scrollTo({ top: 0, behavior: 'smooth' });
                setIdParaRolagem(null); // Reseta o estado para evitar chamadas repetidas
            } else {
                console.warn(`Elemento com ID "${idParaRolagem}" não encontrado.`);
            }
        }
    }, [idParaRolagem]); // O efeito roda sempre que `idParaRolagem` mudar
    const clicarNoId = (id) => {
        setIdParaRolagem(id); // Define o ID para rolar ao elemento
    };
    return (<>
        <section style={{ minHeight: '95dvh' }}>
            <div className='row' style={{ padding: '0px' }} >
                <div className='col-md-6'>
                    <TitleL1>
                        Pesquisadores Parcerias
                    </TitleL1>
                    <div className='col-md-12  mx-auto ' style={{ padding: '0px' }}>
                        <h5 className='text-justify text-dark '>
                            Nesta secção apresentamos oportunidades para o público pesquisador
                            fazer participação nos nossos projetos em escala. Uma das formas de participação que oferecemos é a possibilidade de validar pesquisas conosco ou ainda adquirir
                            ou obter a concessão de um equipamento a um preço de custo extremamente acessível
                            <br /> <br />
                            Isso significa que você pode escolher a modalidade que melhor se adequa às suas necessidades, seja colaborando nas validações ou
                            adquirindo os equipamentos em validação
                            <br /> <br />
                            Estamos sempre comprometidos em tornar nossos projetos e tecnologias acessíveis a um público mais amplo e diversificado, e essas oportunidades são um reflexo desse compromisso
                        </h5>
                    </div>
                    <small className='font-weight-bold'><b>Oportunidades abertas:</b></small>
                    <div className='py-1 col-md-12 mx-auto text-center d-flex justify-content-center'>
                        <div className="d-flex flex-column align-items-center mx-2">
                            <Tooltip title="Termografia Humana">
                                <Avatar
                                    size={80}
                                    src={img_inf}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => clicarNoId('equipamentosMedicos')}
                                />
                            </Tooltip>
                            <small className='font-weight-bold'><b>Termografia Médica</b></small>
                        </div>
                        <div className="d-flex flex-column align-items-center mx-2">
                            <Tooltip title="Termografia Animal">
                                <Avatar
                                    size={80}
                                    src={img_bio}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => clicarNoId('equipamentosPesquisasAnimais')}
                                />
                            </Tooltip>
                            <small className='font-weight-bold'><b>Termografia Veterinária</b></small>
                        </div>
                        <div className="d-flex flex-column align-items-center mx-2">
                            <Tooltip title="Pesquisadores">
                                <Avatar
                                    size={80}
                                    src={img_pesquisadores}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => clicarNoId('ColaboreConosco')}
                                />
                            </Tooltip>
                            <small className='font-weight-bold'><b>Colabore Conosco</b></small>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='text-justify'>
                        <img className="d-block image-fluid mx-auto col-md-12"
                            style={{ maxHeight: '450px', padding: '0px' }}
                            src={require('../assets/pesq.jpg')} alt='Imagem de lâmpada moderna para representar a ideia e o desenvolvimento' />
                        <small className='mt-5 font-weight-bold'><strong>Benefícios:</strong></small>
                        <ul>
                            <small>✅Bolsas</small><br />
                            <small>✅Publicações</small><br />
                            <small>✅Concessões de equipamentos</small><br />
                            <small>✅Equipamento a preço de custo</small><br />
                            <small>✅Prototipações</small><br />
                            <small>✅Acesso conveniente às nossas tecnologias inovadoras</small><br />
                            <small>✅Participar de projetos escalonados conosco</small><br />
                        </ul>
                    </div>
                </div>
                <div className='col-md-6 d-flex justify-content-center align-items-center'>
                    <div style={{ position: 'relative', width: '100%', maxWidth: '600px', margin: '0 auto', padding: '0px' }}>
                        <Button
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '0',
                                transform: 'translateY(-50%)',
                                zIndex: 1,
                                backgroundColor: '#334B90',
                                border: 'none',
                                boxShadow: "0px 4px 4px 0px  rgb(21, 120, 67), inset  0px 0px 6px 0px  rgb(128, 229, 165)",
                                fontSize: '24px',
                                color: '#fff',
                            }}
                            onClick={() => carouselRef.current.prev()}
                        >
                            ◀
                        </Button>
                        <Carousel ref={carouselRef} dots={{ className: 'custom-dots' }} autoplay={false}>
                            {images.map((item) => (
                                <div key={item.id}>
                                    {item.type === 'image' ? (
                                        <Image
                                            width={'100%'}
                                            className="w-100 rounded"
                                            style={{
                                                height: '370px',
                                                borderRadius: '30px',
                                                border: '1px light',
                                                boxShadow: '1px 1px 2px #000000',
                                            }}
                                            src={item.url}
                                            alt="Produto Imagem"
                                        />
                                    ) : (
                                        <iframe
                                            className="w-100 rounded"
                                            style={{
                                                borderRadius: '30px',
                                                border: '1px light',
                                                boxShadow: '1px 1px 2px #000000',
                                                height: '370px'
                                            }}
                                            src={item.url}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                            title="YouTube Video"
                                        />
                                    )}
                                </div>
                            ))}
                        </Carousel>
                        <Button
                            style={{
                                position: 'absolute',
                                top: '50%',
                                right: '0',
                                transform: 'translateY(-50%)',
                                zIndex: 1,
                                backgroundColor: '#334B90',
                                border: 'none',
                                fontSize: '24px',
                                color: '#fff',
                                boxShadow: "0px 4px 4px 0px  rgb(21, 120, 67), inset  0px 0px 6px 0px  rgb(128, 229, 165)"
                            }}
                            onClick={() => carouselRef.current.next()}
                        >
                            ▶
                        </Button>
                    </div>
                </div>
                <div className='col-md-6'>
                    <TitleL1>
                        Nosso Laboratório
                    </TitleL1>
                    <div className='col-md-12  mx-auto ' style={{ padding: '0px' }}>
                        <h5 className='text-justify text-dark '>
                            Bem-vindo ao nosso laboratório de inovação!
                            Aqui contamos com uma infraestrutura de ponta para impulsionar a tecnologia e a inovação. Nosso laboratório é equipado com:
                            <br />
                            <br />                       ✅ Fresa 5 eixos – Precisão extrema para usinagem complexa.
                            <br />                       ✅ Extrusora 3D Vulcão – Impressão com alta resolução e eficiência.
                            <br />                       ✅ Sensores – Diversos incluindo ópticos avançados
                            <br />                       ✅ Microcontroladores – Diversos incluindo raspyberrys
                            <br />                       ✅ Infraestrutura avançada – Computadores de alto desempenho para redes e acesso a ferramentas exclusivas.
                            <br /><br />
                            Aqui, desenvolvemos soluções inovadoras, desde prototipagem avançada até processamento de inteligência artificial, sempre com tecnologia de última geração!
                            <br />    <br />
                            💡 Quer saber mais assine nossa newsletter no momento do cadastro
                        </h5>
                    </div>
                    <ButtonCadastrar />
                </div>
            </div>
        </section>
    </>)
}
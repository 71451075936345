import React from 'react';
export const CadastrarPhotoProfissionalInfo = () => {
  return (
    <div>
      <h5 className='font-weight-bold'>Importância da Foto Profissional de Qualidade</h5>
      <p>A escolha da sua foto profissional é uma parte fundamental da sua presença online. Uma foto de qualidade transmite profissionalismo, confiança e estabelece uma conexão genuína com os outros usuários da plataforma.</p>
      <hr />
      <h5 className='font-weight-bold'>Primeira Impressão Duradoura</h5>
      <p>Sua foto profissional é muitas vezes a primeira coisa que as pessoas veem ao interagir com você. Uma foto bem escolhida cria uma impressão inicial duradoura e influencia a percepção que os outros têm de você.</p>
      <hr />
      <h5 className='font-weight-bold'>Conexão Pessoal</h5>
      <p>Uma foto autêntica e bem tirada permite que os outros o vejam como um profissional real, tornando mais fácil estabelecer uma conexão pessoal. Isso ajuda a construir relacionamentos profissionais significativos.</p>
      <hr />
      <h5 className='font-weight-bold'>Profissionalismo e Confiança</h5>
      <p>Uma foto de qualidade demonstra seu compromisso com a profissionalismo. Ela cria confiança entre você e seus colegas, clientes e parceiros de negócios, transmitindo uma imagem profissional e confiável.</p>
      <hr />
      <h5 className='font-weight-bold'>Identificação e Reconhecimento</h5>
      <p>Sua foto é uma parte central da sua identificação online. Ela permite que outros usuários o reconheçam facilmente em várias interações e ajuda a criar uma presença consistente em toda a plataforma.</p>
      <hr />
      <h5 className='font-weight-bold'>Dicas para uma Foto Profissional</h5>
      <ol className='ml-3'>
        <li>Escolha um fundo neutro e limpo.</li>
        <li>Mantenha a iluminação adequada para evitar sombras indesejadas.</li>
        <li>Mantenha uma expressão facial amigável e profissional.</li>
        <li>Use roupas adequadas à sua área de atuação.</li>
        <li>Evite distorções e edição excessiva.</li>
      </ol>
      <hr />
      <h5 className='font-weight-bold'>Atualização e Relevância</h5>
      <p>Lembre-se de manter sua foto atualizada para refletir sua aparência mais recente. Uma foto antiga pode causar desconexão e falta de confiança.</p>
      <hr />
      <h5 className='font-weight-bold'>Destaque Sua Personalidade</h5>
      <p>Embora uma foto profissional seja importante, isso não significa que você precisa ser excessivamente formal. Você pode mostrar um pouco de sua personalidade para tornar a foto mais autêntica e memorável.</p>
      <hr />
      <h5 className='font-weight-bold'>Escolha com Cuidado</h5>
      <p>Utilizando o componente <strong>FotoProfissional</strong>, escolha uma foto que represente sua imagem profissional da maneira que você deseja ser percebido pelos outros.</p>
    </div>
  );
};
export default CadastrarPhotoProfissionalInfo;
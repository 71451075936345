import React, { useState } from 'react';
import { TitleL1 } from '@components';
import RedesSociais from './RedesSociais';
import Motivos from './Motivos';
import { Image } from 'antd';
const ColaboreConosco = () => {
    const [talentos, setTalentos] = useState('');
    const [fields, setFields] = useState([{ name: '', url: '', otherName: '' }]);
    return (
        <section style={{ minHeight: '95dvh' }}>
            <div className="row">
                <div className="col-md-6">
                    <div className="trabalhe-conosco-texto">
                        <TitleL1>Colabore Conosco</TitleL1>
                        <h5 className='text-justify'>
                            Na nossa empresa, acreditamos que as pessoas são o nosso maior patrimônio.
                            Se você é apaixonado pelo que faz, busca desafios constantes e deseja fazer
                            parte de um time inovador e dinâmico, convidamos você a conhecer nossa área
                            de "Trabalhe Conosco". Aqui, valorizamos o talento, a dedicação e a vontade de
                            crescer. Oferecemos um ambiente de trabalho colaborativo, oportunidades de desenvolvimento
                            profissional e a chance de impactar positivamente o mercado. Junte-se a nós e ajude a construir o futuro da nossa empresa!
                        </h5>
                        <h5 className='text-justify mb-5 mt-3'>
                            Estamos sempre em busca de novos talentos que possam contribuir com sua
                            paixão e habilidades únicas. Queremos saber mais sobre você! Utilize o
                            espaço abaixo para nos contar sobre seus talentos, experiências e o que
                            te faz especial. Sua história pode ser o próximo passo para uma jornada incrível conosco
                        </h5>
                    </div>
                </div>
                <div className="col-md-6 text-center d-flex flex-wrap justify-content-center mx-auto align-items-center">
                    <div className="text-center">
                        <Image
                            src={require("../assets/pngegg.png")}
                            alt="Liberdade Financeira"
                            style={{ maxHeight: "280px", maxWidth: "580px", minHeight: "280px" }}
                            className="col-md-12"
                        />
                        <br/>
                   <small className="mt-5 font-weight-bold">
                        <strong>Onboarding universal</strong>
                    </small>
                    </div>
                </div></div>
            <div className='border bg-light py-2 rounded text-center'>
                <div className='ml-2 mr-2'>
                    <Motivos talentos={talentos} setTalentos={setTalentos} />
                    <RedesSociais fields={fields} setFields={setFields} /></div>
            </div>
        </section>
    );
};
export default ColaboreConosco;
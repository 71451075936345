import { useState, useContext, useEffect, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Divider, Form, Select } from "antd";  // Importando o Select do Ant Design
import PhoneInput from 'react-phone-input-2';
import {
  ButtonAPI,
  InputPadrao,
  InputPadraoTitle,
  InputDataNascimento,
  TermoDeResponsabilidadeCadastro,
  is_cpf,
  mCPF,
  TitleL1,
  TitleL2,
} from 'components'
import { AuthContext } from "guard"
import { useProfisional } from "context";
import { useProfessionalData } from "hooks";
import TesteAutoCadastrar from "./testes/TesteAutoCadastrar";
import { CadastroAuth } from "./components/CadastroAuth";
import CadastroAddress from "./components/CadastroAddress";
import useLocalizacao from "./components/useLocalizacao";
import { checkPasswordSecurity, generateSecurePassword, handleConfirmPasswordChange, SecurityBars } from "./utils/PasswordChange";
import { handleEmailChange, IsValidEmail } from "./utils/IsValidEmail";
import { validateForm } from "./utils/validateForm";  // Importando a função de validação
import { cadastrar } from "./functions/cadastrar";
import './style/style.css';
import { MdOutlinePersonAddAltMD } from "@components/icons";
import { useUser } from "@context/components/UserProvider";
const { Option } = Select;  // Extraindo o Option do Select
export const Index = () => {
  const navigate = useNavigate();
  const { setAuthenticated, setLoading, setAuthenticatedProfissional } = useContext(AuthContext);
  const { setUser } = useUser();
  const { updateProfessionalData, clearProfessionalData } = useProfessionalData();
  const { updateProfissional } = useProfisional()
  const [cadastroNativo, setCadastroNativo] = useState(false);
  const [name, setName] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [phone, setPhone] = useState("");
  const [cpf, setCpf] = useState("");
  const [validationResponse, setValidationResponse] = useState("");
  const { latitude, setLatitude, longitude, setLongitude, address, getLocalizacao } = useLocalizacao();
  const inputRef = useRef(null)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [securityLevel, setSecurityLevel] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [profissional, setProfissional] = useState("SIM");
  const [user_type, setUser_type] = useState("paciente");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const convite_code = params.get('convite_code');
  const [aceitouTermo, setAceitouTermo] = useState(false);
  const onclickCadastroNativo = () => {
    setCadastroNativo((prevCadastroNativo) => !prevCadastroNativo);
    setAceitouTermo(true)
  };
  const [prevCadastroNativo, setPrevCadastroNativo] = useState(false);
  //console.log("Convite Code:", convite_code);  
  const handleProfissionalChange = value => {
    setProfissional(value);
    monitorarCadastroProfissional(value);
  }
  function monitorarCadastroProfissional(value) {
    if (value === 'SIM') {
      localStorage.setItem('cadastroProfissional', 'SIM');
    } else {
      localStorage.removeItem('cadastroProfissional');
    }
  }
  const postCadastrarPaciente = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const formValues = { name, cpf, validationResponse, dataNascimento, email, phone, password, confirmPassword, aceitouTermo };
    const validationResult = validateForm(formValues, setError, IsValidEmail);
    if (validationResult) {
      alert(validationResult.message);
      document.getElementById(validationResult.field)?.focus(); // Focar no campo com erro
      setIsLoading(false);
      return;
    }
    setUser_type(profissional ? 'paciente' : 'paciente');
    try {
      await cadastrar(
        email,
        name,
        password,
        cpf,
        address,
        phone,
        dataNascimento,
        user_type,
        convite_code,
        setUser,
        setAuthenticated,
        setLoading,
        setAuthenticatedProfissional,
        updateProfessionalData,
        clearProfessionalData,
        updateProfissional,
        navigate);
      alert('Cadastrado com sucesso transferindo para parte interna');
    } catch (error) {
      alert('Erro ao cadastrar: ' + error.response.data.message);
      setIsLoading(false);
    }
  }
  const handlePhoneChange = (value) => {
    setPhone(value);
  };
  const isCadastrarPage = location.pathname.includes("/cadastrar");
  useEffect(() => {
    if (!prevCadastroNativo && cadastroNativo) {
      setTimeout(() => {
        window.scrollBy({ top: 600, behavior: "smooth" });
      }, 500);
    }
    setPrevCadastroNativo(cadastroNativo);
  }, [cadastroNativo, prevCadastroNativo]);
  return (<>
    <div id="cadastrar" className={`py-5 d-flex align-items-center justify-content-center`} style={{ minHeight: '85vh', overflow: 'hidden' }} >
      <div className="w-100">
        <div className='text-center'>
          <MdOutlinePersonAddAltMD color={'#334B90'} size={90} />
        </div>
        <TitleL1>Cadastrar</TitleL1>
        {aceitouTermo && <> <CadastroAuth onclickCadastroNativo={onclickCadastroNativo} /></>}
        {(cadastroNativo) && <>
          <div className='text-center'>
            <TesteAutoCadastrar
              setName={setName}
              setEmail={setEmail}
              setCpf={setCpf}
              setDataNascimento={setDataNascimento}
              setPhone={setPhone}
              setPassword={setPassword}
              setConfirmPassword={setConfirmPassword}
              setShowPassword={setShowPassword}
              setProfissional={setProfissional}
              getLocalizacao={getLocalizacao}
            />
          </div>
          {aceitouTermo && <Form
            className='border bg-light py-2 rounded'
            initialValues={{ remember: true }}
            layout="vertical"
            name="cadastrar"
            onSubmit={postCadastrarPaciente}>   <TitleL2>Dados do Usuário</TitleL2>
            <div className="row col-md-12 justify-content-center">
              <div className=" col-md-5">
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: 'Por favor, insira um nome!' }]}>
                  <InputPadrao
                    title={'Nome'}
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nome"
                    id="name"
                    name="name"
                    required
                  />
                </Form.Item>
              </div>
              <div className="form-group col-md-5 ">
                <InputPadrao
                  title={'Identificação Única'}
                  type="text"
                  value={cpf}
                  maxLength="14"
                  onChange={(e) => {
                    const formattedCpf = mCPF(e.target.value);
                    setCpf(formattedCpf);
                    const isCpfValid = is_cpf(e.target.value);
                    setValidationResponse(isCpfValid ? 'VÁLIDO' : 'ATENÇÃO: Informe um CPF válido. Insira apenas números');
                  }}
                  name="cpf"
                  placeholder="Brasil: CPF"
                  id="cpf"
                  required
                />
                <span id="cpfResponse">{validationResponse}</span>
              </div>
              <div className="form-group col-md-5 ">
                <InputDataNascimento dataNascimento={dataNascimento} setDataNascimento={setDataNascimento} />
              </div>
              <div className="form-group col-md-5 ">
                <InputPadraoTitle title={'Telefone'} />
                <PhoneInput
                  country={'br'}
                  placeholder="Número de telefone"
                  value={phone}
                  required
                  onChange={handlePhoneChange}
                />
              </div>
              <div className="form-group col-md-5 ">
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: 'Por favor, insira um e-mail!' },
                    { type: 'email', message: 'O e-mail informado não é válido!' }
                  ]}>
                  <InputPadrao
                    title={'E-mail'}
                    type="email"
                    value={email}
                    style={{ borderRadius: ' 2px 2px 2px 2px' }}
                    name="email"
                    placeholder="E-MAIL"
                    id="email"
                    onChange={(event) => handleEmailChange(event, setEmail, setError)} // Usa a função exportada
                  />  {error && <> {error}</>}
                </Form.Item>
              </div>
              <CadastroAddress latitude={latitude} setLatitude={setLatitude}
                longitude={longitude} setLongitude={setLongitude} getLocalizacao={getLocalizacao} />
              <div className="form-group col-md-5">
                <InputPadrao
                  title={isCadastrarPage ? 'SENHA' : 'SENHA ANTIGA'}
                  type={showPassword ? "text" : "password"}
                  value={password}
                  required
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setSecurityLevel(checkPasswordSecurity(e.target.value));
                    handleConfirmPasswordChange(e, setConfirmPassword)
                  }}
                  name="password"
                  placeholder="********"
                  id="password" />
              </div>
              <div className="form-group col-md-5 text-center">
                <InputPadrao
                  title={'CONFIRMA SENHA'}
                  id="confirmPassword"
                  value={confirmPassword}
                  ref={inputRef}
                  onChange={(e) => handleConfirmPasswordChange(e, setConfirmPassword)}
                  required
                  type={showPassword ? "text" : "password"}
                  placeholder="********"
                  data-bouncer-message="ATENÇÃO: Repita à senha válida."
                />
                <div className="justify-content-center col-md-12 align-items-center mx-auto">
                  <SecurityBars securityLevel={securityLevel} />
                  <div className="form-group col-md-5 mt-1 d-flex mx-auto justify-content-center">
                    <i className={`fa text-center fa-${showPassword ? 'eye' : 'eye-slash'} `}
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? "Ocultar senha" : "Exibir senha"} </i>
                  </div> </div>
                <button className='btn btn-primary mt-2'
                  type="button"
                  onClick={() => generateSecurePassword(setPassword, setConfirmPassword, setSecurityLevel)}>
                  Gerar senha segura<i className='fa fa-lock ml-2'></i>
                </button>
                <br />
              </div>
              <div className="col-md-12">
                <TitleL2>Progressão</TitleL2>
                <Divider /></div>
              <div className="form-group col-md-5">
                <InputPadraoTitle title={'Cadastro Profissional'} />
                <Select
                  className="w-100  text-center"
                  value={profissional}
                  onChange={handleProfissionalChange}
                  id="collapseCadastroProfissional"
                >
                  <Option value="SIM">SIM</Option>
                  <Option value="NÃO">NÃO</Option>
                </Select>
              </div>
            </div>
            <div className="col-md-10 mx-auto">
              <Form.Item>
                <ButtonAPI htmlType="submit" isLoading={isLoading} onClick={postCadastrarPaciente}>Cadastrar</ButtonAPI>
              </Form.Item>
            </div>
          </Form >}
        </>} 
        <TermoDeResponsabilidadeCadastro aceitouTermo={aceitouTermo} setAceitouTermo={setAceitouTermo} />      
      </div >     
    </div>
  </>)
}
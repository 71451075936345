import React, { useState, useEffect, useCallback } from 'react';
import { AutoComplete, Input } from 'antd';
import { MaisInfoMenosInfo } from '@components';
import { useUser } from '@context/components/UserProvider';
import CBOInfo from '../info/CBOInfo';
import buscarCBO from '../services/get_user_cbo_info';
import { MdAddCircleOutlineMD, MdDeleteMD } from '@components/icons';
export const CBO = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedOccupations, setSelectedOccupations] = useState([]);
  const { user } = useUser();
  // Função debounce para otimizar chamadas
  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };
  // Busca ocupações com debounce
  const fetchOccupations = useCallback(
    debounce(async (term) => {
      if (term.length > 2) {
        buscarCBO((occupations) => {
          setOptions(
            occupations.map((occupation) => ({
              value: occupation.titulo,
              occupation, // Salva o objeto inteiro para uso posterior
            }))
          );
        }, term, user.id);
      } else {
        setOptions([]);
      }
    }, 500),
    [user.id]
  );
  useEffect(() => {
    fetchOccupations(searchTerm);
  }, [searchTerm, fetchOccupations]);
  // Adiciona ocupação
  const handleSelect = (_, option) => {
    if (!selectedOccupations.some((o) => o.id === option.occupation.id)) {
      setSelectedOccupations((prev) => [...prev, option.occupation]);
    }
    setSearchTerm('');
  };
  // Remove ocupação
  const handleDeleteSelectedOccupation = (occupationId) => {
    setSelectedOccupations((prev) => prev.filter((occupation) => occupation.id !== occupationId));
  };
  return (
    <>
      <div className="col-md-3">
        <small>Nome da ocupação (profissão)</small>
        <AutoComplete
          options={options}
          onSelect={handleSelect}
          onSearch={setSearchTerm}
          value={searchTerm}
          style={{ width: '100%' }}
        >
          <Input placeholder="Nome da ocupação (profissão)" />
        </AutoComplete>
      </div>
      {/* Lista de ocupações selecionadas */}
      {selectedOccupations.length > 0 && (
        <div className="py-3 col-md-12 border border-dark rounded">
          <small>CBO selecionados</small>
          <ul>
            {selectedOccupations.map((occupation, index) => (
              <li
                key={occupation.id}
                className={`col-md-12 align-items-center d-flex mx-auto ${index % 2 === 0 ? '' : 'bg-light'}`}
                onClick={() => handleDeleteSelectedOccupation(occupation.id)}
                style={{ cursor: 'pointer', padding: '10px' }}
              >
                {occupation.titulo}{' '}
                <MdDeleteMD className="align-right" color="red" />
              </li>
            ))}
          </ul>
          <div className="col-md-12 text-center">
            <MaisInfoMenosInfo text={<CBOInfo />} />
          </div>
        </div>
      )}
    </>
  );
};
export default CBO;
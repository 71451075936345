import { apiToken } from "services";
export const buscarAtestadosService = async (props) => {
    try {
        const response = await apiToken().get(`get_atestados/${props.professional_id}`);
        const data = response.data;
        props.setAtestados(data.atestados);
        // alert(JSON.stringify(data.atestados)    )
    } catch (error) {
        console.error('Error fetching atestados:', error);
    }
};
export default buscarAtestadosService;
import { useContext } from "react";
import { AuthContext } from "./Auth";
import { Navigate } from "react-router";
export const AuthUser = ({ children }) => {
  const { authenticated, loading } = useContext(AuthContext);
  if (loading) {
    return null;
  }
  if (!authenticated) {
    return <Navigate to="/login" />;
  }
  return children;
};
import { createContext, useContext, useState, useEffect } from 'react';
const ProfissionalContext = createContext(null);
export const ProfissionalProvider = ({ children }) => {
  const getStoredProfissional = () => {
    try {
      const storedProfissional = localStorage.getItem('profissional');
      return storedProfissional ? JSON.parse(storedProfissional) : null;
    } catch (error) {
      console.error('Erro ao recuperar profissional do localStorage:', error);
      return null;
    }
  };
  const [profissional, setProfissional] = useState(getStoredProfissional);
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'profissional') {
        try {
          setProfissional(event.newValue ? JSON.parse(event.newValue) : null);
        } catch (error) {
          console.error('Erro ao atualizar profissional do localStorage:', error);
        }
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);
  const updateProfissional = (newProfissional) => {
    try {
      localStorage.setItem('profissional', JSON.stringify(newProfissional));
      setProfissional({ ...newProfissional }); // Garante que o React detecte a mudança
    } catch (error) {
      console.error('Erro ao salvar profissional no localStorage:', error);
    }
  };
  const clearProfissional = () => {
    localStorage.removeItem('profissional');
    setProfissional(null);
  };
  return (
    <ProfissionalContext.Provider value={{ profissional, updateProfissional, clearProfissional }}>
      {children}
    </ProfissionalContext.Provider>
  );
};
export const useProfisional = () => {
  const context = useContext(ProfissionalContext);
  if (!context) {
    throw new Error('useProfisional deve ser usado dentro de um ProfissionalProvider');
  }
  return context;
};
import React from 'react';
import { ApresentacaoServicos } from './vender/ApresentacaoServicos';
import SolucaoGerencimamentoAvancado from './vender/components/GerenciamentoAvancado';
import SolucaoIAAvancadaServicos from './vender/components/SolucaoIAAvancadaServicos';
import PlanosPrecosServicos from './vender/components/PlanosPrecosServicos';
import ImagensSistemaServicos from './vender/components/ImagensSistemaServicos';
import DashboardService from './vender/components/DashboardService';
import AgendaCompartilhada from './vender/components/AgendaCompartilhada';
import ServicosList from './comprar/ServicosList';
export const items = [
  {
    id: 'servicos', title: 'Comprar Serviços', component: <ServicosList />,
    subItems: [],
  },
  {
    id: 'apresentacaoServicos', title: 'Vender Serviços', component: <ApresentacaoServicos />,
    subItems: [
      {
        id: 'SolucaoGerencimamentoAvancado', title: 'Gerenciamento', component: (<>
          <SolucaoGerencimamentoAvancado />
        </>),
      },
      {
        id: 'AgendaService', title: 'Agenda', component: (<>
          <AgendaCompartilhada />
        </>),
        subItems: [],
      },
      {
        id: 'DashboardService', title: 'Dashboard', component: (<>
          <DashboardService />
        </>),
        subItems: [],
      },
      {
        id: 'SolucaoIAAvancadaServicos', title: 'IA e RM', component: (<>
          <SolucaoIAAvancadaServicos />
        </>),
        subItems: [],
      },
      {
        id: 'ImagensSistemaServicos', title: 'Imagens', component: (<>
          <ImagensSistemaServicos />
        </>),
        subItems: [],
      },
      {
        id: 'PlanosPrecosServicos', title: 'Planos e Preços', component: (<>
          <PlanosPrecosServicos />
        </>),
        subItems: [],
      },
    ],
  },
];
export default items;
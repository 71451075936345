import React, { useContext } from "react";
import { Tag, Button } from "antd";
import { WarningOutlined, CheckOutlined } from "@ant-design/icons";
import { TitleL2 } from "@components";
import { handleActiveProfissional } from "controllers/functions";
import { useUser } from "@context/components/UserProvider";
import { useProfisional } from "@context/index";
import { AuthContext } from "@guard/index";
export const ViewDeletedProfissionalController = ({
  professionalData,
  updateProfessionalData,
  clearProfessionalData,
}) => {
  const { user, setUser } = useUser();
  const {
    setAuthenticated,
    setLoading,
    setAuthenticatedProfissional
  } = useContext(AuthContext);
  const { updateProfissional } = useProfisional();
  if (!professionalData?.professionalData?.length) {
    return <p>Nenhum profissional deletado encontrado.</p>;
  }
  return (
    <div>
      {professionalData.professionalData.map((profissional, index) => (
        <Tag
          key={index}
          className="mt-3 mb-3 d-flex col-md-12"
          style={{ width: "100%", padding: "60px", whiteSpace: "pre-line" }}
          icon={
            <WarningOutlined
              style={{ fontSize: "48px", paddingInlineEnd: "50px" }}
            />
          }
          color="warning"
        >
          <TitleL2 className="text-danger">
            Seu cadastro profissional foi deletado
          </TitleL2>
          {profissional.professional_name && (
            <li className="text-dark mt-3">
              <strong>Nome profissional: </strong> {profissional.professional_name}
            </li>
          )}
          {profissional.created_at && (
            <li className="text-dark">
              <strong>Deletado em: </strong>{" "}
              {new Date(profissional.created_at).toLocaleString()}
            </li>
          )}
          {profissional.state && (
            <li className="text-dark">
              <strong>Estado: </strong> {profissional.state}
            </li>
          )}
          <li className="text-success mt-5" style={{ whiteSpace: "pre-line" }}>
            <strong>Política de segurança</strong>
            <ol className="text-dark text-justify">
              <li>Relembrando os termos anteriormente aceitos</li>
              <li>
                Segundo as regras da empresa, só é possível ter um master cadastro
                profissional:
              </li>
              <ul className="ml-3">
                <li>2.1 Você pode ter várias ocupações</li>
                <li>2.2 Você pode ter vários vínculos</li>
                <li>2.3 Você pode ter várias empresas e sub</li>
                <li>
                  2.4 No entanto, todas essas funções profissionais estão
                  vinculadas ao master profissional
                </li>
              </ul>
              <li>
                Todos os seus dados e serviços dependências diretas estão pausadas
                temporariamente
              </li>
              <li>
                Todos os seus dados estão e continuarão armazenados
                indefinidamente
              </li>
              <li>
                Se você precisa de cópias de documentos, basta ativar o cadastro
              </li>
              <li>
                Para voltar à ativa profissional e ganhar tempo e dinheiro, é só
                ativar novamente o cadastro
              </li>
            </ol>
          </li>
          <Button
            type="primary"
            onClick={() => handleActiveProfissional({
              profissional,
              user,             
              setAuthenticatedProfissional,
              updateProfessionalData,
              clearProfessionalData,
              updateProfissional})}
            icon={<CheckOutlined />}
            className="mt-3 btn-success"
          >
            Ativar Cadastro Profissional
          </Button>
        </Tag>
      ))}
    </div>
  );
};
export default ViewDeletedProfissionalController;
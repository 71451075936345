import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { mainData } from "./NavData";
import { MdCircleMD } from "@components";
import { FaChevronDownMD, FaChevronRightMD } from "@components/icons";
export const SiderProfessional = () => {
  const [isOpen, setIsOpen] = useState([]);
  const collapseRef = useRef([]);
  const toggleCollapse = (index) => {
    setIsOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };
  const location = useLocation();
  const { pathname, search } = location;
  useEffect(() => {
    const initialIsOpen = mainData.map((data) =>
      data.subData.some((subData) => subData.href + search === pathname)
    );
    setIsOpen(initialIsOpen);
  }, [pathname, search]);
  return (
    <div>
      {mainData.map((data, index) => {
        const isCurrentPath = data.href === `../..${pathname}`;
        return (
          <div
            className={`${data.topDist} ${isCurrentPath ? 'bg-primary col-md-12' : 'col-md-12'}`}
            style={{ padding: '0px', borderRadius: '0.25rem' }}
            key={data.title}
          >
            {data.subData.length === 0 ? (
              <Link
                to={`${data.href}${search}`}
                className={`navbar-brand nav-link font-weight-bold ${isCurrentPath ? 'font-weight-bold text-white' : 'text-dark'}`}
                style={{ cursor: 'pointer', marginTop: '0px', paddingLeft:'0px' }}
              >
                {data.icon}
                <span className={`ml-1 ${isCurrentPath ? 'text-white' : 'text-dark'}`}>
                  {data.title}
                  {isOpen[index] ? (
                    <i className="fas fa-chevron-down align-right" style={{ scale: "0.5" }}></i>
                  ) : data.subData.length === 0 ? (
                    <>
                      {isCurrentPath && <MdCircleMD className=" align-right mt-2  mr-2" size={6} />}
                      {!isCurrentPath && <MdCircleMD className=" align-right mt-2  mr-2" size={6} />}
                    </>
                  ) : (
                    <FaChevronRightMD className="align-right mt-2 mr-2" size={6} />
                  )}
                </span>
              </Link>
            ) : (
              <>
                <ul
                  onClick={() => toggleCollapse(index)}
                  className={`navbar-brand  nav-link font-weight-bold ${isCurrentPath ? 'font-weight-bold text-white' : 'text-dark'} ${data.topDist}`}
                  style={{ backgroundColor: 'none', cursor: 'pointer', marginBottom: '0px', paddingLeft:'0px' }}
                >
                  {data.icon}
                  <span className="ml-1 text-dark">
                    {data.title}
                    {isOpen[index] ? (
                      <FaChevronDownMD className="align-right mt-2 mr-2" size={6} />
                    ) : (
                      <FaChevronRightMD className="align-right mt-2 mr-2" size={6} />
                    )}
                  </span>
                </ul>
                <ul
                  className={`collapse ${isOpen[index] ? "show" : ""}`}
                  ref={(el) => (collapseRef.current[index] = el)}
                  style={{ padding: '0px', marginBottom: '0px' }}
                >
                  {data.subData.map((subData) => (
                    <li className=" text-dark nav-link font-weight-bold text-left mt-1 ml-3" style={{ cursor: 'pointer', padding: '0px', marginBottom: '0px'  }} key={subData.id}>
                      <Link to={`${subData.href}${search}`}>
                        {subData.icon}
                        <span className="ml-1 text-dark">{subData.title}</span>
                        <p className='text-right  mr-2 ' style={{ marginTop: '-33px' }}>
                          <MdCircleMD size={4} className={isCurrentPath === subData.id ? "text-white" : "text-dark"} />
                        </p>
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default SiderProfessional;
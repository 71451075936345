import { createContext, useEffect, useState } from "react";
export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [authenticatedProfisional, setAuthenticatedProfissional] = useState(null);
  useEffect(() => {
    const token = localStorage.getItem('token');
    const recoveredProfissional = localStorage.getItem('professionalData');
    if (token) {
      setAuthenticated(true);
    }
    if (recoveredProfissional) {
      setAuthenticatedProfissional(true)
    }
    setLoading(false);
  }, []);
  return (
    <AuthContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        loading,
        setLoading,
        authenticatedProfisional,
        setAuthenticatedProfissional
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
import { Index } from "./Index";
import {HeaderAnt, SiderUserMain } from "components";
export const ServicosUser = () => {
    return (<>
        <HeaderAnt/>
        <div className="d-flex mt-3" style={{ overflow: 'hidden' }}>
            <SiderUserMain />
            <section className="w-100">
                <Index />
            </section>
        </div>
    </>)
}
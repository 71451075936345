import { useState } from 'react';
import axios from 'axios';
import { baseURL } from '@services';
import { ReferenciaLocal, ButtonAPI, InputPadraoTitle, MaisInfoMenosInfo } from '@components';
import ProspeccaoClientesTelefoneInfo from './info/ProspeccaoClientesTelefoneInfo';
import { InputPadrao } from '@components/Input';
import { TitleL1 } from '@components/text';
import { SelectAnt } from '@components/select';
export const ProspeccaoClientesTelefone = () => {
    const [numbers, setNumbers] = useState('');
    const [message, setMessage] = useState('');
    const [tipoComunicacaoTelefone, setTipoComunicacaoTelefone] = useState('');
    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post(`${baseURL}enviar-mensagem`, { numbers, message })
            .then((response) => {
                alert(response);
            })
            .catch((error) => {
                alert(error);
            });
    };
    const handleNumbersChange = (event) => {
        setNumbers(event.target.value);
    };
    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };
    const handleTipoComunicacaoTelefoneChange = (event) => {
        setTipoComunicacaoTelefone(event.target.value);
    };
    return (<>
        <div className='col-md-6'>
            <TitleL1>Enviar via Telefone</TitleL1>
            <form onSubmit={handleSubmit}>
                <SelectAnt
                    value={tipoComunicacaoTelefone} onChange={handleTipoComunicacaoTelefoneChange}
                    title={'Tipo de comunicação'}
                    options={[
                        { value: 'WatsApp', label: 'WatsApp' },
                        { value: 'Msg', label: 'Msg' },
                        { value: 'Ambos', label: 'Ambos' },
                    ]}
                />
                <InputPadrao
                    type="text"
                    title={'Números de telefone (separados por vírgula):'}
                    value={numbers}
                    onChange={handleNumbersChange}
                />
                <textarea
                    className="form-control text-center col-md-12 border border-dark mb-3"
                    value={message}
                    onChange={handleMessageChange}
                />
                <ButtonAPI type="submit">Enviar</ButtonAPI>
            </form>
            <div className='py-4 mt-3'>
                <MaisInfoMenosInfo text={<ProspeccaoClientesTelefoneInfo />}></MaisInfoMenosInfo>
            </div>
        </div>
    </>);
}
export default ProspeccaoClientesTelefone;
import React, { useState } from 'react';
import { createSchedule } from "./services/createSchedule";
// import ModalContent from './modalAgenda.jsx'; 
import "./Styles/Agenda.css";
const ConfigHorarioAgenda = () => {
  const [workSchedule, setWorkSchedule] = useState([]);
  const [weekday, setWeekday] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const handleDayClick = (index, selectedDay) => {
    const updatedSchedule = [...workSchedule];
    updatedSchedule[index].day = selectedDay;
    setWorkSchedule(updatedSchedule);
  };
  const handleAddPeriod = () => {
    setWorkSchedule([...workSchedule, { day: '', startTime: '', endTime: '' }]);
  };
  // const handleDayChange = (index, day) => {
  //   const updatedSchedule = [...workSchedule];
  //   updatedSchedule[index].day = day;
  //   setWorkSchedule(updatedSchedule);
  // };
  const handleStartTimeChange = (index, startTime) => {
    const updatedSchedule = [...workSchedule];
    updatedSchedule[index].startTime = startTime;
    setWorkSchedule(updatedSchedule);
  };
  const handleEndTimeChange = (index, endTime) => {
    const updatedSchedule = [...workSchedule];
    updatedSchedule[index].endTime = endTime;
    setWorkSchedule(updatedSchedule);
  };
  const handleRemovePeriod = (index) => {
    const updatedSchedule = [...workSchedule];
    updatedSchedule.splice(index, 1);
    setWorkSchedule(updatedSchedule);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(weekday, startTime, endTime);
    createSchedule(weekday, startTime, endTime)
      .then((resp) => {
        if (resp.status === 200) {
          console.log("Status ok");
        }
      })
      .catch((erro) => {
        console.log("Error");
      });
  };
  return (
    <form onSubmit={(event) => { event.preventDefault() }}>
      {workSchedule.map((period, index) => (
        <div key={index}>
          <select
            defaultValue={period.day}
            onChange={(event) => {
              setWeekday(event.target.value);
              //  handleDayChange(index, event.target.value);
            }}
          >
            <option disabled>Selecione um dia</option>
            <option value="Segunda-feira">Segunda-feira</option>
            <option value="Terça-feira">Terça-feira</option>
            <option value="Quarta-feira">Quarta-feira</option>
            <option value="Quinta-feira">Quinta-feira</option>
            <option value="Sexta-feira">Sexta-feira</option>
          </select>
          <div className='startTime'>
            <h5>De</h5>
            <input
              type="time"
              value={period.startTime}
              onChange={(event) => {
                setStartTime(event.target.value);
                handleStartTimeChange(index, event.target.value);
              }}
            />
          </div>
          <button onClick={handleAddPeriod}></button>
          <button onClick={handleSubmit}></button>
          <div className="endTime">
            <h5>Às</h5>
            <input
              className=""
              type="time"
              value={period.endTime}
              onChange={(event) => {
                setEndTime(event.target.value);
                handleEndTimeChange(index, event.target.value);
              }}
            />
          </div>
          <button
            id='remover'
            className='remove-button'
            onClick={() => handleRemovePeriod(index)}
          >
            Remover
          </button>
        </div>
      ))}
      {/* <div id='periodo'>
        <button className='periodo' onClick={handleAddPeriod}>
          Adicionar Período Antigo
        </button>
      </div> */}
      {workSchedule.map((period, index) => (
        <div key={index}>
          <select
            defaultValue={period.day}
            onChange={(event) => handleDayClick(event.target.value)}
          >
            <option disabled>Selecione um dia</option>
            <option value="Segunda-feira">Segunda-feira</option>
            <option value="Terça-feira">Terça-feira</option>
            <option value="Quarta-feira">Quarta-feira</option>
            <option value="Quinta-feira">Quinta-feira</option>
            <option value="Sexta-feira">Sexta-feira</option>
          </select>
        </div>
      ))}
      {/* <button className='send' onClick={handleSubmit}>
        Enviar
      </button> */}
    </form>
  );
};
export default ConfigHorarioAgenda;
import { createContext, useContext, useState, useEffect, useRef } from "react";
import Peer from "peerjs";
const UserContext = createContext();
export const UserPeerProvider = ({ children }) => {
  const peerRef = useRef(null);
  const [conn, setConn] = useState(null);
  const [peerId, setPeerId] = useState(() => sessionStorage.getItem("peerId") || "");
  const [screenStream, setScreenStream] = useState(null);
  const [userType, setUserType] = useState(() => sessionStorage.getItem("userType") || ""); // "atendente" ou "usuario"
  // Função que inicia o Peer quando chamado
  const iniciarConexaoPeer = () => {
    if (!peerRef.current) {
      const storedPeerId = sessionStorage.getItem("peerId") || `peer_${Math.random().toString(36).substr(2, 9)}`;
      sessionStorage.setItem("peerId", storedPeerId);
      const userPeer = new Peer(storedPeerId, {
        host: "localhost",
        port: 9000,
        path: "/peerjs",
        secure: false,
        debug: 2,
      });
      userPeer.on("open", (id) => {
        setPeerId(id);
        console.log("🔗 Meu PeerID:", id); // Confirma o PeerID local
      });
      userPeer.on("connection", (connection) => {
        console.log("🔗 Conectado a:", connection.peer); // Mostra quando a conexão é estabelecida
        connection.on("data", (data) => {
          // Exemplo: receber comandos para mover o mouse ou simular cliques
          if (data.type === "mouseMove") {
            moverMouse(data.x, data.y);
          } else if (data.type === "click") {
            simularClique(data.x, data.y);
          }
          console.log("📩 Dados recebidos:", data); // Logs os dados recebidos
        });
        setConn(connection);
        console.log("🟢 Conexão estabelecida com o atendente!");
      });
      userPeer.on("call", (call) => {
        console.log("📞 Chamando o atendente...");
        call.answer(); // Aceita chamadas (por exemplo, de compartilhamento de tela)
        call.on("stream", (remoteStream) => {
          setScreenStream(remoteStream);
          console.log("🎥 Compartilhamento de tela iniciado...");
        });
      });
      userPeer.on("disconnected", () => {
        console.log("🔴 Conexão perdida. Tentando reconectar...");
        setConn(null); // Resetar a conexão
        setTimeout(() => {
          userPeer.reconnect(); // Reconectar automaticamente
        }, 5000);
      });
      userPeer.on("close", () => {
        console.log("🟠 Conexão encerrada. Tentando reconectar...");
        setTimeout(() => {
          userPeer.reconnect(); // Reconectar automaticamente
        }, 5000);
      });
      userPeer.on("error", (err) => {
        console.error("❌ Erro na conexão:", err.message);
        setTimeout(() => {
          userPeer.reconnect(); // Reconectar após erro
        }, 5000);
      });
      peerRef.current = userPeer;
    }
  };
  // Função para definir o tipo de usuário
  const definirTipoUsuario = (tipo) => {
    sessionStorage.setItem("userType", tipo);
    setUserType(tipo);
  };
  const moverMouse = (x, y) => {
    let cursor = document.getElementById("cursor-remoto");
    if (!cursor) {
      cursor = document.createElement("div");
      cursor.id = "cursor-remoto";
      cursor.style.position = "absolute";
      cursor.style.width = "15px";
      cursor.style.height = "15px";
      cursor.style.backgroundColor = "red";
      cursor.style.borderRadius = "50%";
      cursor.style.pointerEvents = "none";
      cursor.style.zIndex = "99999";
      document.body.appendChild(cursor);
    }
    cursor.style.left = `${x - cursor.offsetWidth / 2}px`;
    cursor.style.top = `${y - cursor.offsetHeight / 2}px`;
  };
  const simularClique = (x, y) => {
    const elemento = document.elementFromPoint(x, y);
    const eventoClique = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    if (elemento) {
      elemento.dispatchEvent(eventoClique);
    } else {
      document.body.dispatchEvent(eventoClique);
    }
  };
  // Outras funções (compartilharTela, etc.) podem ser incluídas aqui se necessário
  return (
    <UserContext.Provider
      value={{
        peer: peerRef.current,
        conn,
        peerId,
        setConn,
        userType,
        definirTipoUsuario,
        screenStream,
        setScreenStream,
        iniciarConexaoPeer, // Agora você pode chamar essa função quando quiser
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export const UseUserPeer = () => useContext(UserContext);
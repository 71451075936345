import {
  UpdateEmpresaController,
  UpdateEmpresaFilialController,
  UpdateProfissionalController,
  ViewDeletedProfissionalController,
  UpdateProfissionalVinculadoController
} from './index';
import { useProfessionalData } from 'hooks';
import { useEffect, useState } from 'react';
import { RenderTesteIfLocalhost } from '@tests';
import TestApiTokenProfissional from '../testes/TestApiTokenProfissional';
export const ViewProfissionalController = ({ updateProfissional }) => {
  const { professionalData, updateProfessionalData, clearProfessionalData, } = useProfessionalData();
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    setIsActive(professionalData?.professionalData?.[0]?.state === "active");
  }, [professionalData]);
  return (<>
    {isActive ? (<>
      <small className='font-weight-bold'><b>Controle profissional</b></small>
      <div className='col-md-12 mt-2 mb-2'>
        <div className='row'>
          <UpdateProfissionalController
            professionalData={professionalData}
            updateProfissional={updateProfissional}
            updateProfessionalData={updateProfessionalData}
          />
          <UpdateProfissionalVinculadoController
            professionalData={professionalData}
            updateProfissional={updateProfissional}
          />
          <UpdateEmpresaController
            professionalData={professionalData}
            updateProfissional={updateProfissional}
          />
          <UpdateEmpresaFilialController
            professionalData={professionalData}
            updateProfissional={updateProfissional}
          />
        </div>
      </div>
    </>) : (
      <ViewDeletedProfissionalController
        professionalData={professionalData}
        updateProfessionalData={updateProfessionalData}
        clearProfessionalData={clearProfessionalData} />
    )}
    <RenderTesteIfLocalhost> <TestApiTokenProfissional /></RenderTesteIfLocalhost>
  </>
  );
};
export default ViewProfissionalController;
import { useState, useEffect, useRef } from "react";
import GraficosDinamicos from "../graficosDinamicos/GraficosDinamicos.js";
import MontarGraficosDinamicos from "./MontarGraficosDinamicos.jsx";
import { baseURL } from "@services";
import ConexaoDB from "./ConexaoDB.jsx";
import { ButtonAPI, ReferenciaLocal } from "@components";
import { Card, Col, Radio, Row, Statistic, Table } from 'antd';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Title, Tooltip, Legend, LineController, PieController } from 'chart.js';
// Registrar os componentes do Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  LineController,  // Adicionando o controlador de linha
  PieController    // Adicionando o controlador de pizza
);
export function GraficosFinanceiros() {
  const [questions, setQuestions] = useState([]);
  const [responses, setResponses] = useState([]);
  useEffect(() => {
    async function fetchDataFromAPI() {
      try {
        const response = await fetch(`${baseURL}montarDashboard`);
        const data = await response.json();
        setQuestions(data);
      } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
      }
    }
    fetchDataFromAPI();
  }, []);
  const [period, setPeriod] = useState('ano'); // Estado para controlar o período selecionado
  const [lineData, setLineData] = useState(null);
  const [pieData, setPieData] = useState(null);
  const lineChartRef = useRef(null);
  const pieChartRef = useRef(null);
  const getLineData = (period) => {
    switch (period) {
      case 'dia':
        return {
          labels: ['Dia 1', 'Dia 2', 'Dia 3', 'Dia 4'],
          datasets: [
            {
              label: 'Faturamento (R$)',
              data: [10000, 12000, 15000, 18000],
              borderColor: '#1890ff',
              backgroundColor: 'rgba(24, 144, 255, 0.2)',
              fill: true,
            },
          ],
        };
      case 'semana':
        return {
          labels: ['Semana 1', 'Semana 2', 'Semana 3', 'Semana 4'],
          datasets: [
            {
              label: 'Faturamento (R$)',
              data: [30000, 35000, 40000, 45000],
              borderColor: '#1890ff',
              backgroundColor: 'rgba(24, 144, 255, 0.2)',
              fill: true,
            },
          ],
        };
      case 'ano':
        return {
          labels: ['2020', '2021', '2022', '2023'],
          datasets: [
            {
              label: 'Faturamento (R$)',
              data: [100000, 120000, 150000, 180000],
              borderColor: '#1890ff',
              backgroundColor: 'rgba(24, 144, 255, 0.2)',
              fill: true,
            },
          ],
        };
      default:
        return {};
    }
  };
  const getPieData = (period) => {
    switch (period) {
      case 'dia':
        return {
          labels: ['Vendas', 'Marketing', 'Operacional'],
          datasets: [
            {
              data: [30, 40, 30],
              backgroundColor: ['#36A2EB', '#FFCE56', '#FF6384'],
            },
          ],
        };
      case 'semana':
        return {
          labels: ['Vendas', 'Marketing', 'Operacional'],
          datasets: [
            {
              data: [40, 30, 30],
              backgroundColor: ['#36A2EB', '#FFCE56', '#FF6384'],
            },
          ],
        };
      case 'ano':
        return {
          labels: ['Vendas', 'Marketing', 'Operacional'],
          datasets: [
            {
              data: [60, 25, 15],
              backgroundColor: ['#36A2EB', '#FFCE56', '#FF6384'],
            },
          ],
        };
      default:
        return {};
    }
  };
  useEffect(() => {
    const newLineData = getLineData(period);
    const newPieData = getPieData(period);
    setLineData(newLineData);
    setPieData(newPieData);
  }, [period]);
  // Use useEffect para criar o gráfico com o Chart.js
  useEffect(() => {
    if (lineData && lineChartRef.current) {
      const lineChart = new ChartJS(lineChartRef.current, {
        type: 'line',
        data: lineData,
        options: {
          responsive: true,
          maintainAspectRatio: false, // Impede que a proporção do gráfico seja mantida
          aspectRatio: 1,  // Controla a proporção de largura x altura do gráfico
        },
      });
      // Cleanup function to destroy the chart when the component unmounts or data changes
      return () => {
        if (lineChart) lineChart.destroy();
      };
    }
  }, [lineData]);
  useEffect(() => {
    if (pieData && pieChartRef.current) {
      const pieChart = new ChartJS(pieChartRef.current, {
        type: 'pie',
        data: pieData,
        options: {
          responsive: true,
          maintainAspectRatio: false, // Impede que a proporção do gráfico seja mantida
          aspectRatio: 1,  // Controla a proporção de largura x altura do gráfico
        },
      });
      // Cleanup function to destroy the chart when the component unmounts or data changes
      return () => {
        if (pieChart) pieChart.destroy();
      };
    }
  }, [pieData]);
 // Definindo as colunas e os dados da tabela
 const columns = [
  { title: 'Produto', dataIndex: 'produto', key: 'produto' },
  { title: 'Preço', dataIndex: 'preco', key: 'preco' },
  { title: 'Vendas', dataIndex: 'vendas', key: 'vendas' },
];
const tableData = [
  { key: 1, produto: 'Notebook', preco: 'R$ 4.500', vendas: 120 },
  { key: 2, produto: 'Smartphone', preco: 'R$ 3.200', vendas: 200 },
  { key: 3, produto: 'Monitor', preco: 'R$ 1.500', vendas: 80 },
];
  const handlePeriodChange = (e) => {
    setPeriod(e.target.value);
  };
  useEffect(() => {
    async function fetchDataForTableAndColumn() {
      if (Array.isArray(questions) && questions.length > 0) {
        try {
          const fetchPromises = questions.map(async (question) => {
            const url = `${baseURL}buscarDados/${question.tabela}/${question.coluna}`;
            const response = await fetch(url);
            const data = await response.json();
            return { question, response: data };
          });
          const questionResponsePairs = await Promise.all(fetchPromises);
          setResponses(questionResponsePairs);
        } catch (error) {
          console.error('Erro ao buscar dados da tabela e coluna:', error);
        }
      }
    }
    fetchDataForTableAndColumn();
  }, [questions]);
  return (<>
      <div className="bg-light" style={{ padding: 24}}>
        <Row gutter={16}>
          <Col span={8}>
            <Card>
              <Statistic title="Faturamento Diário" value={125000} prefix="R$" suffix="mil" valueStyle={{ color: '#3f8600' }} />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic title="Despesas Diárias" value={45000} prefix="R$" suffix="mil" valueStyle={{ color: '#cf1322' }} />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic title="Novos Clientes" value={350} valueStyle={{ color: '#1890ff' }} />
            </Card>
          </Col>
        </Row>
        <Row style={{ marginBottom: 20 }}>
            <Col span={24}>
              <Radio.Group value={period} onChange={handlePeriodChange}>
                <Radio.Button value="dia">Diário</Radio.Button>
                <Radio.Button value="semana">Semanal</Radio.Button>
                <Radio.Button value="ano">Anual</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 20 }}>
        <Col span={12}>
          <Card title="Evolução de Receita">
            <canvas ref={lineChartRef}  style={{ height: '300px' }} />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Distribuição de Custos">
            <canvas ref={pieChartRef} style={{ height: '300px' }} />
          </Card>
        </Col>
      </Row>
        <Row gutter={16} style={{ marginTop: 20 }}>
          <Col span={24}>
            <Card title="Últimas Vendas">
              <Table columns={columns} dataSource={tableData} />
            </Card>
          </Col>
        </Row>
      </div>
    <div className=" text-center col-md-12 w-100" style={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}>
      {questions && responses.length > 0 && (
        <MontarGraficosDinamicos questions={questions} responses={responses} />
      )}
      {/* <h5>Esta de conferência das respostas JSON back-end</h5>
        {Array.isArray(responses) && (
          <div className="col-md-12">
            <ul>
              {responses.map(({ question, response }) => (
                <li className="col-md-12 mt-5" key={question.id}>
                  <h5 className="col-md-12">Question Coluna: {JSON.stringify(question)}</h5>
                  <h5 className="col-md-12">Response: {JSON.stringify(response)}</h5>
                </li>
              ))}
            </ul>
          </div>
        )} */}
    </div>
    <GraficosDinamicos />
    <ReferenciaLocal text="Config. Avançadas">
      <ConexaoDB />
    </ReferenciaLocal>
  </>);
}
export default GraficosFinanceiros;
import { useEffect, useState } from 'react';
import {
    HeaderAnt,
    SiderUserMain,
    BreadcrumbAnt,
} from '@components';
import { useProfisional } from '@context/index';
import { ViewProfissionalController } from 'controllers';
import ProfissionalEditar from '../ProfissionalEditar/ProfissionalEditar';
export const UpdateCadastroProfissional = () => {
    const { profissional, updateProfissional } = useProfisional();
    // Estados para armazenar os valores atualizados
    const [hasProfissionalId, setHasProfissionalId] = useState(false);
    const [hasProfissionalUnitId, setHasProfissionalUnitId] = useState(false);
    const [hasCompanyId, setHasCompanyId] = useState(false);
    const [hasCompanyNitId, setHasCompanyNitId] = useState(false);
    useEffect(() => {
        setHasProfissionalId(!!profissional?.professional_id);
        setHasProfissionalUnitId(!!profissional?.profissional_unit_id);
        setHasCompanyId(!!profissional?.company_id);
        setHasCompanyNitId(!!profissional?.company_nit_id);
    }, [profissional]);
    return (
        <>
            <HeaderAnt />
            <div className="d-flex mt-3">
                <SiderUserMain />
                <div className="w-100">
                    <section>
                        <BreadcrumbAnt />
                        <ViewProfissionalController updateProfissional={updateProfissional} />
                        {hasProfissionalId && (
                            <ProfissionalEditar />
                        )}
                        {hasCompanyId && (
                            <div>
                                <p>Dono de empresa matriz:</p>
                            </div>
                        )}
                        {hasCompanyId && hasCompanyNitId && (
                            <div>
                                <p>Dono de empresa filial:</p>
                            </div>
                        )}
                        {hasProfissionalId && hasProfissionalUnitId && hasCompanyId && hasCompanyNitId && (
                            <div>
                                <p>Opção 4: Profissional ID + Unidade + Empresa + NIT da Empresa</p>
                            </div>
                        )}
                    </section>
                </div>
            </div>
        </>
    );
};
export default UpdateCadastroProfissional;
import React, { useEffect, useState } from 'react';
import { Avatar } from 'antd';
import { getInitialsName } from '@functions';
import { ModalSimples, } from '@components';
import { ViewProfissionalController } from 'controllers';
import { useProfisional } from '@context/index';
import MdMenuMD from '@components/icons/components/MdMenuMD';
export const ImagemPerfilProfissional = () => {
    const { profissional, updateProfissional } = useProfisional()
    const [isVisiblelogoAtivaOpenFullScreen, setIsVisiblelogoAtivaOpenFullScreen] = useState(false);
    const logoAtivaOpenFullScreen = () => {
        setIsVisiblelogoAtivaOpenFullScreen(true);
    };
    const logoAtivaCloseFullScreen = () => {
        setIsVisiblelogoAtivaOpenFullScreen(false);
    };
    useEffect(() => {
        setIsVisiblelogoAtivaOpenFullScreen(false);
    }, [profissional]);
    return (<>
        <div className='d-flex justify-content-end align-items-end'
            style={{ position: "relative", display: "inline-block", marginTop: '-20%', marginRight: '6%' }}>
            {profissional?.images?.length > 0 ? (
                <img className="d-block rounded-circle w-25"
                    src={profissional.images[0]}
                    alt="Imagem do Paciente"
                    onClick={logoAtivaOpenFullScreen}
                    style={{
                        maxHeight: '50px',
                        minHeight: '50px',
                        maxWidth: '50px',
                        minWidth: '50px',
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.2)"
                    }}
                />
            ) : (
                <Avatar size={50} onClick={logoAtivaOpenFullScreen} >
                    {profissional && !profissional?.images?.length && profissional?.company_name ?
                        getInitialsName(profissional.company_name) :
                        null}
                </Avatar>
            )}
            <MdMenuMD className="holder icone-branco position-absolute" onClick={logoAtivaOpenFullScreen} style={{ zIndex: '1', bottom: '0', transform: 'translate(-260%, 78%)', scale: '0.5' }} />
        </div>
        {isVisiblelogoAtivaOpenFullScreen && (
            <ModalSimples showModal={isVisiblelogoAtivaOpenFullScreen} handleClose={logoAtivaCloseFullScreen}>
                <ViewProfissionalController updateProfissional={updateProfissional} />
            </ModalSimples>
        )}
    </>);
};
export default ImagemPerfilProfissional;
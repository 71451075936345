import React, { useState } from 'react';
export const LGPDModules = () => {
  const [showModules, setShowModules] = useState(true);
  const toggleModules = () => {
    setShowModules(!showModules);
  };
  return (
    <div className='col-md-6'>
       <abbr title="Clique para ver mais informações"><h5 className={`${showModules ? 'font-weight-bold text-justify text-success' : 'font-weight-bold text-justify text-primary'}`} onClick={toggleModules}>Princípios da LGPD &nbsp;<i className='fa fa-info-circle'></i></h5></abbr>
      {showModules && <>
        <ol className='ml-3'>
          <li><strong>Consentimento Transparente</strong></li>
          <p>
            O primeiro princípio da LGPD é obter o consentimento transparente dos titulares dos dados.
            Isso significa que a organização deve solicitar permissão clara e inequívoca antes de coletar ou processar dados pessoais.
            O consentimento deve ser específico para cada finalidade de tratamento de dados,
            e os titulares dos dados têm o direito de retirar o consentimento a qualquer momento,
            de forma simples e eficaz.
          </p>
           <hr /><li><strong>Finalidade Específica</strong></li>
          <p>
            O segundo princípio da LGPD exige que o tratamento de dados pessoais seja feito para finalidades específicas,
            legítimas e explícitas.
            A organização deve informar claramente o motivo pelo qual os dados estão sendo coletados e como serão usados.
            Além disso, os dados não podem ser tratados posteriormente de maneira incompatível com as finalidades originais sem o consentimento adicional dos titulares dos dados.
          </p>
           <hr /><li><strong>Necessidade</strong></li>
          <p>
            O terceiro princípio da LGPD estabelece que a coleta de dados pessoais deve ser limitada ao necessário para cumprir as finalidades declaradas.
            Isso significa que as organizações devem coletar apenas os dados que são relevantes e indispensáveis para a finalidade específica.
            A coleta excessiva de dados é proibida,
            e as organizações devem garantir que os dados coletados sejam adequados,
            relevantes e limitados ao necessário.
          </p>
           <hr /><li><strong>Qualidade dos Dados</strong></li>
          <p>
            O quarto princípio da LGPD exige que os dados pessoais sejam precisos e, quando necessário, atualizados.
            As organizações devem adotar medidas para garantir a qualidade e a exatidão dos dados coletados e armazenados.
            Os titulares dos dados têm o direito de solicitar a correção de informações imprecisas ou desatualizadas.
          </p>
           <hr /><li><strong>Transparência</strong></li>
          <p>
            O quinto princípio da LGPD enfatiza a importância da transparência no tratamento de dados pessoais.
            As organizações devem fornecer informações claras e acessíveis sobre como os dados são tratados.
            Isso inclui a divulgação de finalidades de tratamento, bases legais, direitos dos titulares dos dados e informações de contato do controlador de dados.
          </p>
           <hr /><li><strong>Segurança</strong></li>
          <p>
            O sexto princípio da LGPD exige que as organizações adotem medidas técnicas e organizacionais adequadas para proteger os dados pessoais contra perda,
            roubo, acesso não autorizado e uso indevido.
            A segurança de dados é essencial para garantir a confidencialidade e a integridade das informações pessoais.
          </p>
           <hr /><li><strong>Acesso e Portabilidade</strong></li>
          <p>
            O sétimo princípio da LGPD garante aos titulares dos dados o direito de acessar seus próprios dados pessoais e,
            quando aplicável, o direito à portabilidade desses dados para outros serviços ou organizações.
            Isso permite que os titulares dos dados tenham controle sobre suas informações e facilitem a mudança de provedores de serviços.
          </p>
           <hr /><li><strong>Não Discriminação</strong></li>
          <p>
            O oitavo princípio da LGPD estabelece que o tratamento de dados pessoais não deve resultar em discriminação injusta ou ilegal dos titulares dos dados.
            As organizações não podem usar os dados pessoais para tomar decisões discriminatórias com base em raça,
            gênero, religião, orientação sexual, entre outros.
          </p>
           <hr /><li><strong>Retenção de Dados</strong></li>
          <p>
            O nono princípio da LGPD estabelece que os dados pessoais devem ser retidos apenas pelo tempo necessário para cumprir as finalidades do tratamento,
            a menos que haja uma obrigação legal de retenção.
            As organizações devem ter políticas de retenção de dados claras e garantir a exclusão segura de informações quando não forem mais necessárias.
          </p>
           <hr /><li><strong> Responsabilidade e Prestação de Contas</strong></li>
          <p>
            O décimo princípio da LGPD estabelece que as organizações são responsáveis pelo tratamento de dados pessoais e devem prestar contas conforme a LGPD.
            Isso inclui a nomeação de um encarregado de proteção de dados (DPO),
            se aplicável, e a adoção de políticas e práticas para garantir a conformidade com a lei.
          </p>
           <hr /><li><strong> Revisão da Política</strong></li>
          <p>
            O décimo primeiro princípio da LGPD estabelece que a política de tratamento de dados deve ser revisada periodicamente
            para garantir a conformidade contínua com a LGPD e pode ser atualizada conforme necessário.
            A revisão regular da política é importante para garantir que a organização
            esteja em conformidade com as regulamentações de proteção de dados em constante evolução.
          </p>
        </ol>
      </>}
    </div>
  );
};
export default LGPDModules;
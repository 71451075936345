import React, { useState, useEffect } from "react";
import { Form, Input, Button, Flex, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ImageDragDrop, BreadcrumbAnt, DragDropPdf, InputPadraoSearch } from "components";
import { api, apiTokenProfissional } from "services";
import MarkdownEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import MarkdownIt from "markdown-it";
import { useProfisional } from "context";
const mdParser = new MarkdownIt();
const ProductForm = ({ product, onUpdate }) => {
  const [form] = useForm();
  const [fileList, setFileList] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);
  const { profissional } = useProfisional();
  useEffect(() => {
    if (product) {
      form.setFieldsValue({
        services_name: product.services_name || "",
        value: product.value || "",
        services_stock: product.services_stock || "",
        services_description: product.services_description || "",
      });
      if (product.services_image) {
        try {
          const images = JSON.parse(product.services_image);
          setFileList(images.map((img) => ({ uid: img.id, url: img.url })));
        } catch (error) {
          console.error("Erro ao carregar imagens do serviço:", error);
        }
      }
    }
  }, [product, form]);
  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
      formData.append("pdfs", pdfFile);
      fileList.forEach((file) => {
        if (file.originFileObj) {
          formData.append("images", file.originFileObj);
        }
      });
      const response = await apiTokenProfissional().post(
        `post_services/${profissional.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success("Produto atualizado com sucesso!");
      onUpdate(response.data);
    } catch (error) {
      console.error("Erro ao atualizar o produto:", error);
    }
  };
  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <div className="col-md-12 bg-light mt-1 py-1">
        <div className="row">
          <div className="col-md-6">
            <Form.Item
              label="Nome"
              name="services_name"
              rules={[{ required: true, message: "Nome obrigatório" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Valor"
              name="value"
              rules={[{ required: true, message: "Valor obrigatório" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Estoque" name="services_stock">
              <Input />
            </Form.Item>
            <Form.Item label="Descrição" name="services_description">
              <MarkdownEditor
                style={{ height: "200px" }}
                renderHTML={(text) => mdParser.render(text)}
              />
            </Form.Item>
            <Form.Item>
              <Flex vertical gap="small" style={{ width: "10%" }}>
                <Button type="primary" htmlType="submit">
                  Salvar
                </Button>
              </Flex>
            </Form.Item>
          </div>
          <div className="col-md-6">
            <ImageDragDrop fileList={fileList} setFileList={setFileList} />
            <Form.Item label="Arquivo de PDF" name="informacao_pdf">
              <DragDropPdf onPdfUpload={setPdfFile} />
            </Form.Item>
          </div>
        </div>
      </div>
    </Form>
  );
};
export const Index = () => {
  const { profissional } = useProfisional();
  const [productList, setProductList] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Estado para o filtro de nome
  useEffect(() => {
    async function fetchServices() {
      try {
        const response = await api.get(`get_services?${profissional.id}&filterName=${searchTerm}`);
        if (Array.isArray(response.data.servicos)) {
          setProductList(response.data.servicos); // Atualiza a lista com os produtos filtrados do backend
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    }
    fetchServices();
  }, [profissional.id, searchTerm]); // A requisição será feita sempre que o searchTerm mudar
  const handleSearch = (e) => {
    setSearchTerm(e.target.value); // Atualiza o termo de busca
  };
  const handleUpdate = (updatedProduct) => {
    setProductList((prev) =>
      prev.map((p) => (p.id === updatedProduct.id ? updatedProduct : p))
    );
  };
  return (
    <>
      <BreadcrumbAnt />
      <InputPadraoSearch
        placeholder="Buscar por nome"
        value={searchTerm}
        onChange={handleSearch}
      />
      {productList.map((product) => (
        <ProductForm key={product.id} product={product} onUpdate={handleUpdate} />
      ))}
    </>
  );
};
export default Index;
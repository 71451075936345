export const pdf_download_6 = ({
    doc,
    imageData,
    pdf_download_footer,
    qrCode,
    professionalInfo,
    imgWidth,
    imgHeight,
    photos,
    drawAttentionIcon,
    modifiedLandmarks,
    landmarks_botox_soma_un,
    landmarks_botox_color,
    pontoDestacado,
    drawTextBox,
    selectedValuesBotox
}) => {
    if (
        !doc ||
        !imageData ||
        !pdf_download_footer ||
        !qrCode ||
        !professionalInfo ||
        !imgWidth ||
        !imgHeight ||
        !photos ||
        !drawAttentionIcon ||
        !modifiedLandmarks ||
        !landmarks_botox_soma_un ||
        !landmarks_botox_color ||
        !drawTextBox ||
        !modifiedLandmarks.FaceFrontal
    ) {
        console.warn("Parâmetros ausentes, página não adicionada.");
        return;
    }
    doc.addPage();
    doc.addImage(require('../assets/fundoPDF.png'), 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
    doc.setFont('times', 'bold');
    doc.setFontSize(25);
    doc.text('Indicações: Botox', 100, 15, { align: 'center' });
    doc.setFont('times', 'bold');
    doc.setFontSize(12);
    doc.addImage(imageData, 'JPEG', 15, 70, imgWidth, imgHeight);
    if (photos.FaceAtencao) {
        doc.text('[ Frontal ]', 15, 25);
    } else {
        doc.text('[ Frontal ]', 15, 25);
        doc.setFont('times', 'normal');
        doc.setFontSize(8);
        drawAttentionIcon(doc, 100, 24, 3); // Coordenadas (30, 25), tamanho do ícone 10
        doc.text('- insira foto em atenção para melhores caracterizações', 35, 25)
        doc.setFillColor(255, 255, 255);
        doc.rect(155.5, 69.5, 40, 40, 'F'); // Ajuste as coordenadas e dimensões conforme necessário
        drawAttentionIcon(doc, 175, 85, 10); // Coordenadas (30, 25), tamanho do ícone 100
        doc.setFont('times', 'bold');
        doc.setFontSize(8);
        doc.text('FALTA FOTO', 166, 95);
        doc.setFont('times', 'normal');
        doc.text('[ Frontal Atenção ]', 164, 105);
    }
    doc.setFont('times', 'normal');
    doc.setFontSize(8);
    const selectValue = selectedValuesBotox.frontal
    console.log(selectValue)
    console.log(modifiedLandmarks.FaceFrontal)
    const combinedText = [
        modifiedLandmarks.FaceFrontal[selectValue].texts.muscle_contraction_pattern
            .replace(/\n/g, '')   // Remove as quebras de linha
            .replace(/\s{2,}/g, ' '),  // Remove espaços duplos
        modifiedLandmarks.FaceFrontal[selectValue].texts.recommendations
            .replace(/\n/g, '')   // Remove as quebras de linha
            .replace(/\s{2,}/g, ' '),  // Remove espaços duplos
        modifiedLandmarks.FaceFrontal[selectValue].customization
            .replace(/\n/g, '')   // Remove as quebras de linha
            .replace(/\s{2,}/g, ' ')   // Remove espaços duplos
    ].join('\n\n');  // Adiciona duas quebras de linha entre os textos para separá-los como parágrafos
    const combinedTextLines = doc.splitTextToSize(combinedText, 180);
    let currentY = 30;
    const lineSpacing = 8;
    doc.text(combinedTextLines, 15, currentY);
    currentY += combinedTextLines.length * lineSpacing;
    doc.addImage(modifiedLandmarks.FaceFrontal[selectValue].image, 'JPEG', 15, 70, 15, 15);
    doc.setFontSize(5);
    doc.text('Ayres, E. L., & Sandoval, M. H. L. (2016). Toxina botulínica na dermatologia: guia prático de técnicas e produtos. 1. ed. Rio de Janeiro: Guanabara Koogan.', 15, 75 + imgHeight);
    doc.text('Arora, G., & Arora, S. (2024). Where and how to use botulinum toxin on the face and neck – Indications and techniques. Journal of Dermatological Applications, 12(3), 45-50. https://cosmoderma.org/content/130/2021/1/1/pdf/CSDM-1-17.pdf', 15, 77 + imgHeight);
    doc.setFontSize(8);
    const somaUNValor = landmarks_botox_soma_un(modifiedLandmarks.FaceFrontal[selectValue].landmarks);
    drawTextBox(doc, 'UN Total Frontal: ' + somaUNValor, 17, 68);
    modifiedLandmarks.FaceFrontal[selectValue].landmarks.forEach((landmark, index) => {
        landmarks_botox_color(doc, landmark, index, imgWidth, imgHeight, pontoDestacado);
    });
    doc.setTextColor(0, 0, 0);  // Define a cor do texto
    pdf_download_footer(doc, qrCode, professionalInfo);
};
import { TitleL2 } from '@components'
import { InfinityLifeParcerias } from '../../../Produtos/components/vender/PreLancamento/InfinityLifeParcerias'
import video from '../assets/videos/DesenvolvimentoInfinityLifeHealthcare.mp4'
import { RiWhatsappFillRi } from '@components/icons'
export const EquipamentosMedicos = () => {
  return (<>
    <section  style={{ minHeight: '95dvh' }}>
      <div className='row'>
        <InfinityLifeParcerias />
        <div className='col-md-6'>
          <div className=" mx-auto rounded d-flex">
            <video className="w-100 rounded" controls >
              <source src={video} type="video/mp4" alt='Parceria para treinamento de software de visão computacional' />
            </video>
          </div>
        <TitleL2>
          Aquisições do Protótipo
        </TitleL2>
        <h5 className='text-justify'>  O protótipo funcional proposto é um hardware composto por uma câmera unidade termográfica.
          O protótipo funcional não inclui um software com soluções em visão computacional, capaz de identificar doenças.
          Estamos atualmente treinando essa IA, o que justifica a comercialização do protótipo funcional a valor de custo.
          Dessa forma, empresas ou profissionais que adquirirem o protótipo nos ajudarão a aprimorar nossa IA.
          Além disso, já realizamos testes em ambiente laboratorial e dominamos a tecnologia envolvida no protótipo funcional
        </h5>
        <div className='d-flex mx-auto justify-content-center'>
          <div className="text-center rounded col-md-6 mt-5 py-3 ">
            <a className=" align-itens-center mx-auto mb-5"
              target={'_blank'} rel={'noopener noreferrer'}
              href={'https://api.whatsapp.com/send?phone=5566996675486&text=Estou%20entrando%20em%20contato%20para%20adiquirir%20o%20protótipo%20funcional%20do%20equipamento%20infinity%20Life%20Healthcare%20'}>
                <RiWhatsappFillRi color={'green'} size={50}/> </a><br />
            Compra uso e treinamento - entre em contato agora mesmo através deste botão
          </div>
        </div>
        </div>
      </div>
    </section >
  </>)
}
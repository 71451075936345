import api from "./api";
export const apiToken = () => {
  const token = localStorage.getItem('token');
  const instance = api.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return instance;
};
export default apiToken;
import { HeaderAnt, TitleL1 } from "components";
import { Button, Image } from "antd";
import { useNavigate } from "react-router-dom";
export const NotFound = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // Navegar de volta para a página anterior
  };
  const handleGoHome = () => {
    navigate("/"); // Navegar para a página inicial
  };
  return (<>
    <HeaderAnt />
    <div style={{ textAlign: "center", padding: "50px" }}>
      <Image
        width={300}
        src="/assets/images/pages/Public/404.png"
        preview={false}
        alt="404 Error"
        style={{ marginBottom: "20px" }}
      />
      <TitleL1> Página não encontrada</TitleL1>
      <p className="text-center">
        <small>
          Desculpe, a página que você procura não existe. Se você acha que algo está quebrado, reporte um problema.
        </small>
      </p>
      <Button className="btn-primary" onClick={handleGoHome}>
        Ir para a Página Inicial
      </Button>
      &emsp;
      <Button className="btn-success" onClick={handleGoBack}>
        Voltar
      </Button>
    </div>
  </>);
};
export default NotFound;
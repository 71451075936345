export const botox_frontal = [
    { value: 'landmarks_botox_frontal_ausencia', label: 'ausencia' },
    { value: 'landmarks_botox_frontal_total', label: 'total' },
    { value: 'landmarks_botox_frontal_central', label: 'central' },
    { value: 'landmarks_botox_frontal_lateral', label: 'lateral' },
  ];
  export const botox_glabela = [
    { value: 'landmarks_botox_glabela_ausencia', label: 'ausencia' },
    { value: 'landmarks_botox_glabela_omega_invertido', label: 'omega invertido' },
    { value: 'landmarks_botox_glabela_omega', label: 'omega' },
    { value: 'landmarks_botox_glabela_setas', label: 'setas' },
    { value: 'landmarks_botox_glabela_u', label: 'u' },
    { value: 'landmarks_botox_glabela_v', label: 'v' },
  ];
  export const botox_periorbital = [
    { value: 'landmarks_botox_periorbital_ausencia', label: 'ausencia' },
    { value: 'landmarks_botox_periorbital_total', label: 'total' },
  ];
  export const botox_oralemento = [
    { value: 'landmarks_botox_oralemento_ausencia', label: 'ausencia' },
    { value: 'landmarks_botox_oralemento_total', label: 'total' },
  ];
  export const botox_dorso_nasal = [
    { value: 'landmarks_botox_dorso_nasal_ausencia', label: 'ausencia' },
    { value: 'landmarks_botox_dorso_nasal_total', label: 'total' },
  ];
  export const botox_asa_nasal = [
    { value: 'landmarks_botox_asa_nasal_ausencia', label: 'ausencia' },
    { value: 'landmarks_botox_asa_nasal_total', label: 'dilatada' },
  ];
  export const botox_base_nasal = [
    { value: 'landmarks_botox_base_nasal_ausencia', label: 'ausencia' },
    { value: 'landmarks_botox_base_nasal_total', label: 'nariz de tomada' },
  ];
  export const botox_sorriso_gengival = [
    { value: 'landmarks_botox_sorriso_gengival_ausencia', label: 'ausencia' },
    { value: 'landmarks_botox_sorriso_gengival_total', label: 'total' },
  ];
  export const botox_periorbicular = [
    { value: 'landmarks_botox_periorbicular_ausencia', label: 'ausencia' },
    { value: 'landmarks_botox_periorbicular_total', label: 'total' },
  ];
  export const botox_mento = [
    { value: 'landmarks_botox_mento_ausencia', label: 'ausencia' },
    { value: 'landmarks_botox_mento_total', label: 'total' },
  ];
  export const botox_angulo_mandibula = [
    { value: 'landmarks_botox_angulo_mandibula_ausencia', label: 'ausencia' },
    { value: 'landmarks_botox_angulo_mandibula_total', label: 'total' },
  ];
  export const botox_platisma = [
    { value: 'landmarks_botox_platisma_ausencia', label: 'ausencia' },
    { value: 'landmarks_botox_platisma_total', label: 'total' },
  ];
  export const botox_bruxismo = [
    { value: 'landmarks_botox_bruxismo_ausencia', label: 'ausencia' },
    { value: 'landmarks_botox_bruxismo_total', label: 'total' },
  ];
export const imagesINF1 = [
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Human1.jpg'), 
    alt: `Primeira imagem do carousel funcionalidades em condições normais do infinity life healthcare 
    - Desmonstrativo da identificação de mais de 5.000 doenças em humanos com a utilização do equipamento, 
    vendo resultado na tela do celular`, 
    text: 'Equipamento identifica mais de 5.000 doenças' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Human6.jpg'), 
    alt:`Segunda imagem do carousel funcionalidades  em condições normais  do infinity life healthcare -
     Desmonstrativo da identificação de momografia térmica não invasiva`, 
    text: 'Mamografia termografica preventiva' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Human7.jpg'), 
    alt: `Terceira imagem do carousel funcionalidades em condições normais  do infinity life healthcare 
     - Desmonstrativo termografia para saúde familia`, 
    text: 'Monitoramento termografico da saúde familiar' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Human2.jpg'), 
    alt: `Quarta imagem do carousel funcionalidades em condições normais  do infinity 
    life healthcare  - Desmonstrativo mostrando que o equipamento nao utiliza radiação para fazer 
    a identificação de mais de 5.000 doenças em humano`, 
    text: 'Equipamento não invasivo sem uso de radiação' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Human3.jpg'), 
    alt: `Quinta imagem do carousel funcionalidades em condições normais  do infinity life healthcare  
    - Desmonstrativo de ponto de doença evidenciado para mostrar que pode identificar doenças em vários 
    estágios de evolução através da termografia, calor emitido pelo corpo, resultado sendo mostrado em 
    computador para  mostrar a adptabilidade do software do equipament`, 
    text: 'Identificação de pontos de calor anormais e evidencia' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Human4.jpg'), 
    alt: `Sexta imagem do carousel funcionalidades em condições normais do infinity life healthcare 
    - Mostrando monitoramento termofrafico de capacete espotivo para monitoramento de procedimentos cirugicos, 
    fraturas e semelhantes`, 
    text: 'Monitoramento de procedimentos cirurgicos' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Human5.jpg'), 
    alt: `Sétima imagem do carousel funcionalidades em condições normais do infinity life healthcare -
     Monitoramento termografico de atleta`, 
    text: 'Monitoramento de atletas de alta performance' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Human8.jpg'), 
    alt: `Oitava imagem do carousel funcionalidades em condições normais  do infinity life healthcare  - 
     Monitoramento termografico de atletas mostrando atletla em esteira para fazer a evidência de calor execessivo em pontos específico`, 
    text: 'Identificação de aquecimento anormais' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Human9.jpg'), 
    alt: `Nona imagem do carousel funcionalidades  em condições normais do infinity life healthcare -
     Mostrando que utilizamos inteligência artificial para fazer os pré-laudos termograficos de forma automat`, 
    text: 'Utilização de inteligência artificial para laudos' },
];
export const cellCountINF1 = 9;
//Carousel INF Funções de pesquisa
//Carousel BIO funções normais 
export const imagesBIO1 = [
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Animal/OtherAnimals1.jpeg'), 
    alt: `Primeira imagem do carousel funcionalidades em condições normais biotério forte - Desmonstrativo da `, 
    text: 'Identificar pontos de calor anormais nos animais' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Animal/OtherAnimals2.jpeg'), 
    alt: `Segunda imagem do carousel funcionalidades  em condições normais biotério forte - Desmonstrativo da`, 
    text: 'Monitoramento de animais de alta performance' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Animal/OtherAnimals3.jpeg'), 
    alt: `Terceira imagem do carousel funcionalidades em condições normais biotério forte  - Desmonstrativo te`, 
    text: 'Monitoramento de animais silvestres e domesticos' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Animal/OtherAnimals3.jpeg'), 
    alt: `Quarta imagem do carousel funcionalidades em condições normais biotério forte  - Desmonstrativo mosturas`, 
    text: 'Monitoramento de procedimentos cirurgicos' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Animal/OtherAnimals1.jpeg'), 
    alt: `Quinta imagem do carousel funcionalidades em condições normais biotério forte  - Desmonstrativo de p`, 
    text: 'Terrmografia médica veterinária de precisão I.A.' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Animal/OtherAnimals1.jpeg'), 
    alt: `Sexta imagem do carousel funcionalidades em condições normais biotério forte - Mostrando monitoramen`, 
    text: 'Auxílio na manutenção da saúde animal' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Animal/OtherAnimals2.jpeg'), 
    alt: `Sétima imagem do carousel funcionalidades em condições normais biotério forte - Monitoramento termog`, 
    text: 'Equipamento não invasivo sem uso de radiação' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Animal/OtherAnimals3.jpeg'), 
    alt: `Oitava imagem do carousel funcionalidades em condições normais biotério forte  -  Monitoramento term`, 
    text: 'Equipamento ideal para quase todos tipos de ambiente' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Animal/OtherAnimals3.jpeg'), 
    alt: `Nona imagem do carousel funcionalidades  em condições normais biotério forte - Mostrando que utilizatura`, 
    text: 'Software autoindicativo multi plataformas' },
];
export const cellCountBIO1 = 9;
//Carousel BIO Funções de pesquisas
export const imagesBIO2 = [
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Animal/Rats1.jpeg'),
     alt: `Primeira imagem do carousel funcionalidades em condições de pesquisas do biotério forte - Desmonstrativo da id`, 
     text: 'Analisar a homogenização dos grupos' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Animal/Rats2.jpeg'),
     alt: `Segunda imagem do carousel funcionalidades  em condições de pesquisas  do biotério forte - Desmonstrativo da i`, 
     text: 'Analisar diferencial termografico dos grupos' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Animal/Ambiente.jpeg'),
     alt: `Terceira imagem do carousel funcionalidades em condições de pesquisas  do biotério forte  - Desmonstrativo ter `, 
     text: 'Analisar e controlar fatores ambientais do biotério' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Animal/Rats4.jpeg'),
     alt: `Quarta imagem do carousel funcionalidades em condições de pesquisas  do biotério forte  - Desmonstrativo mostruras `, 
     text: 'Analisar fatores dietéticos dos animais' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Animal/Rats1.jpeg'),
     alt: `Quinta imagem do carousel funcionalidades em condições de pesquisas  do biotério forte  - Desmonstrativo de po`, 
     text: 'Terrmografia médica veterinária de precisão I.A.' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Animal/Rats1.jpeg'),
     alt: `Sexta imagem do carousel funcionalidades em condições de pesquisas do biotério forte - Mostrando monitoramento`, 
     text: 'Pré laudo das pesquisas em tempos específicados' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Animal/Rats2.jpeg'),
     alt: `Sétima imagem do carousel funcionalidades em condições de pesquisas do biotério forte - Monitoramento termogra`, 
     text: 'Equipamento não invasivo sem uso de radiação' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Animal/Rats3.jpeg'),
     alt: `Oitava imagem do carousel funcionalidades em condições de pesquisas  do biotério forte  -  Monitoramento termo`, 
     text: 'Compara pesquisas para saber se deu certo ou não' },
    { src: require('@pages/Public/Home/components/assets/images/Termografia/Animal/Rats4.jpeg'),
     alt: `Nona imagem do carousel funcionalidades  em condições de pesquisas do biotério forte - Mostrando que utilizamo`, 
     text: 'Dentre muitas outra medidas e análises de pesquisa' },
];
export const cellCountBIO2 = 9;
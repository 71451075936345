import { MasterMedicacao } from "./MasterMedicacao";
import { FooterProfissional,HeaderAnt, NavProfissionalMain } from "../../../../components";
export const MedicacaoProfissional = () => {
    return (<>
   <HeaderAnt/>
        <div className="d-flex mt-3">
            <NavProfissionalMain />
            <div className="w-100">
                <MasterMedicacao />
                <FooterProfissional />
            </div>
        </div>
    </>)
};
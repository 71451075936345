import React, { useContext } from 'react';
import { AuthContext } from 'guard';
import { MaisInfoMenosInfo } from '@components';
import { Link } from 'react-router-dom';
import { Image } from 'antd';
import { apiToken } from 'services';
import { handleUpdateProfissionalController } from 'controllers/functions';
export const UpdateProfissionalVinculadoController = ({ professionalData, updateProfissional }) => {
    const { user } = useContext(AuthContext);
    const handleExcludeProfessionalUnit = async (id) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                return;
            }
            const id = user.id;
            const response = await apiToken().put(
                `updateProfessionalUnitState/${id}`,
            );
            if (response.status === 200) {
                alert(`Unidade Profissional com ID ${id} marcada como excluída na API.`);
                const updatedData = professionalData.filter((professionalUnit) => professionalUnit.id !== id);
                localStorage.setItem('professionalData.professionalUnitData', JSON.stringify(updatedData));
            }
        } catch (error) {
            alert(id);
            alert('Erro ao atualizar o estado da unidade profissional:', error);
        }
    };
    const handleUpdateProfissionalEmpresa = (item) => {
        try {
            const companyInfo = {
                ...item.company,
                ...item.company_unit,
            };
            localStorage.setItem('drive', JSON.stringify(companyInfo));
            localStorage.setItem('cadastroProfissional', 'SIM');
        } catch (error) {
            alert('Erro ao adicionar informações ao localStorage:', error);
        }
    };
    if (professionalData.professionalUnitData > 0) {
        return (<>
            {professionalData !== null && <>
                <h5 className='font-weight-bold'><strong>Vínculos Profissionais</strong></h5>
                <div>
                    {professionalData?.professionalUnitData?.map((item) => (
                        <div className='bg-light rounded mt-1 col-md-12' key={item.id}>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-2'>
                                        <Image className='w-100 mt-1 rounded' src={item.company_unit.images[0]} alt="Professional Image" />
                                    </div>
                                    <div className='col-md-10 mt-1'>
                                        <small className='font-weight-bold'>Nome da Empresa:<small> {item.company.company_name}</small></small><br />
                                        <small className='font-weight-bold'>Cargo:<small> {item.company_unit.professional_type}</small></small><br />
                                        <Link onClick={() => handleUpdateProfissionalController(item, updateProfissional)}><i className='fa fa-suitcase text-dark mr-2 ml-2'></i></Link>
                                        <Link onClick={() => handleUpdateProfissionalEmpresa(item.id)}><i className='fa fa-edit text-warning mr-2 ml-2'></i></Link>
                                        <Link onClick={() => handleExcludeProfessionalUnit(item.id)}><i className='fa fa-trash text-danger mr-2 ml-2'></i></Link>
                                        <hr />
                                        <MaisInfoMenosInfo title={'Mais info'} text={<>
                                            <p>Id: {item.id}</p>
                                            <p>Professional Id: {item.company_unit.professional_id}</p>
                                            <p>Company Unit Id: {item.company_unit.company_unit_id}</p>
                                            <p>Convite Id: {item.company_unit.convite_id}</p>
                                            <p>State: {item.company_unit.state}</p>
                                        </>} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </>}
        </>);
    }
};
export default UpdateProfissionalVinculadoController;
import { ButtonAPI, MaisInfoMenosInfo } from '@components';
import CreateTrainIATextStage2Info from './info/CreateTrainIATextStage2Info';
function CreateTrainIATextStage2({
  onTrain,
  isLoading,
  trainingCompleted,
  handleTrain,
  epoch,
  setEpoch,
  wordCount,
  setWordCount,
  fragmentSize,
  setFragmentSize
}) {
  return (<>
    <div className="col-md-5 mt-2 rounded"
      style={{
        backgroundColor: '#f1f1f1', /* Cor do background */
        padding: '10px', /* Espaçamento interno em todos os lados */
        marginLeft: '10px', // Add margin of 5 pixels to the left
        marginRight: "10px", // Add margin of 5 pixels to the right
      }}>
      <h5 className="font-weight-bold mt-3 mb-3">Criar I.A. - Stage 2 - Criar e Treinar</h5>
      <input
        className={`input-field form-control mt-1 form-control-lg text-dark text-center`}
        type="number"
        placeholder="Número de épocas"
        value={epoch}
        onChange={(e) => setEpoch(parseInt(e.target.value))}
      />
         <input
        className={`input-field form-control mt-1 form-control-lg text-dark text-center`}
        type="number"
        placeholder="Número de fragmentos"
        value={fragmentSize}
        onChange={(e) => setFragmentSize(parseInt(e.target.value))}
      />
      <input
        className={`input-field form-control mt-1 form-control-lg text-dark text-center`}
        type="number"
        placeholder="Número de palavras"
        value={wordCount}
        onChange={(e) => setWordCount(parseInt(e.target.value))}
      />
      <ButtonAPI
        isLoading={isLoading}
        className="btn btn-primary w-100"
        onClick={handleTrain}
        disabled={trainingCompleted}
      >
        Treinar
      </ButtonAPI>
    <MaisInfoMenosInfo text={<CreateTrainIATextStage2Info />}></MaisInfoMenosInfo>
    </div>
    </>);
}
export default CreateTrainIATextStage2;
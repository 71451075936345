import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { InputPadraoSearch } from '@components';
import ServicesCardList from './ServicesCardList';
import { AutoComplete, Spin, Empty } from 'antd';
import "@pages/Public/Produtos/components/comprar/styles/promotion.css";
import { post_cart } from '@pages/Public/Carrinho/components/services/post_cart';
import { api, baseURL } from 'services';
import { useUser } from '@context/components/UserProvider';
import { AuthContext } from '@guard/index';
import { MdMenuMD, MdSearchMD } from '@components/icons';
const { Option } = AutoComplete;
export const ServicosList = () => {
  const [servicos, setServicos] = useState([]);
  const [quantidadeExibida] = useState(10);
  const [totalServices, setTotalServices] = useState(0);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [isExpanded, setIsExpanded] = useState(false);
  const [filters, setFilters] = useState({
    name: '',
    type: '',
    priceMin: '',
    priceMax: '',
    company: '',
    companyUnit: '',
    professional: ''
  });
  const [isFetching, setIsFetching] = useState(false);
  const bottomRef = useRef(null);
  const { user } = useUser()
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || []);
  const [hasMore, setHasMore] = useState(true);
  const [filtersActive, setFiltersActive] = useState(false);
  const [screenY, setScreenY] = useState(window.innerWidth); // Inicialize com a altura atual da tela
  const fetchServicos = useCallback(async () => {
    if (isFetching || !hasMore) return; // Prevent fetching if already fetching or no more services
    setIsFetching(true);
    try {
      const url = new URL(`${baseURL}get_services?`);
      Object.entries(filters).forEach(([key, value]) => {
        if (value) url.searchParams.append(`filter${key.charAt(0).toUpperCase() + key.slice(1)}`, value);
      });
      url.searchParams.append('pagina', paginaAtual);
      url.searchParams.append('resultadosPorPagina', quantidadeExibida);
      const response = await api.get(url);
      // Append new services to the existing ones, avoiding duplicates
      setServicos((prev) => {
        const newUniqueServicos = response.data.servicos.filter(
          (service) => !prev.some((existingService) => existingService.service_id === service.service_id)
        );
        return [...prev, ...newUniqueServicos];
      });
      setTotalServices(response.data.totalServicos);
      // Check if there are more services available
      if (paginaAtual * quantidadeExibida >= response.data.totalServicos) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error) {
      console.error("Error fetching servicos:", error);
    } finally {
      setIsFetching(false);
    }
  }, [filters, paginaAtual, quantidadeExibida, isFetching, hasMore]);
  useEffect(() => {
    // Check if all filters are active or not
    const allFiltersFilled = Object.values(filters).every(value => value !== '');
    setFiltersActive(allFiltersFilled);
  }, [filters]); // This will update whenever filters change
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 &&
        !filtersActive &&
        servicos.length < totalServices
      ) {
        // Garantir que só envia a nova requisição quando a anterior for concluída
        if (!hasMore) {
          setHasMore(true); // Ativar o carregamento de novos serviços
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [servicos.length, totalServices, hasMore]);
  useEffect(() => {
    if (hasMore) {
      setPaginaAtual((prev) => prev + 1);
      fetchServicos().then(() => {
        setHasMore(false); // Desativar spinner após o carregamento
      });
    }
  }, [hasMore]);
  const handleFilterChange = (filterKey, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: value
    }));
    setServicos([]); // Reset services on filter change
    setPaginaAtual(1); // Reset to first page when a filter is applied
    setHasMore(true); // Reset the hasMore flag when filters change
  };
  const handleAddToCart = (product) => {
    post_cart({product, cart, setCart, user});
  };
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (<>
    <section ref={bottomRef} style={{ minHeight: '95dvh' }}>
      <InputPadraoSearch
        type="text"
        placeholder='Buscar serviços por nome...'
        id="filtro"
        value={filters.name}
        onChange={(e) => handleFilterChange('name', e.target.value)}
      />
      <div id="mostraMaisOpcoesBusca" className={`collapse  align-items-center justify-content-center ${isExpanded ? 'show' : ''}`}>
        <div className="d-flex flex-wrap justify-content-center">
          <div className='flex-wapper col-md-3 mt-2'>
            <small>Localização cidade</small><br />
            <AutoComplete
              className="w-100 text-dark text-center"
              value={filters.filterType}
              onChange={(value) => setFilters(value)}
              placeholder="Selecione o Tipo"
            >
              {Array.from(new Set(servicos.map((servicos) => servicos.servicos_type))).map((servicos_type) => (
                <Option key={servicos_type} value={servicos_type}>
                  {servicos_type}
                </Option>
              ))}
            </AutoComplete>
          </div>
          <div className='flex-wapper col-md-3 mt-2'>
            <small>Tipo de profissional</small><br />
            <AutoComplete
              className="w-100 text-dark text-center"
              value={filters.filterType}
              onChange={(value) => setFilters(value)}
              placeholder="Selecione o Tipo"
            >
              {Array.from(new Set(servicos.map((servicos) => servicos.professional_id))).map((professional_id) => (
                <Option key={professional_id} value={professional_id}>
                  {professional_id}
                </Option>
              ))}
            </AutoComplete>
          </div>
          <div className='flex-wapper col-md-3 mt-2'>
            <small>Tipo de atendimento</small><br />
            <AutoComplete
              className="w-100 text-dark text-center"
              value={filters.filterType}
              onChange={(value) => setFilters(value)}
              placeholder="Selecione o Tipo"
            >
              {Array.from(new Set(servicos.map((servicos) => servicos.servicos_type))).map((servicos_type) => (
                <Option key={servicos_type} value={servicos_type}>
                  {servicos_type}
                </Option>
              ))}
            </AutoComplete>
          </div>
          <div className='flex-wapper col-md-3 mt-2'>
            <small>Tipo de serviço</small><br />
            <AutoComplete
              className="w-100 text-dark text-center"
              value={filters.filterType}
              onChange={(value) => setFilters(value)}
              placeholder="Selecione o Tipo"
            >
              {Array.from(new Set(servicos.map((servicos) => servicos.servicos_type))).map((servicos_type) => (
                <Option key={servicos_type} value={servicos_type}>
                  {servicos_type}
                </Option>
              ))}
            </AutoComplete>
          </div>
          <div className='flex-wapper col-md-3 mt-2'>
            <small>Empresa</small><br />
            <AutoComplete
              className="w-100 text-dark text-center"
              value={filters.company}
              onChange={(value) => setFilters(value)}
              placeholder="Selecione a Empresa"
            >
              {Array.from(new Set(servicos.map((servicos) => servicos.company_id))).map((company_id, index) => (
                <Option key={`${company_id}_company_id_${index}`} value={company_id}>
                  {company_id}
                </Option>
              ))}
            </AutoComplete>
          </div>
          <div className='flex-wapper col-md-3 mt-2'>
            <small>Empresa unidade</small><br />
            <AutoComplete
              className="w-100 text-dark text-center"
              value={filters.filterCompanyUnit}
              onChange={(value) => setFilters(value)}
              placeholder="Selecione a Empresa Unidade"
            >
              {Array.from(new Set(servicos.map((servicos) => servicos.company_unit_id))).map((company_unit_id, index) => (
                <Option key={`${company_unit_id}_company_unit_id_${index}`} value={company_unit_id}>
                  {company_unit_id}
                </Option>
              ))}
            </AutoComplete>
          </div>
          <div className='flex-wapper col-md-3 mt-2'>
            <small>Profissional</small><br />
            <AutoComplete
              className="w-100 text-dark text-center"
              value={filters.professional}
              onChange={(value) => setFilters(value)}
              placeholder="Selecione o Profissional"
            >
              {Array.from(new Set(servicos.map((servicos) => servicos.professional_id))).map((professional_id) => (
                <Option key={professional_id} value={professional_id}>
                  {professional_id}
                </Option>
              ))}
            </AutoComplete></div>
          <div className='flex-wapper col-md-3 mt-2'>
            <small>Valor mínimo</small><br />
            <AutoComplete
              className="w-100 text-dark text-center"
              type="number"
              placeholder="a partir"
              value={filters.filterPriceMin}
              onChange={(value) => setFilters(value)}
            >
              {Array.from(new Set(servicos.map((servicos) => servicos.value))).map((value) => (
                <Option key={value} value={value}>
                  {value}
                </Option>
              ))}
            </AutoComplete>
          </div>
          <div className='flex-wapper col-md-3 mt-2'>
            <small>Valor máximo</small><br />
            <AutoComplete
              className="w-100 text-dark text-center"
              type="number"
              placeholder="até"
              value={filters.filterPriceMax}
              onChange={(value) => setFilters(value)}
            >
              {Array.from(new Set(servicos.map((servicos) => servicos.value))).map((value) => (
                <Option key={value} value={value}>
                  {value}
                </Option>
              ))}
            </AutoComplete>
          </div>
          <div className="col-md-6 text-center collapse">
            <div className="input-group-append col-md-12 mt-3 mx-auto">
              <input
                type="text"
                className="form-control mt-1 col-md-11 form-control-lg text-dark"
                placeholder='Buscar local'
                style={{ borderRadius: '20px 0px 0px 20px' }}
                id="inputEscolherLocal"
                value={""}
                onChange={''}
              />
              <div className="input-group-append"><button disabled className=" mt-1 btn btn-success" type="button" style={{ borderRadius: '0px 20px 20px 0px' }}><i
                className="fa fa-search text-dark mt-1"></i></button></div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12" style={{ padding: '10px' }}>
        <div className='d-flex flex-wrap '>
          <div className={`input-group-append h-100 w-100 col-md-6`} style={{ padding: '0px', alignItems: 'center' }}>
            <small className={`font-weight-bold ${isExpanded ? "text-danger " : 'text-primary'}`}
              onClick={toggleExpand}
              style={{ cursor: "pointer", border: 'none', background: 'none', padding: 0, margin: 0, fontFamily: ' Arial, Helvetica' }}                             >
              <MdMenuMD />   {`${isExpanded ? 'menos opções de filtros' : 'mais opções de filtros'}`} <MdSearchMD />
            </small>
          </div>
          <div className={`col-md-6     font-weight-bold  ${screenY > 1058 ? 'text-right' : ''}`}
            style={{ padding: '0px' }}>
            <small
              style={{ fontFamily: ' Arial, Helvetica' }}>Serviços encontrados:
              <b style={{ fontFamily: ' Arial, Helvetica' }}>{totalServices} </b> </small> </div>
        </div>
      </div>
      <ServicesCardList servicesData={servicos} onAddToCart={handleAddToCart} />
      {/* Mostrar o spinner enquanto estiver buscando novos serviços */}
      {isFetching && <div className="text-center"><Spin /></div>}
      {/* Mostrar a mensagem de fim da lista */}
      {!hasMore && <div className="text-center"><Empty description="Não há mais serviços para carregar" /></div>}
    </section>
  </>);
};
export default ServicosList;
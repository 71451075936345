import React, { useState, useEffect } from "react";
import apiTokenProfissional from "@services/apiTokenProfissional";
import { useProfisional } from "@context/index";
import { InputPadrao } from "@components/Input";
import { ButtonAPI } from "@components/button";
import { SelectAnt } from "@components/select";
import { TitleL2 } from "@components/text";
const TestApiTokenProfissional = () => {
  const { profissional, updateProfissional } = useProfisional();
  const [formData, setFormData] = useState({});
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [method, setMethod] = useState("GET");
  const [id, setId] = useState("1");
  const [body, setBody] = useState("{}");
  const [urlParams, setUrlParams] = useState("");
  useEffect(() => {
    if (profissional && Object.keys(profissional).length > 0) {
      setFormData(profissional);
    }
  }, [profissional]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = () => {
    updateProfissional(formData);
  };
  const makeApiRequest = async () => {
    try {
      let url = `test_token_profissional/${id}`;
      if (method === "GET" && urlParams) {
        const queryParams = new URLSearchParams({ ...formData, ...JSON.parse(urlParams) }).toString();
        url += `?${queryParams}`;
      }
      const config = {
        method,
        url,
        headers: {
          Authorization: `Bearer your_token_here`, // Substitua com seu token real
          "Content-Type": "application/json",
        },
        data: ["POST", "PUT"].includes(method) ? JSON.parse(body) : null,
      };
      setLoading(true);
      const res = await apiTokenProfissional(config); // Chamada para o serviço de API
      setResponse(res.data);
    } catch (error) {
      setError(error.response ? error.response.data : "Erro desconhecido");
    } finally {
      setLoading(false);
    }
  };
  const handleSelectChange = (value) => {
    setMethod(value); // Atualiza o método com o valor selecionado
  };
  return (
    <div className="col-md-12 text-white">
      <small>Context = useProfissional</small>
      <div className="row">
        {Object.keys(formData).length === 0 ? (
          <p>Carregando dados...</p>
        ) : (
          Object.keys(formData).map((key) => (
            <div className="col-md-3 text-white" key={key}>
              {Array.isArray(formData[key]) ? (
                <pre className="text-white" style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", maxWidth: "100%", overflowX: "auto" }}>
                  {JSON.stringify(formData[key], null, 2)}
                </pre>
              ) : (
                <InputPadrao
                  type="text"
                  title={key}
                  name={key}
                  value={formData[key] || ""}
                  onChange={handleInputChange}
                />
              )}
            </div>
          ))
        )}
      </div>
      <ButtonAPI onClick={handleSubmit}>
        Atualizar Contexto
      </ButtonAPI>
      <small>Testar Rota de Autenticação</small>
        <div className="row mb-2">
          <div className="col-md-3">
            <SelectAnt
              value={method}
              onChange={handleSelectChange} // Corrigido para lidar com o valor diretamente
              title={"Escolha o método:"}
              options={[
                { label: "GET", value: "GET" },
                { label: "POST", value: "POST" },
              ]}
            />
          </div>
          <div className="col-md-3">
            <InputPadrao
              title={"ID da Requisição:"}
              type="text"
              value={id}
              onChange={(e) => setId(e.target.value)}
            />
          </div>
          {method === "GET" && (
            <div className="col-md-3">
              <InputPadrao
                title={"Parâmetros da URL (JSON):"}
                type="text"
                value={urlParams}
                onChange={(e) => setUrlParams(e.target.value)}
                placeholder='Exemplo: {"key1": "value1", "key2": "value2"}'
              />
            </div>
          )}
          {["POST", "PUT"].includes(method) && (
            <label>
              Corpo da Requisição (JSON):
              <textarea
                rows="3"
                value={body}
                onChange={(e) => setBody(e.target.value)}
              />
            </label>
          )}
        </div>
      <ButtonAPI onClick={makeApiRequest} disabled={loading}>
        {loading ? "Enviando..." : "Enviar Requisição"}
      </ButtonAPI>
      {error && (
        <div className="text-white">
          <TitleL2>Erro:</TitleL2>
          <pre>{error}</pre>
        </div>
      )}
      {response && (
        <div
          className={"text-white"}
          style={{ marginTop: "20px", padding: "10px", border: "1px solid black" }}
        >
          <small>Resposta:</small>
          <pre className="text-white">{JSON.stringify(response, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};
export default TestApiTokenProfissional;
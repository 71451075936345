import React, { useState } from 'react';
import { Card, Avatar, Descriptions, Image, Button } from 'antd';
import { baseURL } from '@services/api';
import { ModalSimples } from '@components/index';
const { Meta } = Card;
// Componente separado para renderizar descrições com navegação
const RenderDescriptions = ({ data }) => {
  const [currentPath, setCurrentPath] = useState(null);
  const handleItemClick = (path) => {
    setCurrentPath(path);
  };
  const handleBack = () => {
    const parentPath = currentPath.split('.').slice(0, -1).join('.');
    setCurrentPath(parentPath || null);
  };
  const renderItems = (data, path = '') => {
    return Object.entries(data).map(([key, value]) => {
      const newPath = path ? `${path}.${key}` : key;
      const isObject = value && typeof value === 'object' && !Array.isArray(value);
      if (isObject) {
        return (
          <Descriptions.Item label={key} key={newPath}>
            <Button type="link" onClick={() => handleItemClick(newPath)}>
              ➡ 
            </Button>
          </Descriptions.Item>
        );
      }
      return (
        <Descriptions.Item label={key} key={newPath}>
          {Array.isArray(value) ? value.join(', ') : String(value)}
        </Descriptions.Item>
      );
    });
  };
  const getDataByPath = (path) => {
    return path.split('.').reduce((acc, key) => acc?.[key], data);
  };
  const currentData = currentPath ? getDataByPath(currentPath) : data;
  return (
    <div>
      {currentPath && (
        <Button onClick={handleBack} style={{ marginBottom: '10px' }}>
          ⬅ Voltar
        </Button>
      )}
      <Descriptions bordered column={1} size="small">
        {renderItems(currentData, currentPath)}
      </Descriptions>
    </div>
  );
};
// Componente principal
export const ProfessionalCardServices = ({ professional }) => {
  const [showModal, setShowModal] = useState(false);
  if (!professional || !professional.professional_name) return null;
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  return (
    <>
      <Card
        hoverable
        style={{ width: '100%', marginRight: '8px', margin: 0, padding: 0 }}
        bodyStyle={{ margin: 0, padding: 0 }}
        onClick={handleOpenModal}
      >
        <Meta
          avatar={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                margin: 0,
                padding: 0,
              }}
            >
              <Avatar
                style={{ margin: 0, padding: 0 }}
                src={`${baseURL}imagem?diretorio=../../services/pages/Private/Profissional/Cadastro/FotoFaceProfissional/Database/&image=${professional.images}`}
              />
            </div>
          }
          title={
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {professional.professional_name}
            </div>
          }
          description={professional.professional_address?.city || 'Não disponível'}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 0,
            padding: 0,
          }}
        />
        <style jsx>{`
          .ant-card-meta-title {
            margin-bottom: 0 !important;
          }
          .ant-card-meta-description {
            margin-top: 0 !important;
          }
        `}</style>
      </Card>
      <ModalSimples showModal={showModal} handleClose={handleCloseModal}>
        <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
          <Image
            width={150}
            height={150}
            className="rounded-circle"
            style={{ margin: 0, padding: 0 }}
            src={`${baseURL}imagem?diretorio=../../services/pages/Private/Profissional/Cadastro/FotoFaceProfissional/Database/&image=${professional.images}`}
          />
        </div>
        <RenderDescriptions data={professional} />
      </ModalSimples>
    </>
  );
};
export default ProfessionalCardServices;
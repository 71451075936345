// Tramento de url com [ # ] rolar até o elemento id. Exemplo - http://localhost:3000/#elementoid
import { useEffect } from 'react';
export const UrlCheckerHash = () => {
  useEffect(() => {
    const clickElement = (selector) => {
      const element = document.querySelector(selector);
      if (element) {
        element.click();
      }
    };
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const checkUrlAndClick = async () => {
      const originalUrl = window.location.href;
      const url = new URL(originalUrl);
      const hash = url.hash; // Exemplo: "#apresentacaoServicos#SolucaoIAAvancadaServicos?convite_id=faskf%20ask"
      const search = url.search; // Parte da query string (se houver)
      if (!hash) return;
      let storedParams = '';      // Vai armazenar o "?" e tudo que vem depois
      let hashWithoutParams = hash;
      if (hash.includes('?')) {
        const [base, params] = hash.split('?');
        hashWithoutParams = base;       // Ex: "#apresentacaoServicos#SolucaoIAAvancadaServicos"
        storedParams = '?' + params;      // Ex: "?convite_id=faskf%20ask"
      }
      const hashParts = hashWithoutParams.split('#').filter(Boolean);
      for (let i = 0; i < hashParts.length; i++) {
        const h = hashParts[i];
        clickElement(`#${h}`);
        await delay(500);
      }
      console.log("Stored parameters:", storedParams);
      const newUrl = `${url.pathname}${hashWithoutParams}${storedParams}${search}`;
      window.history.replaceState(null, '', newUrl);
    };
    checkUrlAndClick();
    window.addEventListener('hashchange', checkUrlAndClick);
    return () => {
      window.removeEventListener('hashchange', checkUrlAndClick);
    };
  }, []);
  return null;
};
export default UrlCheckerHash;
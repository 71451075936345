import { apiToken } from "services";
export const handleSendImage = async (user, image, setIsLoading, setUser) => {
  setIsLoading(true);
  try {
    const formData = new FormData();
    formData.append('id', `${user.id}`);
    formData.append('file', dataURItoBlob(image));
    await apiToken().post(`fotoFace/${user.id}`, formData);
    const updatedUser = { ...user, images: image };
    setUser(updatedUser);
    alert('Imagem enviada com sucesso!');
  } catch (error) {
    console.error(error);
    alert('Erro ao enviar a imagem.', error);
  } finally {
    setIsLoading(false);
  }
};
const dataURItoBlob = (dataURI) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: mimeString });
  return blob;
};
import React, { useEffect, useState } from "react";
import { ButtonCadastrar, MaisInfoMenosInfo, TitleL1, ChartPieData } from "components";
import DadosDeParceriasInfo from "../info/DadosDeParceriasInfo";
import { api } from "services";
export const DadosDeProjetos = () => {
    const [parceriasCadastradas, setParceriasCadastradas] = useState({
        parceriasCadastradas: 0,
        FeedBack: 0,
        EmDesenvolvimento: 0,
        Resolvidas: 0,
    });
    const [mediaDiasResposta, setMediaDiasResposta] = useState(0);
    // Sempre inicia com valores padrão (não aparece vazio)
    const [chartData, setChartData] = useState([
        ["Categoria", "Quantidade", "Cor"],
        ["FeedBack", 1, "#FF6384"],
        ["Em Desenvolvimento", 1, "#36A2EB"],
        ["Resolvidas", 1, "#4CAF50"],
    ]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`get_partnerships`);
                const data = response.data;
                const quantidadeParcerias =
                    (data.active || 0) + (data.feedback || 0) + (data.development || 0) + (data.resolved || 0);
                const feedbackCount = data.feedback || 0;
                const emDesenvolvimentoCount = data.development || 0;
                const resolvidasCount = data.resolved || 0;
                setParceriasCadastradas({
                    parceriasCadastradas: quantidadeParcerias,
                    FeedBack: feedbackCount,
                    EmDesenvolvimento: emDesenvolvimentoCount,
                    Resolvidas: resolvidasCount,
                });
                // Se não houver dados reais, mantemos o gráfico preenchido para não parecer "vazio"
                setChartData([
                    ["Categoria", "Quantidade", "Cor"],
                    ["FeedBack", feedbackCount || 1, "#FF6384"],
                    ["Em Desenvolvimento", emDesenvolvimentoCount || 1, "#36A2EB"],
                    ["Resolvidas", resolvidasCount || 1, "#4CAF50"],
                ]);
            } catch (error) {
                console.error("Erro ao carregar os dados:", error);
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        const obterMediaDiasResposta = async () => {
            try {
                const response = await api.get(`get_partnerships_report_average`);
                const mediaDiasResposta = response.data.mediaDiasResposta || 0;
                setMediaDiasResposta(mediaDiasResposta);
            } catch (error) {
                console.error("Erro ao obter a média de dias de resposta:", error);
                setMediaDiasResposta(0);
            }
        };
        obterMediaDiasResposta();
    }, []);
    return (
        <section style={{ minHeight: "95dvh" }}>
            <div className="row">
                <div className="col-md-6">
                    <TitleL1>Dados de Projetos</TitleL1>
                    <h5 className="text-justify">
                        Aqui mostramos o andamento dos projetos já cadastrados no sistema como uma forma de informativo em tempo
                        real para o público geral.                        Caso tenha cadastrado algum projeto conosco, eles fazem parte desses números
                    </h5>
                    <br />
                </div>
                <div className="col-md-6 text-center d-flex flex-wrap justify-content-center mx-auto align-items-center">
                    <p className="text-center">
                        <img
                            src={require("../assets/ImagensDoSistema.png")}
                            alt="Liberdade Financeira"
                            style={{ maxHeight: "280px", maxWidth: "580px", minHeight: "280px" }}
                            className="col-md-12"
                        />
                        <small className="mt-5">
                            Transparência total no desenvolvimento
                        </small>
                    </p>
                </div>
                <div className="bg-light text-center d-flex flex-wrap justify-content-center mx-auto align-items-center col-md-12">
                    <div className="col-md-12 text-left mt-1" style={{padding:'0px'}}>
                        <small className="font-weight-bold"><b>Gráficos informativos</b></small>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex flex-column align-items-center">
                            <div style={{ marginBottom: "20px", maxWidth: "100%" }}>
                                <ChartPieData data={chartData} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <small className="font-weight-bold mt-3">
                            <strong>Número de projetos</strong>
                        </small>
                        <br />
                        <small className="font-weight-bold">Cadastros: {parceriasCadastradas.parceriasCadastradas}</small>
                        <br />
                        <small className="font-weight-bold">FeedBack: {parceriasCadastradas.FeedBack}</small>
                        <br />
                        <small className="font-weight-bold">Em Dev.: {parceriasCadastradas.EmDesenvolvimento}</small>
                        <br />
                        <small className="font-weight-bold">Resolvidas: {parceriasCadastradas.Resolvidas}</small>
                        <br />
                        <small className="font-weight-bold">Média Dias Resp.: {mediaDiasResposta}</small>
                        <br />
                    </div>
                </div>
            </div>
            <MaisInfoMenosInfo
                text={
                    <DadosDeParceriasInfo
                        parceriasCadastradas={parceriasCadastradas.parceriasCadastradas}
                        feedBack={parceriasCadastradas.FeedBack}
                        EmDesenvolvimento={parceriasCadastradas.EmDesenvolvimento}
                        Resolvidas={parceriasCadastradas.Resolvidas}
                        mediaDiasResposta={mediaDiasResposta}
                    />
                }
            />
            <ButtonCadastrar />
        </section>
    );
};
export default DadosDeProjetos;
// UserPage.js
import { useEffect, useState } from "react";
import { HeaderAnt, SiderUserMain } from "@components/layout";
import { UseUserPeer } from "@context/components/UserPeerProvider";
import { FaClipboard } from "react-icons/fa";
import { Button } from "antd";
import { MdMenuMD } from "@components/icons";
import "./UserPage.css";
export const UserPage = () => {
  const { peer, conn, peerId, setConn, definirTipoUsuario, userType } = UseUserPeer();
  const [status, setStatus] = useState("Aguardando conexão...");
  const [sessionCode, setSessionCode] = useState("");
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [isChatMinimized, setIsChatMinimized] = useState(false);
  // Define o usuário como "usuário" se ainda não estiver definido
  useEffect(() => {
    if (!userType) {
      definirTipoUsuario("usuário");
    }
  }, [userType, definirTipoUsuario]);
  // Configura os eventos do PeerJS
 // Configura os eventos do PeerJS
useEffect(() => {
    if (peer) {
      peer.on("open", (id) => {
        setSessionCode(id);
        setStatus("Aguardando conexão...");
      });
      peer.on("connection", (connection) => {
        connection.on("data", (data) => {
          if (data.type === "mouseMove") {
            moverMouse(data.x, data.y);
          } else if (data.type === "click") {
            simularClique(data.x, data.y);
          } else if (data.type === "chatMessage") {
            setMessages((prev) => [...prev, { sender: "Atendente", text: data.message }]);
          }
        });
        setConn(connection);
        setStatus("Conectado com o atendente.");
        connection.send({ type: "status", message: "Conexão estabelecida com sucesso!" });
      });
      // Monitorando a desconexão
      peer.on("disconnected", () => {
        setStatus("Conexão perdida. Tentando reconectar...");
        setConn(null); // Resetar a conexão
        // Tente reconectar após alguns segundos
        setTimeout(() => {
          peer.reconnect(); // Reconectar automaticamente
        }, 5000);
      });
      // A reconexão pode ser tentada também quando o peer é fechado
      peer.on("close", () => {
        setStatus("Conexão encerrada. Tentando reconectar...");
        setTimeout(() => {
          peer.reconnect(); // Reconectar automaticamente
        }, 5000);
      });
      peer.on("error", (err) => {
        setStatus("Erro na conexão: " + err.message);
        // Tente reconectar após um erro
        setTimeout(() => {
          peer.reconnect();
        }, 5000);
      });
    }
    // NÃO destruir o peer para manter a conexão entre páginas!
    // return () => { if(peer) peer.destroy(); };
  }, [peer, setConn]);
  // Função para mover o cursor remoto (bolinha vermelha)
  const moverMouse = (x, y) => {
    let cursor = document.getElementById("cursor-remoto");
    if (!cursor) {
      cursor = document.createElement("div");
      cursor.id = "cursor-remoto";
      cursor.style.position = "absolute";
      cursor.style.width = "15px";
      cursor.style.height = "15px";
      cursor.style.backgroundColor = "red";
      cursor.style.borderRadius = "50%";
      cursor.style.pointerEvents = "none";
      cursor.style.zIndex = "99999";
      document.body.appendChild(cursor);
    }
    cursor.style.left = `${x - cursor.offsetWidth / 2}px`;
    cursor.style.top = `${y - cursor.offsetHeight / 2}px`;
  };
  // Função para simular clique na posição recebida
  const simularClique = (x, y) => {
    const elemento = document.elementFromPoint(x, y);
    const eventoClique = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    if (elemento) {
      elemento.dispatchEvent(eventoClique);
    } else {
      document.body.dispatchEvent(eventoClique);
    }
  };
  // Envio de mensagem via chat
  const enviarMensagem = () => {
    if (conn && messageInput.trim()) {
      conn.send({ type: "chatMessage", message: messageInput });
      setMessages((prev) => [...prev, { sender: "Você", text: messageInput }]);
      setMessageInput("");
    }
  };
  const copiarCodigo = () => {
    if (sessionCode) {
      navigator.clipboard.writeText(sessionCode)
        .then(() => {
          setTooltipVisible(true);
          setTimeout(() => setTooltipVisible(false), 2000);
        })
        .catch((err) => alert("Falha ao copiar o código: " + err));
    } else {
      alert("Código da sessão não disponível.");
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      enviarMensagem();
    }
  };
  return (
    <>
      <HeaderAnt />
      <div className="user-content">
        <div className="user-page-container">
          <SiderUserMain />
          <div className={`chat-panel ${isChatMinimized ? "minimized" : ""}`}>
            <div className="chat-header">
              Chat com o Atendente
              <button className="minimize-btn" onClick={() => setIsChatMinimized(!isChatMinimized)}>
                <MdMenuMD />
              </button>
            </div>
            {!isChatMinimized && (
              <div className="messages-container">
                {messages.map((msg, index) => (
                  <div key={index} className={`message ${msg.sender === "Você" ? "sent" : "received"}`}>
                    <strong>{msg.sender}:</strong> {msg.text}
                  </div>
                ))}
              </div>
            )}
            <div className="chat-input-container">
              <input
                type="text"
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                placeholder="Digite sua mensagem..."
                onKeyDown={handleKeyDown}
                className="chat-input"
              />
              <button onClick={enviarMensagem} className="send-button">Enviar</button>
            </div>
            <div className="session-info">
              <div className="copy-code mt-1 mb-1" onClick={copiarCodigo}>
                Código da Sessão: <strong>{sessionCode}</strong>
                <Button>
                  <FaClipboard size={15} />
                  <span>Copiar</span>
                </Button>
              </div>
              <p>Compartilhe este código com o atendente para iniciar a sessão.</p>
            </div>
          </div>
        </div>
      </div>
      {tooltipVisible && <div className="tooltip">Código copiado!</div>}
    </>
  );
};
export default UserPage;
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Image } from 'antd';
import { MaisInfoMenosInfo } from '@components';
import MasterCadastroCompanyInfo from 'controllers/info/MasterCadastroCompanyInfo';
import { useProfisional } from 'context';
import {
    handleUpdateProfissionalController,
    handleDeletedProfessional,
} from 'controllers/functions';
import { handleNavigateUpdateProfessional } from '../functions/handleNavigateUpdateProfessional';
import { MdBusinessMD, MdDeleteMD, MdEditMD, MdKeyMD } from '@components/icons';
export const UpdateEmpresaController = ({ professionalData, updateProfissional }) => {
    const navigate = useNavigate();
    return (<>
        {professionalData && Array.isArray(professionalData.professionalOwnerCompany) &&
            professionalData.professionalOwnerCompany.flat().map((item, index) => (
                <div className="col-md-3" key={index}>
                    <div className='bg-light d-flex rounded'>
                        <div className='container'>
                            <small className="font-weight-bold mt-2"><b>Cadastro Empresa Matriz</b></small>
                            <hr style={{marginTop:'0px'}} />
                            <div className="row">
                                <div className="bg-light d-flex flex-column rounded">
                                    <div>
                                        {item.images > 0 ? (
                                            <Image
                                                height={50}
                                                width={50}
                                                className="rounded"
                                                src={item.images}
                                                alt="Professional Image"
                                                preview={false}
                                            />
                                        ) : (
                                            <MdBusinessMD size={50} className="rounded" /> // Fallback para o ícone de câmera
                                        )}
                                        <br />
                                        <small
                                            className="font-weight-bold text-center"
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            Nome Empresa: <small>{item.company_name}</small><br />
                                        </small>
                                        <button
                                            style={{ border: '0px' }}
                                            onClick={() => handleUpdateProfissionalController(item, updateProfissional)}
                                        >
                                            <MdKeyMD
                                                color={
                                                    JSON.stringify(item) === localStorage.getItem('profissional')
                                                        ? 'green'
                                                        : 'black'
                                                }
                                                className="mr-2 ml-2"
                                            />
                                        </button>
                                        <button
                                            style={{ border: '0px' }}
                                            onClick={() =>
                                                handleNavigateUpdateProfessional({
                                                    item,
                                                    updateProfissional,
                                                    navigate
                                                })
                                            }
                                        >
                                            <MdEditMD className="text-warning mr-2 ml-2" />
                                        </button>
                                        <Link onClick={() => handleDeletedProfessional(item.id)}>
                                            <MdDeleteMD className="text-danger mr-2 ml-2" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <MaisInfoMenosInfo title={'Mais info'} text={<MasterCadastroCompanyInfo />} />
                            <hr />
                        </div>
                    </div>
                </div>
            ))
        }
    </>
    );
};
export default UpdateEmpresaController;
import { useState, useEffect } from 'react';
import { InputPadrao } from '@components';
import { baseURL } from '@services';
import { UpdateClienteController } from 'controllers';
import { useProfisional } from 'context';
export const Index = () => {
    const [procedimentos, setProcedimentos] = useState([]);
    const [novoProcedimento, setNovoProcedimento] = useState('');
    const [procedimentosFiltrados, setProcedimentosFiltrados] = useState([]);
    const { profissional } = useProfisional();
    useEffect(() => {
        fetch(`${baseURL}procedimentos/${profissional.id}`)
            .then(response => response.json())
            .then(data => {
                setProcedimentos(data);
            });
    }, [profissional.id]);
    const handleAdicionarProcedimento = () => {
        setProcedimentos([...procedimentos, novoProcedimento]);
        setNovoProcedimento('');
    };
    const handleNovoProcedimentoChange = (event) => {
        const valor = event.target.value;
        setNovoProcedimento(valor);
        const filtrados = procedimentos.filter((procedimento) =>
            procedimento.toLowerCase().includes(valor.toLowerCase())
        );
        setProcedimentosFiltrados(filtrados);
    };
    const handleProcedimentoClick = (event) => {
        const valor = event.target.innerText;
        setNovoProcedimento(valor);
        setProcedimentosFiltrados([]);
    };
    const carregarProcedimentos = async () => {
        const resposta = await fetch(`${baseURL}procedimentos`);
        const dados = await resposta.json();
        setProcedimentos(dados);
    };
    useEffect(() => {
        carregarProcedimentos();
    }, []);
    return (<>
        <UpdateClienteController
            title="Buscar usuário"
        />
        <div className={`mt-3 col-md-12 mx-auto `} >
            <div className="container">
                <div className="row">
                    <div className="col-md-6 text-center">
                        <div className="form-group">
                            <InputPadrao
                                title={'Serviço'}
                                type="text"
                                value={novoProcedimento}
                                onChange={handleNovoProcedimentoChange}
                            />
                            {procedimentosFiltrados.length > 0 && (
                                <ul className="list-group">
                                    {procedimentosFiltrados.map((procedimento, index) => (
                                        <li
                                            key={index}
                                            className="list-group-item"
                                            onClick={handleProcedimentoClick}
                                        >
                                            {procedimento}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className="col-md-12 d-flex justify-content-center mx-auto align-items-center"> <button
                        className="btn btn-primary btn-block w-50 mb-2 mt-3 mx-auto a-86 text-center" style={{ boxShadow: "inset 0px -6px 3px 5px rgba(0, 0, 0, 0.5)" }} onClick={handleAdicionarProcedimento}>Adicionar Procedimento</button></div>
                    <div className='col-md-12 mx-auto d-flex  justify-content-center'>
                        <small className=" text-white text-center mb-3 mt-3 "> Confira a tabela abaixo junto com valor total e envie para o paciente o orçamento</small>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Procedimento</th>
                            </tr>
                        </thead>
                        <tbody>
                            {procedimentos.map((procedimento) => (
                                <tr key={procedimento}>
                                    <td>{procedimento}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>)
}
import { Index } from "./components/Index";
import { FooterProfissional,HeaderAnt, NavProfissionalMain } from "components";
export const IA = () => {
    return (<>
        <HeaderAnt/>
        <div className="d-flex mt-3" style={{ overflowX: 'hidden' }}>
            <NavProfissionalMain />
            <section className="w-100">
                <Index />
                <FooterProfissional />
            </section>
        </div>
    </>)
};
import React from 'react';
import { ButtonCadastrar, LinkHref, TitleL1 } from 'components';
import { Button, Card, Row, Col, Typography, Image } from 'antd';
import { AccountBookFilled, UsergroupAddOutlined, FileTextOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'
const { Title, Text } = Typography;
export const ApresentacaoServicos = () => {
    const handleCardClick = (href) => {
        // Modifica o hash da URL sem recarregar a página
        if (window.location.hash !== href) {
            window.location.hash = href;  // Atualiza o hash na URL
        }
    }
    return (<>
        <section style={{ minHeight: '85dvh' }}>
            <div className='col-md-12' style={{ background: '#fff', padding: '10px 0' }}>
                <div style={{ padding: '5px' }} >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} lg={8} onClick={() => handleCardClick('#PlanosPrecosServicos')}>
                            <Card
                                style={{
                                    overflow: 'hidden',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%', // Garante altura flexível
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Sombra suave para um visual moderno
                                    borderRadius: '12px', // Bordas arredondadas
                                }}
                                hoverable
                                cover={<Image
                                    preview={false}
                                    width={'100%'}
                                    height={'250px'}
                                    src={require('./assets/1626446451986-1024x576.jpg')}
                                    alt="Liberdade Financeira"
                                />}
                                actions={[
                                    <div style={{ paddingTop: '10px', paddingBottom: '10px', textAlign: 'center', }}>
                                    <Text style={{ fontSize: '14px', color: '#1890ff' }}>  <FileTextOutlined key="suporte" /><br/> Clique para saber mais</Text>
                                </div>
                                ]}
                            >
                                <TitleL1 >Liberdade Financeira</TitleL1>
                                <h5 className='text-justify' style={{
                                    overflow: 'hidden',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: '6', // Limita o texto a 3 linhas
                                    textOverflow: 'ellipsis', // Adiciona "..." quando o texto for maior que 3 linhas
                                }} >
                                    Alcance sua Liberdade Financeira com nosso software. Seja você um profisssional, empresa ou instituição, temos o plano correto para você alcançar a liberdade financeira.&nbsp;
                                  </h5>
                            </Card>
                        </Col>
                        {/* Service 2 */}
                        <Col xs={24} sm={12} lg={8} onClick={() => handleCardClick('#SolucaoGerencimamentoAvancado')}>
                            <Card
                                style={{
                                    overflow: 'hidden',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%', // Garante altura flexível
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Sombra suave para um visual moderno
                                    borderRadius: '12px', // Bordas arredondadas
                                }}
                                hoverable
                                cover={<Image
                                    preview={false}
                                    width={'100%'}
                                    height={'250px'}
                                    src={require('./assets/whitelabel.webp')}
                                    alt="Liberdade Financeira"
                                />} actions={[
                                    <div style={{ paddingTop: '10px', paddingBottom: '10px', textAlign: 'center', }}>
                                        <Text style={{ fontSize: '14px', color: '#1890ff' }}>    <UsergroupAddOutlined key="desenvolvimento" /> <br/>Clique para saber mais</Text>
                                    </div>
                                ]}
                            >
                                <TitleL1 >Controle Total</TitleL1>
                                <h5 className='text-justify'
                                    style={{
                                        overflow: 'hidden',
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: '6',
                                        textOverflow: 'ellipsis',
                                    }} >
                                    Explore várias opções de gerenciamento profissional e co-criação de sistemas com dois cliques.
                                    ERP + E-commerce + Dropshipping - White Label. Além disso você pode ter comunicação com seus clientes no CRM configurando Inteligências Artificiais I.A. de Business Intelligence B.I. para fazer suas vendas recorrentes.
                                </h5>
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} lg={8} onClick={() => handleCardClick('#SolucaoIAAvancadaServicos')}>
                            <Card
                                style={{
                                    overflow: 'hidden',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%', // Garante altura flexível
                                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Sombra suave para um visual moderno
                                    borderRadius: '12px', // Bordas arredondadas
                                }}
                                hoverable
                                cover={<Image
                                    preview={false}
                                    width={'100%'}
                                    height={'250px'}
                                    src={require('./assets/exameia.webp')}
                                    alt="Liberdade Financeira"
                                />}
                                actions={[
                                    <div style={{ paddingTop: '10px', paddingBottom: '10px', textAlign: 'center', }}>
                                        <Text style={{ fontSize: '14px', color: '#1890ff' }}>  <AccountBookFilled key="consultoria" /><br/> Clique para saber mais</Text>
                                    </div>
                                ]}
                            >
                                <TitleL1 >Monetização Garantida</TitleL1>
                                <h5 className='text-justify' style={{
                                    overflow: 'hidden',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: '6', // Limita o texto a 3 linhas
                                    textOverflow: 'ellipsis', // Adiciona "..." quando o texto for maior que 3 linhas
                                }}>
                                    Venda os mais diversos exames de imagem laudados por Inteligência Artificial (I.A.).
                                   Crie Inteligência Artificial com dois cliques! I.A para classificação de imagens dinâmica de propósito geral de fácil uso, dispensa conhecimento
                                    em programação. Pode verificar de condições médicas a linhas industriais literalmente qualquer coisa 
                                </h5>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <ButtonCadastrar href={'/profissionais/servicos'} />
        </section>
    </>)
}
import React, { useState } from 'react';
export const GDPRModules = () => {
  const [showModules, setShowModules] = useState(true);
  const toggleModules = () => {
    setShowModules(!showModules);
  };
  return (<>
    <div className='text-justify col-md-6'>
      <abbr title="Clique para ver mais informações"> <h5 className={`${showModules ? 'font-weight-bold text-justify text-success mb-5' : 'font-weight-bold text-justify text-primary mt-2'}`} onClick={toggleModules}>Princípios da GDPR &nbsp;<i className='fa fa-info-circle'></i></h5></abbr>
      {showModules &&
        <ol className='ml-3'>
          <li><strong>Consentimento</strong></li>
          <p>
            O primeiro princípio da GDPR é obter o consentimento explícito dos titulares dos dados para o processamento de seus dados pessoais.
            Isso requer que as organizações solicitem permissão clara e inequívoca antes de coletar ou processar qualquer dado pessoal.
            Além disso, os titulares dos dados têm o direito de retirar seu consentimento a qualquer momento,
            e as organizações devem tornar esse processo tão fácil quanto dar o consentimento inicial.
          </p>
          <hr /><li><strong>Finalidade Limitada</strong></li>
          <p>
            O segundo princípio da GDPR exige que as organizações coletem e processem dados pessoais apenas para finalidades específicas,
            claras e legítimas. Isso significa que as organizações não podem usar os dados pessoais para finalidades diferentes daquelas para as quais foram coletados.
            As finalidades do processamento de dados devem ser determinadas com antecedência,
            e qualquer mudança significativa requer novo consentimento dos titulares dos dados.
          </p>
          <hr /><li><strong>Minimização de Dados</strong></li>
          <p>
            O terceiro princípio da GDPR estabelece que as organizações devem coletar apenas os dados pessoais necessários para as finalidades do processamento.
            Isso significa que a coleta de dados deve ser limitada ao mínimo necessário.
            As organizações também devem garantir que os dados coletados sejam precisos e atualizados.
          </p>
          <hr /><li><strong>Precisão</strong></li>
          <p>
            O quarto princípio da GDPR exige que as organizações garantam a precisão dos dados pessoais.
            Isso inclui a obrigação de manter os dados atualizados e corrigir quaisquer imprecisões prontamente.
            Os titulares dos dados têm o direito de solicitar a correção de informações imprecisas.
          </p>
          <hr /><li><strong>Limitação de Armazenamento</strong></li>
          <p>
            O quinto princípio da GDPR estabelece que os dados pessoais devem ser armazenados apenas pelo tempo necessário para cumprir as finalidades do processamento.
            Isso significa que as organizações não podem reter dados indefinidamente.
            As organizações devem ter políticas claras de retenção de dados e excluir dados quando não forem mais necessários.
          </p>
          <hr /><li><strong>Integridade e Confidencialidade</strong></li>
          <p>
            O sexto princípio da GDPR exige que as organizações protejam a integridade e a confidencialidade dos dados pessoais.
            Isso envolve a implementação de medidas de segurança apropriadas para proteger os dados contra perda, roubo, acesso não autorizado e uso indevido.
            As organizações também devem garantir a capacidade de restaurar a disponibilidade e o acesso aos dados em caso de incidente.
          </p>
          <hr /><li><strong>Responsabilidade e Prestação de Contas</strong></li>
          <p>
            O sétimo princípio da GDPR estabelece que as organizações são responsáveis pelo cumprimento da regulamentação e devem ser capazes de demonstrar conformidade.
            Isso envolve a nomeação de um Encarregado de Proteção de Dados (Data Protection Officer - DPO) quando aplicável e a documentação das práticas de tratamento de dados.
            As organizações devem estar preparadas para prestar contas às autoridades reguladoras em caso de violação de dados.
          </p>
          <hr /><li><strong>Direitos dos Titulares dos Dados</strong></li>
          <p>
            O oitavo princípio da GDPR estabelece que os titulares dos dados têm vários direitos,
            incluindo o direito de acesso aos seus dados pessoais, o direito de corrigir informações imprecisas,
            o direito de serem esquecidos (apagamento), o direito de portabilidade de dados e o direito de se opor ao processamento.
            As organizações devem estar preparadas para responder a solicitações dos titulares dos dados relacionadas a esses direitos.
          </p>
          <hr /><li><strong>Transferência Internacional de Dados</strong></li>
          <p>
            O nono princípio da GDPR regula a transferência de dados pessoais para fora da União Europeia (UE) e do Espaço Econômico Europeu (EEE).
            As organizações só podem transferir dados para países ou organizações que ofereçam um nível adequado de proteção de dados,
            a menos que medidas de segurança apropriadas sejam implementadas.
            As transferências internacionais de dados devem ser cuidadosamente avaliadas e documentadas.
          </p>
          <hr /><li><strong> Violação de Dados</strong></li>
          <p>
            O décimo princípio da GDPR exige que as organizações relatem violações de dados às autoridades reguladoras e aos titulares dos dados afetados.
            Isso deve ser feito dentro de um prazo específico após a descoberta da violação.
            As organizações também devem ter planos de resposta a incidentes para lidar eficazmente com violações de dados.
          </p>
          <hr /><li><strong> Avaliação de Impacto de Proteção de Dados</strong></li>
          <p>
            O décimo primeiro princípio da GDPR exige que as organizações realizem avaliações de impacto de proteção de dados (DPIAs) sempre que estiverem envolvidas em processamento de dados que possa representar um alto risco para os direitos e liberdades dos titulares dos dados.
            As DPIAs ajudam a identificar e mitigar riscos à privacidade dos dados e são uma parte essencial do cumprimento da GDPR.
          </p>
        </ol>}
    </div>
  </>);
};
export default GDPRModules;
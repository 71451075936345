export const pdf_download_2 = ({
    doc,
    imageData,
    calcularPontoMaisBaixo,
    calcularPontoMaisAlto,
    points,
    drawBlueSquareWithArrowDown,
    pdf_download_footer,
    qrCode,
    professionalInfo,
    drawSquareWithCheckmark,
    calcularVolumeLandmarks,
    drawBlueSquareWithArrow,
    imgWidthHalf,
    imgHeightHalf,
    imageX,
    imageY,
    imageXDir,
    imageYDir,
    linhaMediaPag,
    yEndVerticalLineMediaPag,
    textY,
    landmarks_position
}) => {
    doc.addPage();
    doc.addImage(require('../assets/fundoPDF.png'), 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
    doc.addImage(imageData, 'JPEG', imageX, imageY, imgWidthHalf, imgHeightHalf);
    const sobrancelhaEsquerda = [
        { nome: '157', x: imageX + points.point157.x * imgWidthHalf, y: imageY + points.point157.y * imgHeightHalf / 2 },
        { nome: '47', x: imageX + points.point47.x * imgWidthHalf, y: imageY + points.point47.y * imgHeightHalf / 2 },
        { nome: '54', x: imageX + points.point54.x * imgWidthHalf, y: imageY + points.point54.y * imgHeightHalf / 2 },
        { nome: '53', x: imageX + points.point53.x * imgWidthHalf, y: imageY + points.point53.y * imgHeightHalf / 2 },
        { nome: '223', x: imageX + points.point223.x * imgWidthHalf, y: imageY + points.point223.y * imgHeightHalf / 2 },
        { nome: '222', x: imageX + points.point222.x * imgWidthHalf, y: imageY + points.point222.y * imgHeightHalf / 2 },
        { nome: '56', x: imageX + points.point56.x * imgWidthHalf, y: imageY + points.point56.y * imgHeightHalf / 2 },
        { nome: '67', x: imageX + points.point67.x * imgWidthHalf, y: imageY + points.point67.y * imgHeightHalf / 2 },
        { nome: '106', x: imageX + points.point106.x * imgWidthHalf, y: imageY + points.point106.y * imgHeightHalf / 2 },
        { nome: '64', x: imageX + points.point64.x * imgWidthHalf, y: imageY + points.point64.y * imgHeightHalf / 2 },
        { nome: '71', x: imageX + points.point71.x * imgWidthHalf, y: imageY + points.point71.y * imgHeightHalf / 2 },
        { nome: '157', x: imageX + points.point157.x * imgWidthHalf, y: imageY + points.point157.y * imgHeightHalf / 2 }
    ];
    const sobrancelhaDireita = [
        { nome: '286', x: imageX + points.point286.x * imgWidthHalf, y: imageY + points.point286.y * imgHeightHalf / 2 },
        { nome: '442', x: imageX + points.point442.x * imgWidthHalf, y: imageY + points.point442.y * imgHeightHalf / 2 },
        { nome: '443', x: imageX + points.point443.x * imgWidthHalf, y: imageY + points.point443.y * imgHeightHalf / 2 },
        { nome: '444', x: imageX + points.point444.x * imgWidthHalf, y: imageY + points.point444.y * imgHeightHalf / 2 },
        { nome: '445', x: imageX + points.point445.x * imgWidthHalf, y: imageY + points.point445.y * imgHeightHalf / 2 },
        { nome: '446', x: imageX + points.point446.x * imgWidthHalf, y: imageY + points.point446.y * imgHeightHalf / 2 },
        { nome: '354', x: imageX + points.point354.x * imgWidthHalf, y: imageY + points.point354.y * imgHeightHalf / 2 },
        { nome: '384', x: imageX + points.point384.x * imgWidthHalf, y: imageY + points.point384.y * imgHeightHalf / 2 },
        { nome: '301', x: imageX + points.point301.x * imgWidthHalf, y: imageY + points.point301.y * imgHeightHalf / 2 },
        { nome: '294', x: imageX + points.point294.x * imgWidthHalf, y: imageY + points.point294.y * imgHeightHalf / 2 },
        { nome: '335', x: imageX + points.point335.x * imgWidthHalf, y: imageY + points.point335.y * imgHeightHalf / 2 },
        { nome: '297', x: imageX + points.point297.x * imgWidthHalf, y: imageY + points.point297.y * imgHeightHalf / 2 },
    ];
    // Pontos mais alto sobrancelha
    const pontoMaisBaixoSombrancelhaEsquerda = calcularPontoMaisAlto(sobrancelhaEsquerda);
    const pontoMaisBaixoSobrancelhaEsquerdaY = pontoMaisBaixoSombrancelhaEsquerda.y
    const pontoMaisBaixoSombrancelhaDireita = calcularPontoMaisAlto(sobrancelhaDireita);
    const pontoMaisBaixoSombrancelhaDireitaY = pontoMaisBaixoSombrancelhaDireita.y
    // Pontos mais baixo sobrancelha
    const pontoMaisAltoSombrancelhaEsquerda = calcularPontoMaisBaixo(sobrancelhaEsquerda);
    const pontoMaisAltosobrancelhaEsquerdaY = pontoMaisAltoSombrancelhaEsquerda.y
    const pontoMaisAltoSombrancelhaDireita = calcularPontoMaisBaixo(sobrancelhaDireita);
    const pontoMaisAltoSombrancelhaDireitaY = pontoMaisAltoSombrancelhaDireita.y
    // Página - 2 - Análises horizontais
    if (points.point3 && points.point469 && points.point153 && points.point9) {
        const totalImageWidth = imgWidthHalf;
        const halfImageWidth = totalImageWidth / 2;
        doc.setLineWidth(0.2);
        doc.setDrawColor(255, 0, 0);
        const imageX = 5;
        doc.line(imageX, landmarks_position.y3, imageX + totalImageWidth, landmarks_position.y3);
        doc.line(imageX, landmarks_position.y469, imageX + totalImageWidth, landmarks_position.y469);
        doc.line(imageX, landmarks_position.y9, imageX + totalImageWidth, landmarks_position.y9);
        doc.line(imageX, landmarks_position.y153, imageX + totalImageWidth, landmarks_position.y153);
        doc.setLineWidth(0.2);
        const textX = imageX - halfImageWidth;
        const textY = imageY + imgHeightHalf + 5;
        doc.setFontSize(12);
        const textXRight = textX + 45;
        doc.setTextColor(0, 0, 0);
        const lineLength9to11 = Math.sqrt(Math.pow(landmarks_position.x9 - landmarks_position.x469, 2) + Math.pow(landmarks_position.y469 - landmarks_position.y9, 2));
        const distancePixels = Math.sqrt(Math.pow(landmarks_position.x3 - landmarks_position.x9, 2) + Math.pow(landmarks_position.y3 - landmarks_position.y9, 2));
        const lineLength3to153 = Math.sqrt(Math.pow(landmarks_position.x153 - landmarks_position.x3, 2) + Math.pow(landmarks_position.y153 - landmarks_position.y3, 2));
        const totalLength = lineLength9to11 + distancePixels + lineLength3to153;
        const percentTercoSuperior = (lineLength9to11 / totalLength) * 100;
        const percentTercoMedio = (distancePixels / totalLength) * 100;
        const percentTercoInferior = (lineLength3to153 / totalLength) * 100;
        doc.setTextColor(0, 0, 0);
        const lineLength9to11Text = `Terço Superior - Tam.: ${lineLength9to11.toFixed(2)} unidades (${percentTercoSuperior.toFixed(2)}%)`;
        if (percentTercoSuperior >= 32 && percentTercoSuperior <= 34) {
            drawSquareWithCheckmark(doc, textXRight + 85, textY + 5, 5);
        } else if (percentTercoSuperior > 34) {
            drawBlueSquareWithArrow(doc, textXRight + 85, textY + 5, 5);
        } else {
            drawBlueSquareWithArrowDown(doc, textXRight + 85, textY + 5, 5);
        }
        const distanceText = `Terço Médio - Tam.: ${distancePixels.toFixed(2)} unidades (${percentTercoMedio.toFixed(2)}%)`;
        let equilibrioTercoHorizontal = "Equilibrado";
        if (percentTercoMedio >= 32 && percentTercoMedio <= 34) {
            drawSquareWithCheckmark(doc, textXRight + 85, textY + 15, 5);
        } else if (percentTercoMedio > 34) {
            drawBlueSquareWithArrow(doc, textXRight + 85, textY + 15, 5);
            equilibrioTercoHorizontal = "Desequilibrado"
        } else {
            drawBlueSquareWithArrowDown(doc, textXRight + 85, textY + 15, 5);
            equilibrioTercoHorizontal = "Desequilibrado"
        }
        const lineLength3to153Text = `Terço Inferior - Tam.: ${lineLength3to153.toFixed(2)} unidades (${percentTercoInferior.toFixed(2)}%)`;
        if (percentTercoInferior >= 32 && percentTercoInferior <= 34) {
            drawSquareWithCheckmark(doc, textXRight + 85, textY + 25, 5);
        } else if (percentTercoInferior > 34) {
            drawBlueSquareWithArrow(doc, textXRight + 85, textY + 25, 5);
        } else {
            drawBlueSquareWithArrowDown(doc, textXRight + 85, textY + 25, 5);
        }
        doc.setFontSize(12);
        doc.setFont('times', 'bold');
        doc.text(`Análises dos Terços Horizontais:`, 5, textY + 2);
        if (equilibrioTercoHorizontal === "Desequilibrado") {
            doc.setTextColor(255, 0, 0); // Vermelho para desequilibrado
        } else {
            doc.setTextColor(0, 128, 0); // Verde para equilibrado
        }
        doc.text(`${equilibrioTercoHorizontal}`, 65, textY + 2);
        doc.setTextColor(0, 0, 0);
        doc.setFont('times', 'normal');
        doc.text(lineLength9to11Text, textXRight, textY + 9);
        doc.setTextColor(255, 0, 0);
        doc.setFontSize(8);
        doc.text(`Ref - Dist. entre Ponto 469 e Ponto 9 `, textXRight, textY + 13);
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        doc.text(distanceText, textXRight, textY + 19);
        doc.setTextColor(255, 0, 0);
        doc.setFontSize(8);
        doc.text('Ref - Dist. entre Ponto 9 e Ponto 3', textXRight, textY + 23);
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        doc.text(lineLength3to153Text, textXRight, textY + 29);
        doc.setTextColor(255, 0, 0);
        doc.setFontSize(8);
        doc.text('Ref - Dist. entre Ponto 3 e Ponto 153', textXRight, textY + 33);
        doc.setTextColor(0, 0, 0);
        doc.text('Os terços devem ter a mesma % entre si para harmonia facial, proporção áurea. ', textXRight, textY + 37);
        doc.text('Nesse caso o padrão ouro seria 33.33% em cada 1/3. Nos  caucasianos moder-', textXRight, textY + 40);
        doc.text('nos o terço inferior  da face é geralmente um pouco maior que  o terço médio. ', textXRight, textY + 43);
        doc.setFont('times', 'normal');
        doc.setFontSize(5);
        doc.text('Ref: Proffit, W. R. Ortodontia Contemporânea (8ª ed.). Elsevier, 2018. pag. 167', textXRight, textY + 47);
        doc.setFontSize(12);
        // Página - 2 Análise horizontal do terço superior
        //Segunda imagem lado esquerdo          
        doc.addImage(imageData, 'JPEG', imageX, imageY + 150, imgWidthHalf, imgHeightHalf - 50);
        doc.setFontSize(12);
        doc.text('Análises Terço Horizontal Superior - Sobrancelha ', textXRight, textY + 100);
        doc.setTextColor(0, 0, 0);
        doc.setFont('times', 'normal');
        sobrancelhaEsquerda.forEach((landmark, index) => {
            const { x, y } = landmark;
            const newY = y + imageY + 119;
            doc.setDrawColor(255, 0, 0);
            doc.setFillColor(255, 0, 0);
            doc.circle(x, newY, 0.1, 'F');
            doc.setFontSize(5);
            doc.setTextColor(0, 0, 255);
            // doc.text(`${index + 1}`, x + 0, newY + 0);
            if (index === 0) {
                const tenthLandmark = sobrancelhaEsquerda[11];
                doc.setDrawColor(255, 0, 0);
                doc.line(x, newY, tenthLandmark.x, tenthLandmark.y + imageY + 119);
            }
            if (index < sobrancelhaEsquerda.length - 1) {
                const nextLandmark = sobrancelhaEsquerda[index + 1];
                doc.setDrawColor(255, 0, 0);
                doc.line(x, newY, nextLandmark.x, nextLandmark.y + imageY + 119);
            }
        });
        sobrancelhaDireita.forEach((landmark2, index) => {
            const { x, y } = landmark2;
            const newY = y + imageY + 119;
            doc.setDrawColor(255, 0, 0);
            doc.setFillColor(255, 0, 0);
            doc.circle(x, newY, 0.1, 'F');
            doc.setFontSize(5);
            doc.setTextColor(0, 0, 255);
            // doc.text(`${index + 11}`, x + 0, newY + 0);
            if (index === 0) {
                const twentySecondLandmark = sobrancelhaDireita[11];
                doc.setDrawColor(0, 0, 0);
                doc.line(x, newY, twentySecondLandmark.x, twentySecondLandmark.y + imageY + 119);
            }
            if (index < sobrancelhaDireita.length - 1) {
                const nextLandmark = sobrancelhaDireita[index + 1];
                doc.setDrawColor(0, 0, 0);
                doc.line(x, newY, nextLandmark.x, nextLandmark.y + imageY + 119);
            }
        });
        // Calcular volume sobrancelha
        const volumesobrancelhaEsquerda = calcularVolumeLandmarks(sobrancelhaEsquerda);
        const volumesobrancelhaDireita = calcularVolumeLandmarks(sobrancelhaDireita);
        const volumeTotalSobrancelhas = volumesobrancelhaEsquerda + volumesobrancelhaDireita
        const percentsobrancelhaDireita = 100 * (volumesobrancelhaDireita / volumeTotalSobrancelhas);
        const percentsobrancelhaEsquerda = 100 * (volumesobrancelhaEsquerda / volumeTotalSobrancelhas);
        const diferenca = percentsobrancelhaDireita - percentsobrancelhaEsquerda;
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(8);
        doc.setFont('times', 'bold');
        doc.text('Implantação', textXRight, textY + 104);
        doc.setFont('times', 'normal');
        const diferencaYSobrancelhaPontoMaisAlto = Math.abs(sobrancelhaDireita[1].y - sobrancelhaEsquerda[1].y);
        doc.text(`Sup. dif.: ${diferencaYSobrancelhaPontoMaisAlto.toFixed(4)}`, textXRight, textY + 107);
        doc.text('', textXRight + 10, textY + 107);
        //Todo Diferença de implantação horizontal das sobrancelhas 
        if (percentsobrancelhaDireita >= 40 && percentsobrancelhaDireita <= 60) {
            drawSquareWithCheckmark(doc, textXRight + 20, textY + 104, 3);
        } else if (percentsobrancelhaDireita > 60) {
            drawBlueSquareWithArrow(doc, textXRight + 20, textY + 104, 3);
        } else {
            drawBlueSquareWithArrowDown(doc, textXRight + 20, textY + 104, 3);
        }
        if (percentsobrancelhaDireita >= 40 && percentsobrancelhaDireita <= 60) {
            drawSquareWithCheckmark(doc, textXRight + 50, textY + 104, 3);
        } else if (percentsobrancelhaDireita > 60) {
            drawBlueSquareWithArrow(doc, textXRight + 50, textY + 104, 3);
        } else {
            drawBlueSquareWithArrowDown(doc, textXRight + 50, textY + 104, 3);
        }
        const diferencaYSobrancelhaPontoMaisBaixo = Math.abs(sobrancelhaDireita[9].y - sobrancelhaEsquerda[11].y);
        doc.text(`Inf. dif.:${diferencaYSobrancelhaPontoMaisBaixo.toFixed(4)}`, textXRight + 30, textY + 107);
        doc.setFont('times', 'bold');
        doc.text('Volume', textXRight, textY + 111);
        doc.setFont('times', 'normal');
        doc.text('Volume da Sobrancelha Esq.: ' + volumesobrancelhaDireita.toFixed(2) + ' (' + percentsobrancelhaDireita.toFixed(2) + '%)', textXRight, textY + 115);
        if (percentsobrancelhaDireita >= 40 && percentsobrancelhaDireita <= 60) {
            drawSquareWithCheckmark(doc, textXRight + 86, textY + 112, 3);
        } else if (percentsobrancelhaDireita > 60) {
            drawBlueSquareWithArrow(doc, textXRight + 86, textY + 112, 3);
        } else {
            drawBlueSquareWithArrowDown(doc, textXRight + 86, textY + 112, 3);
        }
        doc.text('Volume da Sobrancelha Dir.: ' + volumesobrancelhaEsquerda.toFixed(2) + ' (' + percentsobrancelhaEsquerda.toFixed(2) + '%)', textXRight, textY + 119);
        if (percentsobrancelhaEsquerda >= 40 && percentsobrancelhaEsquerda <= 60) {
            drawSquareWithCheckmark(doc, textXRight + 86, textY + 116, 3);
        } else if (percentsobrancelhaEsquerda > 60) {
            drawBlueSquareWithArrow(doc, textXRight + 86, textY + 116, 3);
        } else {
            drawBlueSquareWithArrowDown(doc, textXRight + 86, textY + 116, 3);
        }
        doc.setFont('times', 'bold');
        doc.text('Diferença Volumétrica da Sobrancelha: ' + diferenca.toFixed(2), textXRight, textY + 124);
        doc.setFont('times', 'normal');
        doc.text('A diferença volumétrica da sobrancelha deve ser a menor possível.', textXRight, textY + 129);
        doc.text('Padrão de porporção áurea os lados precisam ser semelhante; iguais. ', textXRight, textY + 133);
        doc.setTextColor(0, 0, 0);
        pdf_download_footer(doc, qrCode, professionalInfo);
    }
    doc.setLineWidth(0.5);
    doc.setDrawColor(0, 0, 0);
    doc.line(linhaMediaPag, imageY, linhaMediaPag, yEndVerticalLineMediaPag + 5);
    // Página - 2  imagem 1 Análise do quintos verticais
    doc.addImage(imageData, 'JPEG', imageXDir, imageYDir, imgWidthHalf, imgHeightHalf);
    doc.setLineWidth(0.2);
    doc.setDrawColor(255, 0, 0);
    doc.line(landmarks_position.x49, imageY, landmarks_position.x49, yEndVerticalLineMediaPag - 140); // Quinto Central
    doc.line(landmarks_position.x279, imageY, landmarks_position.x279, yEndVerticalLineMediaPag - 140); // Quinto Central
    doc.line(landmarks_position.x131, imageY, landmarks_position.x131, yEndVerticalLineMediaPag - 140); // Quinto Central
    doc.line(landmarks_position.x264, imageY, landmarks_position.x264, yEndVerticalLineMediaPag - 140); // Quinto Central
    // Calcular a distância entre as linhas "Quinto Central"
    const distanciaQuintoCentral = landmarks_position.x131 - landmarks_position.x49;
    const distanciaQuintoCentralDir = landmarks_position.x264 - landmarks_position.x279;
    doc.line(distanciaQuintoCentral + landmarks_position.x131, imageY, distanciaQuintoCentral + landmarks_position.x131, yEndVerticalLineMediaPag - 140);
    doc.line(distanciaQuintoCentralDir + landmarks_position.x264, imageY, distanciaQuintoCentralDir + landmarks_position.x264, yEndVerticalLineMediaPag - 140);
    const lineLength49to279 = Math.abs(landmarks_position.x49 - landmarks_position.x279);
    const lineLength49to131 = Math.abs(landmarks_position.x49 - landmarks_position.x131);
    const lineLength131to264 = Math.abs(landmarks_position.x264 - landmarks_position.x279);
    const totalLength = lineLength49to279 + lineLength49to131 + lineLength49to131 + lineLength131to264 + lineLength131to264;
    const percentQuintoCentral = (lineLength49to279 / totalLength) * 100;
    const percentQuintoCentralEsq = (lineLength49to131 / totalLength) * 100;
    const percentQuintoCentralDir = (lineLength131to264 / totalLength) * 100;
    // Página - 2  texto da Análise do quintos verticais
    doc.setFontSize(12);
    doc.setFont('times', 'bold');
    let equilibrioQuintosVerticais = "Equilibrado"
    doc.text('Análises dos Quintos Verticais:', imageXDir, textY + 2);
    doc.setFont('times', 'normal');
    doc.text('Quinto Cent. Tam.: ' + lineLength49to279.toFixed(2) + ' unidades (' + percentQuintoCentral.toFixed(2) + '%)', imageXDir, textY + 29);
    if (percentQuintoCentral >= 19 && percentQuintoCentral <= 21) {
        drawSquareWithCheckmark(doc, imageXDir + 85, textY + 25, 5);
    } else if (percentQuintoCentral > 21) {
        drawBlueSquareWithArrow(doc, imageXDir + 85, textY + 25, 5);
        equilibrioQuintosVerticais = "Desequilibrado"
    } else {
        drawBlueSquareWithArrowDown(doc, imageXDir + 85, textY + 25, 5);
        equilibrioQuintosVerticais = "Desequilibrado"
    }
    doc.setTextColor(255, 0, 0);
    doc.setFontSize(8);
    doc.text('Ref - Dist. entre Ponto 49 e Ponto 279', imageXDir, textY + 33);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    doc.text('Quinto Cent. Esq. Tam.: ' + lineLength49to131.toFixed(2) + ' unidades (' + percentQuintoCentralEsq.toFixed(2) + '%)', imageXDir, textY + 19);
    if (percentQuintoCentralEsq >= 19 && percentQuintoCentralEsq <= 21) {
        drawSquareWithCheckmark(doc, imageXDir + 85, textY + 15, 5);
    } else if (percentQuintoCentralEsq > 21) {
        drawBlueSquareWithArrow(doc, imageXDir + 85, textY + 15, 5);
        equilibrioQuintosVerticais = "Desequilibrado"
    } else {
        drawBlueSquareWithArrowDown(doc, imageXDir + 85, textY + 15, 5);
        equilibrioQuintosVerticais = "Desequilibrado"
    }
    doc.setTextColor(255, 0, 0);
    doc.setFontSize(8);
    doc.text('Ref - Dist. entre Ponto 49 e Ponto 131', imageXDir, textY + 23);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    doc.text('Quinto Esq. Tam.: ' + lineLength49to131.toFixed(2) + ' unidades (' + percentQuintoCentralEsq.toFixed(2) + '%)', imageXDir, textY + 9);
    if (percentQuintoCentralEsq >= 19 && percentQuintoCentralEsq <= 21) {
        drawSquareWithCheckmark(doc, imageXDir + 85, textY + 5, 5);
    } else if (percentQuintoCentralEsq > 21) {
        drawBlueSquareWithArrow(doc, imageXDir + 85, textY + 5, 5);
        equilibrioQuintosVerticais = "Desequilibrado"
    } else {
        drawBlueSquareWithArrowDown(doc, imageXDir + 85, textY + 5, 5);
        equilibrioQuintosVerticais = "Desequilibrado"
    }
    doc.setTextColor(255, 0, 0);
    doc.setFontSize(8);
    doc.text('Ref - Mesma dist. entre Ponto 49 e Ponto 131', imageXDir, textY + 13);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    doc.text('Quinto Cent. Dir. Tam.: ' + lineLength131to264.toFixed(2) + ' unidades (' + percentQuintoCentralDir.toFixed(2) + '%)', imageXDir, textY + 39);
    if (percentQuintoCentralDir >= 19 && percentQuintoCentralDir <= 21) {
        drawSquareWithCheckmark(doc, imageXDir + 85, textY + 35, 5);
    } else if (percentQuintoCentralDir > 21) {
        drawBlueSquareWithArrow(doc, imageXDir + 85, textY + 35, 5);
        equilibrioQuintosVerticais = "Desequilibrado"
    } else {
        drawBlueSquareWithArrowDown(doc, imageXDir + 85, textY + 35, 5);
        equilibrioQuintosVerticais = "Desequilibrado"
    }
    doc.setTextColor(255, 0, 0);
    doc.setFontSize(8);
    doc.text('Ref - Dist. entre Ponto 279 e Ponto 264', imageXDir, textY + 43);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    doc.text('Quinto Dir. Tam.: ' + lineLength131to264.toFixed(2) + ' unidades (' + percentQuintoCentralDir.toFixed(2) + '%)', imageXDir, textY + 49);
    if (percentQuintoCentralDir >= 19 && percentQuintoCentralDir <= 21) {
        drawSquareWithCheckmark(doc, imageXDir + 85, textY + 45, 5);
    } else if (percentQuintoCentralDir > 21) {
        drawBlueSquareWithArrow(doc, imageXDir + 85, textY + 45, 5);
        equilibrioQuintosVerticais = "Desequilibrado"
    } else {
        drawBlueSquareWithArrowDown(doc, imageXDir + 85, textY + 45, 5);
        equilibrioQuintosVerticais = "Desequilibrado"
    }
    doc.setTextColor(255, 0, 0);
    doc.setFontSize(8);
    doc.text('Ref - Mesma dist. entre Ponto 279 e Ponto 264', imageXDir, textY + 53);
    doc.setFontSize(8);
    doc.setTextColor(0, 0, 0);
    doc.text('Cada 1/5 deve ter 20%  da largura total facial. Deve ser notado que o rosto deve ', imageXDir, textY + 59);
    doc.text('enquadrar nos traços vermelhos. Devios de normalidade  no  quadrante  médio ', imageXDir, textY + 63);
    doc.text('base do nariz podem deixar as orelhas fora das linhas - nariz largo', imageXDir, textY + 67);
    doc.setFontSize(5);
    doc.text('Ref: Proffit, W. R. Ortodontia Contemporânea (8ª ed.). Elsevier, 2018. pag. 164', imageXDir, textY + 71);
    doc.setFontSize(8);
    if (equilibrioQuintosVerticais === "Desequilibrado") {
        doc.setTextColor(255, 0, 0); // Vermelho para desequilibrado
    } else {
        doc.setTextColor(0, 128, 0); // Verde para equilibrado
    }
    doc.setFontSize(12);
    doc.setFont('times', 'bold');
    doc.text(`${equilibrioQuintosVerticais}`, imageXDir + 58, textY + 2);
    doc.setTextColor(0, 0, 0); // Verde para equilibrado
    // Página - 2 imagem 2 esquerda
    doc.addImage(imageData, 'JPEG', imageXDir, imageY + 175, imgWidthHalf, imgHeightHalf - 50);
    const totalImageWidth = imgWidthHalf;
    // Linhas da implantação horizontal dos olhos
    doc.setLineWidth(0.2);
    doc.setDrawColor(255, 0, 0);
    doc.line(imageX, pontoMaisAltosobrancelhaEsquerdaY + 149, imageX + totalImageWidth, pontoMaisAltosobrancelhaEsquerdaY + 149);
    doc.line(imageX, pontoMaisBaixoSobrancelhaEsquerdaY + 149, imageX + totalImageWidth, pontoMaisBaixoSobrancelhaEsquerdaY + 149);
    doc.setLineWidth(0.2);
    doc.setDrawColor(0, 0, 0);
    doc.line(imageX, pontoMaisAltoSombrancelhaDireitaY + 149, imageX + totalImageWidth, pontoMaisAltoSombrancelhaDireitaY + 149);
    doc.line(imageX, pontoMaisBaixoSombrancelhaDireitaY + 149, imageX + totalImageWidth, pontoMaisBaixoSombrancelhaDireitaY + 149);
    //Linhas ideais para sombracelha
    doc.setDrawColor(16, 173, 34);
    doc.line(landmarks_position.x49, imageY + 175, landmarks_position.x49, imageY + 175 + landmarks_position.y49 - 50); // Asa do nariz
    doc.line(landmarks_position.x279, imageY + 175, landmarks_position.x279, imageY + 175 + landmarks_position.y279 - 50); // Asa do nariz
    doc.line(landmarks_position.x161, landmarks_position.y161 + 150, landmarks_position.x161, landmarks_position.y161 + 160); // Iris externo
    doc.line(landmarks_position.x388, landmarks_position.y388 + 150, landmarks_position.x388, landmarks_position.y388 + 160); // Iris externo
    // doc.line(x264, imageY + 149, x264, yEndVerticalLineMediaPag + 140); // Quinto Lateral
    doc.setFontSize(12);
    doc.setFont('times', 'bold');
    doc.text('Desvio Linha Média - Sobrancelha', imageXDir, textY + 125);
    doc.setFont('times', 'normal');
    doc.setFontSize(8);
    doc.text('Linhas:', imageXDir, textY + 129);
    doc.setTextColor(255, 0, 0)
    doc.text('__', imageXDir + 10, textY + 128);
    doc.text('__________', imageXDir + 15, textY + 129);
    doc.text('Vermelha', imageXDir + 15, textY + 132);
    doc.setTextColor(0, 0, 0)
    doc.text('Média e Final', imageXDir + 14, textY + 129);
    doc.text('__', imageXDir + 35, textY + 128);
    doc.text('_____________', imageXDir + 38, textY + 129);
    doc.text('Preta', imageXDir + 45, textY + 132);
    doc.setTextColor(0, 0, 0)
    doc.text('____________', imageXDir + 40, textY + 129);
    doc.text('Início e Arq. G.', imageXDir + 39, textY + 129);
    doc.setTextColor(107, 63, 160)
    doc.text('__', imageXDir + 64, textY + 128);
    doc.text('____________', imageXDir + 68, textY + 129);
    doc.text('Roxa', imageXDir + 74, textY + 132);
    doc.setTextColor(0, 0, 0)
    doc.text('Reta e Arq. P.', imageXDir + 69, textY + 129);
    doc.setFontSize(5);
    doc.text('Ref: PETERSON, L. J. et al. Cirurgia oral e maxilofacial contemporânea. 6. ed.', imageXDir, textY + 136);
    doc.text('Rio de Janeiro: Elsevier, 2014. p. 1244', imageXDir, textY + 139);
    doc.setFontSize(8);
    doc.setLineWidth(0.5);
    doc.setDrawColor(0, 0, 0);
    doc.line(linhaMediaPag, imageY + 175, linhaMediaPag, yEndVerticalLineMediaPag - 15);
    // Página - 2 imagem 2 direita
    doc.setLineWidth(0.2);
    doc.setDrawColor(255, 0, 0);
    // Linha média da face
    doc.line(landmarks_position.x5, imageY + 175, landmarks_position.x5, yEndVerticalLineMediaPag - 15);
    const distanciaX56toX5 = Math.abs(landmarks_position.x56 - landmarks_position.x5);
    const distanciaX286toX5 = Math.abs(landmarks_position.x286 - landmarks_position.x5);
    const distanciaX67toX5 = Math.abs(landmarks_position.x67 - landmarks_position.x5);
    const distanciaX297toX5 = Math.abs(landmarks_position.x297 - landmarks_position.x5);
    const distanciaX106toX5 = Math.abs(landmarks_position.x106 - landmarks_position.x5);
    const distanciaX335toX5 = Math.abs(landmarks_position.x335 - landmarks_position.x5);
    const distanciaX227toX5 = Math.abs(landmarks_position.x227 - landmarks_position.x5);
    const distanciaX447toX5 = Math.abs(landmarks_position.x447 - landmarks_position.x5);
    const distanciaX71toX5 = Math.abs(landmarks_position.x71 - landmarks_position.x5);
    const distanciaX301toX5 = Math.abs(landmarks_position.x301 - landmarks_position.x5);
    //Inicio sobrancelha
    doc.setLineWidth(0.1);
    doc.setDrawColor(0, 0, 0)
    doc.line(landmarks_position.x56, imageY + 175, landmarks_position.x56, yEndVerticalLineMediaPag - 43); //Inicio sobrancelha esq.    
    doc.line(landmarks_position.x286, imageY + 175, landmarks_position.x286, yEndVerticalLineMediaPag - 43); //Inicio sobrancelha dir. 
    doc.line(landmarks_position.x56, imageY + 175, landmarks_position.x286, imageY + 175);
    doc.setDrawColor(107, 63, 160)
    doc.line(landmarks_position.x67, imageY + 174.6, landmarks_position.x67, yEndVerticalLineMediaPag - 45); //Inicio arqueamento interno sobrancelha esq.
    doc.line(landmarks_position.x297, imageY + 174.6, landmarks_position.x297, yEndVerticalLineMediaPag - 45); //Inicio arqueamento interno sobrancelha dir.
    doc.line(landmarks_position.x67, imageY + 174.6, landmarks_position.x297, imageY + 174.6);
    doc.setDrawColor(0, 0, 0)
    doc.line(landmarks_position.x106, imageY + 174.4, landmarks_position.x106, yEndVerticalLineMediaPag - 44); //Arqueamento g. sobrancelha esq.          
    doc.line(landmarks_position.x335, imageY + 174.4, landmarks_position.x335, yEndVerticalLineMediaPag - 44); //Arqueamento g. sobrancelha dir.
    doc.line(landmarks_position.x106, imageY + 174.4, landmarks_position.x335, imageY + 174.4);
    doc.setDrawColor(107, 63, 160)
    doc.line(landmarks_position.x227, imageY + 174.2, landmarks_position.x227, yEndVerticalLineMediaPag - 44);//Arqueamento peq. sobrancelha
    doc.line(landmarks_position.x447, imageY + 174.2, landmarks_position.x447, yEndVerticalLineMediaPag - 44); //Arqueamento peq. sobrancelha
    doc.line(landmarks_position.x227, imageY + 174.2, landmarks_position.x447, imageY + 174.2);
    doc.setDrawColor(255, 0, 0)
    doc.line(landmarks_position.x71, imageY + 174, landmarks_position.x71, yEndVerticalLineMediaPag - 43);//Final da sobrancelha esq.
    doc.line(landmarks_position.x301, imageY + 174, landmarks_position.x301, yEndVerticalLineMediaPag - 43);//Final da sobrancelha dir.
    doc.line(landmarks_position.x71, imageY + 174, landmarks_position.x301, imageY + 174);
    doc.setFontSize(5);
    doc.setTextColor(0, 0, 0);
    doc.text(`${distanciaX56toX5.toFixed(0)}`, landmarks_position.x56 + 1, yEndVerticalLineMediaPag - 58);
    doc.text(`${distanciaX286toX5.toFixed(0)}`, landmarks_position.x5 + 1, yEndVerticalLineMediaPag - 58);
    doc.setTextColor(107, 63, 160);
    doc.text(`${distanciaX67toX5.toFixed(0)}`, landmarks_position.x67 + 1, yEndVerticalLineMediaPag - 57);
    doc.text(`${distanciaX297toX5.toFixed(0)}`, landmarks_position.x286 + 1, yEndVerticalLineMediaPag - 57);
    doc.setTextColor(0, 0, 0);
    doc.text(`${distanciaX106toX5.toFixed(0)}`, landmarks_position.x106 + 1, yEndVerticalLineMediaPag - 58);
    doc.text(`${distanciaX335toX5.toFixed(0)}`, landmarks_position.x297 + 1, yEndVerticalLineMediaPag - 58);
    doc.setTextColor(107, 63, 160);
    doc.text(`${distanciaX227toX5.toFixed(0)}`, landmarks_position.x227 + 1, yEndVerticalLineMediaPag - 57);
    doc.text(`${distanciaX447toX5.toFixed(0)}`, landmarks_position.x335 + 1, yEndVerticalLineMediaPag - 57);
    doc.setTextColor(255, 0, 0);
    doc.text(`${distanciaX71toX5.toFixed(0)}`, landmarks_position.x71 + 1, yEndVerticalLineMediaPag - 58);
    doc.text(`${distanciaX301toX5.toFixed(0)}`, landmarks_position.x447, yEndVerticalLineMediaPag - 58);
};
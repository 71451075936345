import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Avatar } from 'antd';
import { useCliente } from '@context';
import { getInitialsName } from '@functions';
import { ModalSimples, TitleL2 } from '@components';
export const ImagemPerfilCliente = () => {
  const { cliente, clearCliente } = useCliente();
  const location = useLocation();
  const [isDriveUserOpenFullScreen, setIsDriveUserOpenFullScreen] = useState(false);
  const isDriveUserCloseFullScreen = () => {
    setIsDriveUserOpenFullScreen(false);
  }
  return (<>
    {cliente && location.pathname.includes('profissionais') && (
        <div className='d-flex justify-content-end align-items-end' style={{ position: "relative", display: "inline-block", marginBottom: '-20%', marginRight: '6%' }}>
          <Avatar
            src={cliente.fotoFace || null}  // Se não houver foto, usa null
            alt="Imagem do Paciente"
            size={50}
            style={{
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.2)",
              cursor: 'pointer',
              zIndex: 1000
            }}
            onClick={() => setIsDriveUserOpenFullScreen(true)}
          >
            {cliente.fotoFace ? null : getInitialsName(cliente.name)}
          </Avatar>
          <i className="fa fa-trash holder icone-branco position-absolute" onClick={clearCliente} style={{ zIndex: '1000', bottom: '0', transform: 'translate(-260%, 78%)', scale: '0.5' }}></i>
        </div>
    )}
    {isDriveUserOpenFullScreen && (
      <ModalSimples showModal={isDriveUserOpenFullScreen} handleClose={isDriveUserCloseFullScreen}>
        <TitleL2>Atendendo</TitleL2>
        {cliente.name}
      </ModalSimples>
    )}
  </>);
};
export default ImagemPerfilCliente;
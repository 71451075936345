import React from 'react';
import { Spin, Button } from 'antd';
import { FaWrench } from 'react-icons/fa';
import './styles/MaintenancePage.css';
import { useNavigate } from 'react-router-dom';
const MaintenancePage = () => {
  const navigate = useNavigate(); // Instanciando o hook useNavigate
  const handleGoBack = () => {
    navigate(-1); // Navega para a página anterior
  };
  return (
    <div className="maintenance-container">
      <div className="maintenance-content">
        <FaWrench className="maintenance-icon" size={100} />
        <h1 className="maintenance-title">Página em Manutenção</h1>
        <p className="maintenance-message">
          Estamos trabalhando para melhorar a sua experiência. Volte em breve!
        </p>
        <div className="spinner-container">
          <Spin size="large" />
        </div>
        <Button type="primary" className="back-button" onClick={handleGoBack}>
          Voltar
        </Button>
      </div>
      <div className="background-animation">
        {/* Partículas flutuantes */}
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
        <div className="particle"></div>
      </div>
    </div>
  );
};
export default MaintenancePage;
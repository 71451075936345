import React from 'react';
import { BioterioForteParcerias } from '../../../Produtos/components/vender/PreLancamento/BioterioForteParcerias'
import video from '../assets/videos/DesenvolvimentoBioterioForte.mp4'
import { TitleL1 } from '@components';
import { RiWhatsappFillRi } from '@components/icons';
import { TitleL2 } from '@components/text';
export const EquipamentosPesquisasAnimais = () => {
    return (<>
        <section  style={{ minHeight: '95dvh' }}>
            <div className='row' style={{padding:'0px'}} >
                <div className='col-md-6'>
                    <TitleL1>
                        Termografia Veterinária
                    </TitleL1>
                    <h5 className='text-justify'>
                        Pesquisas farmacêuticas são comumente testadas em roedores.
                        Esses animais são mantidos em caixas (moradia) geralmente sem recursos tecnológicos.
                        O monitoramento contínuo com auxílio de diferentes sensores e opções de automatização pode,
                        por exemplo, detectar precocemente a ineficiência de medicamentos, economizando meses de pesquisa.
                        Sendo assim, foi proposto o desenvolvimento deste sistema capaz de sensoriar, monitorar remotamente e agregar
                        inteligência às caixas moradia
                        <br />   <br />
                        O protótipo funcional proposto trata-se de um hardware composto por uma placa de aquisição de dados,
                        câmeras, 1 termográfica e outra de espectro visível, microfone, sensores de iluminação,
                        umidade e temperatura. O protótipo funcional acompanha um software com soluções na área de visão computacional,
                        como termografia acoplada e registro de informações.
                        A placa de aquisição e o software de interface com o usuário já foram desenvolvidos e uma câmera termográfica
                        com custo adequado à comercialização no protótipo funcional está sendo faturada para testes de compatibilidade.
                        Já possuímos testes em ambiente laboratorial. A tecnologia envolvida no protótipo funcional é dominada pela equipe.
                    </h5>
                    <BioterioForteParcerias />
                </div>
                <div className='col-md-6' >
                    <div className="mx-auto rounded d-flex ">
                        <video className="w-100 rounded" controls >
                            <source src={video} type="video/mp4" alt='Parceria para treinamento de software de visão computacional' />
                        </video>
                    </div>
                    <TitleL2>
                        Aquisições do Protótipo
                    </TitleL2>
                    <h5 className='text-justify'>  O protótipo funcional proposto é um hardware composto por uma câmera unidade termográfica.
                        O protótipo funcional inclui um software com soluções em visão computacional, capaz de identificar doenças em rattus novergicus winstar.
                        Estamos atualmente treinando essa IA, o que justifica o empréstimo e ou a comercialização do protótipo funcional a valor de custo.
                        Dessa forma, empresas ou profissionais podem requerer o empréstismo ou adquirirem o protótipo nos ajudarão a aprimorar nossa IA.
                        Além disso, já realizamos testes em ambiente laboratorial e dominamos a tecnologia envolvida no protótipo funcional
                    </h5>
                    <h5 className="text-center mt-5">
                        <a className=" align-itens-center mx-auto  font-weight-bold"
                            target={'_blank'} rel={'noopener noreferrer'}
                            href={'https://api.whatsapp.com/send?phone=5566996675486&text=Estou%20entrando%20em%20contato%20para%20adiquirir%20o%20protótipo%20funcional%20do%20equipamento%Biotério%20Forte%20'}>
                            <RiWhatsappFillRi color={'green'} size={50} /> </a><br />
                        Compra uso empréstimo e treinamento - entre em contato com a equipe no ícone acima
                    </h5>
                </div>
            </div>
        </section >
    </>)
}
import { CardDirecionamento, BreadcrumbAnt, MdEditMD } from '@components';
import { MdAddCircleOutlineMD } from '@components/icons';
import { useProfessionalData } from 'hooks';
import { useLocation } from 'react-router-dom';
export const Index = () => {
    const location = useLocation();
    const { search } = location;
    const professionalData = useProfessionalData()
    return (<>
        <section style={{ minHeight: "95dvh" }}>
            <div className='w-100'>
                <BreadcrumbAnt icon={'user fa'} text={'Cadastro'} />
                <div className="text-center">
                    <div className="container-fluid">
                        <div className="row d-inline-flex justify-content-center w-100">
                            <CardDirecionamento text={'Cadastrar'} icon={<MdAddCircleOutlineMD size={50} />} href={!professionalData ? `./profissional${search}` : `../../../profissionais/cadastro/cadastrar${search}`} />
                            <CardDirecionamento text={'Editar'} icon={<MdEditMD size={50} />} href={!professionalData ? `./profissional${search}` : `../../../profissionais/cadastro/editar${search}`} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}
export default Index;
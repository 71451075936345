export const FormattedValue = ({ value }) => {
  const formattedValue = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
  return (
    <strong className='font-weight-bold' style={{ padding: '0px' }}>
      <b>{formattedValue}</b>
    </strong>
  );
};
export default FormattedValue;
import { FooterUser, BreadcrumbAnt, MdShoppingBasketMD, MdMonetizationOnMD, CardDirecionamento } from "components";
export const Index = () => {
    return (<>
        <BreadcrumbAnt icon={'user fa'} text={'Serviços'} />
        <div className="text-center">
            <div className="container-fluid">
                <div className="row d-inline-flex justify-content-center w-100">
                    <CardDirecionamento text={'Comprar'} icon={<MdShoppingBasketMD size={50} />} href={`./comprar`} />
                    <CardDirecionamento text={'Vender'} icon={<MdMonetizationOnMD size={50} />} href={`./vender`} />
                </div>
            </div>
        </div>
        <FooterUser />
    </>);
};
import React, { useEffect, useState } from 'react';
import {  RenderImageGrid, TitleL2 } from '../../../../components'
import { api } from '@services';
export const FooterProfissional = () => {
    const [products, setProducts] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await api.get(`get_products`);
                if (Array.isArray(response.data.produtos)) {
                    setProducts(response.data.produtos);
                } else {
                  console.error('A resposta da API não é uma array:', response.data);
                }
            } catch (error) {
                console.error('Erro ao buscar produtos', error);
            }
        };
        fetchProducts();
    }, []);
    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % products.length);
    };
    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + products.length) % products.length);
    };
    return (<>
        <div className="col-md-12 mt-5 py-2" style={{ borderRadius: '0.25rem' }}>
            <div className="container">
                <h4 className="text-center">
                    <small><i className="fa text-success fa-rocket"></i> Recomendações</small>
                </h4>
                <div id="carouselExampleControls" className="carousel slide text-center" data-bs-ride="carousel">
                    <div className="carousel-inner mb-2">
                        {products.map((product, index) => {
                            let images = [];
                            if (typeof product.products_image === 'string') {
                                if (product.products_image.startsWith('[')) {
                                    try {
                                        images = JSON.parse(product.products_image);
                                    } catch (e) {
                                        console.error('Erro ao analisar products_image', e);
                                        images = [{ url: product.products_image }];
                                    }
                                } else {
                                    images = [{ url: product.products_image }];
                                }
                            } else if (Array.isArray(product.products_image)) {
                                images = product.products_image;
                            } else {
                                console.warn('Formato inesperado para products_image:', product.products_image);
                                images = [{ url: '' }];
                            }
                            return (
                                <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
                                    <div className="row align-items-center bg-white">
                                        <div className="col-md-6">
                                        <RenderImageGrid images={images} />
                                        </div>
                                        <div className="col-md-6">
                                            <TitleL2>{product.products_name}</TitleL2>
                                            <p className='text-justify' style={{
                                                display: '-webkit-box',
                                                WebkitLineClamp: 3,
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}>{product.products_description}</p>
                                            <p>Valor: {product.value}</p>
                                            <a href={product.link} className="btn btn-primary mt-2 text-white font-weight-bold">Ver Mais</a>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <button className="btn-primary w-25" type="button" onClick={prevSlide}>
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    </button>&emsp;
                    <button className="btn-primary w-25" type="button" onClick={nextSlide}>
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    </button>
                </div>
            </div>
        </div>
        </> );
};
export default FooterProfissional;
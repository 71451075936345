import React, { useEffect, useState, useCallback } from 'react';
import { api } from 'services';
import SearchMain from './components/layout/SearchMain';
export const Search = ({searchTerm, setSearchTerm}) => {
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);
  const [searching, setSearching] = useState(false);
  const [page, setPage] = useState(1);
  const [resultadosPorPagina, setResultsPorPagina] = useState(10); // Quantidade inicial de resultados por página
  const [hasMore, setHasMore] = useState(true);
  const fetchResults = useCallback(async () => {
    if (!searchTerm) return;
    setSearching(true);
    try {
      const response = await api.get(`get_products`, {
        params: { products_name: searchTerm, pagina: page, resultadosPorPagina }
      });
      const newResults = response.data.produtos;
      setResults((prevResults) => [...prevResults, ...newResults]);
      const totalResults = response.data.totalProdutos;
      const remainingResults = totalResults - page * resultadosPorPagina;
      if (remainingResults > 0) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error(error);
      setError(error);
    }
    setSearching(false);
  }, [searchTerm, page, resultadosPorPagina]);
  const loadMore = () => {
    if (hasMore) {
      setPage(page + 1);
      setResultsPorPagina(resultadosPorPagina + 10);
    }
  };
  useEffect(() => {
    fetchResults();
  }, [searchTerm, page, resultadosPorPagina]); // Include 'fetchResults' in the dependency array
  return (
    <>
      <SearchMain
        info={'Resulta da busca'}
        setResultadosPorPagina={'10'}
        searchTerm={searchTerm}
        searching={searching}
        results={results}
        error={error}
        loadMore={loadMore}
        hasMore={hasMore}
        setHasMore={setHasMore} />
    </>
  );
};
export default Search;
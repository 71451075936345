import { ClienteProvider } from "./ClienteProvider";
import { ProfissionalProvider } from "./ProfissionalProvider";
import { UserPeerProvider } from "./UserPeerProvider";
import { UserProvider } from "./UserProvider";
export const ContextProvider = ({ children }) => {
  return (
    <UserPeerProvider>
        <UserProvider>
          <ProfissionalProvider>
            <ClienteProvider>
              {children}
            </ClienteProvider>
          </ProfissionalProvider>
        </UserProvider>
    </UserPeerProvider>
  );
};
export default ContextProvider;
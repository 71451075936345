import { apiToken } from "services";
export const get_user_cbo_info = async (setOccupations, searchTerm, id, page, setTotalPages) => {
    try {
        const response = await apiToken().get(`get_user_cbo_info/${id}/`, {
            params: {
                search: searchTerm,
                page: page,
            },
        });
        const data = response.data;
        setOccupations(data.data);
        setTotalPages(data.totalPages);
    } catch (error) {
        console.error('Error fetching CBO:', error);
    }
};
export default get_user_cbo_info;
import React, { useEffect, useRef, useState } from 'react';
import { InputPadraoSearch } from 'components';
import { Search } from '@pages/Public';
import SearchListMain from './SearchListMain';
export const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const searchRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        searchRef.current && !searchRef.current.contains(event.target)
      ) {
        setShowSearch(false);
      }
    }
    if (showSearch) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSearch]);
  // Atualiza o estado do showSearch com base no searchTerm
  useEffect(() => {
    if (!searchTerm.trim()) {
      setShowSearch(false);
    }
  }, [searchTerm]);
  return (<>
    <InputPadraoSearch
      type="text"
      placeholder="Busco no site por..."
      value={searchTerm || ''}
      onChange={(event) => {
        const value = event.target.value;
        setSearchTerm(value);
        setShowSearch(!!value.trim()); // Exibe a busca se houver texto
      }}
      style={{ borderRadius: '0.5rem', border: '1px solid #ccc', width: '100%' }}
    />
    {showSearch && (
      <div
        ref={searchRef}

        style={{
          position: 'absolute',
          top: '100%',
          width: window.innerWidth < 1058 ? '100%' : '95%',
          background: 'white',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          borderRadius: '0.5rem',
          zIndex: 10,
          marginLeft: window.innerWidth < 1058 ? '-5px': ''  ,
          maxHeight: '50vh',
          overflowY: 'auto',
          scrollbarWidth: 'thin', // Para Firefox (não arredonda, mas reduz o tamanho)
          scrollbarColor: '#ccc transparent', // Para Firefox
          padding: '0px'
        }}
      >  <style>
          {`
        /* Personaliza a barra de rolagem */
        div::-webkit-scrollbar {
          width: 8px; /* Largura da barra */
          border-radius: 10px; /* Arredondamento da barra */
        }
  
        /* Fundo da barra */
        div::-webkit-scrollbar-track {
          background: transparent;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
  
        /* Estilo do "thumb" (a parte que se move) */
        div::-webkit-scrollbar-thumb {
          background: #ccc;
          border-radius: 10px; /* Arredonda o thumb */
        }
  
        /* Quando o usuário passa o mouse sobre a barra */
        div::-webkit-scrollbar-thumb:hover {
          background: #aaa;
        }
      `}
        </style>

        <SearchListMain
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </div>
    )}
  </>
  );
};
export default SearchBar;
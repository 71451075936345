import React from 'react';
import { Divider, Image, Typography } from 'antd';
import { TitleL4 } from '@components';
import { DocumentosSoftwareAnaliseFacialRegraImagesFrontal } from '.';
import { imgFaceFrontalSorrindo, imgFaceFrontalSorrindo1 } from '../assets';
const { Paragraph, Title } = Typography;
export const DocumentosSoftwareAnaliseFacialAnalisesDoSorriso = () => (
  <>
    <section style={{ marginTop: '20px' }}>
      <Title id="DocumentosSoftwareAnaliseFacialAnalisesDoSorriso" level={3}>Análise Frontal do Sorriso</Title>
      <Divider style={{ margin: '0px', borderColor: 'black', borderWidth: '3px', marginBottom: '10px' }}  />
      <h4> Entrada de imagem para análise orofacial primária</h4>
      <DocumentosSoftwareAnaliseFacialRegraImagesFrontal />
      <TitleL4>Imagens exemplo:</TitleL4>
      <div className='row text-center'>
        <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
          <Image src={imgFaceFrontalSorrindo} alt="Exemplo 2" width={100} height={100} /><br />
          <small>Frontal Sorrindo</small>
        </div>
        <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
          <Image src={imgFaceFrontalSorrindo1} alt="Exemplo 2" width={100} height={100} /><br />
          <small>Frontal Sorrindo</small>
        </div>
      </div>
      <TitleL4>Resultado exemplo:</TitleL4>
      <ul>
        <li><strong>Detecção, Segmentação e Classificação:</strong> Identificação de pontos de referência no sorriso.</li>
        <li><strong>Análise de Características do Sorriso:</strong> Avaliação de simetria e proporções do sorriso.</li>
        <li><strong>Sugestões de Reabilitação:</strong> Recomendações para reabilitações, uso de aparelhos ortodônticos e cirurgias estéticas.</li>
      </ul>
      <div className='row text-center'>
        <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
          <Image src={require('../assets/s1.jpg')} alt="Exemplo 2" width={50} height={50} /><br />
          <small>Seg. Gengiva</small>
        </div>
        <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
          <Image src={require('../assets/s1.jpg')} alt="Exemplo 2" width={50} height={50} /><br />
          <small>Seg. Dentes</small>
        </div>
        <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
          <Image src={require('../assets/s2.jpg')} alt="Exemplo 2" width={50} height={50} /><br />
          <small>Landmarks</small>
        </div>
      </div>
    </section>
    <section style={{ marginTop: '20px' }}>
      <TitleL4>Casos de Uso</TitleL4>
      <Paragraph>
        <ul>
          <li><strong>Análise da Linha do Sorriso: </strong>Verifica se o alinhamento dos dentes acompanha a curvatura do lábio.</li>
          <li><strong>Identificação do Sorriso Gengival: </strong>Detecta exposição excessiva da gengiva ao sorrir.</li>
          <li><strong>Avaliação da Posição dos Dentes: </strong>Examina desalinhamentos e variações na disposição dentária.</li>
          <li><strong>Análise do Tamanho dos Dentes: </strong>Compara proporções dos dentes em relação à face.</li>
          <li><strong>Avaliação da Cor dos Dentes: </strong>Identifica tonalidades e possíveis descolorações para correção estética.</li>
          <li><strong>Análise do Espaço Interdental: </strong>Verifica a presença de diastemas (espaços entre os dentes).</li>
          <li><strong>Simetria do Sorriso: </strong>Avalia se o sorriso está equilibrado em ambos os lados do rosto.</li>
          <li><strong>Proporção Gengiva-Dente: </strong>Analisa a quantidade de gengiva visível em relação ao tamanho dos dentes.</li>
          <li><strong>Detecção de Assimetria Labial: </strong>Identifica diferenças no movimento dos lábios ao sorrir.</li>
          <li><strong>Sugestões para Harmonização do Sorriso: </strong>Gera recomendações para estética dentária e harmonização facial.</li>
        </ul>
      </Paragraph>
    </section>
  </>
);
export default DocumentosSoftwareAnaliseFacialAnalisesDoSorriso;
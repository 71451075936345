import React, { useCallback, useEffect, useState } from 'react';
import { Button, Image, Typography } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import './styles/ProductSidebar.css';
import api from '@services/api';
import { useLocation } from 'react-router-dom';
import { MdMonetizationOnMD } from '@components/icons';
import { Star } from 'lucide-react';
const { Title, Text } = Typography;
export const ServicesCard = ({ service }) => {
    const location = useLocation();
    const { search } = location;
    const parseImages = (imagesString) => {
        try {
            const parsedImages = JSON.parse(imagesString);
            return Array.isArray(parsedImages) ? parsedImages : [];
        } catch (e) {
            return [];
        }
    };
    const images = parseImages(service.services_image)
    return (
        <div className='col-md-4 d-flex ' style={{ padding: '4px' }}>
            <div className='product-card  border'>
                <a
                    href={`${location.pathname.includes("usuario")
                        ? `../../../usuario/servicos/${service.service_id}${search}`
                        : `/servicos/${service.service_id}${search}`
                        }`}
                >
                    <img
                        className="w-100 image-fluid"
                        style={{ minHeight: "80px", maxHeight: "80px", minWidth: '80px', borderRadius: '0' }}
                        src={images[0]?.url || ""}
                        alt="Produtos Imagem"
                    />
                    <Title level={5} className='product-card-title'>{service.services_name}</Title>
                </a>
                {/* <div className='product-card-buttons text-center'>
                    <Button icon={<ShoppingCartOutlined />} />
                    <Button icon={<MdMonetizationOnMD />} />
                </div> */}
            </div>
        </div>
    )
};
export const ServicesSidebar = () => {
    const [products, setProducts] = useState([]);
    const [totalServices, setTotalServices] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 6;
    const fetchProducts = useCallback(async () => {
        try {
            const response = await api.get('get_services', {
                params: {
                    pagina: currentPage,
                    resultadosPorPagina: productsPerPage,
                },
            });
            const formattedProducts = response.data.servicos.map((service) => ({
                ...service,
                value: parseFloat(service.value),
            }));
            setProducts(formattedProducts);
            setTotalServices(response.data.totalProdutos);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    }, [currentPage, setCurrentPage]);
    useEffect(() => {
        fetchProducts();
    }, [fetchProducts, setCurrentPage, currentPage]);
    const handleNext = () => {
        setCurrentPage(currentPage + 1);
    };
    const handlePrev = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    return (
        <>
            <small className='product-sidebar-title'>Serviços em destaque</small>
            <div className="flex space-x-1">
                {[...Array(5)].map((_, index) => (
                    <Star
                        key={index}
                        size={15}
                        className="text-yellow-400"
                        style={{
                            fill: "#FFD700",
                            filter: "drop-shadow(0px 0px 4px #FFD700)",
                        }}
                    />
                ))}
            </div>
            <div className='col-md-12' style={{ padding: '10px' }}>
                <div className='row'>
                    {products.map((service, index) => (
                        <ServicesCard key={index} service={service} />
                    ))}
                </div>
            </div>
            <div className="pagination-buttons text-center col-md-12">
                <Button onClick={handlePrev} disabled={currentPage === 1}>
                    ◀
                </Button>
                <Button onClick={handleNext} >
                    ▶
                </Button>
            </div>
        </>
    );
};
export default ServicesSidebar;
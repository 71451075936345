import React from 'react';
import { ButtonScrollToTop, RiGithubFillRI, RiInstagramFillRI, RiLinkedinBoxFillRI, RiWhatsappFillRi } from 'components';
import { Tooltip } from 'antd';
export const Footer = () => {
    return (
        <>
            <ButtonScrollToTop />
            <footer className="bg-primary" style={{ minHeight: "10dvh", borderRadius: '0.25em', display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                <div style={{ alignContent: 'center', justifyContent: 'center' }}>
                    <h5 className="text-white text-center" style={{ marginBottom: '0' }}>
                        <small>Redes sociais outros contatos</small>
                    </h5>
                    <div className='d-flex py-1'>
                        <div className="d-flex mx-auto text-center">
                            <Tooltip title="LinkedIn">
                                <a aria-label="Siga para as redes sociais" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/rede-social-profissional/">
                                    <RiLinkedinBoxFillRI className="text-white mr-3" size={30} />
                                </a>
                            </Tooltip>
                            <Tooltip title="Instagram">
                                <a aria-label="Siga para as redes sociais" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/rede_social_profissional/">
                                    <RiInstagramFillRI className="text-white mr-3" size={30} />
                                </a>
                            </Tooltip>
                            <Tooltip title="GitHub">
                                <a aria-label="Siga para as redes sociais" target="_blank" rel="noopener noreferrer" href="https://github.com/THOTIACORP">
                                    <RiGithubFillRI className="text-white mr-3" size={30} />
                                </a>
                            </Tooltip>
                            <Tooltip title="WhatsApp">
                                <a aria-label="Siga para as redes sociais" target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=5566996675486&text=Estou%20entrando%20em%20contato%20para%20">
                                    <RiWhatsappFillRi className="text-white" size={30} />
                                </a>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="d-flex inlyn bg-primary" style={{ lineHeight: '0' }}>
                        <div className="d-flex align-items-center mx-auto text-center">
                            <p className="text-white mt-1 text-center">
                                <Tooltip title="Sobre nós - compliance? Clique">
                                    <a href="./sobre"><small>© THOT I.A. CORP. &nbsp;- Todos direitos reservados</small></a>
                                </Tooltip>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};
export default Footer;
import {
    MdWorkMD,
    MdChatMD,
    MdAddShoppingCartMD,
    MdRoomServiceMD,
    MdWidgetsMD,
    MdAppSettingsAltMD,
    MdAssignmentIndMD,
    MdEventAvailableMD,
    MdScienceMD,
    MdInventoryMD,
    MdDevicesOtherMD,
    MdMenuBookMD,
    MdAirlineSeatIndividualSuiteMD,
    MdQrCodeMD,
    MdAccountCircleMD,
    MdLocalPhoneMD,
    MdCloudMD,
    MdMonetizationOnMD,
    MdShoppingBasketMD,
    MdCalendarMonthMD,
    MdAccessAlarmMD,
    MdApprovalMD
} from 'components';
export const mainData = [
    {
        id: "navHome",
        title: " - Usuário",
        icon: <MdAccountCircleMD className={'ml-2'} />,
        href: "../../usuario",
        subData: []
    },
    {
        id: "navServicosInternos",
        title: " - Profissional",
        icon: <MdWorkMD className={'ml-2'} />,
        href: "../../profissionais",
        subData: []
    },
    {
        id: "navSocial",
        title: " - Social",
        icon: <MdChatMD className={'ml-2'} />,
        href: "../../usuario/social",
        subData: []
    },
    {
        id: "navServicos",
        title: " - Serviços",
        icon: <MdRoomServiceMD className={'ml-2'} />,
        topDist: "mt-3",
        subData: [
            {
                id: "navServicosComprar",
                title: "Comprar",
                icon: <MdShoppingBasketMD />,
                href: "../../usuario/servicos/comprar",
            },
            {
                id: "navServicosVender",
                title: "Vender",
                icon: <MdMonetizationOnMD />,
                href: "../../usuario/servicos/vender",
            },
        ]
    },
    {
        id: "navProdutos",
        title: " - Produtos",
        icon: <MdWidgetsMD className={'ml-2'} />,
        subData: [
            {
                id: "navProdutosComprar",
                title: "Comprar",
                icon: <MdShoppingBasketMD />,
                href: "../../usuario/produtos/comprar",
            },
            {
                id: "navProdutosVender",
                title: "Vender",
                icon: <MdMonetizationOnMD />,
                href: "../../usuario/produtos/vender",
            },
        ]
    },
    {
        id: "navConsultas",
        title: " - Consultas",
        icon: <MdEventAvailableMD className={'ml-2'} />,
        subData: [
            {
                id: "navMarcarConsultas",
                title: "Marcar Consultas",
                icon: <MdShoppingBasketMD />,
                href: "../../../usuario/consultas/marcar",
            },
            {
                id: "navConsultasMarcadas",
                title: "Consultas Marcadas",
                icon: <MdCalendarMonthMD />,
                href: "../../../usuario/consultas/marcadas",
            },
            {
                id: "navConsultasFinalizadas",
                title: "Consultas Finalizadas",
                icon: <MdCloudMD />,
                href: "../../../usuario/consultas/finalizadas",
            }
        ]
    },
    {
        id: "navExames",
        title: " - Exames",
        icon: <MdScienceMD className={'ml-2'} />,
        subData: [
            {
                id: "navMarcarExames",
                title: "Marcar Exames",
                icon: <MdShoppingBasketMD />,
                href: "../../../usuario/exames/marcar",
            },
            {
                id: "navExamesMarcados",
                title: "Exames Marcados",
                icon: <MdCalendarMonthMD />,
                href: "../../../usuario/exames/marcados",
            },
            {
                id: "navExamesFinalizados",
                title: "Exames Finalizados",
                icon: <MdCloudMD />,
                href: "../../../usuario/exames/finalizados",
            }
        ]
    },
    {
        id: "navEstoque",
        title: " - Estoque",
        icon: <MdInventoryMD className={'ml-2'} />,
        subData: [
            {
                id: "navReceitasUsoContinuo",
                title: " - Uso Contínuo",
                icon: <MdAccessAlarmMD />,
                href: "../../../usuario/estoque/continuo",
            },
            {
                id: "receitasOcasioesEspecificas",
                title: " - Ocasiões Específicas",
                icon: <MdApprovalMD />,
                href: "../../../usuario/estoque/ocasioes",
            },
            {
                id: "receitasEstoque",
                title: " - Estoque",
                icon: <MdCloudMD />,
                href: "../../../usuario/estoque/estoque",
            }
        ]
    },
    {
        id: "navEquipamentos",
        title: " - Equipamentos",
        icon: <MdDevicesOtherMD className={'ml-2'} />,
        href: "../../usuario/equipamentos",
        subData: []
    },
    {
        id: "navProntuarioUnico",
        title: " - Prontuário Único",
        icon: <MdMenuBookMD className={'ml-2'} />,
        href: "../../usuario/prontuario",
        topDist: "mt-3",
        subData: []
    },
    {
        id: "meusAtestados",
        title: " - Atestados",
        icon: <MdAirlineSeatIndividualSuiteMD className={'ml-2'} />,
        subData: [
            {
                id: "navAtestadosAtivos",
                title: "Ativos",
                icon: <MdAirlineSeatIndividualSuiteMD />,
                href: "../../usuario/atestados",
            },
            {
                id: "navAtestadosArmazenados",
                title: "Armazenados",
                icon: <MdCloudMD />,
                href: "../../usuario/atestados",
            }
        ]
    },
    {
        id: "navCarrinho",
        title: " - Carrinho",
        icon: <MdAddShoppingCartMD className={'ml-2'} />,
        subData: [{
            id: "navCarrinhoItens",
            title: "Items no carrinho",
            icon: <MdAddShoppingCartMD />,
            href: "../../usuario/carrinho",
        },
        {
            id: "navCarrinhoPedidos",
            title: "Pedidos",
            icon: <MdCloudMD />,
            href: "../../usuario/carrinho#NavPublicCarrinhoOrder",
        }]
    },
    {
        id: "navNotasFiscais",
        title: " - Notas Fiscais",
        icon: <MdQrCodeMD className={'ml-2'} />,
        href: "../../usuario/nfe",
        subData: []
    },
    {
        id: "navMeuCadastro",
        title: " - Cadastro",
        icon: <MdAssignmentIndMD className={'ml-2'} />,
        topDist: "mt-3",
        subData: [
            {
                id: "NavDadosPessoais",
                title: "Usuário",
                icon: < MdAccountCircleMD />,
                href: "../../usuario/cadastro/usuario"
            },
            {
                id: "NavDadosProfissionais",
                title: "Profissional",
                icon: <MdWorkMD />,
                href: "../../usuario/cadastro"
            }
        ]
    },
    {
        id: "navPreferencias",
        title: " - Preferências",
        icon: <MdAppSettingsAltMD className={'ml-2'} />,
        href: "../../usuario/preferencias",
        subData: []
    },
    {
        id: "navEmergencia",
        title: " - Emergências",
        icon: <MdLocalPhoneMD className={'ml-2'} />,
        topDist: "mt-3",
        subData: [
            {
                id: "navSOSAmbulancia",
                title: " - Ambulância Pública (SAMU)",
                icon: <MdLocalPhoneMD />,
                href: "tel:+55192"
            },
            {
                id: "navSOSBombeiros",
                title: " - Corpo de Bombeiros",
                icon: <MdLocalPhoneMD />,
                href: "tel:+55193"
            },
            {
                id: "navSOSPM",
                title: " - Polícia Militar",
                icon: <MdLocalPhoneMD />,
                href: "tel:+55190"
            }
            ,
            {
                id: "navSOSCVV",
                title: " - CVV | Centro de Valorização da Vida",
                icon: <MdLocalPhoneMD />,
                href: "tel:+55188"
            }
            ,
            {
                id: "navSOSDH",
                title: " - Disque Direitos Humanos",
                icon: <MdLocalPhoneMD />,
                href: "tel:+55100"
            }
            ,
            {
                id: "navSOSDD",
                title: " - Disque Denúncia",
                icon: <MdLocalPhoneMD />,
                href: "tel:+55181"
            }
            ,
            {
                id: "navSOSMulher",
                title: " - Central de Atendimento para a Mulher - Ministério da Justiça",
                icon: <MdLocalPhoneMD />,
                href: "tel:+55180"
            }
            ,
            {
                id: "navSOSPersonalizado",
                title: " - Configurações personalizadas de SOS",
                icon: <MdLocalPhoneMD />,
                href: "../../../usuario/sosPersonalizado"
            }
        ]
    },
];
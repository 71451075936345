import React from 'react';
import { TitleL2 } from '@components';
export const PlanosPrecosServicosInfo = () => {
    return (<>
        <div className='row '>
            <div className='col-md-6 d-flex' style={{ padding: '18px' }}>
                <div className="text-justify py-2  rounded  text-dark col-md-12" >
                    <TitleL2>Planos Autoescaláveis Dinâmicamente</TitleL2>
                    <h5 >✅
                        Nossos serviços são planos autoescaláveis dinâmicamente para atender às suas necessidades em constante mudança.
                        Se seus requisitos excederem o limite do plano atual, você será automaticamente atualizado para o novo enquadramento,
                        e será cobrado de acordo com o novo plano.
                        Isso garante que você tenha sempre os recursos necessários para crescer sem interrupções e atender à demanda</h5>
                    <h5>
                        ✅ Nosso sistema se ajustará automaticamente para que você pague só pelo que utilizar
                    </h5>
                    <img className="w-100 py-1"
                        src={require('../assets/autoescalavel.jpg')}
                        alt='Empresa desenvolvedora de softwwares e equipamentos para saúde' />
                </div>
            </div>
            <div className='col-md-6 d-flex' style={{ padding: '18px' }}>
                <div className="text-justify py-2  rounded  text-dark col-md-12" >
                    <TitleL2 >Vendas de Exames Digitais</TitleL2>
                    <small className='font-weight-bold '>
                        <strong>     Plano - Software Auto-Sustentável e Rentável</strong></small>
                    <h5 >
                        ✅ Nossa plataforma possui uma tecnologia avançada de Inteligência Artificial (I.A.) de imagens
                        que permite a oferta de serviços de exames digitais de alta qualidade.
                        Quando você se inscreve em um dos nossos planos, você tem a oportunidade de vender exames
                        digitais por meio da plataforma
                    </h5>
                    <h5>
                        ✅   A "Comissão de Vendas" se refere à % porcentagem que repassamos dos lucros das vendas de exames digitais realizadas na plataforma.
                        É importante ressaltar que a comissão repassada varia com base no plano escolhido. Quanto mais recursos e benefícios um plano oferece,
                        maior pode ser a comissão, mas isso também significa que você tem a oportunidade de ganhar mais com vendas
                    </h5>
                    <h5>✅ A comissão retida é uma parte necessária para manter e melhorar constantemente nossos serviços,
                        garantindo que você tenha acesso à mais recente tecnologia de I.A. e suporte de alta qualidade
                    </h5>
                    <h5>
                        ✅ Se você está interessado em se tornar um vendedor de exames digitais em nossa plataforma,
                        consulte os detalhes de comissão específicos do seu plano para entender como as vendas e comissões funcionam para você
                    </h5>
                    <h5>
                        ✅   Estamos comprometidos em criar uma parceria benéfica para todos os envolvidos, e a comissão é uma parte justa e transparente desse processo
                    </h5>
                </div>
            </div>
        </div>
    </>);
};
export default PlanosPrecosServicosInfo;
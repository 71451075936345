import { apiToken } from "services";
export const fetchTabelas = async (id, setTabelas) => {
  try {
    const response = await apiToken().get(`api/tabelas/${id}`);
    if (response.status !== 200) {
      throw new Error("Erro ao buscar tabelas");
    }
    console.log(response.data)
    setTabelas(response.data.tabelas)
  } catch (error) {
    console.error("Erro ao buscar tabelas:", error);
    return [];
  }
};
export default fetchTabelas;
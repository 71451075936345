import SiderHeader from "../SiderHeader/SiderHeader";
import NavProfissionais from "./SiderProfessional";
export const NavProfissionalMain = () => {
    return (<>
    <br/>
        <SiderHeader
            HTML={
                <NavProfissionais />
            }
        />
    </>)
}
import { BreadcrumbAnt, CardDirecionamento } from '@components'
import { MdAddCircleOutlineMD, MdCloudMD, MdEditMD } from '@components/icons'
export const Index = () => {
    return (<>
        <div className=' w-100'>
            <BreadcrumbAnt icon={'user-md fa'} text={'Produtos'} />
            <div className="row d-inline-flex justify-content-center w-100">
                <CardDirecionamento text={'Cadastrar'} icon={<MdAddCircleOutlineMD size={50} />} href={`./cadastrar`} />
                <CardDirecionamento text={'Editar'} icon={<MdEditMD size={50} />} href={`./editar`} />
                <CardDirecionamento text={'Vendidos'} icon={<MdCloudMD size={50} />} href={`./vendidos`} />
            </div>
        </div>
    </>)
}
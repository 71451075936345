import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
    BreadcrumbAnt,
    CardDirecionamento,
    InputPadraoSearch,
    MdAddShoppingCartMD,
    MdDevicesOtherMD,
    MdQrCodeMD,
    MdRoomServiceMD,
    MdWidgetsMD,
    MdAssignmentIndMD,
    MdAppSettingsAltMD,
    MdScienceMD,
    MdAirlineSeatIndividualSuiteMD,
    MdMonetizationOnMD,
    MdInsertChartOutlinedMD,
    MdCalendarMonthMD,
    MdInventoryMD,
    MdSmartToyMD,
    FaResearchgateFA,
    MdStoreMD,
    MdOutlineScreenShareMD
} from 'components';
const cards = [
    { text: 'Vendas', icon: <MdMonetizationOnMD size={50} />, href: 'vendas' },
    { text: 'Agenda', icon: <MdCalendarMonthMD size={50} />, href: 'agenda' },
    { text: 'Gráficos', icon: <MdInsertChartOutlinedMD size={50} />, href: 'graficos' },
    { text: 'E-commerce', icon: <MdStoreMD size={50} />, href: 'ecommerce' },
    { text: 'Serviços', icon: <MdRoomServiceMD size={50} />, href: 'servicos' },
    { text: 'Produtos', icon: <MdWidgetsMD size={50} />, href: 'produtos' },
    { text: 'Equipamentos', icon: <MdDevicesOtherMD size={50} />, href: 'equipamentos' },
    { text: 'Receitas', icon: <MdInventoryMD size={50} />, href: 'medicacao' },
    { text: 'Atestados', icon: <MdAirlineSeatIndividualSuiteMD size={50} />, href: 'atestados' },
    { text: 'Encaminhamento', icon: <MdOutlineScreenShareMD size={50} />, href: 'encaminhamento' },
    { text: 'Exames', icon: <MdScienceMD size={50} />, href: 'exames' },
    { text: 'Carrinho', icon: <MdAddShoppingCartMD size={50} />, href: 'carrinho' },
    { text: 'NFe', icon: <MdQrCodeMD size={50} />, href: 'nfe' },
    { text: 'IA', icon: <MdSmartToyMD size={50} />, href: 'IA' },
    { text: 'Pesquisas', icon: <FaResearchgateFA size={50} />, href: 'pesquisas' },
    { text: 'Cadastro', icon: <MdAssignmentIndMD size={50} />, href: 'cadastro' },
    { text: 'Preferências', icon: <MdAppSettingsAltMD size={50} />, href: 'preferencias' },
];
export const Index = () => {
    const location = useLocation();
    const { search } = location;
    const [searchTerm, setSearchTerm] = useState("");
    const filteredCards = cards.filter(card => card.text.toLowerCase().includes(searchTerm.toLowerCase()));
    return (<>
        <div className='w-100'>
            <BreadcrumbAnt />
            <div className="text-center">
                <InputPadraoSearch
                    type="text"
                    placeholder="Filtre páginas"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="row d-inline-flex justify-content-center w-100">
                    {filteredCards.map((card, index) => (
                        <CardDirecionamento
                            key={index}
                            text={card.text}
                            icon={card.icon}
                            href={`../profissionais/${card.href}${search}`}
                        />
                    ))}
                </div>
            </div>
        </div>
    </>);
}
export default Index;
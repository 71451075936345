import { ButtonCadastrar, TitleL1, TitleL3 } from '@components'
import TRL from './TRL'
import {
    MdInsights
} from "react-icons/md";
import { FaResearchgateFA, MdAccountCircleMD, MdAddShoppingCartMD, MdHandshakeMD, MdHistoryEduMD, MdHomeMD, MdBookMD, MdOutlineEmojiEventsMD, MdOutlinePersonAddAltMD, MdRoomServiceMD, MdWidgetsMD } from '@components/icons';
export const NivelMaturidadeTecnologica = () => {
    return (<>
        <section style={{ minHeight: '95dvh' }}>
            <div className='mt-2'>
                <TitleL1 icon={<MdInsights style={{ scale: '2' }} />}>Maturidade Téc.</TitleL1>
                <h5 className='text-justify mb-2'>
                    A maturidade tecnológica indica o estágio de desenvolvimento de nossos serviços ou produtos.
                    Essa classificação fornece transparência aos usuários sobre o nível de evolução e confiabilidade da tecnologia que estamos oferecendo
                </h5>
                <TitleL3>Serviços</TitleL3>
                <div className="d-flex flex-wrap justify-content-center">
                    <div className="col-md-5  mt-2 align-items-center border rounded  bg-white" style={{ padding: '0px' }}>
                        <img className="img-fluid  col-md-12 d-block mx-auto d-flex justify-content-center align-items-center w-100 rounded"
                            src={require('../assets/background.jpg')} alt='some value'
                            style={{ maxHeight: '280px', width: '100%', minHeight: "280px", margin: '0px', padding: '0px' }} />
                        <TitleL1>Rede Social Profissional</TitleL1>
                        <div className='col-md-12'>
                            <h5 className='py- text-justify'><b><strong>TRL 5:</strong></b> Validação de componente e/ou protótipo em ambiente relevante.  Serviço inaugurado em 2023 </h5>
                        </div>
                        <div className='iconTRL-links'>
                            <a href='../' className='iconTRL-link'>
                                <MdHomeMD className='iconTRL' />
                                <span>Home</span>
                            </a>
                            <a href='../servicos' className='iconTRL-link'>
                                <MdRoomServiceMD className='iconTRL' />
                                <span>Serviços</span>
                            </a>
                            <a href='../produtos' className='iconTRL-link'>
                                <MdWidgetsMD className='iconTRL' />
                                <span>Produtos</span>
                            </a>
                            <a href='../artigos' className='iconTRL-link' >
                                <FaResearchgateFA className='iconTRL' />
                                <span>Artigos</span>
                            </a>
                            <a href='../parcerias' className='iconTRL-link'>
                                <MdHandshakeMD className='iconTRL' />
                                <span>Parcerias</span>
                            </a>
                            <a href='../sobre' className='iconTRL-link'>
                                <MdHistoryEduMD className='iconTRL' />
                                <span>Sobre</span>
                            </a>
                            <a href='../carrinho' className='iconTRL-link'>
                                <MdAddShoppingCartMD className='iconTRL' />
                                <span>Carrinho</span>
                            </a>
                            <a href='../login' className='iconTRL-link'>
                                <MdAccountCircleMD className='iconTRL' />
                                <span>Login</span>
                            </a>
                            <a href='../cadastrar' className='iconTRL-link'>
                                <MdOutlinePersonAddAltMD className='iconTRL' />
                                <span>Cadastrar</span>
                            </a>
                        </div>
                    </div>
                    <div className='col-md-1'></div>
                    <div className="col-md-5  mt-2 align-items-center border rounded  bg-white" style={{ padding: '0px' }}>
                        <img className="img-fluid  col-md-12 d-block mx-auto d-flex justify-content-center align-items-center w-100 rounded"
                            src={require('../assets/nivelMaturidadeTecnologica/imgAnaliseFacial.png')} alt='some value'
                            style={{ maxHeight: '280px', width: '100%', minHeight: "280px", margin: '0px', padding: '0px' }} />
                        <TitleL1>Análise Orofacial</TitleL1>
                        <div className='col-md-12'>
                            <h5 className='py-2 text-justify'><b><strong>TRL 9: </strong></b>Sistema real comprovado por operações de missão bem-sucedidas.  Serviço inaugurado em 2023</h5>
                        </div>
                        <div className='iconTRL-links'>
                            <a href='../servicos#apresentacaoServicos#SolucaoIAAvancadaServicos' className='iconTRL-link'>
                                <MdBookMD className='iconTRL' />
                                <span>Mais informações</span>
                            </a>
                            <a href='../sobre#premios' className='iconTRL-link'>
                                <MdOutlineEmojiEventsMD className='iconTRL' />
                                <span>Reconhecimentos</span>
                            </a>
                        </div >
                    </div >
                </div>
                <TitleL3>Produtos</TitleL3>
                <div className="d-flex flex-wrap justify-content-center">
                    <div className="col-md-5  mt-2 align-items-center border rounded  bg-white">
                        <img className="img-fluid col-md-12 d-block mx-auto d-flex justify-content-center align-items-center mt-2 rounded"
                            src={require('../assets/nivelMaturidadeTecnologica/BioterioForteSF.png')} alt='some value'
                            style={{ maxHeight: '280px', maxWidth: '580px', minHeight: "280px" }} />
                        <div>
                            <TitleL1>BIOTÉRIO FORTE</TitleL1>
                            <h5 className='py-1 text-justify '><b>TRL 4:</b> Projeto mantido por colaboradores internos e sendo avaliado pelo programa Centelha -
                                MT. Em caso positivo saída do projeto Centelha MT com produto
                                TRL - 5 produto pronto para industrializar</h5>
                        </div>
                        <div className='iconTRL-links'>
                            <a href='../parcerias#Pesquisadores#equipamentosPesquisasAnimais' className='iconTRL-link'>
                                <MdHandshakeMD className='iconTRL' />
                                <span>Parcerias</span>
                            </a>
                            <a href='../sobre#premios' className='iconTRL-link'>
                                <MdOutlineEmojiEventsMD className='iconTRL' />
                                <span>Reconhecimentos</span>
                            </a>
                        </div >
                    </div >
                    <div className='col-md-1'></div>
                    <div className="col-md-5  mt-2 align-items-center border rounded  bg-white">
                        <img className="img-fluid d-block mx-auto d-flex justify-content-center align-items-center mt-2 rounded"
                            src={require('../assets/nivelMaturidadeTecnologica/InfinityLifeHealthcareSF.png')} alt='some value'
                            style={{ maxHeight: '280px', maxWidth: '580px', minHeight: "280px" }} />
                        <div>
                            <TitleL1>INFINITY LIFE</TitleL1>
                            <h5 className='py-1 text-justify '><b>TRL 3:</b> Projeto mantido por colaboradores internos
                                com lançamento do produto projetado para 2028 -
                                TRL - 5 produto pronto para industrializar</h5>
                        </div>
                        <div className='iconTRL-links'>
                            <a href='../parcerias#Pesquisadores#equipamentosMedicos' className='iconTRL-link'>
                                <MdHandshakeMD className='iconTRL' />
                                <span>Parcerias</span>
                            </a>
                            <a href='../sobre#premios' className='iconTRL-link'>
                                <MdOutlineEmojiEventsMD className='iconTRL' />
                                <span>Reconhecimentos</span>
                            </a>
                        </div >
                    </div >
                </div>
            </div>
            <TRL />
            <ButtonCadastrar />
        </section>
    </>)
}
import React, { useState } from 'react';
import axios from 'axios';
import { baseURL } from '@services';
import { ReferenciaLocal, ButtonAPI, InputPadraoTitle, MaisInfoMenosInfo } from '@components';
import ProspeccaoClientesEmailInfo from './info/ProspeccaoClientesEmailInfo';
import { InputPadrao } from '@components/Input';
import { TitleL1 } from '@components/text';
export function ProspeccaoClientesEmail() {
  const [emailData, setEmailData] = useState({
    remetente: '',
    senha: '',
    destinatarios: '',
    assunto: '',
    mensagem: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmailData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${baseURL}send-email`, emailData);
      alert('E-mails enviados com sucesso!');
    } catch (error) {
      console.error('Erro ao enviar os e-mails:', error);
      alert('Erro ao enviar os e-mails.');
    }
  };
  return (
    <>
      <div className='col-md-6'>
        <TitleL1>Enviar via E-mail</TitleL1>
        <form onSubmit={handleSubmit}>
          <InputPadrao
            title={'E-mail que enviará msg'}
            type="email"
            name="remetente"
            id="remetente"
            value={emailData.remetente}
            onChange={handleChange}
            required
          />
          <InputPadrao
            title={'Senha do e-mail que enviará msg'}
            type="password"
            name="senha"
            id="senha"
            value={emailData.senha}
            onChange={handleChange}
            required
          />
          <InputPadraoTitle
            htmlFortitle={'Destinatarios'}
          />
          <InputPadrao
            title={'Destinatários (separados por vírgula):'}
            type="text"
            name="destinatarios"
            id="destinatarios"
            value={emailData.destinatarios}
            onChange={handleChange}
            required
          />
          <InputPadrao
            title={'Assunto:'}
            type="text"
            name="assunto"
            id="assunto"
            value={emailData.assunto}
            onChange={handleChange}
            required
          />
          <textarea
            title={'Mensagem'}
            name="mensagem"
            id="mensagem"
            value={emailData.mensagem}
            onChange={handleChange}
            required
          />
          <ButtonAPI type="submit">Enviar</ButtonAPI>
        </form>
        <MaisInfoMenosInfo text={<ProspeccaoClientesEmailInfo />}></MaisInfoMenosInfo>
      </div>
    </>);
}
export default ProspeccaoClientesEmail;
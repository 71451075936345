import React, { useState } from 'react';
import { InputPadrao } from '../../../../components';
export const ThemeContext = ({ onChange }) => {
  const [color, setColor] = useState('');
  const handleColorChange = (event) => {
    const newColor = event.target.value;
    setColor(newColor);
    onChange(newColor);
  };
  return (
    <InputPadrao title={'Cor Primária Sistema'}
      type="color"
      value={color}
      onChange={handleColorChange}
    />
  );
};
export default ThemeContext;
import React from 'react';
import { MaisInfoMenosInfo, TitleL1, TitleL2, ButtonCadastrar } from '@components';
import PlanosPrecosServicosInfo from '../info/PlanosPrecosServicosInfo';
import RepasseAPIPagamento from './RepasseAPIPagamento';
export const PlanosPrecosServicos = () => {
    return (<>
        <section style={{ minHeight: '95dvh' }}>
            <div className='col-md-12' style={{ padding: '0px' }}>
                <div className='row'>
                    <div className='col-md-6'>
                        <TitleL1>Planos e Preços</TitleL1>
                        <h5 className=' text-justify'>
                            Só pague pelo que usar! Máxima automação sempre!
                            O objetivo é manter você com um alto grau de proficiência no centro de todos os seus negócios, com o mínimo esforço possível.
                            Para isso, criamos planos autoescaláveis de forma dinâmica, veja:
                        </h5>
                    </div>
                    <div className='col-md-6 text-center d-flex  flex-wrap justify-content-center mx-auto align-items-center'>
                        <p className='text-center'>
                            <img
                                src={require('../assets/PlanosPrecos.png')}
                                alt="Liberdade Financeira"
                                style={{ maxHeight: '280px', maxWidth: '450px' }}
                                className='col-md-12'
                            /><br />
                            <small className='font-weight-bold'><b>Máquina de vendas, venda muito mais aqui, vendas e revendas automáticas!</b></small>
                        </p>
                    </div>
                </div>
                <div className='col-md-12' style={{ padding: '10px' }}>
                    <div className="row d-flex flex-wrap justify-content-center text-white mt-4" style={{ padding: '0px', border: '2px solid', borderRadius: '27px' }}>
                        <div className="col-md-4 " style={{
                            padding: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            flexShrink: '0',
                            borderRadius: '33px',
                        }}
                        >
                            <div className='trl-container rounded'>
                                <TitleL2 style={{ color: 'white' }}>Profissional Único</TitleL2>
                                <div className='text-justify mr-2 ml-2'>
                                    <ul className='font-weight-bold'><b>Benefícios</b></ul>
                                    <ul>
                                        <li>✅&nbsp;Usuários Ilimitados</li>
                                        <li>✅&nbsp;ERP + CRM: Gerenciamento universal</li>
                                        <li>✅&nbsp;E-commerce como sub-domínio</li>
                                        <li className='font-weight-bold'><strong>✅&nbsp;Comissão de 30% em vendas de exames digitais da plataforma</strong></li>
                                        <br />
                                    </ul>
                                    <ul>
                                        <ul className='font-weight-bold mt-3'>Valor</ul>
                                        <li className='font-weight-bold'>💳 Taxa de mov. bruta: 1.5%</li>
                                        <li className='font-weight-bold '>💳 Mais repasse de API de 3ª: NFe, etc..</li><br />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 " style={{
                            padding: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            flexShrink: '0',
                            borderRadius: '33px',
                        }}
                        >
                            <div className='trl-container rounded'>
                                <TitleL2 style={{ color: 'white' }} >Até 5 profissionais</TitleL2>
                                <div className='text-justify mr-2 ml-2'>
                                    <ul className='font-weight-bold'><b>Benefícios</b></ul>
                                    <ul>
                                        <li>✅&nbsp;Usuários Ilimitados</li>
                                        <li>✅&nbsp;ERP + CRM: Gerenciamento universal</li>
                                        <li>✅ E-commerce como sub-domínio</li>
                                        <li className='font-weight-bold'><strong>✅ Comissão de 50% em vendas de exames digitais da plataforma</strong></li>
                                        <br />
                                    </ul>
                                    <ul>
                                        <ul className='font-weight-bold mt-3'>Valor</ul>
                                        <li className='font-weight-bold '>💳 Taxa de mov. bruta: 2.5%</li>
                                        <li className='font-weight-bold '>💳 Mais repasse de API de 3ª: NFe, etc..</li>
                                        <br />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex" style={{
                            padding: '5px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            flexShrink: '0',
                            borderRadius: '33px',
                        }}
                        >
                            <div className='trl-container rounded'>
                                <TitleL2 style={{ color: 'white' }}>Profissionais Ilimitados</TitleL2>
                                <div className='text-justify mr-2 ml-2'>
                                    <ul className='font-weight-bold'><b>Benefícios</b></ul>
                                    <ul>
                                        <li>✅&nbsp;Usuários Ilimitados</li>
                                        <li>✅&nbsp;ERP + CRM: Gerenciamento universal</li>
                                        <li>✅&nbsp;E-commerce com sub ou domínio</li>
                                        <li className='font-weight-bold'><strong>✅&nbsp;Comissão de 70% em vendas de exames digitais da plataforma</strong></li>
                                        <li className='font-weight-bold'>✅&nbsp; Adição de alunos</li>
                                    </ul>
                                    <ul>
                                        <ul className='font-weight-bold mt-3'>Valor</ul>
                                        <li className='font-weight-bold '>💳 Taxa de mov. bruta: 5%</li>
                                        <li className='font-weight-bold '>💳 Mais repasse de API de 3ª: NFe, etc..</li>
                                        <li className='font-weight-bold '>💳 Hospedagem, domínio e similares</li></ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12 text-justify' style={{ padding: '0px' }}>
                        <small className="text-dark mt-3">O justo é justo! O uso do sistema é open-source [ gratuito ] até o momento da movimentação real.
                            Isso significa que você pode criar sua conta e configurar seu sistema sem custos. Quando o sistema detectar vendas ou transações, o valor do plano será automaticamente deduzido da transação, caso ela seja processada pelo nosso sistema de pagamentos.
                            Se a transação for realizada por um meio externo, o valor será acumulado para dedução em uma futura transação conosco. Caso todas as transações sejam externas, a cobrança será gerada mensalmente com base nas transações registradas na plataforma.</small>
                    </div>
                </div>
                <PlanosPrecosServicosInfo />
                <div className='py-4 text-center'>
                    <MaisInfoMenosInfo text={<>  <RepasseAPIPagamento /> </>} />
                </div>
                <ButtonCadastrar />
            </div>
        </section>
    </>);
};
export default PlanosPrecosServicos;
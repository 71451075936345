import React from 'react';
const CBOInfo = () => {
  return (
    <div>
      <h5 className='font-weight-bold'>Classificação Brasileira de Ocupação (CBO)</h5>
      <p>O componente <strong>CBO</strong> permite aos usuários pesquisar e selecionar ocupações da Classificação Brasileira de Ocupação. Isso é útil para associar as ocupações relevantes ao perfil do usuário, fornecendo informações sobre sua área de atuação.</p>
      <hr />
      <h5 className='font-weight-bold'>Pesquisar e Adicionar Ocupações</h5>
      <p>O campo de pesquisa permite que você digite palavras-chave relacionadas à ocupação desejada. À medida que você digita, a lista de ocupações será filtrada com base nas palavras-chave inseridas.</p>
      <p>Selecione uma ocupação da lista para adicioná-la às suas ocupações selecionadas. Para remover uma ocupação da lista de seleção, basta clicar no ícone de lixeira ao lado dela.</p>
      <hr />
      <h5 className='font-weight-bold'>Ocupações Selecionadas</h5>
      <p>A seção de "Ocupações Selecionadas" exibe as ocupações que você escolheu. Você pode visualizar a lista de ocupações que serão associadas ao seu perfil.</p>
      <p>Se necessário, você pode remover uma ocupação da lista selecionada clicando no ícone de lixeira ao lado dela.</p>
      <hr />
      <h5 className='font-weight-bold'>Enviar Ocupações Selecionadas</h5>
      <p>Quando você estiver satisfeito com as ocupações selecionadas, você pode clicar no botão "Cadastrar Ocupações" para enviar as ocupações ao seu perfil. Isso fornecerá informações valiosas sobre sua área de atuação para a plataforma e permitirá que outros usuários saibam sobre suas competências profissionais.</p>
      <hr />
      <h5 className='font-weight-bold'>Integração com o Perfil Profissional</h5>
      <p>As ocupações selecionadas serão associadas ao seu perfil profissional, enriquecendo as informações apresentadas aos outros usuários. Isso pode ajudar na busca por colaborações, conexões e oportunidades na sua área de atuação.</p>
      <hr />
      <h5 className='font-weight-bold'>Segurança e Privacidade</h5>
      <p>Tenha em mente que as ocupações selecionadas podem revelar informações sobre sua área de atuação. Certifique-se de selecionar apenas as ocupações que você deseja compartilhar com outros usuários e mantenha a segurança e a privacidade das suas informações pessoais.</p>
    </div>
  );
};
export default CBOInfo;
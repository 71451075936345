import React, { useState } from 'react';
import { ButtonAPI, MaisInfoMenosInfo } from '@components';
import { CarouselFotoFace } from './CarouselFotoFace';
import FotoFaceInfo from '../info/FotoFaceInfo';
import { handleSendImage } from '../services';
import { useUser } from '@context/components/UserProvider';
import { MdCameraAltMD } from '@components/icons';
export const ViewFotoPerfil = () => {
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [imageUpdated, setImageUpdated] = useState(false);
  const { user, setUser } = useUser()
  const routeGetSendSingleImage = `user/${user.id}/photos`;
  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setImageName(file.name);
    };
    reader.readAsDataURL(file);
  };
  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setImageName(file.name);
    };
    reader.readAsDataURL(file);
  };
  const handleTakePictureClick = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      const video = document.createElement('video');
      video.srcObject = stream;
      video.onloadedmetadata = () => {
        video.play();
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        const picture = canvas.toDataURL('image/png');
        setImage(picture);
        stream.getTracks().forEach(track => track.stop()); // Stop the camera stream
      };
    } catch (error) {
      console.error(error);
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  return (<>
    <div className="text-center d-flex justify-content-center mt-1 mb-4 col-md-12" >
      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-center col-md-12 align-items-center mx-auto text-center" >
            <div
              className="dropzone py-1 bg-white rounded TridimencionalTotal mx-auto col-md-12"
              onDrop={(e) => handleDrop(e)}
              onDragOver={(e) => e.preventDefault()}              >
              {user && (<>
                <img
                  className="d-block mt-2  rounded-circle mx-auto img-fluid"
                  src={user.images}
                  alt="Imagem"
                  style={{
                    maxHeight: '150px',
                    minHeight: '150px',
                    maxWidth: '150px',
                    minWidth: '150px',
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.2)",
                  }} />
                <p className='collapse'>{imageName}</p>
                <CarouselFotoFace
                  tradeKey={'cadastrarFotoFace'}
                  routeGetSendSingleImage={routeGetSendSingleImage}
                  imageUpdated={imageUpdated}
                  onImageUpdated={() => setImageUpdated(null)}
                  setUser={setUser} />
              </>
              )}
              Solte a imagem aqui ou <br />
              <div className='col-md-12 justify-content-center col-md-12 align-items-center mt-3'
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }} >
                <input className='mr-2 mx-auto'
                  type="file"
                  onChange={(e) => handleFileSelect(e)} />
                <button className='btn btn-primary' onClick={handleTakePictureClick}> <MdCameraAltMD /></button>
              </div>
              <ButtonAPI isLoading={isLoading} onClick={() => handleSendImage(user, image, setIsLoading, setUser)}>Enviar Foto</ButtonAPI>
              <MaisInfoMenosInfo text={<FotoFaceInfo />} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>)
}
export default ViewFotoPerfil;
export const MasterCadastroCompanyUnitInfo = () => {
    return (<>
        <div>
            <h5 className='font-weight-bold'><strong>Master Cadastro Empresa</strong></h5>
            <p>O componente "Master Cadastro Empresa" é uma ferramenta robusta para gerenciar informações detalhadas sobre a empresas. Ele oferece recursos abrangentes para registrar e controlar dados relacionados a empresa, facilitando o gerenciamento eficiente.</p>
            <hr />
            <br />
            <h5 className='font-weight-bold'><strong>Recursos Principais</strong></h5>
            <ol className='ml-3'>
                <li><strong>Cadastro de Empresas:</strong> Registre informações essenciais de várias empresas, como nome, endereço, setor de atuação e muito mais.</li>
                <li><strong>Funcionários:</strong> Associe funcionários às empresas e mantenha registros de cargos, informações de contato e outros detalhes relevantes.</li>
                <li><strong>Clientes:</strong> Mantenha um registro de clientes e parceiros comerciais vinculados a cada empresa.</li>
                <li><strong>Documentação:</strong> Armazene documentos importantes relacionados a cada empresa, como contratos, licenças e registros fiscais.</li>
                <li><strong>Notificações:</strong> Configure notificações e lembretes para datas importantes, como renovação de contratos ou pagamentos.</li>
                <li><strong>Histórico:</strong> Mantenha um histórico completo de atividades e interações com cada empresa.</li>
            </ol>
            <hr />
            <br />
            <h5 className='font-weight-bold'><strong>Benefícios</strong></h5>
            <p>Usar o "Master Cadastro Empresas" oferece diversos benefícios, incluindo:</p>
            <ol className='ml-3'>
                <li>Melhoria na organização e centralização de informações de empresas.</li>
                <li>Facilitação da comunicação interna e externa relacionada a cada empresa.</li>
                <li>Automatização de tarefas de acompanhamento e notificação.</li>
                <li>Maior eficiência na gestão de relacionamentos com clientes e parceiros comerciais.</li>
                <li>Acesso rápido a documentos e registros importantes.</li>
            </ol>
            <hr />
            <br />
            <p>Com o "Master Cadastro Empresas", você terá o controle total sobre as informações das empresas, otimizando a gestão e melhorando a eficiência operacional.</p>
        </div>
    </>);
};
export default MasterCadastroCompanyUnitInfo;
import React, { useState } from 'react';
import { areasConhecimentoJSON } from '../assets/areas_do_conhecimento';
import { ButtonAPI, InputPadraoTitle, MaisInfoMenosInfo } from '@components';
import AreasConhecimentoCNPQInfo from '../info/AreasConhecimentoCNPQInfo';
import { SelectAnt } from '@components/select';
const AreasConhecimentoCNPQ = ({ onAddArea }) => {
    const [selectedGrandeArea, setSelectedGrandeArea] = useState('');
    const [selectedArea, setSelectedArea] = useState('');
    const [selectedSubArea, setSelectedSubArea] = useState('');
    const [selectedEspecialidade, setSelectedEspecialidade] = useState('');
    const handleGrandeAreaChange = (value) => {
        setSelectedGrandeArea(value);
        setSelectedArea('');
        setSelectedSubArea('');
    };
    const handleAreaChange = (value) => {
        setSelectedArea(value);
        setSelectedSubArea('');
    };
    const handleSubAreaChange = (value) => {
        setSelectedSubArea(value);
    };
    const handleEspecialidadeChange = (value) => {
        setSelectedEspecialidade(value);
    };
    const getAreasByGrandeArea = () => {
        const grandeArea = areasConhecimentoJSON['grandes-areas'].find((area) => area.codigo === selectedGrandeArea);
        return grandeArea && Array.isArray(grandeArea.areas) ? grandeArea.areas : [];
    };
    const getSubAreasByArea = () => {
        const areas = getAreasByGrandeArea();
        const area = areas.find((area) => area.codigo === selectedArea);
        return area && Array.isArray(area['sub-areas']) ? area['sub-areas'] : [];
    };
    const getEspecialidadesBySubArea = () => {
        const subAreas = getSubAreasByArea();
        const subArea = subAreas.find((subArea) => subArea.codigo === selectedSubArea);
        return subArea && Array.isArray(subArea.especialidades) ? subArea.especialidades : [];
    };
    const handleAddArea = () => {
        const isGrandeAreaSelected = selectedGrandeArea !== '';
        const isAreaSelected = selectedArea !== '';
        const isSubAreaSelected = selectedSubArea !== '';
        if (isGrandeAreaSelected) {
            if (isAreaSelected && isSubAreaSelected) {
                onAddArea({
                    grande_area: selectedGrandeArea,
                    area: selectedArea,
                    sub_area: selectedSubArea,
                    especialidade: selectedSubArea,
                });
            } else {
                onAddArea({
                    grande_area: selectedGrandeArea,
                });
            }
        }
        setSelectedGrandeArea('');
        setSelectedArea('');
        setSelectedSubArea('');
    };
    const grandeAreaOptions = [
        { value: "", label: "Selecione uma grande área" },
        ...areasConhecimentoJSON['grandes-areas']?.map((grandeArea) => ({
            value: grandeArea.codigo,
            label: grandeArea.nome
        }))
    ];
    const areaOptions = [
        { value: "", label: "Selecione uma área" },
        ...getAreasByGrandeArea()?.map((area) => ({
            value: area.codigo,
            label: area.nome
        }))
    ];
    const subAreaOptions = [
        { value: "", label: "Selecione uma sub-área" },
        ...getSubAreasByArea()?.map((subArea) => ({
            value: subArea.codigo,
            label: subArea.nome,
        })),
    ];
    const especialidadeOptions = [
        { value: "", label: "Selecione uma especialidade" },
        ...getEspecialidadesBySubArea()?.map((especialidade) => ({
            value: especialidade.codigo,
            label: especialidade.nome,
        })),
    ];    
    return (<>
        <div className='col-md-12 mb-2'>
            <div className='row'>
                <div className='col-md-3'>
                    <SelectAnt
                        title={'Grande área'}
                        options={grandeAreaOptions}
                        value={selectedGrandeArea}
                        onChange={handleGrandeAreaChange}
                    >
                    </SelectAnt>
                </div>
                {selectedGrandeArea && (<>
                    <div className='col-md-3'>
                        <SelectAnt
                            title={'Aréa'}
                            value={selectedArea}
                            onChange={handleAreaChange}
                            options={areaOptions}
                        />
                    </div>
                </>)}
                {selectedArea && (<>
                    <div className='col-md-3'>
                        <SelectAnt
                            title="Sub-área"
                            options={subAreaOptions}
                            value={selectedSubArea}
                            onChange={handleSubAreaChange}
                        />
                    </div>
                </>)}
                {selectedSubArea && (<>
                    <div className='col-md-3'>
                        <SelectAnt
                            title="Especialidade"
                            options={especialidadeOptions}
                            value={selectedEspecialidade}
                            onChange={handleEspecialidadeChange}
                        />
                    </div>
                </>)}
            </div></div>
        {selectedGrandeArea && (
            <ButtonAPI onClick={handleAddArea}>
                Adicionar Área
            </ButtonAPI>)}
        <div className='col-md-12 text-center'>
            <MaisInfoMenosInfo text={<AreasConhecimentoCNPQInfo />}></MaisInfoMenosInfo>
        </div>
    </>);
};
export default AreasConhecimentoCNPQ;
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from 'guard';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    BreadcrumbAnt,
    CardDirecionamento,
    InputPadraoSearch,
    MdWorkMD,
    MdChatMD,
    MdAddShoppingCartMD,
    MdRoomServiceMD,
    MdWidgetsMD,
    MdAppSettingsAltMD,
    MdAssignmentIndMD,
    MdEventAvailableMD,
    MdScienceMD,
    MdInventoryMD,
    MdDevicesOtherMD,
    MdMenuBookMD,
    MdAirlineSeatIndividualSuiteMD,
    MdQrCodeMD
} from 'components';
import { apiToken } from 'services';
import { useUser } from '@context/components/UserProvider';
export const Index = () => {
    const { authenticatedProfisional } = useContext(AuthContext);
    const { user } = useUser();
    const navigate = useNavigate();
    const location = useLocation();
    const { search } = location;
    const [searchTerm, setSearchTerm] = useState('');
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || []);
    const [loading, setLoading] = useState(true);
    const cards = [
        { text: 'Profissional', icon: <MdWorkMD size={50} />, href: '../profissionais' },
        { text: 'Social', icon: <MdChatMD size={50} />, href: './social' },
        { text: 'Serviços', icon: <MdRoomServiceMD size={50} />, href: './servicos' },
        { text: 'Produtos', icon: <MdWidgetsMD size={50} />, href: './produtos' },
        { text: 'Consultas', icon: <MdEventAvailableMD size={50} />, href: './consultas' },
        { text: 'Exames', icon: <MdScienceMD size={50} />, href: './exames' },
        { text: 'Estoque', icon: <MdInventoryMD size={50} />, href: './estoque' },
        { text: 'Equipamentos', icon: <MdDevicesOtherMD size={50} />, href: './equipamentos' },
        { text: 'Prontuário', icon: <MdMenuBookMD size={50} />, href: './prontuario' },
        { text: 'Atestados', icon: <MdAirlineSeatIndividualSuiteMD size={50} />, href: './atestados' },
        { text: 'Carrinho', icon: <MdAddShoppingCartMD size={50} />, href: './carrinho' },
        { text: 'NFe', icon: <MdQrCodeMD size={50} />, href: './nfe' },
        { text: 'Cadastro', icon: <MdAssignmentIndMD size={50} />, href: './cadastro' },
        { text: 'Preferências', icon: <MdAppSettingsAltMD size={50} />, href: './preferencias' },
    ];
    const fetchCartData = useCallback(async () => {
        try {
            if (!loading) return; // Evitar chamadas múltiplas
            const response = await apiToken().post(`post_cart/${user.id}`, { cart });
            console.log('Resposta ao adicionar ao carrinho:', response.data);
            setCart(response.data.cartItems);
            localStorage.setItem('cart', JSON.stringify(response.data.cartItems));
            setLoading(false); // Desativar o loading após o carregamento
        } catch (error) {
            console.error('Erro ao adicionar ao carrinho:', error);
        }
    }, [cart, user, loading]);
    useEffect(() => {
        if (loading) {
            fetchCartData(); // Somente chamar se estiver em estado de loading
        }
        const cadastroProfissional = localStorage.getItem('cadastroProfissional');
        if (cadastroProfissional === 'SIM') {
            navigate('/usuario/cadastro/profissional');
        }
        const chavesParaRemover = ["Google", "Facebook", "Github"];
        chavesParaRemover.forEach(chave => {
            localStorage.removeItem(chave);
        });
    }, [navigate, fetchCartData, loading]);
    const filteredCards = cards.filter(card => card.text.toLowerCase().includes(searchTerm.toLowerCase()));
    return (
        <div className='w-100'>
            <BreadcrumbAnt icon={'user fa'} text={'Início Usuário'} />
            <div className="text-center">
                <InputPadraoSearch
                    type="text"
                    placeholder="Filtre páginas"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div>
                    <div className="row d-inline-flex justify-content-center w-100">
                        {authenticatedProfisional && filteredCards.filter(card => card.text === 'Profissional').map(card => (
                            <CardDirecionamento
                                key={card.text}
                                text={card.text}
                                icon={card.icon}
                                href={`${card.href}${search}`}
                            />
                        ))}
                        {filteredCards.filter(card => card.text !== 'Profissional').map(card => (
                            <CardDirecionamento
                                key={card.text}
                                text={card.text}
                                icon={card.icon}
                                href={`${card.href}${search}`}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Index;
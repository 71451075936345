import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { api, apiToken } from '@services';
import {HeaderAnt, Footer, ButtonAPI, Carousel3D, SiderUserMain, BreadcrumbAnt, MarkdownRenderer, PdfViewer, RenderImageGrid } from 'components'
import ProductAlert from './ProductAlert';
import SearchMain from '@pages/Public/Search/components/layout/SearchMain'
import { AuthContext } from 'guard';
import { CustomHelmet } from 'functions';
import { useUser } from '@context/components/UserProvider';
export const ProductsDetailsPage = () => {
  const { products_id } = useParams();
  const [product, setProduct] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || []);
  const [quantidade, setQuantidade] = useState(1);
  const [images, setImages] = useState([]);
  const navigate = useNavigate();
  const { authenticated } = useContext(AuthContext);
  const { user } = useUser()
  const addToCart = async (product) => {
    try {
      const existingProductIndex = cart.findIndex((item) => item.product_code_id === product.product_code_id);
      if (existingProductIndex !== -1) {
        const updatedCart = [...cart];
        const existingQuantity = parseInt(updatedCart[existingProductIndex].quantity, 10);
        const newQuantity = parseInt(product.quantity, 10);
        updatedCart[existingProductIndex].quantity = existingQuantity + newQuantity;
        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
      } else {
        const newCart = [...cart, product];
        setCart(newCart);
        localStorage.setItem('cart', JSON.stringify(newCart));
      }
      const cartWithTable = cart.map(item => ({ ...item }));
      const cartWithUserId = { ...cartWithTable, user: user.id };
      if (authenticated) {
        const response = await apiToken().post(`post_cart/${user.id}`, { cart: cartWithUserId });
        console.log('Resposta ao adicionar ao carrinho:', response.data);
        localStorage.setItem('cart', JSON.stringify(response.data.cartItems));
      }
    } catch (error) {
      console.error('Erro ao adicionar ao carrinho:', error);
    }
  };
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searching, setSearching] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [resultadosPorPagina, setResultsPorPagina] = useState(10);
  const [bulaInfo, setBulaInfo] = useState('');
  const location = useLocation();
  const { search } = location;
  const fetchResults = useCallback(async () => {
    if (!searchTerm) return;
    setSearching(true);
    try {
      const response = await api.get(`get_products`, {
        params: { nome: searchTerm, pagina: page }
      });
      const newResults = response.data.produtos;
      setResults((prevResults) => [...prevResults, ...newResults]);
      const totalResults = response.data.totalProdutos;
      const remainingResults = totalResults - page * resultadosPorPagina;
      setHasMore(remainingResults > 0);
    } catch (error) {
      console.error(error);
      setError(error);
    }
    setSearching(false);
  }, [searchTerm, page, resultadosPorPagina,]);
  const loadMore = () => {
    setPage(page + 1);
    setResultsPorPagina(resultadosPorPagina + 10);
  };
  useEffect(() => {
    fetchResults();
  }, [fetchResults]);
  const handleAddToCart = () => {
    if (product) {
      alert(JSON.stringify(product));
      const productToAdd = {
        id: product.id,
        products_name: product.products_name,
        description: product.products_description,
        image: product.image,
        value: product.value,
        products_type: product.type,
        company_unit_id: product.company_unit_id,
        quantity: quantidade,
        table: 'product'
      };
      addToCart(productToAdd);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
  };
  const handleComprarAgora = () => {
    if (product) {
      const productToAdd = {
        id: product.id,
        products_name: product.products_name,
        description: product.products_description,
        image: product.image,
        value: product.value,
        products_type: product.type,
        company_unit_id: product.company_unit_id,
        quantity: quantidade,
        table: 'product'
      };
      addToCart(productToAdd);
      const urlCarrinho = '/carrinho';
      window.location.href = urlCarrinho;
    }
  };
  useEffect(() => {
    async function fetchProduct() {
      try {
        const response = await api.get(`get_products_id/${products_id}`);
        setProduct(response.data);
        let productImages = [];
        if (typeof response.data.products_image === 'string') {
          try {
            productImages = JSON.parse(response.data.products_image);
          } catch (error) {
            console.error('Error parsing JSON string:', error);
            productImages = [{ url: response.data.products_image, id: 1 }];
          }
        } else if (Array.isArray(response.data.products_image)) {
          productImages = response.data.products_image;
        }
        if (productImages.length > 0) {
          while (productImages.length < 9) {
            productImages.push(...productImages);
          }
          productImages = productImages.slice(0, 9);
          setImages(productImages);
        }
        if (response.data.products_name) {
          localStorage.setItem('searchTerm', JSON.stringify(response.data.products_name));
          setSearchTerm(response.data.products_name);
        }
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    }
    fetchProduct();
  }, [navigate, products_id, search]);
  useEffect(() => {
    async function fetchBulaInfo() {
      try {
        const response = await api.get(`get_products_bula_id/${products_id}`);
        const data = response.data;
        setBulaInfo(data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchBulaInfo();
  }, [products_id]);
  const pdfUrl = `bula/${bulaInfo.bulaPacienteFileName}`;
  if (!product) {
    return <div>Loading...</div>;
  }
  const currentURL = window.location.href;
  const whatsappMessage = `Veja este produto: ${product.products_name}. Valor R$ ${product.value} - Link:${currentURL} `;
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  return (<>
    <CustomHelmet />
    <HeaderAnt/>
    <div className="d-flex mt-3" style={{ overflowX: 'hidden' }}>
      {currentURL.includes('usuario') && <SiderUserMain />}
      <section className="w-100 ">
        {currentURL.includes('usuario') && <BreadcrumbAnt icon={'user fa'} text={'Cadastro'} />}
        <div className="col-md-12" style={{ minHeight: "100dvh", padding: '0px' }} >
          <div className="d-flex flex-wrap   justify-content-center  ">
            <RenderImageGrid images={images} />
            <div className='col-md-6   justify-content-center align-items-center '>
              <h3 className='text-center  font-weight-bold py-3'>{product.products_name}</h3>
              <div className="text-justify">
                <MarkdownRenderer content={product.products_description} />
              </div>
              <p className='text-justify'><b className='font-weight-bold'>Tipo:</b> {product.products_type}</p>
              <p className='text-justify'><b className='font-weight-bold'>Empresa:</b> {product.company_name}</p>
              <p className='text-left text-dark font-weight-bold'>R${product.value}</p>
              <small>Entregas:   &nbsp; </small>
              <i className="fa fa-envelope text-success" aria-hidden="true"></i>  &nbsp; <i className="fa fa-truck text-success" aria-hidden="true"></i>  &nbsp;  <i className="fa fa-motorcycle text-success" aria-hidden="true"></i>
            </div>
            <div className='text-center ml-3  bg-light rounded ' style={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.2)", }}>
              <div className='mr-2 ml-2'>
                <label className='font-weight-bold mt-1 text-dark text-center' >Quantidade</label><br />
                <input
                  className='text-center form-control mb-3' style={{ borderRadius: '20px' }}
                  type="number"
                  value={quantidade}
                  onChange={(event) => setQuantidade(event.target.value)}
                  inputMode="numeric"
                  min="0"
                />
                <ButtonAPI style={{ minWidth: '100%' }} onClick={() => handleComprarAgora(quantidade)}>👆 Comprar Agora</ButtonAPI>
                <ButtonAPI style={{ minWidth: '100%' }} onClick={() => handleAddToCart(quantidade)}><i className="fa text-white flip-horizontal">🛒</i> Adicionar ao Carrinho</ButtonAPI>
              </div>
            </div>
          </div>
          <div className="col-md-12  bg-primary  justify-content-center align-items-center mt-5 text-center py-4" style={{ overflowX: 'hidden', borderRadius: '0.25rem' }}>
            <div className='d-flex mx-auto  justify-content-center  align-items-center ' >
              <div className='d-flex mx-auto justify-content-center align-items-center'>
                {images.length > 0 && (
                  <Carousel3D images={images.map((images, index) => ({
                    src: images.url,
                    alt: `Product Image ${index + 1}`,
                    text: `Imagem ${index + 1}`,
                  }))} />
                )}
              </div>
            </div>
          </div>
          <SearchMain
            info={'As pessoas também visualizaram'}
            setResultadosPorPagina={setResultsPorPagina}
            searching={searching}
            results={results}
            error={error}
            loadMore={loadMore}
            hasMore={hasMore}
            setHasMore={setHasMore} />
          {pdfUrl && <div className="mt-5 bg-light py-3">
            <h2 className='font-weight-bold text-center'>{product.products_type === "Medicações" ? 'Bula' : 'Manual'}</h2>
            <div className="  d-flex flex-wrap justify-content-center align-items-center">
              <PdfViewer pdfUrl={pdfUrl} />
            </div>
          </div>}
        </div>
        <div className="col-md-12  text-center justify-content-center align-items-center mt-4 mb-4 py-4">
          <a href={`whatsapp://send?text=${encodeURIComponent(whatsappMessage)}`}>
            <i className="ion-social-whatsapp  text-success fa-3x" />
            <p className='font-weight-bold text-dark'> Compartilhar Produto</p>
          </a>
          <p>Preço: R${product.value}</p>
        </div>
        <ProductAlert
          show={showAlert}
          onHide={handleCloseAlert}
          products_name={product.products_name}
          products_type={product.products_type}
          value={product.value}
          quantity={product.quantidade}
        />
        <div style={{ paddingLeft: '0px' }}>
          <Footer />
        </div>
      </section>
    </div>
  </>);
};
export default ProductsDetailsPage;
import { MdWarningMD } from "@components/icons";
import { TitleL2 } from "@components/text";
import { Tag } from "antd";
export const RecomendacoesInfo = () => {
    return (<>
        <Tag className='mt-3 mb-3 d-flex ' style={{
            width: '100%',
            whiteSpace: 'pre-line'
        }} icon={<MdWarningMD size={150} color={'yellow'} />} color="yellow">
            <div className='col-md-12'>
                <TitleL2 style={{ color: "green", textAlign: "left" }}>Recomendações</TitleL2>
                <small className=" col-md-12 text-justify mt-4 text-dark">
                    Cuidado ao utilizar ferramentas de terceiros para prospectar pacientes,
                    especialmente se você estiver usando uma ferramenta gratuita. Algumas das
                    ferramentas gratuitas de terceiros que são comumente usadas para prospectar
                    pacientes incluem WhatsApp, e-mail e redes sociais.
                    Se você decidir usar uma conexão com terceiros para prospectar pacientes
                    gratuitamente, tenha em mente que deve limitar o número de pacientes prospectados.
                    No máximo, você deve prospectar cerca de 30 pacientes por vez ou por dia, caso contrário,
                    há um forte risco de bloqueio na ferramenta de terceiros.
                    Lembre-se de que não nos responsabilizamos pelo uso inadequado que possa
                    resultar em bloqueio nas plataformas de terceiros. Criamos esta ferramenta
                    para ajudá-lo a prospectar pacientes, então use com moderação e de acordo com as regras.
                    Evite incomodar seus pacientes com informações excessivas enviadas repetidamente para o mesmo número.
                    Isso pode ser considerado inoportuno e até mesmo invasivo para alguns pacientes.
                    Uma boa prática é enviar lembretes ou informações relevantes apenas quando necessário e de forma organizada,
                    talvez mensalmente. Dessa forma, você pode manter uma comunicação eficaz com seus pacientes sem incomodá-los
                    ou parecer spam. Lembre-se sempre de respeitar a privacidade e a paz de seus pacientes e usar as ferramentas
                    de comunicação de forma consciente e moderada.</small>
            </div>
        </Tag>
    </>);
}
export default RecomendacoesInfo;
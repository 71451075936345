import React from 'react';
import { useLocation } from 'react-router-dom';
const CadastroAuthInfo = () => {
  const location = useLocation();
  const isCadastrarPage = location.pathname.startsWith("/cadastrar");
  return (
    <>
      <div className="text-justify col-md-12">
        {isCadastrarPage && <>
          <h5>Como Funciona o <strong>Cadastro ou Login Rápidos</strong> com Outras Contas</h5>
          <p>
            Você pode se cadastrar ou vincular sua conta a partir de outras plataformas facilmente. Veja como funciona:
          </p>
          <hr />
          <ol className="ml-3">
            <li><strong>Cadastro ou Login com Google:</strong> Clique no botão "Cadastrar com Google" para criar uma conta usando sua conta do Google.</li>
            <li><strong>Cadastro ou Login com Apple:</strong> Utilize o botão "Cadastrar com Apple" para se registrar com sua ID Apple.</li>
            <li><strong>Cadastro ou Login com Facebook:</strong> Clique no botão "Cadastrar com Facebook" para criar uma conta vinculada ao seu perfil do Facebook.</li>
            <li><strong>Cadastro ou Login com GitHub:</strong> Você pode se cadastrar rapidamente usando sua conta GitHub clicando no botão "Cadastrar com GitHub".</li>
          </ol>
          <hr />
          <p>
            Após clicar em um dos botões acima, siga as instruções fornecidas pela plataforma escolhida para criar ou vincular sua conta. É rápido e fácil!
          </p>
          <br />  <br />  <br />  <br />  <br />  <br />  <br />  <br />
        </>}
        <h5>Integração Multiplataformas -  <strong>Cadastro ou Login Rápidos</strong></h5>
        <p>
          Oferecemos a conveniência de se cadastrar ou fazer login rapidamente usando suas contas de outras plataformas. Veja como funciona:
        </p>
        <hr />
        <ul className="ml-3">
          <li><strong>Cadastro ou Login com Google:</strong> Clique no botão "Cadastrar com Google" para criar uma conta usando sua conta do Google. Se você já possui uma conta conosco, pode vincular sua conta do Google à sua conta existente.</li>
          <li><strong>Cadastro ou Login com Facebook:</strong> Utilize o botão "Cadastrar com Facebook" para criar uma conta vinculada ao seu perfil do Facebook. Se você já possui uma conta conosco, pode vincular sua conta do Facebook à sua conta existente.</li>
          <li><strong>Cadastro ou Login com GitHub:</strong> Você pode se cadastrar rapidamente usando sua conta GitHub clicando no botão "Cadastrar com GitHub". Se você já possui uma conta conosco, pode vincular sua conta do GitHub à sua conta existente.</li>
          <ol className='ml-3 mt-3'>
            <li>Para vincular uma conta já existente na nossa plataforma faça seu <strong> Login</strong></li>
            <li>Siga até <strong> Meu Cadastro</strong></li>
            <li>Role a página  <strong> Meu Cadastro</strong> até <strong> Cadastro ou Login Rápidos</strong></li>
            <li>Verifique quais contas já estão relacionadas e como as outras podem ser relacionadas</li>
          </ol>
        </ul>
        <hr />
        <p>
          A melhor parte é que você pode vincular várias contas de plataformas diferentes a um único perfil em nosso sistema. Isso significa que você pode usar suas contas do Google, Facebook e GitHub para fazer login em sua conta conosco sem perder nenhuma informação.
          Após clicar em um dos botões acima, siga as instruções fornecidas pela plataforma escolhida para criar ou vincular sua conta. É rápido, seguro e permite que você acesse facilmente nossos serviços.
        </p>
      </div>
    </>);
};
export default CadastroAuthInfo;
import React from 'react';
import { BreadcrumbAnt, CardDirecionamento, MdAccessAlarmMD, MdApprovalMD, MdCloudMD } from 'components';
export const ControllerMedicamentos = () => {
    return (<>
        <div className=' w-100' >
            <BreadcrumbAnt icon={'user fa'} text={'Meus Medicamentos'} />
            <div className="py-3">
                <div className="text-center">
                    <div className="container-fluid">
                        <div className="row d-inline-flex justify-content-center w-100">
                            <CardDirecionamento text={'Uso contínuo'} icon={<MdAccessAlarmMD size={50} />} href={'../usuario/estoque/continuo'} />
                            <CardDirecionamento text={'Uso ocasional'} icon={<MdApprovalMD size={50} />} href={'../usuario/estoque/ocasioes'} />
                            <CardDirecionamento text={'Estoque'} icon={<MdCloudMD size={50} />} href={'../usuario/estoque/estoque'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}
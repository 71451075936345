import React from 'react';
import { Divider, Image, Typography } from 'antd';
import { TitleL4 } from '@components';
import { DocumentosSoftwareAnaliseFacialRegraImagesFrontal } from '.';
// import { imgFaceFrontalBravo, imgFaceFrontalBravo1 } from '../assets';
const { Paragraph, Title } = Typography;
export const DocumentosSoftwareAnaliseFacialAnalisesFrontalBravo = () => (
  <>
    <section style={{ marginTop: '20px' }}>
      <Title id='DocumentosSoftwareAnaliseFacialAnalisesFrontalBravo' level={3}>Análise Frontal com Expressão Brava</Title>
      <Divider style={{ margin: '0px', borderColor: 'black', borderWidth: '3px', marginBottom: '10px' }}  />
      <h4>Entrada de imagem para análise de rugas da glabela e nariz</h4>
      <DocumentosSoftwareAnaliseFacialRegraImagesFrontal />
      <TitleL4>Imagens exemplo:</TitleL4>
      <div className='row text-center'>
        <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
          {/* <Image src={imgFaceFrontalBravo} alt="Exemplo 1" width={100} height={100} /><br /> */}
          <small>Frontal Bravo</small>
        </div>
        <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
          {/* <Image src={imgFaceFrontalBravo1} alt="Exemplo 2" width={100} height={100} /><br /> */}
          <small>Frontal Bravo</small>
        </div>
      </div>
      <TitleL4>Resultado exemplo:</TitleL4>
      <ul>
        <li><strong>Detecção e Segmentação:</strong> Identificação de rugas na glabela e no nariz.</li>
        <li><strong>Análise de Intensidade:</strong> Classificação do grau das rugas conforme profundidade e distribuição.</li>
        <li><strong>Sugestões Estéticas:</strong> Recomendações para atenuação de rugas, como skincare ou procedimentos minimamente invasivos.</li>
      </ul>
      <div className='row text-center'>
        <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
          {/* <Image src={require('../assets/rugaGlabela.jpg')} alt="Exemplo 1" width={50} height={50} /><br /> */}
          <small>Rugas Glabela</small>
        </div>
        <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
          {/* <Image src={require('../assets/rugaNariz.jpg')} alt="Exemplo 2" width={50} height={50} /><br /> */}
          <small>Rugas Nariz</small>
        </div>
        <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
          {/* <Image src={require('../assets/landmarksBravo.jpg')} alt="Exemplo 3" width={50} height={50} /><br /> */}
          <small>Landmarks</small>
        </div>
      </div>
    </section>
    <section style={{ marginTop: '20px' }}>
      <TitleL4>Casos de Uso</TitleL4>
      <Paragraph>
        <ul>
          <li><strong>Detecção de Rugas na Glabela:</strong> Identifica a presença e severidade das rugas entre as sobrancelhas.</li>
          <li><strong>Análise de Rugas no Nariz:</strong> Avalia linhas finas e rugas ao redor da base do nariz.</li>
          <li><strong>Identificação de Expressões Faciais:</strong> Detecta padrões de tensão na região frontal e nasal.</li>
          <li><strong>Avaliação da Pele:</strong> Verifica a textura e elasticidade da pele na região frontal.</li>
          <li><strong>Sugestões para Estética Facial:</strong> Indica possíveis procedimentos para suavizar marcas de expressão.</li>
        </ul>
      </Paragraph>
    </section>
  </>
);
export default DocumentosSoftwareAnaliseFacialAnalisesFrontalBravo;
import { InvitationList } from "./components/InvitationList";
import { SiderUserMain, FooterUser,HeaderAnt, BreadcrumbAnt } from "../../../../components";
import { NotificationSystem } from "./components/NotificationSystem";
export const Notificacoes = () => {
    return (<>
        <HeaderAnt/>
        <div className="d-flex mt-3" style={{ overflowX: 'hidden' }}>
            <SiderUserMain />
            <section className="w-100">
                <div className=' w-100' >
                    <BreadcrumbAnt icon={'user fa'} />
                    <NotificationSystem />
                    <InvitationList />
                    <FooterUser />
                </div>
            </section>
        </div>
    </>)
}
import { post_user_login } from "../services/post_user_login";
export const login = async (
    email,
    password,
    setUser,
    setAuthenticated,
    setLoading,
    setAuthenticatedProfissional,
    updateProfessionalData,
    clearProfessionalData,
    updateProfissional,
    navigate,
) => {
    try {
        const response = await post_user_login(email, password);
        setLoading(false);
        const { user, images, token, userId, user_type, professionalData } = response.data;
        const updatedUser = { ...user, id: userId, user_type, images };
        localStorage.setItem("user", JSON.stringify(updatedUser));
        localStorage.setItem("token", token);
        setUser(updatedUser);                
        setAuthenticated(true);
        if (user_type === "profissional") {
            setAuthenticatedProfissional(true)
            updateProfessionalData(professionalData);
            updateProfissional(professionalData.professionalData[0])
        } else {
            clearProfessionalData();
        }
        navigate("/usuario");
    } catch (error) {
        alert('Login error')
    }
};
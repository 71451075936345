import { ButtonAPI, TitleL2 } from '@components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState, useContext } from "react";
import { AuthContext } from "guard";
import TesteDesenvolvedorLogin from './testes/TesteDesenvolvedorLogin';
import CadastroBotoesTentent from '@pages/Public/Cadastrar/components/components/CadastroAuth';
import { api } from '@services';
import { Divider, Form, Input } from 'antd';
import { login } from './functions/login';
import { useProfessionalData } from 'hooks/useProfessionalData';
import { useProfisional } from 'context';
import { MdAccountCircleMD } from '@components/icons';
import { useUser } from '@context/components/UserProvider';
import { RenderTesteIfLocalhost } from '@tests/index';
import { TitleL1 } from '@components/text';
export const Index = () => {
  // const  {authenticated, login} = useContext(AuthContext)
  const { setAuthenticated, setLoading, setAuthenticatedProfissional } = useContext(AuthContext);
  const { user, setUser } = useUser();
  const { updateProfessionalData, clearProfessionalData } = useProfessionalData();
  const { updateProfissional } = useProfisional()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loginNativo, setLoginNativo] = useState(false);
  const location = useLocation();
  const { search } = location;
  const navigate = useNavigate();
  const efetuarLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await login(
        email,
        password,
        setUser,
        setAuthenticated,
        setLoading,
        setAuthenticatedProfissional,
        updateProfessionalData,
        clearProfessionalData,
        updateProfissional,
        navigate);
      if (response.status === 200) {
        alert('Logged in successfully');
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  const onclickLoginNativo = () => {
    const userStr = localStorage.getItem('user');
    if (userStr) {
      navigate('/usuario' + search);
    }
    setLoginNativo((prevLoginNativo) => !prevLoginNativo);
    if (window.location.hostname === 'localhost') {
      setEmail("teste@thotiacorp.com");
      setPassword("12345");
    }
    setTimeout(() => {
      const emailField = document.getElementById('email');
      if (emailField) {
        emailField.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 400);
  };
  const onclickRedirecionaParteInterna = () => {
    navigate('/usuario' + search);
  };
  const logout = () => {
    localStorage.clear();
    setUser(false)
  };
  const recuperarSenha = async () => {
    try {
      const response = await api.post(`post_user_recover_passowrd_email`, { emailOrCpf: email });
      if (response.status === 200) {
        alert('Email de recuperação de senha enviado com sucesso.');
      }
    } catch (error) {
      alert('Erro ao enviar email de recuperação de senha.');
    }
  };
  return (<>
    <div id="login" className=' mt-5'>
      {/* <p>{String(authenticated)}</p> */}
      <div className='text-center'>
        {!user && (<MdAccountCircleMD color={'#334B90'} size={90} />)}
        {user && (
          <div style={{ position: "relative", display: "inline-block" }}>
            <img className="d-block rounded-circle w-50 mx-auto image-fluid"
              style={{
                maxHeight: '80.8px',
                minHeight: '80.8px',
                maxWidth: '77.6px',
                minWidth: '77.6px',
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.2)"
              }}
              src={user?.images}
              id="FaceFrontalLogin"
              alt="Imagem do Usuário"
              onClick={onclickRedirecionaParteInterna} />
            <i className=" fa fa-power-off mt-2  text-danger position-absolute h-25" onClick={logout} style={{
              zIndex: '1', top: '95%', left: '50%', transform: 'translate(-50%, -50%)',
            }}></i>
          </div>
        )}
      </div>
      <TitleL1>Entrar</TitleL1>
      <CadastroBotoesTentent onclickLoginNativo={onclickLoginNativo} text={'Login Rápido'} />
      {loginNativo &&
        <> <div className='text-center'>
          <RenderTesteIfLocalhost>
            <TesteDesenvolvedorLogin
              efetuarLogin={efetuarLogin}
              setEmail={setEmail}
              setPassword={setPassword}
              setUser={setUser}
              setAuthenticated={setAuthenticated}
              setLoading={setLoading}
              setAuthenticatedProfissional={setAuthenticatedProfissional}
              updateProfessionalData={updateProfessionalData}
              clearProfessionalData={clearProfessionalData}
              updateProfissional={updateProfissional}
              navigate={navigate}
            />
          </RenderTesteIfLocalhost>
        </div>
          <Form
            className='border bg-light py-2 rounded'
            initialValues={{ remember: true }}
            layout="vertical"
            onSubmit={efetuarLogin}>
            <div className='col-md-10 mx-auto'>
              <TitleL2>Dados do Usuário</TitleL2>
              <Divider />
              <Form.Item rules={[{ required: true, message: 'Por favor, insira um e-mail!' }]} name="email" label={'E-mail'}>
                <Input
                  type="text"
                  placeholder="E-mail"
                  data-bouncer-message=" ATENÇÃO: Insira um e-mail válido."
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} // Atualiza o estado 'email'
                  id="email" />
              </Form.Item>
              <Form.Item rules={[{ required: true, message: 'Por favor, insira uma senha!' }]} name="password" label={'Senha'}>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Senha"
                  id="password" />
              </Form.Item>
            </div>
            <div className="form__field col-md-10 mx-auto  mt-2 text-center  d-flex  justify-content-end align-items-end">
              <small className="form-text text-right text-dark "><Link onClick={recuperarSenha}><b className='text-dark bg-warning'>Recuperar
                Senha</b></Link></small>
            </div>
            <ButtonAPI isLoading={isLoading} style={{ minWidth: '50%' }} type={'button'} onClick={efetuarLogin} > Entrar</ButtonAPI >
            <h6 className="text-center mt-1">-OU-</h6>
            <Link className="text-white" to={'../cadastrar'} > <ButtonAPI style={{ minWidth: '50%' }} isLoading={isLoading} > Cadastrar</ButtonAPI ></Link>
          </Form>
        </>}
    </div>
  </>)
};
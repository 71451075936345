import {api} from "services";
export const fetchColunas = async (tabelaSelecionada, setColunas) => {
    try {
        if (tabelaSelecionada) {
            const response = await api.get(
                `api/tabelas/${tabelaSelecionada.value}/colunas`
            );
            setColunas(
                response.data.colunas.map((coluna) => ({ value: coluna, label: coluna }))
            );
        } else {
            setColunas([]);
        }
    } catch (error) {
        console.error("Erro ao buscar colunas:", error);
    }
};
export default fetchColunas;
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Image, Typography } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import './styles/ProductSidebar.css';
import api from '@services/api';
import { useLocation } from 'react-router-dom';
import ServicesSidebar from './ServicesSidebar';
import { Star } from 'lucide-react';
import { MdMonetizationOnMD } from '@components/icons';
const { Title, Text } = Typography;
export const ProductCard = ({ product }) => {
    const location = useLocation();
    const { search } = location;
    const parseImages = (imagesString) => {
        try {
            const parsedImages = JSON.parse(imagesString);
            return Array.isArray(parsedImages) ? parsedImages : [];
        } catch (e) {
            return [];
        }
    };
    const images = parseImages(product.products_image)
    return (
        <div className='col-md-4 d-flex ' style={{ padding: '4px' }}>
            <div className='product-card  border'>
                <a
                    href={`${location.pathname.includes("usuario")
                        ? `../../../usuario/produtos/${product.id}${search}`
                        : `/produtos/${product.id}${search}`
                        }`}
                >
                    <img
                        className="w-100 image-fluid"
                        style={{ minHeight: "80px", maxHeight: "80px", minWidth: '80px', borderRadius: '0' }}
                        src={images[0]?.url || ""}
                        alt="Produtos Imagem"
                    />
                </a>
                <Title level={5} className='product-card-title'>{product.products_name}</Title>
                {/* <div className='product-card-buttons text-center'>
                    <Button icon={<ShoppingCartOutlined />} />
                    <Button icon={<MdMonetizationOnMD />} />
                </div> */}
            </div>
        </div>
    )
};
export const ProductSidebar = () => {
    const [products, setProducts] = useState([]);
    const [totalProducts, setTotalProducts] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 6;
    const fetchProducts = useCallback(async () => {
        try {
            const response = await api.get('get_products', {
                params: {
                    pagina: currentPage,
                    resultadosPorPagina: productsPerPage,
                },
            });
            const formattedProducts = response.data.produtos.map((product) => ({
                ...product,
                value: parseFloat(product.value),
            }));
            setProducts(formattedProducts);
            setTotalProducts(response.data.totalProdutos);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    }, [currentPage]);
    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);
    const handleNext = () => {
        if (currentPage < (totalProducts / productsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handlePrev = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    return (
        <>
            <ServicesSidebar />
            <small className='product-sidebar-title'>Produtos em destaque</small>
            <div className="flex space-x-1">
                {[...Array(5)].map((_, index) => (
                    <Star
                        key={index}
                        size={15}
                        className="text-yellow-400"
                        style={{
                            fill: "#FFD700",
                            filter: "drop-shadow(0px 0px 4px #FFD700)",
                        }}
                    />
                ))}
            </div>
            <div className='col-md-12' style={{ padding: '10px' }}>
                <div className='row'>
                    {currentProducts.map((product, index) => (
                        <ProductCard key={index} product={product} />
                    ))}
                </div>
            </div>
            <div className="pagination-buttons text-center col-md-12">
                <Button onClick={handlePrev} disabled={currentPage === 1}>
                    ◀
                </Button>
                <Button onClick={handleNext} >
                    ▶
                </Button>
            </div>
        </>
    );
};
export default ProductSidebar;
import React from 'react';
import { useLocation } from 'react-router-dom';
import {HeaderAnt} from '../../../components';
import Index from './components/Index';
export const Carrinho = () => {
  const location = useLocation();
  const isCarrinhoPage = location.pathname.startsWith("/carrinho");
  return (<>
    {isCarrinhoPage && <> <HeaderAnt/> </>}
    <div><Index /></div>
  </>);
};
export default Carrinho;
import { apiToken } from "services";
export const updateImageDate = async (id, imageId, url, setUser) => {
  try {
    const shouldUpdate = window.confirm(`Tem certeza que deseja usar essa imagem no perfil ?`);
    if (!shouldUpdate) {
      return;
    }
    const response = await apiToken().put(
      `atualizarDataImagem/${id}/${imageId}`,
      { status: 'active' },
    );
    if (response.status === 200) {
      setUser(prevUser => {
        const updatedUser = {
          ...prevUser, // Mantém as outras informações do usuário
          images: url, // Atualiza apenas o campo de imagem
        };
        localStorage.setItem("user", JSON.stringify(updatedUser));
        return updatedUser; // Retorna o usuário atualizado
      });
    } else {
      console.error('Failed to update image status:', response.status);
      alert(`Erro ao atualizar o status da imagem: ${response.status}`);
    }
  } catch (error) {
    console.error('Error updating image status:', error);
    alert('Erro ao atualizar o status da imagem:', error);
  }
};
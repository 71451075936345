import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Carousel } from 'antd';
import { ButtonCadastrar, CardFragmentIdentifier, TitleL1, TitleL3 } from 'components';
import predioTHOT from '../assets/predioTHOT.webp';
import {
    MdHistoryEdu,
    MdOutlineCoPresent,
    MdArchitecture,
    MdOutlineDiversity2,
    MdHandyman,
    MdTranslate,
    MdInsights,
    MdTroubleshoot,
    MdOutlineEmojiEvents,
} from "react-icons/md";
// import SatisfactionLevels from './SatisfactionLevels';
import EcosystemMenu from './EcosystemMenu';
import { MdInfoOutlineMD } from '@components/icons';
export const Apresentacao = () => {
    const carouselRef = useRef(null);
    const images = [
        { id: 1, url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3809.015201275089!2d-53.22132272506701!3d-17.314815683560038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x937c8545bc645f53%3A0x7fa367389d9704a2!2sTHOT%20I.A.%20CORP!5e0!3m2!1spt-BR!2sbr!4v1739494610687!5m2!1spt-BR!2sbr', type: 'video' },
        { id: 2, url: predioTHOT, type: 'image' },
        { id: 3, url: 'https://www.youtube.com/embed/_2hNMPk9FmI?si=GijEZog-PYd9sqFq', type: 'video' },
        { id: 4, url: 'https://www.youtube.com/embed/oKxBgOpLQRQ?si=AtzDJnvGTsqw7yhH', type: 'video' },
    ];
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);
    const handleWindowResize = () => {
        setScreenWidth(window.innerWidth);
    };
    return (<>
        <section className='mr-2 ml-2' style={{ minHeight: '95dvh' }}>
            <TitleL1 icon={<MdInfoOutlineMD style={{ scale: '2' }} />}>
                Informações Globais
            </TitleL1>
            <h5 className="text-justify text-dark">  As informações globais da empresa são o resumo dos dados essenciais que ajudam a construir uma visão ampla e estratégica do negócio.
                Essas informações são fundamentais para a transparência corporativa, fortalecimento da marca e tomada de decisões estratégicas mútua. Como segue as habilidades:
            </h5>
            <div className="text-center py-3 mt-4 bg-secondary trl-container " style={{ borderRadius: '33px', position: 'relative' }}>
                <div className='row'>
                    <div className='col-md-12'><h5 className="text-justify text-white mt-3 font-weight-bold">
                        Empresa especializada na consultoria e no desenvolvimento de
                        softwares e equipamentos com Inteligência Artificial e Realidade Mista Integrada.
                        Nossa atuação abrange áreas como:
                    </h5>
                    </div>
                    <div className={` ${screenWidth >= 1058 ? 'col-md-6' : 'col-md-12'}`}>
                        <h5 className="text-justify text-white mt-3">
                            <ul className='mt-3'>
                                <p >  <strong className='mt-3'>  Consultoria e desenvolvimento:</strong>
                                    <li>✅ IA (Inteligência Artificial)</li>
                                    <li>✅ RM (Realidade Mista )</li>
                                    <li>✅ IoT (Internet das Coisas)</li>
                                    <li>✅ Web Dev.</li>
                                    <li>✅ E afins</li>
                                </p >
                            </ul>
                        </h5>
                        <div className='text-left mt-2 py-2'>
                            <Link
                                to='../parcerias'
                                style={{ cursor: 'pointer' }}
                                className=' nav-link text-white font-weight-bold '
                            >
                                <li>Faça parcerias conosco aqui</li>
                            </Link>
                            <Link
                                to='../produtos'
                                style={{ cursor: 'pointer', }}
                                className=' nav-link text-white font-weight-bold'
                            >
                                <li>Veja nossos produtos aqui</li>
                            </Link>
                        </div>
                    </div>
                    {screenWidth >= 1058 && <>
                        <div className='col-md-6 d-flex justify-content-center align-items-center'>
                            <div style={{ position: 'relative', width: '100%', maxWidth: '600px', margin: '0 auto' }}>
                                <Button
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '0',
                                        transform: 'translateY(-50%)',
                                        zIndex: 1,
                                        backgroundColor: '#334B90',
                                        border: 'none',
                                        boxShadow: "0px 4px 4px 0px  rgb(21, 120, 67), inset  0px 0px 6px 0px  rgb(128, 229, 165)",
                                        fontSize: '24px',
                                        color: '#fff',
                                    }}
                                    onClick={() => carouselRef.current.prev()}
                                >
                                    ◀
                                </Button>
                                <Carousel ref={carouselRef} dots={{ className: 'custom-dots' }} autoplay={false}>
                                    {images.map((item) => (
                                        <div key={item.id}>
                                            {item.type === 'image' ? (
                                                <img
                                                    className="w-100 rounded image-fluid"
                                                    style={{
                                                        height: '270px',
                                                        borderRadius: '30px',
                                                        border: '1px light',
                                                        boxShadow: '1px 1px 2px #000000',
                                                    }}
                                                    src={item.url}
                                                    alt="Produto Imagem"
                                                />
                                            ) : (
                                                <iframe
                                                    className="w-100 rounded"
                                                    style={{
                                                        borderRadius: '30px',
                                                        border: '1px light',
                                                        boxShadow: '1px 1px 2px #000000',
                                                        height: '270px'
                                                    }}
                                                    src={item.url}
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                    title="YouTube Video"
                                                />
                                            )}
                                        </div>
                                    ))}
                                </Carousel>
                                <Button
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: '0',
                                        transform: 'translateY(-50%)',
                                        zIndex: 1,
                                        backgroundColor: '#334B90',
                                        border: 'none',
                                        fontSize: '24px',
                                        color: '#fff',
                                        boxShadow: "0px 4px 4px 0px  rgb(21, 120, 67), inset  0px 0px 6px 0px  rgb(128, 229, 165)"
                                    }}
                                    onClick={() => carouselRef.current.next()}
                                >
                                    ▶
                                </Button>
                            </div>
                        </div>
                        <div className='iconTRL-links col-md-6'>
                            {/* <SatisfactionLevels /> */}
                        </div>
                        <EcosystemMenu />
                    </>}
                </div>
            </div>
            <TitleL3 className='mt-5'>⏣ Mais informações gerais da empresa</TitleL3>
            <div className='mb-5 d-flex flex-wrap justify-content-center '>
                <CardFragmentIdentifier href={'historia'} title={'História'} text={'Como foi a construção da empresa da ideia à evolução'} icon={<MdHistoryEdu style={{ marginTop: '28px', scale: '5' }} />} />
                <CardFragmentIdentifier href={'significados'} title={'Significados'} text={'Branding marketing, criação do nome, logo, paleta de cores'} icon={<MdOutlineCoPresent style={{ marginTop: '28px', scale: '4' }} />} />
                <CardFragmentIdentifier href={'Condutas'} title={'Condutas'} text={'Modo de agir da empresa matriz, objetivo, missão, visão'} icon={<MdArchitecture style={{ marginTop: '28px', scale: '4' }} />} />
                <CardFragmentIdentifier href={'Team'} title={'Equipe'} text={'Pessoas que participaram de alguma forma na construção'} icon={<MdOutlineDiversity2 style={{ marginTop: '28px', scale: '4' }} />} />
            </div>
            <TitleL3 className='mt-5'>⏣ Mais informações técnicas da empresa</TitleL3>
            <div className='mb-5 d-flex flex-wrap justify-content-center '>
                <CardFragmentIdentifier href={'ferramentas'} title={'Ferramentas'} text={'Referências da construção, propagação e expansão'} icon={<MdHandyman style={{ marginTop: '28px', scale: '4' }} />} />
                <CardFragmentIdentifier href={'linguagem'} title={'Linguagem'} text={'Material de orientação computacional'} icon={<MdTranslate style={{ marginTop: '28px', scale: '4' }} />} />
                <CardFragmentIdentifier href={'maturidade'} title={'Maturidade'} text={'Domínio das tecnologias da plataforma'} icon={<MdInsights style={{ marginTop: '28px', scale: '4' }} />} />
                <CardFragmentIdentifier href={'nossosNumeros'} title={'Nossos Números'} text={'Métricas de visualizações, utilizações'} icon={<MdTroubleshoot style={{ marginTop: '28px', scale: '4' }} />} />
            </div>
            <TitleL3 className='mt-5'>⏣ Conquistas da empresa</TitleL3>
            <div className='mb-5 d-flex flex-wrap justify-content-center '>
                <CardFragmentIdentifier href={'premios'} title={'Prêmios'} text={'Conquistas externas da equipe empresarial'} icon={<MdOutlineEmojiEvents style={{ marginTop: '28px', scale: '4' }} />} />
            </div>
            {screenWidth <= 1058 && <>
                <div className='py-4 d-flex justify-content-center'>
                    <div style={{ position: 'relative', width: '100%', maxWidth: '600px', margin: '0 auto' }}>
                        <Button
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '0',
                                transform: 'translateY(-50%)',
                                zIndex: 1,
                                backgroundColor: '#334B90',
                                border: 'none',
                                fontSize: '24px',
                                color: '#fff',
                                boxShadow: "0px 4px 4px 0px  rgb(21, 120, 67), inset  0px 0px 6px 0px  rgb(128, 229, 165)"
                            }}
                            onClick={() => carouselRef.current.prev()}
                        >
                            ◀
                        </Button>
                        <Carousel ref={carouselRef} dots={{ className: 'custom-dots' }} autoplay={false}>
                            {images.map((item) => (
                                <div key={item.id}>
                                    {item.type === 'image' ? (
                                        <img
                                            className="w-100 rounded image-fluid"
                                            style={{
                                                height: '270px',
                                                borderRadius: '30px',
                                                border: '1px light',
                                                boxShadow: '1px 1px 2px #000000',
                                            }}
                                            src={item.url}
                                            alt="Produto Imagem"
                                        />
                                    ) : (
                                        <iframe
                                            className="w-100 rounded"
                                            style={{
                                                borderRadius: '30px',
                                                border: '1px light',
                                                boxShadow: '1px 1px 2px #000000',
                                                height: '270px'
                                            }}
                                            src={item.url}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                            title="YouTube Video"
                                        />
                                    )}
                                </div>
                            ))}
                        </Carousel>
                        <Button
                            style={{
                                position: 'absolute',
                                top: '50%',
                                right: '0',
                                transform: 'translateY(-50%)',
                                zIndex: 1,
                                backgroundColor: '#334B90',
                                border: 'none',
                                fontSize: '24px',
                                color: '#fff',
                                boxShadow: "0px 4px 4px 0px  rgb(21, 120, 67), inset  0px 0px 6px 0px  rgb(128, 229, 165)"
                            }}
                            onClick={() => carouselRef.current.next()}
                        >
                            ▶
                        </Button>
                    </div>
                </div>
                {/* <div className='iconTRL-links col-md-6'>
                    <SatisfactionLevels />
                </div> */}
            </>}
            <ButtonCadastrar />
        </section>
    </>);
};
export default Apresentacao;
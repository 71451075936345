import { usePaymentInputs, PaymentInputsContainer } from "react-payment-inputs";
import images from "react-payment-inputs/images";
const PaymentFormCard = ({state, setState}) => {
  // Usando o hook usePaymentInputs para obter os props necessários
  const {
    meta,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    getCardImageProps,
  } = usePaymentInputs();
  return (
    <div className="col-md-12" id="PaymentForm">
      <PaymentInputsContainer>
        {() => (
          <>
            {/* Imagem do cartão */}
            <svg {...getCardImageProps({ images })} width="150" height="150" />
            {/* Campo do número do cartão */}
            <input
              {...getCardNumberProps({
                value: state.number,
                onChange: (e) =>
                  setState((prev) => ({ ...prev, number: e.target.value })),
              })}
              placeholder="Card Number"
              className="ant-input css-dev-only-do-not-override-19lec04 ant-input-outlined" // Usando as classes do Ant Design
            />
            {/* Campo de expiração */}
            <input
              {...getExpiryDateProps({
                value: state.expiry,
                onChange: (e) =>
                  setState((prev) => ({ ...prev, expiry: e.target.value })),
              })}
              placeholder="MM/YY Expiry"
              className="ant-input css-dev-only-do-not-override-19lec04 ant-input-outlined" // Usando as classes do Ant Design
            />
            {/* Campo do CVC */}
            <input
              {...getCVCProps({
                value: state.cvc,
                onChange: (e) =>
                  setState((prev) => ({ ...prev, cvc: e.target.value })),
              })}
              placeholder="CVC"
              className="ant-input css-dev-only-do-not-override-19lec04 ant-input-outlined" // Usando as classes do Ant Design
            />
          </>
        )}
      </PaymentInputsContainer>
      {/* Exibição de erro, se houver */}
      {meta.isTouched && meta.error && <span>{meta.error}</span>}
    </div>
  );
};
export default PaymentFormCard;
import React, { useState, useEffect, useContext } from 'react';
import { Button, message, Modal } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useLocation } from 'react-router-dom';
import { ButtonCadastrar, InputPadraoTitle } from 'components'
import { apiToken } from '@services';
import ResumoCompra from './ResumoCompra';
import ImpostoBR from '../utils/ImpostoBR';
import ItemsNoCarrinho from './ItemsNoCarrinho';
import FormaDePagamento from './FormaDePagamento';
import FinalizarCompra from './FinalizarCompra';
import PaymentFormCard from './PaymentFormCard';
import PaymentDetails from './ordens/PaymentDetails';
import { useUser } from '@context/components/UserProvider';
import { AuthContext } from '@guard/index';
import GetFrete from './GetFrete';
export const MainCarrinhoPublic = () => {
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || []);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const { authenticated } = useContext(AuthContext);
    const { user } = useUser()
    const totalValor = cart.reduce((accumulator, currentValue) => accumulator + currentValue.value * currentValue.quantity, 0);
    const [cartao, setCartao] = useState(false);
    const [payment, setPayment] = useState([]);
    const [open, setOpen] = useState(false);
    const [qrCode, setQrCode] = useState('');
    const [qrCodeText, setQrCodeText] = useState('');
    const [state, setState] = useState({
        cvc: '',
        expiry: '',
        focus: '',
        name: '',
        number: '',
        issuer: ''
    });
    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);
    const handleWindowResize = () => {
        setScreenWidth(window.innerWidth);
    };
    const handlequantityChange = async (index, event) => {
        const newCart = [...cart];
        newCart[index].quantity = parseInt(event.target.value);
        setCart(newCart);
        localStorage.setItem('cart', JSON.stringify(newCart));
        if (authenticated) {
            const cartWithUserId = { ...newCart, user_id: user.id };
            await apiToken().post(`post_cart/${user.id}`, { cart: cartWithUserId });
        }
    };
    const handleDeleteItem = async (index) => {
        const newCart = [...cart];
        const item = newCart[index]; // Pega o item completo
        newCart.splice(index, 1);
        setCart(newCart);
        localStorage.setItem('cart', JSON.stringify(newCart));
        if (authenticated) {
            await updateCartItem(item); // Chama a função para atualizar o estado do item no backend com o item completo
        }
    };
    const updateCartItem = async (index) => {
        try {
            //alert(JSON.stringify(index))
            const newCart = [...cart];
            newCart.splice(index, 1);
            setCart(newCart);
            if (authenticated) {
                const response = await apiToken().put(`put_cart_state/${user.id}`, { cart: index });
                console.log('Resposta ao atualizar o item do carrinho:', response.data);
            }
        } catch (error) {
            console.error('Erro ao atualizar o item do carrinho:', error);
        }
    };
    const hasItemsToRemove = () => {
        const itemToRemove = cart.find(item => !item.quantity || !item.value || isNaN(item.quantity) || isNaN(item.value));
        if (itemToRemove) {
            updateCartItem(itemToRemove.id);
        }
    };
    const handleDeleteItemZero = async () => {
        const updatedCart = cart.filter((item) => item.quantity > 0);
        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        const cartWithUserId = { ...updatedCart, user_id: user.id };
        await apiToken().post(`post_cart/${user.id}`, { cart: cartWithUserId });
    };
    function calcularTotaisPorTipo(cart) {
        const totaisPorTipo = {};
        cart.forEach(item => {
            const tipo = item.products_type || "outros";
            totaisPorTipo[tipo] = totaisPorTipo[tipo] || { quantity: 0, valorTotal: 0 };
            totaisPorTipo[tipo].quantity += parseInt(item.quantity);
            totaisPorTipo[tipo].valorTotal += item.value * parseInt(item.quantity);
        });
        return totaisPorTipo;
    }
    const totaisPorTipo = calcularTotaisPorTipo(cart);
    let procedimentosValor = 0;
    cart.forEach((item) => {
        if (item.products_type !== "Equipamentos" && item.products_type !== "Medicamentos") {
            procedimentosValor += parseFloat(item.value) * parseInt(item.quantity);
        }
    });
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(3);
    const [selectedType, setSelectedType] = useState("Todos");
    const filteredCart = cart.filter((item) => {
        if (selectedType === "Todos") {
            return item.products_name.toLowerCase().includes(searchTerm.toLowerCase());
        } else {
            return (
                item.products_type === selectedType &&
                item.products_name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
    });
    const types = [...new Set(cart.map((item) => item.products_type))];
    types.unshift("Todos");
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredCart.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredCart.length / itemsPerPage);
    const pageButtons = [];
    for (let i = 1; i <= totalPages; i++) {
        pageButtons.push(
            <button
                key={i}
                onClick={() => setCurrentPage(i)}
                className={`btn btn-primary mx-1 ${currentPage === i ? "active" : ""
                    }`}
            >
                {i}
            </button>
        );
    }
    const handleTypeChange = (value) => {
        console.log('Selected Type:', value);
        setSelectedType(value);
    };
    const [checkbox1, setCheckbox1] = useState(false);
    const [checkbox2, setCheckbox2] = useState(false);
    const [checkbox3, setCheckbox3] = useState(false);
    const [tipoPagamento, setTipoPagamento] = useState('');
    const handleCheckbox1Change = () => {
        setCheckbox1(checkbox1 => !checkbox1);
        setCheckbox2(false);
        setCheckbox3(false);
        setTipoPagamento('PIX');
        setCartao(false); // Adicione esta linha
    };
    const handleCheckbox2Change = () => {
        setCheckbox2(checkbox2 => !checkbox2);
        setCheckbox1(false);
        setCheckbox3(false);
        setTipoPagamento('boleto');
        setCartao(false); // Adicione esta linha
    };
    const handleCheckbox3Change = () => {
        setCheckbox3(checkbox3 => !checkbox3);
        setCheckbox1(false);
        setCheckbox2(false);
        setTipoPagamento('credit');
        setCartao(prevCartao => !prevCartao);
    };
    function finalizarCompra() {
        const cartFromStorage = JSON.parse(localStorage.getItem('cart')) || {};
        const cartId = cartFromStorage.length > 0 ? cartFromStorage[0].cart_id : '';
        const cartOrderDetails = {
            tipoPagamento: tipoPagamento,
            cart_id: cartId
        };
        apiToken().post(`post_cart_order_details/${user.id}`, cartOrderDetails)
            .then(response => {
                localStorage.setItem('payments', response.data);
                apiToken().post(`post_create_payment/${user.id}`, cartOrderDetails)
                    .then(response => {
                        const paymentData = response.data.data; // Acessa a propriedade data
                        setPayment(paymentData); // Use os dados do pagamento
                        const qrCodeImage = paymentData.paymentMethod.qrCodeImage;
                        const qrCodeText = paymentData.paymentMethod.qrCode;
                        setQrCode(qrCodeImage);
                        setQrCodeText(qrCodeText);
                        setOpen(true);
                        localStorage.removeItem('cart');
                    })
                    .catch(error => {
                        console.error(error);
                        alert('Não foi possível finalizar a compra. Por favor, tente novamente mais tarde.');
                    });
                localStorage.removeItem('cart');
                alert('Compra realizada com sucesso!');
            })
            .catch(error => {
                console.error(error);
                alert('Não foi possível finalizar a compra. Por favor, tente novamente mais tarde.');
            });
    }
    const handleCopy = () => {
        message.success('Texto copiado com sucesso!');
    };
    const location = useLocation();
    const isCarrinhoPage = location.pathname.startsWith("/carrinho");
    return (<>
        <section style={{ minHeight: '95dvh' }}>
            <div className="col-md-12" >
                <div className="row rounded ">
                    <ItemsNoCarrinho
                        cart={cart}
                        procedimentosValor={procedimentosValor}
                        totalValor={totalValor}
                        isCarrinhoPage={isCarrinhoPage}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        InputPadraoTitle={InputPadraoTitle}
                        selectedType={selectedType}
                        handleTypeChange={handleTypeChange}
                        types={types}
                        currentItems={currentItems}
                        screenWidth={screenWidth}
                        handlequantityChange={handlequantityChange}
                        handleDeleteItem={handleDeleteItem}
                        pageButtons={pageButtons}
                        hasItemsInCart={cart.length > 0}
                    />
                    {cart.length === 0 ? (
                        null
                    ) : (<>
                        <ResumoCompra cart={cart} totaisPorTipo={totaisPorTipo} totalValor={totalValor} procedimentosValor={procedimentosValor} />
                        {screenWidth <= 1058  &&  <GetFrete />}
                        {authenticated && <><FormaDePagamento
                            handleCheckbox1Change={handleCheckbox1Change}
                            checkbox1={checkbox1}
                            handleCheckbox2Change={handleCheckbox2Change}
                            checkbox2={checkbox2}
                            handleCheckbox3Change={handleCheckbox3Change}
                            checkbox3={checkbox3}
                        />
                            {cartao && <PaymentFormCard state={state} setState={setState} />}
                            {payment && <FinalizarCompra
                                finalizarCompra={finalizarCompra}
                                hasItemsToRemove={hasItemsToRemove}
                                handleDeleteItemZero={handleDeleteItemZero}
                                setCart={setCart}
                            />}
                        </>}
                        {!authenticated && <> <ButtonCadastrar styleText={{ color: 'red' }} text={'Cadastre-se ou entre para finalizar sua compra'} /> </>}
                    </>)}
                </div>  </div>
            {payment && Object.keys(payment).length > 0 && <PaymentDetails data={payment} />}
            <div className='col--md-2 bg-light'></div>
            <Modal
                open={open}
                title="Pagamento PIX"
                onCancel={() => setOpen(false)}
                footer={[
                    <Button key="close" onClick={() => setOpen(false)}>
                        Fechar
                    </Button>
                ]}
            >
                <div style={{ textAlign: 'center' }}>
                    <img src={qrCode} alt="QR Code" style={{ marginBottom: '20px', maxWidth: '100%' }} />
                    <CopyToClipboard text={qrCodeText} onCopy={handleCopy}>
                        <Button type="primary">Copiar Código PIX</Button>
                    </CopyToClipboard>
                </div>
            </Modal>
        </section>
    </>);
};
export default MainCarrinhoPublic;
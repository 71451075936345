import React from 'react';
import { Divider, Image, Typography } from 'antd';
import { TitleL4 } from '@components';
import { DocumentosSoftwareAnaliseFacialRegraImagesFrontal } from '.';
import { imgFaceFrontalAtencao, imgFaceFrontalAtencao1 } from '../assets';
const { Paragraph, Title } = Typography;
export const DocumentosSoftwareAnaliseFacialAnalisesFrontalSurpresa = () => (
  <>
    <section style={{ marginTop: '20px' }}>
      <Title id='DocumentosSoftwareAnaliseFacialAnalisesFrontalSurpresa'  level={3}>Análise Frontal com Expressão de Surpresa</Title>
      <Divider style={{ margin: '0px', borderColor: 'black', borderWidth: '3px', marginBottom: '10px' }}  />
      <h4>Entrada de imagem para análise de rugas na testa</h4>
      <DocumentosSoftwareAnaliseFacialRegraImagesFrontal />
      <TitleL4>Imagens exemplo:</TitleL4>
      <div className='row text-center'>
        <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
          {/* <Image src={imgFaceFrontalAtencao} alt="Exemplo 1" width={100} height={100} /><br /> */}
          <small>Frontal Surpresa</small>
        </div>
        <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
          {/* <Image src={imgFaceFrontalAtencao1} alt="Exemplo 2" width={100} height={100} /><br /> */}
          <small>Frontal Surpresa</small>
        </div>
      </div>
      <TitleL4>Resultado exemplo:</TitleL4>
      <ul>
        <li><strong>Detecção e Segmentação:</strong> Identificação de rugas horizontais e verticais na testa.</li>
        <li><strong>Análise de Intensidade:</strong> Classificação da profundidade e extensão das rugas.</li>
        <li><strong>Sugestões Estéticas:</strong> Recomendações para prevenção e suavização de marcas de expressão.</li>
      </ul>
      <div className='row text-center'>
        <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
          {/* <Image src={require('../assets/rugaTesta1.jpg')} alt="Exemplo 1" width={50} height={50} /><br /> */}
          <small>Rugas Horizontais</small>
        </div>
        <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
          {/* <Image src={require('../assets/rugaTesta2.jpg')} alt="Exemplo 2" width={50} height={50} /><br /> */}
          <small>Rugas Verticais</small>
        </div>
        <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
          {/* <Image src={require('../assets/landmarksAtencao.jpg')} alt="Exemplo 3" width={50} height={50} /><br /> */}
          <small>Landmarks</small>
        </div>
      </div>
    </section>
    <section style={{ marginTop: '20px' }}>
      <TitleL4>Casos de Uso</TitleL4>
      <Paragraph>
        <ul>
          <li><strong>Detecção de Rugas Horizontais:</strong> Avaliação das linhas que surgem ao levantar as sobrancelhas.</li>
          <li><strong>Identificação de Rugas Verticais:</strong> Análise de marcas causadas por tensão na região frontal.</li>
          <li><strong>Avaliação da Pele da Testa:</strong> Verificação de textura, elasticidade e presença de marcas de expressão.</li>
          <li><strong>Comparação de Antes e Depois:</strong> Monitoramento da evolução das rugas ao longo do tempo.</li>
          <li><strong>Sugestões Estéticas:</strong> Recomendações para tratamentos estéticos e cuidados preventivos.</li>
        </ul>
      </Paragraph>
    </section>
  </>
);
export default DocumentosSoftwareAnaliseFacialAnalisesFrontalSurpresa;
import { useLocation } from 'react-router-dom';
import { MasterRenderArtigos } from "./MasterRenderArtigos"
import {HeaderAnt, Footer } from '@components'
export const RenderArtigos = () => {
    const location = useLocation();
    return (<>
        <HeaderAnt location={location} />
        <MasterRenderArtigos />
        <Footer />
    </>)
}
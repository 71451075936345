import moment from 'moment';
import { apiToken } from "services";
export const fetchPatientData = async (id, setName, setCpf, setDataNascimento, setEmail, setLatitude, setLongitude, setPhone) => {
  try {
    const response = await apiToken().get(`get_user/${id}`);
    const userData = response.data.user;
    setName(userData.name);
    setCpf(userData.cpf);
    setDataNascimento(moment(userData.dataNascimento).format('YYYY-MM-DD'));
    setEmail(userData.email);
    setLatitude(userData.latitude);
    setLongitude(userData.longitude);
    setPhone(userData.phone);
  } catch (error) {
    if (error.response && error.response.status === 401) {
      throw new Error('Erro de autenticação. Faça login novamente.');
    } else {
      throw new Error('Erro ao buscar dados do paciente.');
    }
  }
};
import React from 'react';
import { ItemsList } from './SiderItems';
import { SiderAnt } from 'components';
import GetFrete from './components/GetFrete';
export const Index = () => {
    const items = ItemsList();
    return (<>
        <SiderAnt items={items} itemsDir={<GetFrete/>}/>
    </>);
};
export default Index;
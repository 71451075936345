import { useState, useContext, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { AuthContext } from "@guard"
import {
  ButtonAPI,
  InputPadrao,
  InputPadraoTitle,
  InputDataNascimento,
  TermoDeResponsabilidadeCadastro,
  is_cpf,
  mCPF,
  TitleL2,
} from '@components'
import { Divider, Form } from "antd";  // Importando o Select do Ant Design
import PhoneInput from 'react-phone-input-2';
import '@pages/Public/Cadastrar/components/style/style.css';
import CadastroAddress from "@pages/Public/Cadastrar/components/components/CadastroAddress";
import useLocalizacao from "@pages/Public/Cadastrar/components/components/useLocalizacao";
import { checkPasswordSecurity, generateSecurePassword, handleConfirmPasswordChange, SecurityBars } from "@pages/Public/Cadastrar/components/utils/PasswordChange";
import { handleEmailChange } from "@pages/Public/Cadastrar/components/utils/IsValidEmail";
import { atualizarCadastroPaciente, fetchPatientData } from "@pages/Private/Usuarios/Cadastro/components/usuario/services";
export const UpdateCadastroForm = () => {
  const [name, setName] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [phone, setPhone] = useState("");
  const [cpf, setCpf] = useState("");
  const [validationResponse, setValidationResponse] = useState("");
  const { latitude, setLatitude, longitude, setLongitude, address, getLocalizacao } = useLocalizacao();
  const inputRef = useRef(null)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [securityLevel, setSecurityLevel] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const [passwordAtualizado, setPasswordAtualizado] = useState("")
  const [aceitouTermo, setAceitouTermo] = useState(true);
  const handlePhoneChange = (value) => {
    setPhone(value);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchPatientData(user.id, setName, setCpf, setDataNascimento, setEmail, setLatitude, setLongitude, setPhone);
      } catch (error) {
      }
    };
    fetchData();
  }, [user]);
  return (<>
    <Form
      className='border bg-light py-2 rounded'
      initialValues={{ remember: true }}
      layout="vertical"
      name="cadastrar"
    >   <TitleL2>Dados do Usuário</TitleL2>
      <Divider />
      <div className="row col-md-12 justify-content-center">
        <div className=" col-md-5">
          <Form.Item
            name="name"
            rules={[{ required: true, message: 'Por favor, insira um nome!' }]}>
            <InputPadrao
              title={'Nome'}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nome"
              id="name"
              name="name"
              required
            />
          </Form.Item>
        </div>
        <div className="form-group col-md-5 ">
          <InputPadrao
            title={'Identificação Única'}
            type="text"
            value={cpf}
            maxLength="14"
            onChange={(e) => {
              const formattedCpf = mCPF(e.target.value);
              setCpf(formattedCpf);
              const isCpfValid = is_cpf(e.target.value);
              setValidationResponse(isCpfValid ? 'VÁLIDO' : 'ATENÇÃO: Informe um CPF válido. Insira apenas números');
            }}
            name="cpf"
            placeholder="Brasil: CPF"
            id="cpf"
            required
          />
          <span id="cpfResponse">{validationResponse}</span>
        </div>
        <div className="form-group col-md-5 ">
          <InputDataNascimento dataNascimento={dataNascimento} setDataNascimento={setDataNascimento} />
        </div>
        <div className="form-group col-md-5 ">
          <InputPadraoTitle title={'Telefone'} />
          <PhoneInput
            country={'br'}
            placeholder="Número de telefone"
            value={phone}
            required
            onChange={handlePhoneChange}
          />
        </div>
        <div className="form-group col-md-5 ">
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Por favor, insira um e-mail!' },
              { type: 'email', message: 'O e-mail informado não é válido!' }
            ]}>
            <InputPadrao
              title={'E-mail'}
              type="email"
              value={email}
              style={{ borderRadius: ' 2px 2px 2px 2px' }}
              name="email"
              placeholder="E-MAIL"
              id="email"
              onChange={(event) => handleEmailChange(event, setEmail, setError)} // Usa a função exportada
            />  {error && <> {error}</>}
          </Form.Item>
        </div>
        <CadastroAddress latitude={latitude} setLatitude={setLatitude}
          longitude={longitude} setLongitude={setLongitude} getLocalizacao={getLocalizacao} />
        <div className="form-group col-md-5">
          <InputPadrao
            title={'Senha'}
            type={showPassword ? "text" : "password"}
            value={password}
            required
            onChange={(e) => {
              setPassword(e.target.value);
              setSecurityLevel(checkPasswordSecurity(e.target.value));
              handleConfirmPasswordChange(e, setConfirmPassword)
            }}
            name="password"
            placeholder="********"
            id="password" />
        </div>
        <div className="form-group col-md-5">
          <div className="input-group">
            <InputPadrao
              title={'Senha Nova'}
              type={showPassword ? "text" : "password"}
              value={passwordAtualizado}
              required
              onChange={(e) => {
                setPasswordAtualizado(e.target.value);
              }}
              name="passwordAtualizado"
              placeholder="********"
              id="passwordAtualizado"
            />
            <div className="input-group-append">
            </div>
          </div>
        </div>
        <div className="form-group col-md-5 text-center">
        </div>
        <div className="form-group col-md-5 text-center">
          <InputPadrao
            title={'CONFIRMA SENHA'}
            id="confirmPassword"
            value={confirmPassword}
            ref={inputRef}
            onChange={(e) => handleConfirmPasswordChange(e, setConfirmPassword)}
            required
            type={showPassword ? "text" : "password"}
            placeholder="********"
            data-bouncer-message="ATENÇÃO: Repita à senha válida."
          />
          <div className="justify-content-center col-md-12 align-items-center mx-auto">
            <SecurityBars securityLevel={securityLevel} />
            <div className="form-group col-md-5 mt-1 d-flex mx-auto justify-content-center">
              <i className={`fa text-center fa-${showPassword ? 'eye' : 'eye-slash'} `}
                type="button"
                onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? "Ocultar senha" : "Exibir senha"} </i>
            </div> </div>
          <button className='btn btn-primary mt-2'
            type="button"
            onClick={() => generateSecurePassword(setPassword, setConfirmPassword, setSecurityLevel)}>
            Gerar senha segura<i className='fa fa-lock ml-2'></i>
          </button>
          <br />
        </div>
      </div>
      <div className="col-md-10 mx-auto">
        <ButtonAPI isLoading={isLoading} onClick={() => atualizarCadastroPaciente(
          user.id,
          email,
          name,
          password,
          passwordAtualizado,
          cpf,
          phone,
          dataNascimento,
          address,
          setIsLoading)}>
          Atualizar
        </ButtonAPI>
      </div>
    </Form >
    <hr />
    <TermoDeResponsabilidadeCadastro aceitouTermo={aceitouTermo} setAceitouTermo={setAceitouTermo} />
  </>)
}
import { Select } from 'antd';
export const SelectAnt = ({ title, options, value, onChange, placeholder = "Selecione uma opção", defaultValue }) => {
    return (
        <>
            <small className='font-weight-bold'><b>{title}</b></small><br />
            <Select
                style={{ width: '100%' }}
                placeholder={placeholder}
                value={value}
                defaultValue={defaultValue} // Adicionado defaultValue
                onChange={onChange}
                options={options}
            />
        </>
    );
};
export default SelectAnt;
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { ReferenciaLocal, InputPadrao, MaisInfoMenosInfo } from '@components';
import { baseURL } from '@services';
import { AuthContext } from '@guard';
import BuscarEquipamentoInfo from '../info/BuscarEquipamentoInfo';
export const BuscarEquipamentos = ({ buscaEquipamentos }) => {
    const [equipamentos, setEquipamentos] = useState([]);
    const [editandoEquipamento, setEditandoEquipamento] = useState(null);
    const [camposEditaveis, setCamposEditaveis] = useState({});
    const [usandoEquipamento, setUsandoEquipamento] = useState(null);
    const { user } = useContext(AuthContext);
    useEffect(() => {
        buscarEquipamentos();
    }, []);
    const buscarEquipamentos = async () => {
        try {
            const response = await fetch(`${baseURL}buscaEquipamentos`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Erro ao buscar equipamentos');
            }
            const data = await response.json();
            setEquipamentos(data);
        } catch (error) {
            console.error('Erro ao buscar equipamentos:', error);
        }
    };
    const handleEditarEquipamento = (id) => {
        setEditandoEquipamento(id);
        // Copie os valores dos campos editáveis do equipamento em camposEditaveis
        const equipamento = equipamentos.find((e) => e.id === id);
        setCamposEditaveis({ ...equipamento });
    };
    const handleCancelarEdicao = () => {
        setEditandoEquipamento(null);
    };
    const handleSalvarEdicao = async () => {
        try {
            const response = await fetch(`${baseURL}editarEquipamento/${editandoEquipamento}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(camposEditaveis)
            });
            if (!response.ok) {
                throw new Error('Erro ao editar equipamento');
            }
            alert('Equipamento editado com sucesso!');
            buscarEquipamentos(); // Atualize a lista de equipamentos após a edição ser concluída com sucesso
            setEditandoEquipamento(null); // Saia do modo de edição
        } catch (error) {
            console.error('Erro ao editar equipamento:', error);
            alert('Erro ao editar equipamento. Verifique o console para mais informações.');
        }
    };
    const handleEditarCampo = (campo, valor) => {
        setCamposEditaveis((prevCampos) => ({
            ...prevCampos,
            [campo]: valor
        }));
    };
    const handleUsarEquipamento = (id) => {
        setUsandoEquipamento(id);
        handleRegistrarUsoEquipamento(id);
    };
    const handleCancelarUsoEquipamento = () => {
        // Cancelar o uso do equipamento
        setUsandoEquipamento(null);
    };
    const handleRegistrarUsoEquipamento = async () => {
        try {
            const response = await fetch(`${baseURL}registrarUsoEquipamento`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    equipamento_id: usandoEquipamento,
                    user_id: user.id,
                    data_inicio: new Date(),
                })
            });
            if (!response.ok) {
                throw new Error('Equipamento em uso');
            }
            alert('Uso de equipamento registrado com sucesso!');
        } catch (error) {
            if (error.response && error.response.status === 500) {
                console.error('Erro interno do servidor (500):', error);
                alert('Erro interno do servidor ao registrar uso de equipamento.');
            } else {
                console.error('Erro ao registrar uso de equipamento:', error);
                alert('Equipamento em uso');
            }
        }
    };
    return (
        <>
            <ReferenciaLocal text={'Equipamentos Cadastrados'} />
            <div>
                <ul>
                    <div className="col-md-12">
                        <div className="d-flex flex-wrap justify-content-center">
                            {equipamentos.map((equipamento) => (
                                <div key={equipamento.id} className='col-md-3 mt-3' >
                                    {editandoEquipamento === equipamento.id ? (
                                        // Renderize o formulário de edição se estiver editando este equipamento
                                        <div >
                                            <InputPadrao
                                                title={'Nome:'}
                                                type="text"
                                                name="nome"
                                                value={camposEditaveis.nome}
                                                onChange={(e) => handleEditarCampo('nome', e.target.value)}
                                            />
                                            <br />
                                            <strong>Tipo:</strong>
                                            <InputPadrao
                                                title={''}
                                                type="text"
                                                name="tipo"
                                                value={camposEditaveis.tipo}
                                                onChange={(e) => handleEditarCampo('tipo', e.target.value)}
                                            />
                                            <br />
                                            <strong>Marca:</strong>
                                            <InputPadrao
                                                title={''}
                                                type="text"
                                                name="marca"
                                                value={camposEditaveis.marca}
                                                onChange={(e) => handleEditarCampo('marca', e.target.value)}
                                            />
                                            <br />
                                            <strong>Número de Série:</strong>
                                            <InputPadrao
                                                title={''}
                                                type="text"
                                                name="numeroSerie"
                                                value={camposEditaveis.numeroSerie}
                                                onChange={(e) => handleEditarCampo('numeroSerie', e.target.value)}
                                            />
                                            <br />
                                            <button className='mr-2 ml-2 ' onClick={handleSalvarEdicao}>Salvar</button>
                                            <button onClick={handleCancelarEdicao}>Cancelar</button>
                                        </div>
                                    ) : (
                                        // Renderize os detalhes do equipamento se não estiver editando
                                        <div className="col-md-12 mt-2 rounded py-1"
                                            style={{
                                                backgroundColor: '#f1f1f1',
                                                padding: '5px',
                                                marginLeft: '5px',
                                                marginRight: "5px",
                                            }}>
                                            <strong>Nome:</strong> {equipamento.nome}<br />
                                            <strong>Tipo:</strong> {equipamento.tipo}<br />
                                            <strong>Marca:</strong> {equipamento.marca}<br />
                                            <strong>Número de Série:</strong> {equipamento.numeroSerie}<br />
                                            {usandoEquipamento === equipamento.id ? (
                                                <button className='btn btn-danger' onClick={handleCancelarUsoEquipamento}>Cancelar Uso</button>
                                            ) : (
                                                <button className='btn btn-primary mr-2 ml-2' onClick={() => handleUsarEquipamento(equipamento.id)}>
                                                    Usar Equipamento
                                                </button>
                                            )}
                                            <Link onClick={() => handleEditarEquipamento(equipamento.id)}>
                                                <i className="fa fa-edit text-warning mr-2 ml-2"></i>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </ul>
                <hr />
                <MaisInfoMenosInfo text={<> <BuscarEquipamentoInfo />  </>} />
            </div>
        </>);
};
export default BuscarEquipamentos;
import React, { useState } from "react";
import { Button } from "antd";
import { BsFillFileEarmarkTextFill } from 'react-icons/bs';
import {HeaderAnt, NavProfissionalMain, BreadcrumbAnt } from "@components";
import { useNavigate } from "react-router-dom";
import {UpdateClienteController} from "controllers";
import { api } from "services";
export const AtestadosProfissionaisNovo = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState({
    value: "Nome",
    label: "Nome"
  });
  const [data, setData] = useState([]);
  const [dataTableVisible, setDataTableVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [searchValue, setSearchValue] = useState(""); // Adicionado estado para armazenar o valor de pesquisa
  const updateSelectedOption = (newSelectedOption) => {
    setSelectedOption(newSelectedOption);
  };
  const updateSearchValue = (newValue) => {
    setSearchValue(newValue);
  };
  const customColumns = [
    {
      title: "Atestados",
      dataIndex: "atestado",
      key: "atestados",
      render: (_, record) => (
        <Button
          icon={<BsFillFileEarmarkTextFill style={{ fontSize: '17px' }} />}
          onClick={() => handleEmitirAtestado(record)}
          style={{ marginLeft: '8px' }}
        >
        </Button>
      ),
    },
  ];
  const onSearch = async (value, currentPage = 1) => {
    if (value) {
      try {
        const response = await api.post(`UpdateClientePorNome`, {
          nome: selectedOption?.value === "Nome" ? value : undefined,
          cpf: selectedOption?.value === "CPF" ? value : undefined,
          identificador: selectedOption?.value === "Identificador" ? value : undefined,
          pagina: currentPage,
          resultadosPorPagina: pagination.pageSize,
        });
        const { pacientes, totalPacientes } = response.data;
        setDataTableVisible(true);
        setData(pacientes);
        setPagination({
          ...pagination,
          current: currentPage,
          total: totalPacientes,
        });
      } catch (error) {
        console.error("Erro ao buscar pacientes:", error);
        setData([]);
        setPagination({
          ...pagination,
          current: 1,
          total: 0,
        });
      }
    } else {
      console.log("Nome inválido");
    }
  };
  const handleEmitirAtestado = (patient) => {
    console.log(`Emitindo atestado para o paciente com ID ${patient}`);
    localStorage.setItem('selectedPatient', JSON.stringify(patient));
    navigate("/profissionais/atestados/emitir-atestado", { state: { patient } });
    window.location.href = "/profissionais/atestados/emitir-atestado";
  };
  return (
    <>
      <HeaderAnt/>
      <div className="consulta-wrapper">
        <NavProfissionalMain />
        <div className="consulta-container">
          <BreadcrumbAnt text={"Atestados"} icon={"search fa"} />
          <UpdateClienteController
            title="Emitir Atestado"
          />
        </div>
      </div>
    </>);
};
export default AtestadosProfissionaisNovo;
import api from "./api";
export const apiTokenProfissional = () => {
  const token = localStorage.getItem("token");
  const profissional = localStorage.getItem("profissional");
  const instance = api.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  instance.interceptors.request.use((config) => {
    try {
      // Verifica se o 'profissional' está presente e tenta fazer o parse da string JSON
      const profissionalData = profissional ? JSON.parse(profissional) : null;
      // Se a conversão for bem-sucedida e houver dados, adiciona ao cabeçalho
      if (profissionalData) {
        config.headers["X-Profissional-Data"] = JSON.stringify(profissionalData);
      }
    } catch (error) {
      console.error("Erro ao adicionar profissional na requisição:", error);
    }
    return config;
  });
  return instance;
};
export default apiTokenProfissional;
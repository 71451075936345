import React from 'react';
import { Row, Col, Card, Progress, Typography } from 'antd';
import { CheckCircleOutlined, ShoppingCartOutlined, InboxOutlined, HomeOutlined } from '@ant-design/icons';
import './styles/OrderDetails.css';
const { Title, Text } = Typography;
const OrderTracker = () => {
  return (
    <section style={{ backgroundColor: '#8c9eff', height: '100vh' }}>
      <div className="container" style={{ padding: '50px 0', display: 'flex', justifyContent: 'center' }}>
        <Card
          style={{ width: '100%', maxWidth: '800px', borderRadius: '16px' }}
          title={<Title level={4}>INVOICE <Text strong>#Y34XDHR</Text></Title>}
        >
          <Row justify="space-between">
            <Col span={12}>
              <Text>Expected Arrival: <Text strong>01/12/19</Text></Text>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Text>USPS: <Text strong>234094567242423422898</Text></Text>
            </Col>
          </Row>
          {/* Barra de progresso */}
          <Progress
            percent={75}
            status="active"
            strokeColor="#6520ff"
            style={{ margin: '30px 0' }}
          />
          {/* Ícones com efeitos de hover */}
          <Row gutter={16}>
            <Col span={6} style={{ textAlign: 'center' }}>
              <CheckCircleOutlined className="progress-icon" />
              <Text>&nbsp;Pedido</Text>
            </Col>
            <Col span={6} style={{ textAlign: 'center' }}>
              <ShoppingCartOutlined className="progress-icon" />
              <Text>Shipped</Text>
            </Col>
            <Col span={6} style={{ textAlign: 'center' }}>
              <InboxOutlined className="progress-icon" />
              <Text>En Route</Text>
            </Col>
            <Col span={6} style={{ textAlign: 'center' }}>
              <HomeOutlined className="progress-icon" style={{ color: '#cccccc' }} />
              <Text style={{ color: '#cccccc' }}>Arrived</Text>
            </Col>
          </Row>
        </Card>
      </div>
    </section>
  );
};
export default OrderTracker;
export const getInitialsName = (name) => {
    // Se o nome for nulo, indefinido ou uma string vazia, retorne "?"
    if (!name || typeof name !== 'string' || !name.trim()) {
      return "?"; 
    }
    // Divida o nome em palavras, filtra as palavras vazias e pega as iniciais
    const initials = name.split(" ")
      .filter(word => word)  // Filtra palavras vazias
      .map(word => word[0].toUpperCase())  // Converte a primeira letra de cada palavra para maiúscula
      .join("");
    return initials.slice(0, 2);  // Exibe as duas primeiras iniciais
  };
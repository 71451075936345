import React, { useState } from 'react';
import { Tooltip, Button, Modal } from 'antd';
import { MdDeveloperBoard } from 'react-icons/md';
export const RenderTesteIfLocalhost = ({ children, title }) => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [iconColor, setIconColor] = useState('#fff');
  // Verifica se está rodando em localhost
  const isLocalhost = window.location.hostname === "localhost";
  // Função para abrir o modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  // Se não estiver no localhost, não renderiza nada
  if (!isLocalhost) {
    return null;
  }
  return (
    <>
      <div style={{ position: 'fixed', bottom: '45%', right: '0%', zIndex: '1000' }}>
        <Tooltip
          title="Painel desenvolvedor? Clique aqui"
          open={open}
          onMouseEnter={() => { setOpen(true); setIconColor('#000'); }}
          onMouseLeave={() => { setOpen(false); setIconColor('#fff'); }}
        >
          <Button
            className='btn-dark'
            style={{
              color: '#fff',
              border: 'none',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2), inset 0 -4px 5px rgba(255, 255, 255, 0.4)',
              transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
              width: '80px',
              height: '80px',
            }}
            onClick={handleOpenModal}
          >
            <MdDeveloperBoard size={30} color={iconColor} />
          </Button>
        </Tooltip>
      </div>
      <Modal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={null}
        centered
        width="90vw"
        className="custom-modal"
        style={{ paddingTop: '4%' }}
      >
        <div className="py-3 bg-dark mb-1">
          <small className="text-white font-weight-bold">
            Teste desenvolvedores {title}
          </small>
          <br />
          {children}
        </div>
      </Modal>
    </>
  );
};
export default RenderTesteIfLocalhost;
import React, { useState, useEffect, useCallback } from 'react';
import { ButtonAPI, MaisInfoMenosInfo, InputPadrao, SelectAnt } from 'components';
import ProspeccaoClienteSelecionaInfo from './info/ProspeccaoClienteSelecionaInfo';
import { TitleL1 } from '@components/text';
import ProspeccaoClientesInfo from './info/ProspeccaoClientesInfo';
const ProspeccaoClienteSeleciona = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [numClients, setNumClients] = useState(0);
  const [VenderPara, setVenderPara] = useState('');
  const [clientsFound, setClientsFound] = useState(null);
  const [recorrencia, setRecorrencia] = useState(null);
  const handleSubmit = useCallback(() => {
    const dataToSend = {
      selectedOption,
      numClients,
      VenderPara,
    };
    fetch('/sua-rota-de-back-end', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    })
      .then((response) => response.json())
      .then((data) => {
        setClientsFound(data);
        alert('Informações resgatadas com sucesso');
      })
      .catch(() => {
        alert('Erro ao enviar dados para o servidor');
      });
  }, [selectedOption, numClients, VenderPara]);
  useEffect(() => {
    if (selectedOption && VenderPara && numClients) {
      handleSubmit();
    }
  }, [selectedOption, VenderPara, numClients, handleSubmit]);
  return (
    <>
      <div className='bg-light'>
        <TitleL1>Vamos vender?</TitleL1>
        <MaisInfoMenosInfo text={<ProspeccaoClientesInfo />}></MaisInfoMenosInfo>
        <div className="row">
          <div className='col-md-3'>
            <SelectAnt
              title="Quero Vender"
              value={selectedOption}
              onChange={setSelectedOption}
              options={[
                { value: 'Produtos', label: 'Produtos' },
                { value: 'Procedimentos', label: 'Procedimentos' },
                { value: 'Ambos', label: 'Ambos' },
              ]}
            />
          </div>
          <div className='col-md-3'>
            <SelectAnt
              title="Vender Para"
              value={VenderPara}
              onChange={setVenderPara}
              options={[
                { value: 'Público', label: 'Público' },
                { value: 'Privado', label: 'Privado' },
                { value: 'Plano de Saúde', label: 'Plano de Saúde' },
                { value: 'Ambos', label: 'Ambos' },
              ]}
            />
          </div>
          <div className='col-md-3'>
            <InputPadrao
              title="Número de clientes"
              type="number"
              value={numClients}
              onChange={(e) => setNumClients(e.target.value)}
            />
          </div>
          <div className='col-md-3'>
            <InputPadrao
              type="number"
              value={recorrencia}
              onChange={(e) => setRecorrencia(e.target.value)}
            />
          </div>
          <ButtonAPI onClick={handleSubmit}>Procurar clientes</ButtonAPI>
        </div >
        {clientsFound !== null && (
          <div className="py-4 mt-3">
            <p>Número de clientes encontrados: {clientsFound}</p>
          </div>
        )}
        <div className="py-4 mt-3">
          <MaisInfoMenosInfo text={<ProspeccaoClienteSelecionaInfo />} />
        </div>
      </div>
    </>
  );
};
export default ProspeccaoClienteSeleciona;
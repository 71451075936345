import React, { useEffect, useState } from 'react';
import { api } from 'services';
import SearchListProducts from './SearchListProducts';
import SearchListServices from './SearchListServices';
import { Empty } from 'antd';
const NoData = () => <div className="text-center"><Empty description="Não há dados para carregar" /></div>;
export const SearchListMain = ({ searchTerm }) => {
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [error, setError] = useState(null);
  const [searching, setSearching] = useState(false);
  const [noData, setNoData] = useState(false);
  // Estados para paginação
  const [productPage, setProductPage] = useState(1);
  const [servicePage, setServicePage] = useState(1);
  const resultadosPorPagina = 10; // Mantém fixo
  // Controle de carregamento de mais dados
  const [hasMoreProducts, setHasMoreProducts] = useState(true);
  const [hasMoreServices, setHasMoreServices] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  // Busca produtos
  useEffect(() => {
    if (!searchTerm || isFetching) return;
    setSearching(true);
    setIsFetching(true);
    api.get('/get_products', { params: { query: searchTerm, pagina: productPage, resultadosPorPagina } })
      .then((response) => {
        const { produtos = [], totalProdutos = 0 } = response.data;
        // Filtra produtos duplicados com base no id
        setProducts((prev) => [
          ...prev,
          ...produtos.filter((newProduct) => !prev.some((product) => product.id === newProduct.id)),
        ]);
        setHasMoreProducts(productPage * resultadosPorPagina < totalProdutos);
      })
      .catch((err) => setError(err))
      .finally(() => {
        setSearching(false);
        setIsFetching(false);
      });
  }, [searchTerm, productPage]);
  // Busca serviços
  useEffect(() => {
    if (!searchTerm || isFetching) return;
    setSearching(true);
    setIsFetching(true);
    api.get('/get_services', { params: { query: searchTerm, pagina: servicePage, resultadosPorPagina } })
      .then((response) => {
        const { servicos = [], totalServicos = 0 } = response.data;
        // Filtra serviços duplicados com base no id
        setServices((prev) => [
          ...prev,
          ...servicos.filter((newService) => !prev.some((service) => service.services_id === newService.services_id)),
        ]);
        setHasMoreServices(servicePage * resultadosPorPagina < totalServicos);
      })
      .catch((err) => setError(err))
      .finally(() => {
        setSearching(false);
        setIsFetching(false);
      });
  }, [searchTerm, servicePage]);
  // Verifica se há dados
  useEffect(() => {
    if (!searching && products.length === 0 && services.length === 0) {
      setNoData(true);
    } else {
      setNoData(false);
    }
  }, [products, services, searching]);
  // Funções para carregar mais resultados
  const loadMoreProducts = () => {
    if (hasMoreProducts) setProductPage((prevPage) => prevPage + 1);
  };
  const loadMoreServices = () => {
    if (hasMoreServices) setServicePage((prevPage) => prevPage + 1);
  };
  return (
    <>
      {noData ? (
        <NoData />
      ) : (
        <>
          {services.length > 0 && (
            <SearchListServices
              title="Serviços"
              results={services}
              searching={searching}
              error={error}
              loadMore={loadMoreServices}
              hasMore={hasMoreServices}
            />
          )}
          {products.length > 0 && (
            <SearchListProducts
              title="Produtos"
              results={products}
              searching={searching}
              error={error}
              loadMore={loadMoreProducts}
              hasMore={hasMoreProducts}
            />
          )}
        </>
      )}
    </>
  );
};
export default SearchListMain;
import React, { useState } from 'react';
import {
  LoadingModal,
  ButtonCliente
} from '@components';
import {
  ViewFaceGuidedTutorial,
  PostFacePhotoCollector,
  ViewFaceRenderResults,
  PDFGenerator
} from './components';
import {
    botox_asa_nasal,
    botox_base_nasal,
    botox_dorso_nasal,
    botox_frontal,
    botox_glabela,
    botox_periorbicular,
    botox_periorbital,
    botox_sorriso_gengival,
    botox_mento,
    botox_angulo_mandibula,
    botox_platisma,
    botox_bruxismo,
    botox_oralemento
} from "./components/functions";
import { useCliente } from 'context';
import { useLandmark } from './components/hook/useLandmarks';
export const AnaliseFacial = () => {
  //Controle do DB
  const { cliente } = useCliente();
  const [serviceControl, setServiceControl] = useState([]);
  //Controle do serviço
  const [currentType, setCurrentType] = useState(null);
  const [photos, setPhotos] = useState({});
  const [thumbnail, setThumbnail] = useState({});
  const [imageWidth, setImageWidth] = useState(0); // Resumir essa entrada
  const [imageHeight, setImageHeight] = useState(0); // Resumir essa entrada  
  const [loading, setLoading] = useState(false);
  const [apiResults, setApiResults] = useState([]);
  const [apiImages, setApiImages] = useState([]);
  const [others, setOthers] = useState([]) // apiOthers 
  const { landmarks, setLandmarks } = useLandmark()
  const [modifiedLandmarks, setModifiedLandmarks] = useState([]);
  const [modifiedLandmarksType, setModifiedLandmarksType] = useState(null);
  const [selectedValuesBotox, setSelectedValuesBotox] = useState({
    frontal: botox_frontal[0].value,
    glabela: botox_glabela[0].value,
    periorbital: botox_periorbital[0].value,
    oralemento: botox_oralemento[0].value,
    dorso_nasal: botox_dorso_nasal[0].value,
    asa_nasal: botox_asa_nasal[0].value,
    base_nasal: botox_base_nasal[0].value,
    sorriso_gengival: botox_sorriso_gengival[0].value,
    periorbicular: botox_periorbicular[0].value,
    mento: botox_mento[0].value,
    angulo_mandibula: botox_angulo_mandibula[0].value,
    platisma: botox_platisma[0].value,
    bruxismo: botox_bruxismo[0].value,
  });
  // Controle tutorial
  const [mostrarInformacoes, setMostrarInformacoes] = useState(false);
  const [pontoDestacado, setPontoDestacado] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [tutorialStep, setTutorialStep] = useState(0);
  const [piscaMaisInfo, setPiscaMaisInfo] = useState(false);
  const [highlightStates, setHighlightStates] = useState({});
  const handleImageLoad = (e) => {
    setImageWidth(e.target.width);
    setImageHeight(e.target.height);
  };
  return (<>
    <ButtonCliente />
    <ViewFaceRenderResults
      currentType={currentType}
      setLandmarks={setLandmarks}
      landmarks={landmarks}
      setModifiedLandmarks={setModifiedLandmarks}
      modifiedLandmarks={modifiedLandmarks}
      photos={photos}
      imageWidth={imageWidth}
      imageHeight={imageHeight}
      setPontoDestacado={setPontoDestacado}
      pontoDestacado={pontoDestacado}
      setMostrarInformacoes={setMostrarInformacoes}
      mostrarInformacoes={mostrarInformacoes}
      apiImages={apiImages}
      apiResults={apiResults}
      others={others}
      modifiedLandmarksType={modifiedLandmarksType}
      setModifiedLandmarksType={setModifiedLandmarksType}
      handleImageLoad={handleImageLoad}
      selectedValuesBotox={selectedValuesBotox}
      setSelectedValuesBotox={setSelectedValuesBotox}
    />
    <PostFacePhotoCollector
      currentType={currentType}
      setCurrentType={setCurrentType}
      photos={photos}
      setPhotos={setPhotos}
      thumbnail={thumbnail}
      setThumbnail={setThumbnail}
      setApiResults={setApiResults}
      setApiImages={setApiImages}
      piscaMaisInfo={piscaMaisInfo}
      highlightStates={highlightStates}
      setLandmarks={setLandmarks}
      setModifiedLandmarks={setModifiedLandmarks}
      setModifiedLandmarksType={setModifiedLandmarksType}
      setOthers={setOthers}
      setLoading={setLoading}
      modifiedLandmarksType={modifiedLandmarksType}
      serviceControl={serviceControl}
      setServiceControl={setServiceControl}
      landmarks={landmarks}
    />
    <LoadingModal
      visible={loading}
      message="Carregando dados..."
      onClose={() => setLoading(false)}
    />
    <PDFGenerator
      currentType={currentType}
      modifiedLandmarks={modifiedLandmarks}
      pontoDestacado={pontoDestacado}
      photos={photos}
      apiImages={apiImages}
      cliente={cliente}
      others={others}
      imageWidth={imageWidth}
      imageHeight={imageHeight}
      selectedValuesBotox={selectedValuesBotox}
    />
    <ViewFaceGuidedTutorial
      setPiscaMaisInfo={setPiscaMaisInfo}
      setHighlightStates={setHighlightStates}
      setThumbnail={setThumbnail}
      setLoading={setLoading}
      setModalVisible={setModalVisible}
      setCurrentType={setCurrentType}
      setPhotos={setPhotos}
      setLandmarks={setLandmarks}
      setModifiedLandmarks={setModifiedLandmarks}
      setImageWidth={setImageWidth}
      setImageHeight={setImageHeight}
      setApiImages={setApiImages}
      setApiResults={setApiResults}
      setOthers={setOthers}
      setCurrentAction={setCurrentAction}
      setTutorialStep={setTutorialStep}
      currentAction={currentAction}
      modalVisible={modalVisible}
      handleImageLoad={handleImageLoad}
      tutorialStep={tutorialStep}
    />
  </>);
};
export default AnaliseFacial;
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MaisInfoMenosInfo } from '@components';
import MasterCadastroCompanyUnitInfo from '../info/MasterCadastroCompanyUnitInfo';
import {
    handleDriveProfessionalEdit,
    handleUpdateProfissionalController,
    handleDeletedProfessional
} from 'controllers/functions';
import { MdDeleteMD, MdEditMD, MdKeyMD } from '@components/icons';
import { handleNavigateUpdateProfessional } from '../functions/handleNavigateUpdateProfessional';
export const UpdateEmpresaFilialController = ({ professionalData, updateProfissional }) => {
    const navigate = useNavigate();
    return (<>
        {professionalData && professionalData.professionalOwnerCompanyUnit && professionalData.professionalOwnerCompanyUnit.length > 0 && (
            <> <div className='col-md-3'>
                <small className='font-weight-bold'>Master Cadastro Empresa Filiais</small><br />
                <div className="d-flex flex-wrap">
                    {professionalData.professionalOwnerCompanyUnit.map((item, index) => (
                        <div
                            className='bg-white'
                            key={index}
                            style={{
                                backgroundColor: '#fff',
                                padding: '10px',
                                marginLeft: '5px',
                                marginRight: '5px',
                            }}
                        >
                            {item && (
                                <div>
                                    <div className='col-md-2'>
                                        <img className='w-100 mt-1 rounded' src={item.images} alt={`Logo da empresa ${item.company_name}`} />
                                    </div>
                                    <small className='font-weight-bold'>Nome Empresa:<small>{item.professional_name}</small></small><br />
                                    <Link onClick={() => handleUpdateProfissionalController(item, updateProfissional)}><MdKeyMD className={`text-dark mr-2 ml-2 ${localStorage.getItem('profissional') === JSON.stringify(item.company) ? 'text-success' : ''}`} /></Link>
                                    <Link onClick={() => handleNavigateUpdateProfessional(item.id)}><MdEditMD className='text-warning mr-2 ml-2' /></Link>
                                    <Link onClick={() => handleDeletedProfessional(item.id)}><MdDeleteMD className='text-danger mr-2 ml-2' /></Link>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
                <MaisInfoMenosInfo title={'Mais info'} text={<><MasterCadastroCompanyUnitInfo /></>} />
                <hr />
                <br /><br />
            </>)}
    </>);
};
export default UpdateEmpresaFilialController;
import { TitleL1 } from "@components";
import ReconhecimentosList from "./ReconhecimentosList";
import { MdOutlineEmojiEventsMD } from "@components/icons";
export const Reconhecimentos = () => {
    return (<>
        <section style={{ minHeight: '95dvh' }}>
            <TitleL1 icon={<MdOutlineEmojiEventsMD className='text-warning mx-auto ' style={{ scale: '2' }} />}>Prêmios</TitleL1>
            <h5 className="text-justify mr-3 ml-3">A busca contínua da THOT I.A. CORP. por excelência e prestígio, nos faz acreditar
                firmemente que o reconhecimento é uma consequência natural de nosso compromisso inabalável com a excelência.
                Estamos constantemente em busca de ações e iniciativas que nos posicionem como líderes em nosso setor, destacando
                nosso empenho em oferecer produtos e serviços de qualidade superior. Nossa dedicação se reflete em cada aspecto do nosso
                trabalho, desde o desenvolvimento de produtos inovadores até a excelência no atendimento ao cliente
                <br /><br />
                Participamos ativamente de eventos, feiras e competições que nos permitem demonstrar nossa expertise e inovação.
                Além disso, investimos em práticas sustentáveis e socialmente responsáveis, reforçando nosso papel como uma empresa
                consciente e comprometida com o bem-estar da comunidade e do meio ambiente. Essas ações não apenas geram reconhecimento,
                mas também consolidam nossa reputação como uma marca confiável e respeitada
                <br /><br />
                Nosso foco em aprimoramento contínuo e em manter-nos na vanguarda das tendências do mercado nos permite alcançar e
                superar padrões elevados de qualidade. Celebramos cada prêmio, cada certificação e cada menção honrosa como uma validação
                do esforço e da paixão que colocamos em nosso trabalho. Veja nossos reconhecimentos
            </h5>
            <ReconhecimentosList />
        </section>
    </>)
}
export default Reconhecimentos;
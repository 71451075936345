import React, { useState } from 'react';
import CadastrarProdutoUnitario from './Unitario/CadastrarProdutoUnitario';
import CadastrarProdutosNfeXML from './Multiplo/CadastrarProdutoXML/CadastrarProdutosNfeXML';
import { FooterProfissional, NavProfissionalMain, HeaderAnt, BreadcrumbAnt, SelectAnt } from 'components';
export const ProdutosCadastrar = () => {
    const [selectedOption, setSelectedOption] = useState('selectCadastroProdutoUnitario');
    return (<>
        <HeaderAnt />
        <div className="d-flex mt-3">
            <NavProfissionalMain />
            <section className="w-100">
                <BreadcrumbAnt icon={'user fa'} />
                <div className="w-100">
                        <SelectAnt
                            title="Selecione o tipo de cadastro"
                            value={selectedOption}
                            onChange={setSelectedOption}
                            options={[
                                { label: 'Cadastro Unitário', value: 'selectCadastroProdutoUnitario' },
                                { label: 'Cadastro Múltiplo', value: 'selectCadastroProdutoMultiplo' },
                            ]}
                        />
                    {selectedOption === 'selectCadastroProdutoUnitario' && <CadastrarProdutoUnitario />}
                    {selectedOption === 'selectCadastroProdutoMultiplo' && <CadastrarProdutosNfeXML />}
                </div>
                <FooterProfissional />
            </section>
        </div>
    </>);
};
export default ProdutosCadastrar;
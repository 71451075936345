import React from 'react';
import { ApresentacaoParcerias } from './components/ApresentacaoParcerias';
import { EquipamentosMedicos } from './components/EquipamentosMedicos';
import { EquipamentosPesquisasAnimais } from './components/EquipamentosPesquisasAnimais';
import { CadastroParcerias } from './components/CadastroParcerias';
import { DadosDeProjetos } from './components/DadosDeProjetos';
import { OportunidadesDeParcerias } from './components/OportunidadesDeParcerias';
import RepresentacaoComercial from './components/RepresentacaoComercial';
import SoftwareHouse from './components/SoftwareHouse';
import Fornecedores from './components/Fornecedores';
import ColaboreConosco from './components/ColaboreConosco';
export const items = [
  {
    id: 'apresentacaoParcerias',
    title: 'Apresentação',
    component: <ApresentacaoParcerias />,
    subItems: [
    ],
  },
  {
    id: 'representacao',
    title: 'Representação Comercial',
    component: <RepresentacaoComercial />,
    subItems: [
    ],
  },
  {
    id: 'SoftwareHouse',
    title: 'Software House',
    component: <SoftwareHouse />,
    subItems: [
      { id: 'dadosDeParcerias', title: 'Dados de Projetos', component: <DadosDeProjetos /> },
      {
        id: 'cadastroParcerias', title: 'Cadastro de Projetos', component: <CadastroParcerias />,
      },
    ],
  },
  {
    id: 'Pesquisadores',
    title: 'Pesquisadores',
    component: <OportunidadesDeParcerias />, // You can replace this with the actual component you want to render.
    subItems: [
      { id: 'equipamentosMedicos', title: 'Termografia Médica', component: <EquipamentosMedicos /> },
      { id: 'equipamentosPesquisasAnimais', title: 'Termografia Veterinária', component: <EquipamentosPesquisasAnimais /> },
      // Add more sub-items here...
    ],
  },
  {
    id: 'fornecedores',
    title: 'Fornecedores',
    component: <Fornecedores />,
    subItems: [
    ],
  },
  {
    id: 'ColaboreConosco',
    title: 'Colabore Conosco',
    component: <ColaboreConosco />,
    subItems: [
    ],
  },
];
export default items;
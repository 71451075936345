import RecomendacoesInfo from './info/RecomendacoesInfo';
import { ProspeccaoClientesEmail } from './ProspeccaoClientesEmail';
import ProspeccaoClientesTelefone from './ProspeccaoClientesTelefone';
import ProspeccaoClienteSeleciona from './ProspeccaoClienteSeleciona';
export const ProspeccaoClientesMaster = () => {
    return (<>
        <ProspeccaoClienteSeleciona />
        <div className='row'>
            <ProspeccaoClientesEmail />
            <ProspeccaoClientesTelefone />
            <RecomendacoesInfo />
        </div>
    </>);
}
export default ProspeccaoClientesMaster;
import React, { useState } from "react";
import TitleL2 from "../text/TitleL2";
import MdMenuMD from "@components/icons/components/MdMenuMD";
import { FaChevronDownMD } from "@components/icons";
export const ReferenciaLocal = (props) => {
    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };
    return (<>
        <div onClick={toggleVisibility} className="bg-info no-drag d-flex mx-auto justify-content-center col-md-12 mb-4" style={{ borderRadius: '20px 20px 20px 20px' }}>
            <div className="col-md-11 d-flex justify-content-center  align-items-center ">
                <TitleL2>{props.text}</TitleL2>
            </div>
            <div
                className="bg-white ml-2 mb-2 mt-2 justify-content-left h-75 holder"
                style={{ borderRadius: '50%', cursor: 'pointer' }}
                onClick={toggleVisibility}
            >
                <h5 className="font-weight-bold text-center">
                    {isVisible ? <FaChevronDownMD /> : <MdMenuMD />}
                </h5>
            </div>
        </div>
        {isVisible && <>    {props.children}</>}
    </>)
}
export default ReferenciaLocal;
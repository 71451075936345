import { useState, useEffect } from "react";
export const useProfessionalData = () => {
  const [professionalData, setProfessionalData] = useState(null);
  useEffect(() => {
    setProfessionalData(JSON.parse(localStorage.getItem("professionalData")) || null);
  }, []);
  const updateProfessionalData = (data) => {
    setProfessionalData(data);
    localStorage.setItem("professionalData", JSON.stringify(data));
  };
  const clearProfessionalData = () => {
    setProfessionalData(null);
    localStorage.removeItem("professionalData");
  };
  return {
    professionalData,
    updateProfessionalData,
    clearProfessionalData,
  };
};
import { FooterUser,HeaderAnt, BreadcrumbAnt, SiderUserMain } from "components";
import SocialFeed from "./SocialFeed";
export const Social = () => {
    return (<>
     <HeaderAnt/>
        <div className="d-flex mt-3" style={{overflowX:'hidden'}}>
            <SiderUserMain />
            <section className="w-100">
            <BreadcrumbAnt icon={'user fa'} text={'Social'} />
                <SocialFeed />
                <FooterUser />
            </section>
        </div>
    </>)
}
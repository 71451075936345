import { NavProfissionalMain } from "@components";
import ProspeccaoClientesMaster from "./components/ProspeccaoClientesMaster";
import { BreadcrumbAnt, HeaderAnt } from "@components/layout";
export const ProspeccaoClientes = () => {
    return (<>
        <HeaderAnt />
        <div className="d-flex mt-3" style={{ overflowX: 'hidden' }}>
            <NavProfissionalMain />
            <section className="w-100">
                <BreadcrumbAnt />
                <div className="w-100">
                    <ProspeccaoClientesMaster />
                </div>
            </section>
        </div>
    </>)
}
export default ProspeccaoClientes;
import { useState } from 'react';
import moment from 'moment';
import {
    InputPadrao,
    ButtonAPI,
    MdSearchMD,
} from 'components';
import CadastrarLogo from './components/CadastrarLogo';
import CadastrarProfissionalUnit from './components/CadastrarProfissionalUnit';
import { api, apiToken } from 'services';
import { SelectAnt } from '@components/select';
import { getUser } from 'functions';
import { Collapse } from 'antd';
import { MdExpandMore } from "react-icons/md"; // Ícone de "expandir"
const { Panel } = Collapse;
export const CadastrarClinicaInstituicao = () => {
    const [cnpj, setCnpj] = useState("");
    const [maskedCnpj, setMaskedCnpj] = useState("");
    const [cnpjFilial, setCnpjFilial] = useState("");
    const [companies, setCompanies] = useState([]);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [selectedOption, setSelectedOption] = useState('Matriz');
    const [type, setSType] = useState('private');
    const user = getUser();
    const limparDados = () => {
        setData(null);
        setIsLoading(false);
    };
    const procuraInfoCNPJ = (event) => {
        setIsLoading(true);
        const cnpj = selectedOption === 'Matriz' ? document.getElementById('cnpj').value : document.getElementById('cnpjFilial').value;
        const cnpjWithoutMask = cnpj.replace(/\D/g, '');
        apiToken().get(`cnpj/` + cnpjWithoutMask + `/${user.id}`)
            .then(response => response.data)
            .then(data => {
                if (data.status === 'OK') {
                    setData(data);
                    setError(null);
                    setIsLoading(false);
                } else {
                    alert('Erro ao consultar CNPJ!');
                    setIsLoading(false);
                }
            })
            .catch(error => {
                setError(error.message);
                setData(null);
                alert('Erro ao consultar CNPJ!');
                setIsLoading(false);
            });
    };
    const buscarEmpresas = async () => {
        try {
            const response = await api.get(`get_company`);
            const empresas = response.data;
            setCompanies(empresas);
        } catch (error) {
            console.error('Erro ao buscar as empresas:', error);
        }
    };
    const cadastraClinicaInsituicao = () => {
        const empresa = {
            nome: data.nome || "atualizar",
            fantasia: data.fantasia || "atualizar",
            atividade_principal: [
                {
                    text: data.atividade_principal.text || "atualizar",
                    code: data.atividade_principal.code || "atualizar",
                },
            ],
            atividades_secundarias: data.atividades_secundarias.map((atividade) => ({
                text: atividade.text || "atualizar",
                code: atividade.code || "atualizar",
            })),
            abertura: data.abertura
                ? moment(data.abertura, 'DD/MM/YYYY').format('YYYY-MM-DD')
                : 'atualizar',
            situacao: data.situacao || "atualizar",
            data_situacao: data.data_situacao
                ? moment(data.data_situacao, 'DD/MM/YYYY').format('YYYY-MM-DD')
                : 'atualizar',
            capital_social: data.capital_social || "atualizar",
            natureza_juridica: data.natureza_juridica || "atualizar",
            ultima_atualizacao: data.ultima_atualizacao
                ? moment(data.ultima_atualizacao, 'DD/MM/YYYY').format('YYYY-MM-DD')
                : 'atualizar',
            tipo: data.tipo || "atualizar",
            logradouro: data.logradouro || "atualizar",
            numero: data.numero || "atualizar",
            complemento: data.complemento || "atualizar",
            bairro: data.bairro || "atualizar",
            municipio: data.municipio || "atualizar",
            uf: data.uf || "atualizar",
            cep: data.cep || "atualizar",
            telefone: data.telefone || "atualizar",
            email: data.email || "atualizar",
            efr: data.efr || "atualizar",
            situacao_especial: data.situacao_especial || "atualizar",
            data_situacao_especial: data.data_situacao_especial
                ? moment(data.data_situacao_especial, 'DD/MM/YYYY').format('YYYY-MM-DD')
                : 'atualizar',
            owner_id: user.id,
            type: type,
            company_name: data.fantasia,
            created_by: user.id,
        };
        apiToken()
            .post(`post_company/${user.id}`, empresa)
            .then((response) => {
                alert('Dados inseridos com sucesso!');
                setIsLoading(false);
            })
            .catch((error) => {
                alert('Erro ao inserir dados!');
                setIsLoading(false);
            });
    };
    const [isLoading, setIsLoading] = useState(false);
    //Mascaras de CNPJ
    function maskCnpj(value) {
        const cnpjWithoutMask = value.replace(/\D/g, "");
        setCnpj(cnpjWithoutMask);
        return value
            .replace(/\D/g, "") // Remove tudo o que não é dígito
            .replace(/^(\d{2})(\d)/, "$1.$2") // Coloca ponto entre o segundo e o terceiro dígitos
            .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3") // Coloca ponto entre o quinto e o sexto dígitos
            .replace(/\.(\d{3})(\d)/, ".$1/$2") // Coloca barra entre o oitavo e o nono dígitos
            .replace(/(\d{4})(\d)/, "$1-$2") // Coloca hífen depois do bloco de quatro dígitos
            .substr(0, 18); // Limita o tamanho máximo do campo
    }
    return (<>
        <div className="col-md-3">
            <SelectAnt
                id="selectAreaInteresse"
                style={{ width: '100%' }}
                value={selectedOption}
                onChange={(value) => {
                    setSelectedOption(value);
                    limparDados(); // Limpa os dados ao mudar a opção
                }}
                options={[
                    { label: 'Matriz', value: 'Matriz' },
                    { label: 'Filial', value: 'Filial' }
                ]}
            />
        </div>
        <div className="col-md-3">
            <SelectAnt
                style={{ width: '100%' }}
                value={type}
                onChange={(value) => {
                    setSType(value);
                }}
                options={[
                    { label: 'Privada', value: 'private' },
                    { label: 'Pública', value: 'public' }
                ]}
            />
        </div>
        {(selectedOption === "Matriz" || selectedOption === "Filial") && (<>
            <div className=" col-md-3">
                <InputPadrao
                    title={'Identificação única da empresa'}
                    type="text"
                    id="cnpj"
                    name="cnpj"
                    value={maskedCnpj}
                    onChange={(event) => setMaskedCnpj(maskCnpj(event.target.value))}
                    placeholder="Brasil: Digite o CNPJ da matriz"
                />
                <MdSearchMD
                    isLoading={isLoading}
                    onClick={procuraInfoCNPJ}
                    className="generate-button input-filled fa fa-search mt-1 mr-3 flip-horizontal text-dark"
                    aria-label="Botão de busca"
                    title="Buscar"
                />
            </div>
            {selectedOption === "Filial" && (<>
                <select className="form-control text-center" onChange={buscarEmpresas}>
                    <option value="">Selecione uma empresa</option>
                    {companies.map((empresa) => (
                        <option key={empresa.id} value={empresa.id}>
                            {empresa.company_name}
                        </option>
                    ))}
                </select>
            </>)}
        </>)}
        {selectedOption === "Filial" && (
            <div className="form-group py-3 col-md-10 mx-auto">
                <InputPadrao
                    title={'INFORME O Nº DE IDENTIFICAÇÃO ÚNICA DA FILIAL'}
                    type="text"
                    id="cnpjFilial"
                    name="cnpjFilial"
                    value={cnpjFilial}
                    onChange={(event) => setCnpjFilial(event.target.value)}
                    placeholder="Brasil: Digite o CNPJ"
                />
                <ButtonAPI isLoading={isLoading} onClick={procuraInfoCNPJ}>
                    Buscar informações
                </ButtonAPI>
            </div>)}
        <div className="col-md-12">
            <div className="row">
                <div className="col-md-12 text-left mt-2">
                    <small className="text-center text-dark">Informações automatas resgatadas por API empresa a ser cadastrada</small>
                    {data && (<>
                        <div className='col-md-10'>
                            <small><b>Razão Social:</b> {data.nome}</small><br />
                            <small><b>Nome Fantasia:</b> {data.fantasia}</small><br />
                            <small><b>Atividade Principal:</b> {data.atividade_principal[0].text}</small><br />
                            <Collapse
                                expandIcon={({ isActive }) => <MdExpandMore size={20} style={{ transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)' }} />}
                                style={{ background: 'none', border: 'none' }}
                            >
                                <Panel header="Mais infomações" key="1">
                                    <p>CNAE Principal: {data.atividade_principal[0].code}</p>
                                    <ul>
                                        {data.atividades_secundarias.map((atividade, index) => (
                                            <li key={index}>{atividade.text} ({atividade.code})</li>
                                        ))}
                                    </ul>
                                    <p>Data de Abertura: {data.abertura}</p>
                                    <p>Status: {data.situacao}</p>
                                    <p>Data de Situação: {data.data_situacao}</p>
                                    <p>Capital Social: {data.capital_social}</p>
                                    <p>Natureza Jurídica: {data.natureza_juridica}</p>
                                    <p>Última Atualização: {data.ultima_atualizacao}</p>
                                    <p>Tipo: {data.tipo}</p>
                                    <p>Logradouro: {data.logradouro}</p>
                                    <p>Número: {data.numero}</p>
                                    <p>Complemento: {data.complemento}</p>
                                    <p>Bairro: {data.bairro}</p>
                                    <p>Cidade: {data.municipio} - {data.uf}</p>
                                    <p>CEP: {data.cep}</p>
                                    <p>Telefone: {data.telefone}</p>
                                    <p>E-mail: {data.email}</p>
                                    <p>Ente Federativo Responsável: {data.efr}</p>
                                    <p>Situação Especial: {data.situacao_especial}</p>
                                    <p>Data da Situação Especial: {data.data_situacao_especial}</p>
                                </Panel>
                            </Collapse>
                        </div>
                        <ButtonAPI isLoading={isLoading} onClick={cadastraClinicaInsituicao}>
                            Confirmar e Envia Dados
                        </ButtonAPI>
                    </>)}
                    {error && <p className='text-center font-weight-bold text-danger'><i className='fa fa-exclamation-triangle text-warning mr-2'></i>{error}:{cnpj}</p>}
                </div>
            </div>
        </div>
        {selectedOption === "Matriz" && (
            <CadastrarLogo />
        )}
        <div className="form-group mt-5">
            <CadastrarProfissionalUnit title={'Adicionar Profissionais'} />
        </div >
    </>)
}
export const pdf_download_12 = ({
    doc,
    imageData,
    pdf_download_footer,
    qrCode,
    professionalInfo,
    imgWidth,
    imgHeight,
    photos,
    drawAttentionIcon,
    modifiedLandmarks,
    landmarks_botox_soma_un,
    landmarks_botox_color,
    pontoDestacado,
    drawTextBox,
    selectedValuesBotox
}) => {
    if (
        !doc ||
        !imageData ||
        !pdf_download_footer ||
        !qrCode ||
        !professionalInfo ||
        !imgWidth ||
        !imgHeight ||
        !photos ||
        !drawAttentionIcon ||
        !modifiedLandmarks ||
        !landmarks_botox_soma_un ||
        !landmarks_botox_color ||
        !modifiedLandmarks.FaceFrontal ||
        !drawTextBox
    ) {
        console.warn("Parâmetros ausentes, página não adicionada.");
        return;
    }
    doc.addPage();
    doc.addImage(require('../assets/fundoPDF.png'), 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
    doc.setFont('times', 'bold');
    doc.setFontSize(25);
    doc.text('Indicações: Botox', 100, 15, { align: 'center' });
    doc.setFont('times', 'bold');
    doc.setFontSize(12);
    doc.addImage(imageData, 'JPEG', 15, 70, imgWidth, imgHeight);
    if (photos.FaceSorrindo && photos.FaceBravo) {
        // const FaceSorrindo = URL.createObjectURL(photos.FaceSorrindo);
        doc.text('[ Platisma ]', 15, 25);
    } else {
        // Caso FaceSorrindo não esteja disponível, use a imagem padrão
        doc.text('[ Platisma ]', 15, 25);
        doc.setFont('times', 'normal');
        doc.setFontSize(8);
        drawAttentionIcon(doc, 100, 24, 3); // Coordenadas (30, 25), tamanho do ícone 10
        doc.text('- insira foto sorrindo para melhores caracterizações', 40, 25)
        doc.setFillColor(255, 255, 255);
        doc.rect(155.5, 69.5, 40, 40, 'F'); // Ajuste as coordenadas e dimensões conforme necessário
        drawAttentionIcon(doc, 175, 85, 10); // Coordenadas (30, 25), tamanho do ícone 100
        doc.setFont('times', 'bold');
        doc.setFontSize(8);
        doc.text('FALTA FOTO', 165, 95);
        doc.setFont('times', 'normal');
        doc.text('[ Frontal Queixo Erguido ]', 159, 100);
    }
    doc.setFont('times', 'normal');
    doc.setFontSize(8);
    const selectValue = selectedValuesBotox.platisma
    const combinedTextBotoxPlatisma = [
        modifiedLandmarks.FaceFrontal[selectValue].texts.muscle_contraction_pattern
            .replace(/\n/g, '')   // Remove as quebras de linha
            .replace(/\s{2,}/g, ' '),  // Remove espaços duplos
        modifiedLandmarks.FaceFrontal[selectValue].texts.recommendations
            .replace(/\n/g, '')   // Remove as quebras de linha
            .replace(/\s{2,}/g, ' '),  // Remove espaços duplos
    ].join('\n\n');  // Adiciona duas quebras de linha entre os textos para separá-los como parágrafos
    const combinedTextLinesBotoxPlatisma = doc.splitTextToSize(combinedTextBotoxPlatisma, 180);
    let currentYBotoxPlatisma = 30;
    doc.text(combinedTextLinesBotoxPlatisma, 15, currentYBotoxPlatisma);
    currentYBotoxPlatisma += combinedTextLinesBotoxPlatisma.length * 8;
    const somaUNValorPlatisma = landmarks_botox_soma_un(modifiedLandmarks.FaceFrontal[selectValue].landmarks);
    drawTextBox(doc, 'UN Total Platisma: ' + somaUNValorPlatisma, 17, 68);
    modifiedLandmarks.FaceFrontal[selectValue].landmarks.forEach((landmark, index) => {
        landmarks_botox_color(doc, landmark, index, imgWidth, imgHeight, pontoDestacado);
    });
    doc.setTextColor(0, 0, 0);  // Define a cor do texto
    doc.setFontSize(5);
    doc.text('Ayres, E. L., & Sandoval, M. H. L. (2016). Toxina botulínica na dermatologia: guia prático de técnicas e produtos. 1. ed. Rio de Janeiro: Guanabara Koogan.', 15, 75 + imgHeight);
    doc.text('Arora, G., & Arora, S. (2024). Where and how to use botulinum toxin on the face and neck – Indications and techniques. Journal of Dermatological Applications, 12(3), 45-50. https://cosmoderma.org/content/130/2021/1/1/pdf/CSDM-1-17.pdf', 15, 77 + imgHeight);
    doc.setFontSize(8);
    pdf_download_footer(doc, qrCode, professionalInfo);
};
import { useEffect, useState } from 'react';
import { jsPDF } from 'jspdf';
import qrCode from 'qrcode';
import { ButtonDownload } from '@components';
import {
    pdf_download_header,
    pdf_download_footer,
    drawAttentionIcon,
    drawBlueSquareWithArrowDown,
    drawBlueSquareWithArrow,
    drawSquareWithCheckmark,
    drawTextBox,
    calcularPontoMaisAlto,
    calcularPontoMaisBaixo,
    calcularVolumeLandmarks,
    generateTablePDF,
    generateHeatmapPDF,
    landmarks_botox_color,
    landmarks_botox_soma_un,
    landmarks_media_pipe_point,
    landmarks_media_pipe_position_xy,
    projecaoMalarEsq,
    projecaoMalarDir,
    labioInferior,
    labioSuperior,
    pdf_download_1,
    pdf_download_2,
    pdf_download_3,
    pdf_download_4,
    pdf_download_5,
    pdf_download_6,
    pdf_download_7,
    pdf_download_8,
    pdf_download_9,
    pdf_download_10,
    pdf_download_11,
    pdf_download_12,
    pdf_download_13,
    pdf_download_14,
    pdf_download_15,
    pdf_download_16,
    pdf_download_17
} from "../functions"
export function PDFGenerator({
    modifiedLandmarks,
    pontoDestacado,
    photos,
    apiImages,
    cliente,
    others,
    imageWidth,
    imageHeight,
    selectedValuesBotox
}) {
    const [professionalInfo, setProfessionalInfo] = useState({
        profissional: '',
        empresa: '',
        enderecoEletronico: '',
        telefone: ''
    });
    useEffect(() => {
        const storedData = localStorage.getItem('professionalData');
        if (storedData) {
            try {
                const data = JSON.parse(storedData);
                if (Array.isArray(data) && data.length > 0) {
                    const professional = data[0]; // Primeiro objeto do array
                    setProfessionalInfo({
                        profissional: professional.professional_name || '',
                        empresa: professional.empresa || '',
                        enderecoEletronico: professional.enderecoEletronico || '',
                        telefone: professional.telefone || ''
                    });
                } else {
                    console.error('Os dados não estão no formato esperado.');
                }
            } catch (error) {
                console.error('Erro ao analisar os dados do localStorage:', error);
            }
        }
    }, []);
    const handleDownloadPDF = () => {
        if (!photos) throw new Error("Imagem não está definida.");
        if (!modifiedLandmarks || modifiedLandmarks.length === 0) throw new Error("Landmarks estão vazios.");
        const doc = new jsPDF();
        doc.addImage(require('../assets/fundoPDF.png'), 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
        doc.setFont('times', 'bold');
        doc.setFontSize(25);
        doc.text('Relatório de Análise Facial', 100, 20, { align: 'center' });
        pdf_download_header({ doc, cliente, professionalInfo })
        doc.setFont('times', 'normal');
        const img = new Image();
        img.onload = () => {
            const imgWidth = 180;
            const imgHeight = 190;
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = imgWidth;
            canvas.height = imgHeight;
            context.drawImage(img, 0, 0, imgWidth, imgHeight);
            const imageData = canvas.toDataURL('image/jpeg');
            pdf_download_1({
                doc,
                imageData,
                imgWidth,
                imgHeight,
                photos,
                modifiedLandmarks,
                pontoDestacado,
                pdf_download_footer,
                qrCode,
                professionalInfo,
                drawAttentionIcon
            })
            const modifiedLandmarksFrontal = modifiedLandmarks.FaceFrontal.landmarks_media_pipe
            const points = landmarks_media_pipe_point(modifiedLandmarksFrontal);
            const imgWidthHalf = imgWidth / 2;
            const imgHeightHalf = (imgHeight / imgWidth) * imgWidthHalf;
            const imageX = 5;
            const imageY = 30;
            const imageXDir = 105;
            const imageYDir = 30;
            const linhaMediaPag = imageXDir - 5;
            const yEndVerticalLineMediaPag = 75 + imgHeight;
            const textY = imageYDir + imgHeightHalf + 5;
            const landmarks_position = landmarks_media_pipe_position_xy(points, imgWidthHalf, imgHeightHalf, imageXDir, imageYDir, imageX, imageY);
            pdf_download_2({
                doc,
                imageData,
                calcularPontoMaisBaixo,
                calcularPontoMaisAlto,
                points,
                drawBlueSquareWithArrowDown,
                pdf_download_footer,
                qrCode,
                professionalInfo,
                drawSquareWithCheckmark,
                calcularVolumeLandmarks,
                drawBlueSquareWithArrow,
                imgWidthHalf,
                imgHeightHalf,
                imageX,
                imageY,
                imageXDir,
                imageYDir,
                linhaMediaPag,
                yEndVerticalLineMediaPag,
                textY,
                landmarks_position
            })
            pdf_download_3({
                doc,
                imageData,
                points,
                pdf_download_footer,
                qrCode,
                professionalInfo,
                calcularVolumeLandmarks,
                imgWidthHalf,
                imgHeightHalf,
                imageX,
                imageY,
                imageXDir,
                imageYDir,
                linhaMediaPag,
                yEndVerticalLineMediaPag,
                textY,
                landmarks_position,
                modifiedLandmarks,
                projecaoMalarEsq,
                projecaoMalarDir
            })
            pdf_download_4({
                doc,
                imageData,
                points,
                pdf_download_footer,
                qrCode,
                professionalInfo,
                calcularVolumeLandmarks,
                imgWidthHalf,
                imgHeightHalf,
                imageX,
                imageY,
                imageXDir,
                imageYDir,
                linhaMediaPag,
                yEndVerticalLineMediaPag,
                textY,
                landmarks_position,
                drawSquareWithCheckmark,
                drawBlueSquareWithArrow,
                drawBlueSquareWithArrowDown,
                calcularPontoMaisBaixo,
                labioInferior,
                labioSuperior
            })
            pdf_download_5({
                doc,
                imageData,
                pdf_download_footer,
                qrCode,
                professionalInfo,
                imgWidthHalf,
                imgHeightHalf,
                imageX,
                imageY,
                yEndVerticalLineMediaPag,
                drawSquareWithCheckmark,
                drawBlueSquareWithArrow,
                drawBlueSquareWithArrowDown,
                apiImages,
                others
            })
            pdf_download_6({
                doc,
                imageData,
                pdf_download_footer,
                qrCode,
                professionalInfo,
                imgWidth,
                imgHeight,
                photos,
                drawAttentionIcon,
                modifiedLandmarks,
                landmarks_botox_soma_un,
                landmarks_botox_color,
                pontoDestacado,
                drawTextBox,
                selectedValuesBotox
            })
            pdf_download_7({
                doc,
                imageData,
                pdf_download_footer,
                qrCode,
                professionalInfo,
                imgWidth,
                imgHeight,
                photos,
                drawAttentionIcon,
                modifiedLandmarks,
                landmarks_botox_soma_un,
                landmarks_botox_color,
                pontoDestacado,
                drawTextBox,
                selectedValuesBotox
            })
            pdf_download_8({
                doc,
                imageData,
                pdf_download_footer,
                qrCode,
                professionalInfo,
                imgWidth,
                imgHeight,
                photos,
                drawAttentionIcon,
                modifiedLandmarks,
                landmarks_botox_soma_un,
                landmarks_botox_color,
                pontoDestacado,
                drawTextBox,
                selectedValuesBotox
            })
            pdf_download_9({
                doc,
                imageData,
                pdf_download_footer,
                qrCode,
                professionalInfo,
                imgWidth,
                imgHeight,
                photos,
                drawAttentionIcon,
                modifiedLandmarks,
                landmarks_botox_soma_un,
                landmarks_botox_color,
                pontoDestacado,
                drawTextBox,
                selectedValuesBotox
            })
            pdf_download_10({
                doc,
                imageData,
                pdf_download_footer,
                qrCode,
                professionalInfo,
                imgWidth,
                imgHeight,
                photos,
                drawAttentionIcon,
                modifiedLandmarks,
                landmarks_botox_soma_un,
                landmarks_botox_color,
                pontoDestacado,
                drawTextBox,
                selectedValuesBotox
            })
            pdf_download_11({
                doc,
                imageData,
                pdf_download_footer,
                qrCode,
                professionalInfo,
                imgWidth,
                imgHeight,
                photos,
                drawAttentionIcon,
                modifiedLandmarks,
                landmarks_botox_soma_un,
                landmarks_botox_color,
                pontoDestacado,
                drawTextBox,
                selectedValuesBotox
            })
            pdf_download_12({
                doc,
                imageData,
                pdf_download_footer,
                qrCode,
                professionalInfo,
                imgWidth,
                imgHeight,
                photos,
                drawAttentionIcon,
                modifiedLandmarks,
                landmarks_botox_soma_un,
                landmarks_botox_color,
                pontoDestacado,
                drawTextBox,
                selectedValuesBotox
            })
            pdf_download_13({
                doc,
                imageData,
                pdf_download_footer,
                qrCode,
                professionalInfo,
                imgWidth,
                imgHeight,
                modifiedLandmarks,
                landmarks_botox_color,
                pontoDestacado,
                selectedValuesBotox
            })
            pdf_download_14({
                doc,
                pdf_download_footer,
                qrCode,
                professionalInfo,
                imgHeight,
                modifiedLandmarks,
                landmarks_botox_soma_un,
                generateTablePDF
            })
            pdf_download_15({
                doc,
                pdf_download_footer,
                qrCode,
                professionalInfo,
            })
            pdf_download_16({
                doc,
                pdf_download_footer,
                qrCode,
                professionalInfo,
                imageData,
                imageX,
                imageY,
                imgWidthHalf,
                imgHeightHalf,
                drawAttentionIcon,
                photos,
                modifiedLandmarks,
                others,
                generateHeatmapPDF,
                textY,
                imageWidth,
                imageHeight,
            })
            pdf_download_17({
                doc,
                pdf_download_footer,
                qrCode,
                professionalInfo,
                modifiedLandmarks
            })
            // doc.save(`Analise-Facial-${pageNumber}.pdf`);
            // Abrir conteúdo em janela teste 
            const pdfBlob = doc.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl);
        };
        if (photos['FaceFrontal']) {
            if (typeof photos['FaceFrontal'] === "string") {
                img.src = photos['FaceFrontal'];
            } else if (photos['FaceFrontal'] instanceof Blob) {
                img.src = URL.createObjectURL(photos['FaceFrontal']);
            } else {
                console.error("Tipo de fonte de imagem não suportado:", photos['FaceFrontal']);
            }
        } else {
            console.error("Nenhuma imagem encontrada para o tipo:", 'FaceFrontal');
        }
    };
    return (
        <>
            {Object.keys(apiImages).length > 0 && (
                <div>
                    <ButtonDownload id="downloadPDFAnaliseFacial" onClick={handleDownloadPDF} />
                </div>
            )}
        </>
    );
}
import { BreadcrumbAnt, CardDirecionamento } from "components";
import { MdAddCircleOutlineMD, MdCalendarMonthMD, MdCloudMD } from "@components/icons";
export const Index = () => {
  return (<>
    <div className="w-100 ">
      <BreadcrumbAnt text={"Minhas Consultas"} icon={"hand-paper-o fa"} />
      <div className="py-3 ">
        <div className="text-center">
          <div className="container-fluid">
            <div className="row d-inline-flex justify-content-center w-100">
              <CardDirecionamento
                text={"Marcar"}
                icon={< MdAddCircleOutlineMD size={50} />}
                href={"../usuario/servicos/comprar?comprar=consultas"}
              />
              <CardDirecionamento
                text={"Marcadas"}
                icon={<MdCalendarMonthMD size={50} />}
                href={"./marcadas"}
              />
              <CardDirecionamento
                text={"Finalizadas"}
                icon={<MdCloudMD size={50} />}
                href={"./finalizadas"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
};
import React, { useEffect, useState, Suspense } from 'react';
import MainProntuarioPacienteInfo from '../info/MainProntuarioPacienteInfo';
import ProntuarioEstaEmTratamento from './ProntuarioEstaEmTratamento';
import ProntuarioInfoPaciente from './ProntuarioInfoPaciente';
import ProntuarioTomandoMedicamento from './ProntuarioTomandoMedicamento';
import ProntuarioChasFitoterapiaSuplementos from './ProntuarioChasFitoterapiaSuplementos';
import ProntuarioAlergias from './ProntuarioAlergias';
import ProntuarioProblemasCoracao from './ProntuarioProblemasCoracao';
import ProntuarioPressaoArterial from './ProntuarioPressaoArterial';
import ProntuarioFaltaDeAr from './ProntuarioFaltaDeAr';
import ProntuarioDiabetesCicatrizacao from './ProntuarioDiabetesCicatrizacao';
import ProntuarioProblemasDeSaude from './ProntuarioProblemasDeSaude';
import ProntuarioCirurgias from './ProntuarioCirurgias';
import ProntuarioGestante from './ProntuarioGestante';
import ProntuarioEducadorFisico from './ProntuarioEducadorFisico';
import ProntuarioEnfermeiro from './ProntuarioEnfermeiro';
import ProntuarioSelectProfissionalCBO from './ProntuarioSelectProfissionalCBO';
import ProntuarioQueixasPrincipais from './Global/ProntuarioQueixasPrincipais';
import ProntuarioExpectativaTratamento from './Global/ProntuarioExpectativaTratamento';
import ProntuarioDenstita from './Profissionais/Dentista/ProntuarioDenstita';
import { ReferenciaLocal, BreadcrumbAnt, TermoDeResponsabilidadeProntuario } from '@components';
export const MainProntuarioPaciente = () => {
  const [selectedProfissional, setSelectedProfissional] = useState(null);
  const [dynamicComponent, setDynamicComponent] = useState(null);
  const handleProfissionalChange = (selectedOption) => {
    setSelectedProfissional(selectedOption);
  };
  useEffect(() => {
    if (selectedProfissional) {
      // Importe o componente dinâmico com base no valor selecionado
      const componentName = selectedProfissional.label;
      import(`./Profissionais/${componentName}`).then((module) => {
        const DynamicComponent = module.default;
        setDynamicComponent(<DynamicComponent />);
      });
    } else {
      setDynamicComponent(null);
    }
  }, [selectedProfissional]);
  return (
    <>
      <div style={{ overflowX: 'hidden' }}>
        <BreadcrumbAnt icon={'book fa'} text={'Prontuário Único'} />
        <MainProntuarioPacienteInfo />
        <ReferenciaLocal text={'Termo de Responsabilidade'} />
        <TermoDeResponsabilidadeProntuario />
        <ReferenciaLocal text={'Info Paciente'} />
        <ProntuarioInfoPaciente />
        <ReferenciaLocal text={'Anamnese Geral'} />
        <ProntuarioEstaEmTratamento />
        <ProntuarioTomandoMedicamento />
        <ProntuarioChasFitoterapiaSuplementos />
        <ProntuarioAlergias />
        <ProntuarioProblemasCoracao />
        <ProntuarioPressaoArterial />
        <ProntuarioFaltaDeAr />
        <ProntuarioDiabetesCicatrizacao />
        <ProntuarioProblemasDeSaude />
        <ProntuarioCirurgias />
        <ProntuarioGestante />
        <ReferenciaLocal text={'Anamneses Específicas'} />
        <ProntuarioDenstita />
        <ProntuarioSelectProfissionalCBO value={selectedProfissional}
          onChange={handleProfissionalChange} />
        <ProntuarioQueixasPrincipais info={selectedProfissional ? selectedProfissional.label : null} />
        <ProntuarioExpectativaTratamento info={selectedProfissional ? selectedProfissional.label : null} />
        {selectedProfissional && dynamicComponent && (
          <Suspense fallback={<div>Carregando...</div>}>
            <dynamicComponent />
          </Suspense>
        )}   <ProntuarioEducadorFisico />
        <ProntuarioEnfermeiro />
        <ReferenciaLocal text={'Exame Fisicos'} />
      </div>
    </>);
}
export default MainProntuarioPaciente;
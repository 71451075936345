//TesteDesenvolvedorLogin
import React from "react";
import { ButtonAPI} from "components";
const TesteDesenvolvedorLogin = ({ setEmail, setPassword, efetuarLogin, setUser,
  setAuthenticated,
  setLoading,
  setAuthenticatedProfissional,
  updateProfessionalData,
  clearProfessionalData,
  updateProfissional,
  navigate }) => {
  const handleLoginHomolog = (e) => {
    e.preventDefault();
    setEmail("teste@thotiacorp.com");
    setPassword("12345");
    efetuarLogin(e);
  };
  const handleMock = async () => {
    try {
      const response = await fetch('assets/json/response_login.json');
      const mockData = await response.json(); 
      setLoading(false);
      const { user, images, token, userId, user_type, professionalData } = mockData;
      const updatedUser = { ...user, id: userId, user_type, images };
      localStorage.setItem("user", JSON.stringify(updatedUser));
      localStorage.setItem("token", token);
      setUser(updatedUser);
      setAuthenticated(true);
      if (user_type === "profissional") {
        setAuthenticatedProfissional(true)
        updateProfessionalData(professionalData);
        updateProfissional(professionalData.professionalData[0])
      } else {
        clearProfessionalData();
      }
      navigate("/usuario");
    } catch (error) {
      alert('Login error')
    }
  }
  return (<>
    <ButtonAPI onClick={handleLoginHomolog} label={'Auto Teste - Homolog: API ✅ '} />
    <ButtonAPI onClick={handleLoginHomolog} label={'Auto Teste - Local: API ✅ '} />
    <ButtonAPI onClick={handleMock} label={'Auto Teste - Mock: API ⛔'} />
  </>);
};
export default TesteDesenvolvedorLogin;
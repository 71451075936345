export const pdf_download_17 = ({
    doc,
    pdf_download_footer,
    qrCode,
    professionalInfo,
    modifiedLandmarks
}) => {
    doc.addPage();
    doc.addImage(require('../assets/fundoPDF.png'), 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
    let yPosition = 20;
    const lineHeight = 3;
    const numColumns = 6;
    const marginLeft = 4;
    const columnWidth = (doc.internal.pageSize.width - marginLeft) / numColumns;
    let columnIndex = 0;
    let titleAdded = false;
    const landmarksNew = modifiedLandmarks.FaceFrontal.landmarks_media_pipe
    landmarksNew.forEach((landmark, index) => {
        if (yPosition + lineHeight > doc.internal.pageSize.height - 20) {
            doc.setTextColor(0, 0, 0);
            doc.addPage();          
            yPosition = 10;
            doc.addImage(require('../assets/fundoPDF.png'), 'PNG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
            doc.setTextColor(0, 0, 0);
            pdf_download_footer(doc, qrCode, professionalInfo);
            titleAdded = false;
        }
        if (!titleAdded) {
            doc.setFontSize(12);
            doc.setFont('times', 'bold');
            doc.text('Pontos de Referência - Identidade Facial', 65, 20,);
            doc.setFontSize(6);
            doc.setFont('times', 'normal');
            yPosition += 10;
            titleAdded = true;
        }
        const x = landmark.x !== undefined ? landmark.x.toFixed(2) : 'N/A';
        const y = landmark.y !== undefined ? landmark.y.toFixed(2) : 'N/A';
        const z = landmark.z !== undefined ? landmark.z.toFixed(2) : 'N/A';
        const xPosition = marginLeft + columnIndex * columnWidth;
        doc.text(`P. ${index + 1}: X=${x}, Y=${y}, Z=${z}`, xPosition, yPosition);
        columnIndex++;
        if (columnIndex >= numColumns) {
            columnIndex = 0;
            yPosition += lineHeight;
        }
    });
    pdf_download_footer(doc, qrCode, professionalInfo);
};
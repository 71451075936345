// useLocalizacao.js
import { useState } from 'react';
export const useLocalizacao = () => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [address, setAddress] = useState({
    latitude: null,
    longitude: null,
    address: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    zip_code: "",
  });
  const getLocalizacao = async () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        setLatitude(latitude);
        setLongitude(longitude);
        const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`);
        const data = await response.json();
        setAddress({
          latitude,
          longitude,
          address: data.display_name,
          number: data.address.house_number || "",
          complement: data.address.street || "",
          neighborhood: data.address.neighbourhood || data.address.suburb || "",
          city: data.address.city || data.address.town || "",
          state: data.address.state || "",
          zip_code: data.address.postcode || "",
        });
      });
    } else {
      console.error('Geolocation is not available in your browser.');
    }
  };
  return { latitude, setLatitude, longitude, setLongitude, address, getLocalizacao };
};
export default useLocalizacao;
// CadastrarProdutosNfeXML.jsx
import React, { useEffect, useState } from 'react';
import ProductList from './ProductList';
import AlterarValorVenda from './AlterarValorVenda';
import ConfiguracoesRapidas from './ConfiguracoesRapidas';
import { ButtonAPI } from '@components';
import { apiToken } from '@services';
import { useProfisional } from 'context';
export const CadastrarProdutosNfeXML = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [xmlData, setXmlData] = useState([]);
  const [nfe, setNfe] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [editedProductIndexes, setEditedProductIndexes] = useState(null);
  const { profissional } = useProfisional();
  useEffect(() => {
    const fetchData = async () => {
      if (selectedFile) {
        try {
          const formData = new FormData();
          formData.append('xmlFile', selectedFile);
          const response = await apiToken().post(`post_products_nfe_xml_info/${profissional.id}`, formData);
          const data = response.data;
          setNfe(data)
          const infNFe = data.xmlData.NFe?.infNFe || data.xmlData.nfeProc.NFe?.[0]?.infNFe;
          setProductImages(new Array(infNFe.length).fill([]));
          setXmlData(infNFe.map((item, index) => ({ ...item, id: index, det: item.det || [] })));
        } catch (error) {
          console.error(error);
        }
      }
    };
    fetchData();
  }, [selectedFile, profissional.id]);
  const handleSetProductImages = (productIndex, subIndex, images) => {
    setProductImages((prevImages) => {
      const newImages = [...prevImages];
      if (!newImages[productIndex]) {
        newImages[productIndex] = [];
      }
      newImages[productIndex][subIndex] = images;
      return newImages;
    });
  };
  const handleEditProduct = (productIndex, subIndex) => {
    setEditedProductIndexes({ productIndex, subIndex });
  };
  const handleSaveProduct = (editedProduct) => {
    if (!editedProductIndexes) {
      console.error("editedProductIndexes is null");
      return;
    }
    const { productIndex = 0, subIndex = 0 } = editedProductIndexes;
    setXmlData((prevXmlData) => {
      const updatedXmlData = [...prevXmlData];
      updatedXmlData[productIndex].det[subIndex] = {
        ...updatedXmlData[productIndex].det[subIndex],
        xProd: editedProduct.xProd,
        qCom: editedProduct.qCom,
        NCMDescription: editedProduct.NCMDescription,
      };
      return updatedXmlData;
    });
    setProductImages((prevImages) => {
      const newImages = [...prevImages];
      newImages[productIndex][subIndex] = editedProduct.images;
      return newImages;
    });
    setEditedProductIndexes(null);
  };
  const handleCancelEdit = () => {
    setEditedProductIndexes(null);
  };
  const handleEnviarProdutos = async () => {
    try {
      const requestData = {
        professional_id: profissional.id,
        xmlData: xmlData,
        fileName: nfe.xmlData.fileName,
        productImages: productImages,
      };
      const response = await apiToken().post(`post_products_nfe_xml/${profissional.id}`, requestData);
      console.log(response.data);
    } catch (error) {
      console.error('Error sending products:', error);
    }
  };
  return (<>
    <div className='py-5'>
      <p className='text-center'>
        <small className='font-weight-bold mt-5'>
          <strong>Insira NFe XML</strong>
        </small>
      </p>
      <p className='text-center'>
        <input type="file" onChange={(event) => setSelectedFile(event.target.files[0])} /><br />
      </p>
      {Array.isArray(xmlData) && (
        <div className="col-md-12">
          <ConfiguracoesRapidas />
          <AlterarValorVenda xmlData={xmlData} setXmlData={setXmlData} />
          <ProductList
            xmlData={xmlData}
            setXmlData={setXmlData} // Certifique-se de passar o setXmlData como propriedade
            productImages={productImages}
            handleSetProductImages={handleSetProductImages}
            editedProductIndexes={editedProductIndexes}
            handleEditProduct={handleEditProduct}
            handleSaveProduct={handleSaveProduct}
            handleCancelEdit={handleCancelEdit}
          />
          <ButtonAPI onClick={handleEnviarProdutos}>Enviar Produtos</ButtonAPI>
        </div>
      )}
    </div>
  </>);
};
export default CadastrarProdutosNfeXML;
import { Image } from 'antd';
import React, { useState } from 'react';
const ProfessionalUnitCard = ({ professional, onAddProfessional, professionalTypes = [] }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [selectedCBOId, setSelectedCBOId] = useState('');
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const handleTypeChange = (type) => {
    setSelectedType(type);
    onAddProfessional(professional.professional_id, type, selectedCBOId);
  };
  const handleCBOChange = (e) => {
    setSelectedCBOId(e.target.value);
  };
  return (
    <>
      <div
        className="col-md-2 mt-2 rounded"
        style={{ backgroundColor: '#fff', padding: '10px', margin: '5px' }}
      >
        {professional.image_url && (
          <Image
            className="w-100 rounded"
            src={professional.image_url}
            alt="Professional Image"
            style={{ minHeight: '100px', maxHeight: '100px' }}
          />
        )}
        {professional.professional_name && (
          <h5 className="card-title mt-1"><b>{professional.professional_name}</b></h5>
        )}
        {professional.cbos?.length > 0 && (
          <div>
            <h5><b>Ocupação:</b></h5>
            <select className="custom-select text-center border border-dark mx-auto font-weight-bold rounded" onChange={handleCBOChange}>
              <option value="">Selecione uma ocupação</option>
              {professional.cbos.map((cbo) => (
                <option key={cbo.id} value={cbo.id}>
                  {cbo.title}
                </option>
              ))}
            </select>
          </div>
        )}
        <button className="text-primary py-2" style={{ border: '0px', backgroundColor: '#fff' }} onClick={openModal}>
          <i className="fa fa-info-circle"></i>&nbsp; Ver mais info ...
        </button>
        {professionalTypes.length > 0 && (
          <div className="dropdown mt-2">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {selectedType || 'Adicionar profissional'}
            </button>
            <div className="dropdown-menu">
              {professionalTypes.map((type, index) => (
                <button key={index} className="dropdown-item" onClick={() => handleTypeChange(type)}>
                  {type}
                </button>
              ))}
            </div>
          </div>
        )}
        {modalOpen && (
          <div className="modal d-block">
            <div className="modal-dialog w-100 modal-dialog-centered modal-fullscreen modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{professional.professional_name}</h5>
                  <button type="button" className="close" onClick={closeModal}>
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body modal-scrollable">
                  {professional.image_url && <Image className="w-100" src={professional.image_url} alt="Professional Image" />}
                  <h5 className="card-title"><b>{professional.professional_name}</b></h5>
                  {professional.state && <h5><b>Ocupação:</b> {professional.state}</h5>}
                  {professional.address && (
                    <>
                      <h6 className="font-weight-bold">Endereço:</h6>
                      <p>{professional.address}</p>
                      {professional.number && <p>{professional.number}</p>}
                      {professional.complement && <p>{professional.complement}</p>}
                      {professional.neighborhood && <p>{professional.neighborhood}</p>}
                      {professional.city && <p>{professional.city}</p>}
                      {professional.zip_code && <p>{professional.zip_code}</p>}
                    </>
                  )}
                  {(professional.grande_area || professional.area || professional.sub_area || professional.especialidade) && (
                    <>
                      <h6 className="font-weight-bold">Área de Atuação:</h6>
                      {professional.grande_area && <p>{professional.grande_area}</p>}
                      {professional.area && <p>{professional.area}</p>}
                      {professional.sub_area && <p>{professional.sub_area}</p>}
                      {professional.especialidade && <p>{professional.especialidade}</p>}
                    </>
                  )}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={closeModal}>
                    Fechar
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default ProfessionalUnitCard;
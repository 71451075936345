import { SiderUserMain, BreadcrumbAnt, FooterUser, InputPadraoSearch, HeaderAnt } from 'components';
export const EstoqueUser = () => {
    return (<>
        <HeaderAnt/>
        <div className="d-flex mt-3">
            <SiderUserMain />
            <div className='w-100'>
                <BreadcrumbAnt text={'Consultas Marcadas'} icon={'location-arrow fa'} />
                <div className="py-3">
                    <InputPadraoSearch />
                    <div className="text-center">
                        <div className="container-fluid">
                            <div className="row d-inline-flex justify-content-center w-100">
                            </div>
                        </div>
                    </div>
                </div>
                <FooterUser />
            </div>
        </div>
    </>)
}
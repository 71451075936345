import { MdAccountCircleMD, MdMonetizationOnMD, MdOutlinePersonAddAltMD } from '@components/icons';
import { AuthContext } from '@guard/index';
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import'./styles/ButtonCadastrar.css'
export const ButtonCadastrar = (props) => {
  const location = useLocation();
  const { search } = location;
  const { authenticated, authenticatedProfisional } = useContext(AuthContext);
  return (<>
    {!authenticated && <>
      <div className='text-center d-flex col-md-12 mt-3 justify-content-center' style={{ ...props.style }}>
        <div>
          <Link className='btn btn-success rounded-circle py-1 mr-3 ml-3 mb-2 '
            style={{ padding: '6px 6px', boxShadow: "0px 4px 4px 0px rgb(10, 16, 142), inset  0px 0px 6px 0px rgb(128, 229, 165)", cursor: 'pointer' }}
            to={'../cadastrar' + search}>
            <MdOutlinePersonAddAltMD size={50}  className="icon-vibrate"  /></Link><br />    <b> Cadastrar</b>
        </div>
        <div>
          <Link className='btn btn-success rounded-circle py-1 mr-3 ml-3 mb-2 '
            style={{ padding: '6px 6px', boxShadow: "0px 4px 4px 0px  rgb(10, 16, 142), inset  0px 0px 6px 0px  rgb(128, 229, 165)", cursor: 'pointer', ...props.style }}
            to={'../login' + search}>
            <MdAccountCircleMD size={50}  className="icon-rotate" /></Link><br />
          <b>   Entrar</b>
        </div>
      </div>
      <div className='col-md-12 mt-3 text-center'>
        <small className='font-weight-bold' style={{ ...props.styleText }}>
          {props.text && <> {props.text} </>}
          {!props.text && 'Aproveite o máximo do sistema de IA e RM integradas cadastre-se ou entre'}
        </small>
      </div>
    </>}
    {authenticatedProfisional &&
      <div className='text-center mt-3'>
        <Link className='btn btn-success rounded-circle py-1 mr-3 ml-3 mb-2'
          style={{ padding: '6px 6px', boxShadow: "0px 4px 4px 0px  rgb(10, 16, 142), inset  0px 1px 6px 0px  rgb(128, 229, 165)", cursor: 'pointer', ...props.style }}
          to={'../profissionais/vendas' + search}>
          <MdMonetizationOnMD size={50}  className="icon-rotate" /></Link><br />
        <small className='font-weight-bold' style={{ ...props.style }}>Vamos faturar agora!</small>
      </div>
    }
    {authenticated && !authenticatedProfisional &&
      <div className='text-center mt-3'>
        <Link className='btn btn-success rounded-circle py-1 mr-3 ml-3 mb-2'
          style={{ padding: '6px 6px', boxShadow: "0px 4px 4px 0px   rgb(10, 16, 142), inset  0px 1px 6px 0px  rgb(128, 229, 165)", cursor: 'pointer', ...props.style }}
          to={'../usuario/cadastro/profissional' + search}>
          <MdMonetizationOnMD size={50}  className="icon-rotate" /></Link><br />
        <small className='font-weight-bold' style={{ ...props.style }}>Vamos faturar agora!</small>
      </div>
    }
  </>);
};
export default ButtonCadastrar;
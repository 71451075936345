import { apiToken } from "services";
export const post_prontuario = async (data) => {
  try {
    const response = await apiToken().post(`post_prontuario`, data);
    console.log("Informações enviadas com sucesso:", response.data);
    return response.data; // Retorna os dados da resposta se necessário
  } catch (error) {
    console.error("Erro ao enviar as informações:", error.response?.data || error.message);
    throw error; // Lança o erro para ser tratado onde a função for chamada
  }
};
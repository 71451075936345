import React, { useState, useRef, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { mainData } from "./NavData";
import { AuthContext } from "@guard/index";
import { FaChevronDownMD, FaChevronRightMD, MdCircleMD } from "@components/icons";
export const SiderUser = () => {
  const [isOpen, setIsOpen] = useState([]);
  const collapseRef = useRef([]);
  const toggleCollapse = (index) => {
    setIsOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };
  const { authenticatedProfisional } = useContext(AuthContext);
  const location = useLocation();
  const { pathname, search } = location;
  return (
    <>
      {mainData.map((data, index) => {
        const isCurrentPath = data.href === `../..${pathname}`;
        const isCurrentSubPath = data.subData.href === `../..${pathname}`;
        return (
          <div
            className={`
              ${data.topDist}
              ${data.id === "navServicosInternos" && !authenticatedProfisional ? "collapse" : ""}
              ${isCurrentPath || isCurrentSubPath ? 'bg-primary text-white  col-md-12' : 'col-md-12'}
              ${isOpen[index] ? 'bg-info' : ''}
            `}
            style={{ padding: '0px', borderRadius: '0.25rem' }}
            key={data.title}
          >
            {data.subData.length === 0 ? (
              <Link
                to={`${data.href}${search}`}
                className={`navbar-brand  nav-link font-weight-bold ${isCurrentPath ? ' text-white' : 'text-dark'}`}
                style={{ cursor: 'pointer', marginTop: '0px', paddingLeft:'0px' }}
              >
                {data.icon}
                <span className={`ml-1 ${isCurrentPath ? 'text-white' : 'text-dark'}`}>
                  {data.title}
                  {isOpen[index] ? (
                    <FaChevronDownMD className="align-right mt-2  mr-2" size={6} />
                  ) : (
                    data.subData.length === 0 ? (
                      <>
                        {isCurrentPath && <MdCircleMD className="align-right mt-2 mr-2" size={6} />}
                        {!isCurrentPath && <MdCircleMD className="align-right mt-2 mr-2" size={6} />}
                      </>
                    ) : (
                      <FaChevronRightMD className="align-right mt-2  mr-2" size={6} />
                    )
                  )}
                </span>
              </Link>
            ) : (
              <>
                <div
                  onClick={() => toggleCollapse(index)}
                  className={`navbar-brand  nav-link font-weight-bold ${isOpen[index] ? 'nav-link' : 'text-dark mt-1'} ${data.topDist}`}
                  style={{ padding: '0px', cursor: 'pointer', marginBottom: '0px' }}
                >
                  {data.subData.href}
                  {data.icon}
                  <span className="ml-1 text-dark">
                    {data.title}
                    {isOpen[index] ? (
                      <FaChevronDownMD className="align-right mt-2 mr-2" size={6} />
                    ) : (
                      <FaChevronRightMD className="align-right mt-2 mr-2" size={6} />
                    )}
                  </span>
                </div>
                <div
                  className={`collapse ${isOpen[index] ? "show" : ""}`}
                  ref={(el) => (collapseRef.current[index] = el)}
                  style={{ padding: '0px', marginBottom: '0px' }}
                >
                  <ul>
                    {data.subData.map((subData) => (
                      <li className="text-dark  nav-link font-weight-bold text-left mt-1 ml-3" style={{ cursor: 'pointer',padding: '0px',}} key={subData.id}>
                        <Link to={`${subData.href}${search}`}>
                          {subData.icon} -
                          <span className="ml-1 text-dark">{subData.title}</span>
                          <p className='text-right mr-2' style={{ marginTop: '-28px' }}>
                            <MdCircleMD size={4} className={isCurrentPath === subData.id ? "text-white" : "text-dark"} />
                          </p>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </div>
        );
      })}
    </>
  );
};
export default SiderUser;
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Badge } from 'antd';
import MdLogoutMD from '@components/icons/components/MdLogoutMD';
import { AuthContext } from '@guard/index';
import { useUser } from '@context/components/UserProvider';
import { useProfisional } from '@context/index';
import { MdNotificationsActiveMD } from '@components/icons';
import { Link } from 'react-router-dom';
export const BarraDeComandos = () => {
    const [notificacoes, setNotificacoes] = useState(0);
    const location = useLocation();
    const { setAuthenticated, setAuthenticatedProfissional } = useContext(AuthContext);
    const { setUser } = useUser();
    const { clearProfissional } = useProfisional();
    const navigate = useNavigate();
    const isProfissionaisPage = location.pathname.startsWith("/profissionais");
    useEffect(() => {
        const professionalData = localStorage.getItem('professionalData');
        if (!professionalData) {
            setNotificacoes(1);
        }
    }, []);
    const post = () => {
        setNotificacoes(0);
    };
    const handleLogout = () => {
        localStorage.clear();
        setUser(null);
        setAuthenticated(false);
        setAuthenticatedProfissional(false)
        clearProfissional()
        navigate("/login");
    };
    return (
        <div className="d-flex align-items-center justify-content-center py-4">
            <div className="text-center">
                <MdLogoutMD style={{ cursor: 'pointer' }} className=" text-danger" onClick={handleLogout}></MdLogoutMD>
                <Link
                    to={isProfissionaisPage ? '../../../../profissionais/notificacoes' : '../../../../usuario/notificacoes'}
                    className=""
                    onClick={post}
                >
                    &emsp;&emsp;
                    <Badge count={notificacoes > 0 ? notificacoes : 0} overflowCount={99} offset={[5, -5]}>
                        <MdNotificationsActiveMD className=" text-warning" aria-hidden="true" />
                    </Badge>
                </Link>
            </div>
        </div>
    );
};
import React from 'react';
import './styles/ButtonAPI.css'
export function ButtonAPI({ isLoading, onClick, children, type, style, ref, label }) {
  return (<>
    <div className=" d-flex justify-content-center mx-auto align-items-center">
      <button className={isLoading ? 'loading btn btn-success   mb-2 mx-auto text-center mt-1' : 'mt-1 btn btn-primary    mb-2 mx-auto text-center'}
        type={type}
        style={{ borderRadius: '20px 20px 20px 20px', paddingBottom: '10px', padding: '5px', ...style }}
        onClick={onClick}
        disabled={isLoading}
        ref={ref}
      >
        <b className='mr-2 ml-2 text-white'>{children}{label}</b>
        {isLoading && (
          <i className='ml-2 fa fa-spinner fa-spin'></i>
        )}
      </button>
    </div>
  </>);
}
export default ButtonAPI;
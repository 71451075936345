import React, { useState } from "react";
import { ThemeContext } from "./ThemeProvider";
import { ButtonAPI, FooterProfissional, HeaderAnt, NavProfissionalMain, TitleL1, BreadcrumbAnt, SelectAnt } from "../../../../components";
import { Home } from "../../../Public/Home/Home";
import { ImageHome } from "./ImageHome";
import { ThemeContextSecondary } from "./ThemeProviderSecondary";
import ThemeContextTerciary from "./ThemeProviderTerciary";
import ThemeContextTextPrimary from "./ThemeProviderTextPrimary";
import ThemeContextTextSecondary from "./ThemeProviderTextSecondary";
import {
  Produtos,
  Servicos,
  Parcerias,
  Artigos,
  SobreNos,
  Carrinho,
  Cadastrar,
} from "../../../Public";
import { baseURL } from "@services";
import MaintenancePage from "@tests/components/MaintenancePage";
export const Ecommerce = () => {
  const [primaryColor, setPrimaryColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [terciaryColor, setTerciaryColor] = useState("");
  const [textPrimaryColor, setTextPrimaryColor] = useState("");
  const [textSecondaryColor, setTextSecondaryColor] = useState("");
  const [selectedOption, setSelectedOption] = useState("Inicio");
  const handleColorSubmit = () => {
    // Helper function to check if the response is a JSON
    const isJsonResponse = (response) => {
      const contentType = response.headers.get("content-type");
      return contentType && contentType.indexOf("application/json") !== -1;
    };
    // Helper function to handle errors in the fetch request
    const handleFetchError = (response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response;
    };
    const colors = [primaryColor, secondaryColor, terciaryColor, textPrimaryColor, textSecondaryColor];
    // Sending the primaryColor to the server using a POST request
    fetch(`${baseURL}post_ecommerce`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ecommerceData: {
          options: "primaryColor",
          text: colors,
          ecommerce_id: 1,
        }
      }),
    })
      .then(handleFetchError)
      .then((response) => {
        if (isJsonResponse(response)) {
          return response.json();
        } else {
          throw new Error('Invalid response or not a JSON');
        }
      })
      .then((data) => alert(data.message))
      .catch((error) => alert('Error submitting primaryColor: ' + error.message));
    // Sending the secondaryColor to the server using a POST request
    // ... (similar logic for other colors)
  };
  // Rest of your component code here...
  const handleColorChange = (color) => {
    setPrimaryColor(color);
    const elements = document.querySelectorAll(
      '.bg-primary, [class*="-primary"]'
    );
    elements.forEach((element) => {
      element.classList.remove("bg-primary");
      element.classList.add("-primary");
      element.style.backgroundColor = color;
    });
  };
  const handleColorChangeSecundary = (colorSecondary) => {
    setSecondaryColor(colorSecondary);
    const elements = document.querySelectorAll(
      '.CardProdutos, [class*="-secondary"]'
    );
    elements.forEach((element) => {
      element.classList.remove("CardProdutos");
      element.classList.add("-secondary");
      element.style.backgroundColor = colorSecondary;
    });
  };
  const handleColorChangeTerciary = (colorTerciary) => {
    setTerciaryColor(colorTerciary);
    const elements = document.querySelectorAll('.App, [class*="-terciary"]');
    elements.forEach((element) => {
      element.classList.remove("App");
      element.classList.add("-terciary");
      element.style.backgroundColor = colorTerciary;
    });
  };
  const handleColorChangeTextPrimary = (colorTextPrimary) => {
    setTextPrimaryColor(colorTextPrimary);
    const elements = document.querySelectorAll(
      '.text-white, [class*="-textprimary"]'
    );
    elements.forEach((element) => {
      element.classList.remove("text-white");
      element.classList.add("-textprimary");
      element.style.color = colorTextPrimary;
    });
  };
  const handleColorChangeTextSecondary = (colorTextSecondary) => {
    setTextSecondaryColor(colorTextSecondary);
    const elements = document.querySelectorAll(
      '.text-dark, [class*="-textsecondary"]'
    );
    elements.forEach((element) => {
      element.classList.remove("text-dark");
      element.classList.add("-textsecondary");
      element.style.color = colorTextSecondary;
    });
  };
  const options = [
    { value: 'Inicio', label: 'Início' },
    { value: 'Servicos', label: 'Serviços' },
    { value: 'Produtos', label: 'Produtos' },
    { value: 'Artigos', label: 'Artigos' },
    { value: 'SobreNos', label: 'Sobre Nós' },
    { value: 'Parcerias', label: 'Parcerias' },
    { value: 'Carrinho', label: 'Carrinho' },
    { value: 'Cadastrar', label: 'Cadastrar' }
  ];
  const componentes = {
    Inicio: Home,
    Servicos: Servicos,
    Produtos: Produtos,
    Parcerias: Parcerias,
    Artigos: Artigos,
    SobreNos: SobreNos,
    Carrinho: Carrinho,
    Cadastrar: Cadastrar,
  };
  const ComponenteSelecionado = componentes[selectedOption];
  return (<>
    <HeaderAnt />
    <div className="d-flex mt-3">
      <NavProfissionalMain />
      <section>
        <BreadcrumbAnt text={"E-commerce"} icon={"money fa"} />
        <div className="row d-inline-flex justify-content-center w-100">
          <TitleL1>Personalização </TitleL1>
          <div className="col-md-12 w-100">
            <small className="text-dark font-weight-bold"><b>
              Todas as informações são trocadas parcialmente ao vivo
              para facilitar ver a aparência antes de aplicar para o
              cliente
            </b> </small>
          </div>
          <div className="row d-inline-flex justify-content-center w-100 py-1 ">
            <div className="col-md-6">
              <div className="row d-inline-flex justify-content-center w-100 py-1 bg-light rounded">
                <div className="col-md-6">
                  <ThemeContext onChange={handleColorChange} />
                </div>
                <div className="col-md-6">
                  <ThemeContextSecondary onChange={handleColorChangeSecundary} />
                </div>
                <div className="col-md-6">
                  <ThemeContextTerciary onChange={handleColorChangeTerciary} />
                </div>
                <div className="col-md-6">
                  <ThemeContextTextPrimary onChange={handleColorChangeTextPrimary} />
                </div>
                <div className="col-md-6">
                  <ThemeContextTextSecondary onChange={handleColorChangeTextSecondary} />
                </div>
                <div className="col-md-12 w-100 mt-1">
                  <ButtonAPI onClick={handleColorSubmit} > Salvar</ButtonAPI>
                </div>
              </div>
            </div>
            <div className="col-md-6 bg-light rounded py-1">
              <div className="container-fluid">
                <SelectAnt
                  title={'Escolha Página'}
                  style={{ width: '100%' }}
                  placeholder="Selecione uma opção"
                  value={selectedOption}
                  onChange={(value) => setSelectedOption(value)}
                  options={options}
                />
                {ComponenteSelecionado && (
                  <div className="col-md-12" style={{ display: "inline-block", overflow: "hidden" }}>
                    <div style={{ height: "auto", zoom: "0.3" }}>
                      <ComponenteSelecionado />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <TitleL1 text={"Compartilhar Aplicativo"} />
            <small className="text-dark">
              Https://thotiacorp.com.br/?profissional_id=1
              <br />
              <i className="fa fa-share-alt fa-2x  mt-3"></i>
            </small>
          </div>
          <MaintenancePage />
          <FooterProfissional />
        </div>
      </section>
    </div>
  </>
  );
};
export default Ecommerce;
import { FooterUser, HeaderAnt, SiderUserMain, BreadcrumbAnt } from '@components';
import { AuthContext } from '@guard/index';
import { CadastrarProfissionalMain } from '@pages/Private/Profissionais/Cadastro/components/ProfissionalCadastrar/components/CadastrarProfissionalMain';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
export const PostProfissionalUser = () => {
    const { authenticatedProfisional } = useContext(AuthContext);
    const navigate = useNavigate();
    useEffect(() => {
        if (authenticatedProfisional) {
            navigate('/profissionais/cadastro'); // Substitua pelo caminho desejado
        }
        localStorage.removeItem('cadastroProfissional');
    }, [authenticatedProfisional, navigate])
    return (<>
        <HeaderAnt />
        <div className="d-flex mt-3">
            <SiderUserMain />
            <div className="w-100">
                <BreadcrumbAnt icon={'user fa'} text={'Cadastro'} />
                <div className='col-md-12'>
                    <div className='row'>
                        <CadastrarProfissionalMain />
                    </div>
                </div>
                <FooterUser />
            </div>
        </div>
    </>)
}
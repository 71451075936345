import { BreadcrumbAnt, CardDirecionamento, FaResearchgateFA, MdCameraAltMD, MdMonetizationOnMD } from 'components'
export const Index = () => {
    return (<>
        <div className=' w-100' >
            <BreadcrumbAnt icon={'user-md fa'} />
            <div className="py-3">
                <div className="text-center">
                    <div className="container-fluid">
                        <div className="row d-inline-flex justify-content-center w-100">
                            <CardDirecionamento text={'I.A. Texto'} icon={<FaResearchgateFA size={50} />} href={'../profissionais/IA/text'} />
                            <CardDirecionamento text={'I.A. Imagem '} icon={<MdCameraAltMD size={50} />} href={'../profissionais/IA/imagem'} />
                            <CardDirecionamento text={'I.A. Prospecção'} icon={<MdMonetizationOnMD size={50} />} href={'../profissionais/IA/BI'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';
export const ChartPieData = ({ data }) => {
  const chartRef = useRef(null);
  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    const existingChart = chartRef.current.chart;
    if (existingChart) {
      existingChart.destroy();
    }
    const chartData = {
      labels: data.length > 0 ? data.slice(1).map(([label]) => label) : [],
      label: 'Density',
      datasets: [
        {
          data: data.length > 0 ? data.slice(1).map(([, value]) => value) : [],
          backgroundColor: data.slice(1).map(([, , color]) => color || 'blue'),
        },
      ],
    };
    const newChart = new Chart(ctx, {
      type: 'pie',
      data: chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: 'Pie Chart',
        },
        plugins: {
          legend: {
            position: 'bottom',  // Posiciona a legenda sempre embaixo
            align: 'center',     // Alinha a legenda no centro
          },
        },
      },
    });
    chartRef.current.chart = newChart;
    return () => {
      newChart.destroy();
    };
  }, [data]);
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <canvas ref={chartRef} style={{ maxHeight: '248px', display: 'flex' }} />
    </div>
  );
};
export default ChartPieData;
import { Navigate } from "react-router";
import { useContext } from "react";
import { AuthContext } from "guard";
export const AuthProfessional = ({ children }) => {
  const { authenticatedProfisional, loading } = useContext(AuthContext);
  if (loading) {
    return null;
  }
  if (!authenticatedProfisional) {
    return <Navigate to="/usuario" />;
  }
  return children;
};
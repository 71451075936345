import React, { useEffect, useRef, useState, useCallback } from 'react';
import Quagga from 'quagga';
import { FaSpinner } from 'react-icons/fa';
import { apiToken } from '@services';
import { MaisInfoMenosInfo, TitleL1 } from '@components';
import { useProfisional } from 'context';
const BarcodeScanner = ({ onScanSuccess, productInfo, setProductInfo }) => {
  const scannerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [scannedCode, setScannedCode] = useState(null);
  const { profissional } = useProfisional();
  const fetchProductInfo = useCallback(async (barcode) => {
    try {
      const response = await apiToken().get(`get_products_info_barcode/${profissional.id}/${barcode}`);
      setProductInfo(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching product info:', error);
    }
  }, [profissional.id, setProductInfo]);
  useEffect(() => {
    Quagga.init({
      inputStream: {
        type: 'LiveStream',
        constraints: {
          facingMode: 'environment'
        },
        target: scannerRef.current
      },
      decoder: {
        readers: ['ean_reader', 'code_128_reader', 'upc_reader'] // Adicione outros leitores conforme necessário
      }
    }, (err) => {
      if (err) {
        console.error('Error initializing Quagga:', err);
        return;
      }
      Quagga.start();
    });
    Quagga.onDetected((result) => {
      if (result.codeResult) {
        const code = result.codeResult.code;
        setScannedCode(code);
        setLoading(true);
        fetchProductInfo(code);
        onScanSuccess && onScanSuccess(code);
      }
    });
    return () => {
      Quagga.stop();
    };
  }, [onScanSuccess, fetchProductInfo]);
  return (
    <div>
      <div ref={scannerRef} style={{ width: '100%', height: '300px' }} />
      {loading && <><FaSpinner className="spin" /> <p>Loading product information...</p></>}
      {productInfo && (
        <MaisInfoMenosInfo text={
          <div>
            <TitleL1>Product Information</TitleL1>
            {scannedCode && <p>Scanned Code: {scannedCode}</p>}
            {Object.entries(productInfo).map(([key, value]) => (
              <p key={key}>{key}: {value}</p>
            ))}
          </div>
        } />
      )}
    </div>
  );
};
export default BarcodeScanner;
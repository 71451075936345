import MainProntuarioPaciente from "./components/layout/MainProntuarioPaciente";
import { FooterUser,HeaderAnt, SiderUserMain } from "../../../../components";
export const ProntuarioPaciente = () => {
    return (<>
        <HeaderAnt/>
        <div className="d-flex mt-3" style={{overflowX:'hidden'}}>
            <SiderUserMain />
            <section className="w-100">
                <MainProntuarioPaciente />
                <FooterUser />
            </section>
        </div>
    </>)
}
import { SiderUserMain, BreadcrumbAnt, FooterUser,HeaderAnt, } from "components";
import Index from "@pages/Public/Carrinho/Carrinho";
export const CarrinhoPaciente = () => {
  return (<>
    <HeaderAnt/>
    <div className="d-flex mt-3" style={{ overflowX: 'hidden' }}>
      <SiderUserMain />
      <section className="w-100 ">
        <BreadcrumbAnt icon={'user fa'} text={'Carrinho'} />
        <Index />
        <FooterUser />
      </section>
    </div>
  </>)
}
import { useLocation } from 'react-router-dom';
import { MaisInfoMenosInfo, IconThotEmotion } from '@components';
import CadastroAuthInfo from '../info/CadastroAuthInfo';
// import LoginAuthGithub from '../../../Login/partials/layout/LoginAuthGithub';
import LoginAuthGoogle from '@pages/Public/Login/components/components/LoginAuthGoogle';
// import LoginAuthFacebook from '../../../Login/partials/layout/LoginAuthFacebook';
export const CadastroAuth = ({ onclickCadastroNativo, onclickLoginNativo }) => {
  const location = useLocation();
  const isCadastrarPage = location.pathname.includes("/cadastrar");
  return (<>
    <div className='col-md-12 align-items-center'>
      <div className='row text-center justify-content-center'>
        <div className='text-center'>
          <button className=" btn text-white btn-primary " type={'button'} onClick={isCadastrarPage ? onclickCadastroNativo : onclickLoginNativo}>
            <i className="text-white border bg-white rounded"> <IconThotEmotion /></i>
          </button>
          <br />
          <small className='font-weight-bold'><b>Nativo</b></small>
        </div> &nbsp;
        <div className=' text-center justify-content-center '>
          <LoginAuthGoogle />
          {/* <LoginAuthFacebook />
        &nbsp;
        <LoginAuthGithub /> */}
          <br />
          <small className='font-weight-bold'><b>Google</b></small>
        </div>
      </div>
    </div>
    <div className='text-center mt-3'>
      {isCadastrarPage && <small className='font-weight-bold'><b> **Caso não seja brasileiro vincule outras contas como a google... !</b></small>}
    </div>
    <div className='mb-4'>
      <MaisInfoMenosInfo text={<CadastroAuthInfo />}></MaisInfoMenosInfo>
    </div>
  </>);
};
export default CadastroAuth;
import React, { useState } from "react";
import { InputPadraoSearch } from '@components';
import { MdFaceRetouchingNatural, MdImageSearch } from "react-icons/md";
import MainImagemIA from "./Classificacao/MainImagemIA";
import AnaliseFacial from "./AnaliseFacial/AnaliseFacial";
import { Card } from "antd";
import { TitleL2 } from "@components/text";
import Meta from "antd/es/card/Meta";
const cardClassificacaoDeImagens = () => <MainImagemIA />;
const cardAnaliseFacial = () => <AnaliseFacial />;
const cards = [
    { text: 'Análise Orofacial', icon: <MdFaceRetouchingNatural size={40} />, component: cardAnaliseFacial },
    { text: 'Classificação de Imagens', icon: <MdImageSearch size={40} />, component: cardClassificacaoDeImagens },
    // Adicione mais cards conforme necessário
];
export const Index = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [SelectedComponent, setSelectedComponent] = useState(null);
    const filteredCards = cards.filter(card => card.text.toLowerCase().includes(searchTerm.toLowerCase()));
    const handleCardClick = (Component) => {
        setSelectedComponent(() => Component);
    };
    return (<>
        {SelectedComponent ? (<>
            <div style={{ position: 'absolute', top: '140px', right: '5px', zIndex: '1000' }} >
                <button className="btn btn-danger" onClick={() => setSelectedComponent(null)}>Voltar</button>
            </div>
            <div className="component-container text-right" >
                <SelectedComponent />
            </div>
        </>) : (
            <div className="">
                <InputPadraoSearch
                    type="text"
                    placeholder="Filtre páginas"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="row d-inline-flex justify-content-center w-100">
                    {filteredCards.map((card, index) => (
                        <div
                            key={index}
                            className={`col-md-3 mt-2`}
                            onClick={() => handleCardClick(card.component)}
                            style={{ cursor: "pointer", paddingRight: '6px', paddingLeft: '6px' }}
                        >
                            <Card
                                className="rounded border no-drag bg-primary"
                                styles={{ body: { padding: '0' } }}
                                cover={
                                    <div className="bg-light py-1" style={{ padding: '0', borderRadius: '20px 20px 20px 20px' }}>
                                        <div className="text-center mt-1" style={{ padding: '0' }}>
                                            {card.icon}
                                        </div>
                                        <TitleL2
                                            style={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                display: '-webkit-box',
                                                minHeight: "24px",
                                                WebkitLineClamp: 1,
                                                WebkitBoxOrient: 'vertical',
                                                padding: '0'
                                            }}
                                        >
                                            {card.text}
                                        </TitleL2>
                                    </div>
                                }
                            >
                                <Meta
                                    title={
                                        <h4 className="text-white mt-1" style={{ padding: '0' }}>
                                        </h4>
                                    }
                                    style={{ padding: '0' }} // Remove o padding do Meta
                                />
                            </Card>
                        </div>
                    ))}
                </div>
            </div >
        )}
    </>);
};
export default Index;
import React, { useRef, useState } from 'react';
import { InputPadrao, InputPadraoSearch, TitleL2 } from '@components';
import CarrinhoVazio from './vazio/CarrinhoVazio';
import { Divider, Image, Select } from 'antd';
import { MdDeleteMD, MdMenuMD, MdSearchMD } from '@components/icons';
import { SelectAnt } from '@components/select';
const { Option } = Select;
export const ItemsNoCarrinho = ({
    cart,
    searchTerm,
    setSearchTerm,
    selectedType,
    handleTypeChange,
    types,
    currentItems,
    handlequantityChange,
    handleDeleteItem,
    pageButtons
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const buttonRef = useRef(null);
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    const parseImage = (image) => {
        try {
            return JSON.parse(image);
        } catch {
            return image;
        }
    };
    return (<>
        {cart.length === 0 ? (
            <CarrinhoVazio />
        ) : (<>
            <InputPadraoSearch
                id="inlineFormInputGroup"
                placeholder="Busca itens no carrinho..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                typeButton="button"
            />
            <div id="mostraMaisOpcoesBusca" className={`collapse align-items-center justify-content-center ${isExpanded ? 'show' : ''}`} style={{ padding: '0px' }}>
                <SelectAnt
                    title="Filtrar por tipo"
                    value={selectedType}
                    onChange={handleTypeChange}
                    options={types.map((type) => ({ value: type, label: type }))} // Ajuste no formato das opções
                />
            </div>
            <div className="col-md-12" style={{ padding: '10px' }}>
                <div className='d-flex flex-wrap '>
                    <div className={`input-group-append h-100 w-100 col-md-6`} style={{ padding: '0px', alignItems: 'center' }}>
                        <small className={`font-weight-bold ${isExpanded ? "text-danger " : 'text-primary'}`}
                            onClick={toggleExpand}
                            style={{ cursor: "pointer", border: 'none', background: 'none', padding: 0, margin: 0, fontFamily: ' Arial, Helvetica' }}                             >
                            <MdMenuMD />  {`${isExpanded ? 'menos opções de filtros' : 'mais opções de filtros'}`}<MdSearchMD className="flsip-horizontal" />
                        </small>
                    </div>
                </div>
            </div>
            <div className='col-md-6'>
                <div className='row'>
                    {currentItems.map((item, index) => {
                        const images = parseImage(item.image);
                        return (
                            <div className="col-md-12" key={index} style={{ padding: '0px' }}>
                                <div className="row rounded align-items-center">
                                    <div className='col-md-2 d-flex text-center justify-content-center '>
                                        {Array.isArray(images) ? (
                                            <Image
                                                className="w-100 rounded  image-fluid"
                                                height={50} width={50} src={images[0]?.url || ""}
                                                alt="Produtos services_imagem"
                                            />
                                        ) : (
                                            <Image
                                                className="w-100 rounded  image-fluid"
                                                height={50} width={50}
                                                src={images}
                                                alt="Produtos services_imagem"
                                            />
                                        )}
                                    </div>
                                    <div className='col-md-1 d-flex text-center justify-content-center '>
                                        <small className='font-weight-bold' style={{ padding: '0px' }}>
                                            <strong>
                                                {new Intl.NumberFormat("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                }).format(item.value)}
                                                &nbsp;
                                            </strong>
                                        </small>
                                    </div>
                                    <div className='col-md-3 text-center' style={{ padding: '0px', margin: '0px' }}>
                                        {item.products_name && (
                                            <small style={{
                                                padding: '0px',
                                                fontWeight: 600,
                                                overflow: 'hidden',
                                                marginBottom: '0px',
                                                textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical'
                                            }}>{item.products_name}
                                            </small>
                                        )}
                                        {item.services_name && (
                                            <small style={{
                                                padding: '0px',
                                                fontWeight: 600,
                                                overflow: 'hidden',
                                                marginBottom: '0px',
                                                textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical'
                                            }}>{item.services_name}
                                            </small>
                                        )}
                                        <p className="card-text" style={{
                                            overflow: 'hidden',
                                            width: '100%',
                                            textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical'
                                        }}>{item.description}</p>
                                    </div>
                                    <div className='col-md-2 mt-1 text-center'>
                                        {new Intl.NumberFormat("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        }).format(item.value * item.quantity)}
                                        &nbsp;<small style={{ textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical' }}>Total item </small>
                                    </div>
                                    <div className='col-md-2'>
                                        <InputPadrao
                                            title={'Quantidade'}
                                            className="w-100 mt-1 text-dark text-center"
                                            type="number"
                                            min="0"
                                            value={item.quantity}
                                            onChange={(event) =>
                                                handlequantityChange(index, event)
                                            }
                                        />
                                    </div>
                                    {item.options && (
                                        <div className="col-md-2">
                                            <InputPadrao
                                                title={'Opções'}
                                                className="col-md-4 mt-1 text-dark text-center"
                                            />
                                        </div>
                                    )}
                                    <div className="col-md-1 d-flex text-center justify-content-center align-items-center"><br />
                                        <button
                                            onClick={() => handleDeleteItem(index)}
                                            style={{ border: '0px', backgroundColor: 'none', color: 'none' }}
                                        >
                                            <MdDeleteMD color={"red"} />
                                        </button>
                                    </div>
                                </div>
                                <Divider style={{ padding: '0px', margin: '0px' }} />
                            </div>
                        );
                    })}
                </div>
                <div className="d-flex justify-content-center text-center mx-auto mb-3 mt-2">
                    {pageButtons}
                </div>
            </div>
        </>
        )}
    </>);
};
export default ItemsNoCarrinho;
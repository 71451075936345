import React from 'react';
import { Modal, Button } from 'antd';
export const ModalSimples = (props) => {
  return (
    <Modal
      visible={props.showModal}
      onCancel={props.handleClose}
      maskClosable={true} // Permite fechar ao clicar fora
      centered
      width="90vw" // Definindo 90% da largura da tela
      className="custom-modal"
      style={{ paddingTop: '4%', padding: '0px' }}
      footer={[
        <Button key="fechar" className="bg-danger" onClick={props.handleClose}>
          Fechar
        </Button>
      ]}    >
      {props.children}
    </Modal>
  );
};
export default ModalSimples;
import React, { useCallback, useEffect, useState } from 'react';
import { Input, Select, Button, Table, Avatar, Image } from 'antd';
import { MdOutlinePersonSearch } from 'react-icons/md';
import moment from 'moment';
import { TitleL3 } from '@components';
import { useCliente } from 'context';
import { getInitialsName } from 'functions';
import { api } from 'services';
import ClienteSelectView from './ViewClienteController';
import { useUser } from '@context/components/UserProvider';
import { MdAccountCircleMD } from '@components/icons';
const { Option } = Select;
export const UpdateClienteController = ({ title }) => {
  const { cliente, updateCliente, clearCliente } = useCliente();
  const [selectedOption, setSelectedOption] = useState({ value: 'Nome' });
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 50 });
  const { user } = useUser();
  const handleSearchChange = (e) => setSearchValue(e.target.value);
  const handleSelectChange = (value) => setSelectedOption({ value });
  const handlePageChange = (page, pageSize) => {
    setPagination({ ...pagination, current: page, pageSize });
    onSearch();
  };
  const { current, pageSize } = pagination;
  const onSearch = useCallback(async () => {
    const query = searchValue.trim();
    let searchParams = {
      pagina: current, // Usa a variável extraída
      resultadosPorPagina: pageSize, // Usa a variável extraída
    };
    if (selectedOption?.value === 'Nome' && query) {
      searchParams.nome = query;
    } else if (selectedOption?.value === 'CPF' && query) {
      searchParams.identificador = query;
    } else if (selectedOption?.value === 'Identificador' && query) {
      searchParams.unique_identification = query;
    }
    if (Object.keys(searchParams).length > 2) {
      try {
        const response = await api.get(`get_users/${user.id}`, { params: searchParams });
        setPagination({
          current: response.data.currentPage,
          pageSize: pageSize, // Mantém o valor extraído
          total: response.data.totalPacientes,
        });
        setData(response.data.pacientes);
      } catch (error) {
        console.error('Erro ao buscar os pacientes:', error);
      }
    } else {
      console.log("Nenhum parâmetro de busca válido foi fornecido.");
    }
  }, [
    searchValue,
    current, // Agora usa as propriedades desestruturadas
    pageSize, // Agora usa as propriedades desestruturadas
    selectedOption,
    user,
    setPagination,
    setData,
  ]);
  useEffect(() => {
    if (searchValue.trim() !== "") {
      const timer = setTimeout(() => {
        onSearch();
      }, 500); // Aguarda 500ms após a digitação para acionar a busca
      return () => clearTimeout(timer); // Limpa o timeout caso o searchValue mude
    }
  }, [searchValue, onSearch]); // Adicionamos onSearch nas dependências
  useEffect(() => {
    if (cliente) {
      const timer = setTimeout(() => {
        setData([])
      }, 1000); // Aguarda 500ms após a digitação para acionar a busca
      return () => clearTimeout(timer); // Limpa o timeout caso o searchValue mude
    }
  }, [cliente]); // O efeito será executado sempre que 'cliente' mudar
  return (<>
    <div  // Usa a chave para forçar o re-render 
      className='row w-100 bg-info' style={{ marginRight: '0px' }}>
      <div className=' py-1 col-md-6' style={{ borderRadius: '36px' }}>
        <TitleL3>{title} <MdOutlinePersonSearch size={28} /></TitleL3>
        <div style={{ marginBottom: 20 }}>
          <Select
            value={selectedOption?.value}
            onChange={handleSelectChange}
            style={{ width: 150, marginRight: 20 }}
          >
            <Option value="Nome">Nome</Option>
            <Option value="CPF">CPF</Option>
            <Option value="Identificador">Identificador</Option>
          </Select>
          <Input
            value={searchValue}
            onChange={handleSearchChange}
            placeholder="Digite o termo de busca"
            style={{ width: 250, marginRight: 20 }}
            suffix={
              <MdOutlinePersonSearch
                size={20}
                style={{ cursor: 'pointer' }}
                onClick={onSearch} // Ao clicar no ícone de busca, aciona a pesquisa
              />
            }
            onPressEnter={onSearch} // Ao pressionar Enter, aciona a pesquisa
          />
          <Button type="primary" style={{ margin: '10px', backgroundColor: 'green' }} onClick={onSearch}>
            <MdAccountCircleMD />
          </Button>
        </div>
        {data.length > 0 && (
          <Table
            rowKey="id"
            dataSource={data}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: pagination.total,
              onChange: handlePageChange,
            }}
            rowSelection={{
              type: 'radio',
              selectedRowKeys: cliente ? [cliente.id] : [],
              onChange: (_, selectedRows) => {
                updateCliente(selectedRows[0]); // Atualiza o localStorage ao selecionar um paciente
              },
            }}
            onRow={(record) => ({
              onClick: () => updateCliente(record), // Atualiza o localStorage ao clicar na linha
            })}
            columns={[
              {
                title: 'Foto',
                dataIndex: 'fotoFace',
                key: 'fotoFace',
                render: (text, record) => (
                  text ? (
                    <Image
                      src={text}
                      width={40}
                      height={40}
                      style={{ borderRadius: '50%', marginRight: '20px', objectFit: 'cover' }}
                    />
                  ) : (
                    <Avatar
                      src={null}  // Se não houver foto, usa null
                      alt="Imagem do Paciente"
                      size={50}
                      style={{
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.2)",
                        cursor: 'pointer',
                        zIndex: 1000
                      }}
                    >
                      {/* Caso não tenha foto, exibe as iniciais usando 'record.name' */}
                      {record?.name ? getInitialsName(record.name) : ''}
                    </Avatar>
                  )
                ),
              },
              {
                title: 'Nome',
                dataIndex: 'name',
                key: 'name',
              },
              {
                title: 'Data de Nascimento',
                dataIndex: 'birthdate',
                key: 'birthdate',
                render: (text) => moment(text).format('DD/MM/YYYY'),
              },
            ]}
          />
        )}
      </div>
      {cliente && <ClienteSelectView title={'Cliente selecionado'} cliente={cliente} clearCliente={clearCliente} />}
    </div>
  </>);
};
export default UpdateClienteController;
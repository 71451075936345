import { Index } from "./components/Index";
import { FooterProfissional, HeaderAnt, NavProfissionalMain } from "../../../../components";
export const ServicosProfissionais = () => {
    return (<>
        <HeaderAnt />
        <div className="d-flex mt-3">
            <NavProfissionalMain />
            <section className="w-100 ">
                <Index />
                <FooterProfissional />
            </section>
        </div>
    </>)
}
import React from 'react';
import { TitleL2 } from '@components';
import { MdCreditCardMD, MdPixMD } from '@components/icons';
import { FaBarcode  } from 'react-icons/fa';
export const FormaDePagamento = ({
    handleCheckbox1Change,
    checkbox1,
    handleCheckbox2Change,
    checkbox2,
    handleCheckbox3Change,
    checkbox3
}) => {
    return (
        <section>
            <div className='col-md-12 mt-5 mx-auto rounded py-1 justify-content-center align-items-center text-dark text-center' style={{ borderRadius: '20px' }}>
                <TitleL2>Forma de pagamento</TitleL2>
                <div className="row text-center mt-2">
                    <div className="text-dark col-md-4 d-flex py-3 mx-auto justify-content-center align-items-center">
                        <label>
                            <h5 className='text-center text-dark' >
                                <MdPixMD size={50} />
                                <h5 className='text-dark mt-3 font-weight-bold'> PIX</h5>
                                <input
                                    type="checkbox"
                                    checked={checkbox1}
                                    onChange={handleCheckbox1Change}
                                />
                            </h5>
                        </label>
                    </div>
                    <div className="d-flex text-dark py-3 col-md-4 mx-auto justify-content-center align-items-center">
                        <label>
                            <h5 className={checkbox2 ? 'text-center text-success' : 'text-center text-dark'}>
                                <FaBarcode size={50} className={checkbox2 ? ' mx-auto text-center text-success' : ' mx-auto text-center text-dark'} />
                                <h5 className='text-dark mt-1 font-weight-bold'> BOLETO</h5>
                                <input
                                    type="checkbox"
                                    checked={checkbox2}
                                    onChange={handleCheckbox2Change}
                                />
                            </h5>
                        </label>
                    </div>
                    <div className="d-flex text-dark py-3 col-md-4 mx-auto justify-content-center align-items-center">
                        <label>
                            <h5 className='text-center text-dark'>
                                <MdCreditCardMD size={50} className={checkbox3 ? 'mx-auto text-center text-success' : 'fa fa-credit-card fa-3x mx-auto text-center text-dark'} />
                                <h5 className='text-dark mt-1 font-weight-bold'> CARTÃO</h5>
                                <input
                                    type="checkbox"
                                    checked={checkbox3}
                                    onChange={handleCheckbox3Change}
                                />
                            </h5>
                        </label>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default FormaDePagamento;
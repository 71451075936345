import React from "react";
import {HeaderAnt, NavProfissionalMain, BreadcrumbAnt, FooterProfissional } from "@components";
import { Index } from "./components/Index";
export const IAImagem = () => {
  return (<>
    <HeaderAnt/>
    <div className="d-flex mt-3" style={{ overflowX: 'hidden' }}>
      <NavProfissionalMain />
      <section className='w-100'>
        <BreadcrumbAnt text={'I.A Imagem'} icon={'crosshairs fa'} />
        <div >
          <Index />
        </div>
        <FooterProfissional />
      </section>
    </div>
  </>);
};
export default IAImagem;
import { useState, useEffect } from "react";
export const useLandmark = () => {
  const [landmarks, setLandmarks] = useState(null);
  useEffect(() => {
    setLandmarks(JSON.parse(localStorage.getItem("landmarks")) || null);
  }, []);
  return {
    landmarks,
    setLandmarks,
  };
};
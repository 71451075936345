import { Index } from "./components/layout/Index";
import { FooterProfissional,HeaderAnt, NavProfissionalMain } from "../../../../components";
export const DashBoard = () => {
    return (<>
   <HeaderAnt/>
        <div className="d-flex mt-3" style={{overflowX:'hidden'}}>
            <NavProfissionalMain />
            <section className="w-100">
                <Index />
                <FooterProfissional />
            </section>
        </div>
    </>)
}
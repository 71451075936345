import React, { useContext, useEffect, useState } from 'react';
import {
    MaisInfoMenosInfo,
    TermoDeResponsabilidadeCadastro,
    HeaderAnt,
    SiderUserMain,
    BreadcrumbAnt,
    SelectAnt
} from '@components';
import { PerfilCadastroProfissionalInfo } from './info/PerfilCadastroProfissionalInfo';
import { CadastrarClinicaInstituicao } from '../EmpresaCadastrar/CadastroClinicaInstituicao';
import CadastrarProfissional from './components/CadastrarProfissionalMain';
import ProfissionalVinculado from './components/ProfissionalVinculado';
import { TitleL1 } from '@components/text';
import { AuthContext } from '@guard/index';
export const ProfissionalCadastrar = () => {
    const { authenticatedProfisional } = useContext(AuthContext);
    const [aceitouTermo, setAceitouTermo] = useState(true);
    const [selectedOption, setSelectedOption] = useState(null);
    const handleSelectChange = (value) => {
        setSelectedOption(value);
    };
    const options = [
        ...(!authenticatedProfisional ? [{ value: 'professional', label: 'Profissional' }] : []),
        ...(authenticatedProfisional ? [{ value: 'company', label: 'Clínica/Instituição' }] : []),
        ...(authenticatedProfisional ? [{ value: 'professional_unit', label: 'Profissional Vinculado' }] : []),
    ];
    useEffect(() => {
        localStorage.removeItem('cadastroProfissional');
    }, []);
    return (<>
        <HeaderAnt />
        <div className="d-flex mt-2">
            <SiderUserMain />
            <div className="w-100">
                <section>
                    <BreadcrumbAnt />
                    <div className="mx-auto">
                        <div className="form-group bg-light">
                            <TitleL1>Dados Profissional</TitleL1>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className=" col-md-3">
                                        <SelectAnt
                                            title='Tipo'
                                            style={{ width: '100' }}
                                            placeholder="Selecione uma opção"
                                            onChange={handleSelectChange}
                                            options={options}
                                        />
                                        <MaisInfoMenosInfo text={<PerfilCadastroProfissionalInfo />} />
                                    </div>
                                    {selectedOption === 'professional' && <CadastrarProfissional />}
                                    {selectedOption === 'company' && <CadastrarClinicaInstituicao />}
                                    {selectedOption === 'professional_unit' && <ProfissionalVinculado />}
                                </div>
                            </div>
                        </div>
                        <div className=" mt-5 text-center">
                            <TermoDeResponsabilidadeCadastro
                                aceitouTermo={aceitouTermo}
                                setAceitouTermo={setAceitouTermo}
                            />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </>
    );
};
export default ProfissionalCadastrar;
import React from 'react';
import { TitleL3 } from '@components';
import { Divider, Image } from 'antd';
import {
  DocumentosSoftwareAnaliseFacialVirtualReality,
  DocumentosSoftwareAnaliseFacialAnalisesFrontal,
  DocumentosSoftwareAnaliseFacialAnalisesLaterais,
  DocumentosSoftwareAnaliseFacialAnalisesDoSorriso,
  DocumentosSoftwareAnaliseFacialRefTeorica,
  DocumentosSoftwareAnaliseFacialLaudoGerado,
  DocumentosSoftwareAnaliseFacialAnalisesFrontalBravo,
  DocumentosSoftwareAnaliseFacialAnalisesFrontalSurpresa,
  DocumentosSoftwareAnaliseIntraOral
} from '.';
const scrollToElement = (id) => {
  const element = document.getElementById(id);
  console.log(`Tentando rolar para ${id}`); // Verifica se a função está sendo chamada
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};
export const DocumentosSoftwareAnaliseFacial = () => {
  return (
    <section>
      <div className=' text-justify'>
        <section>
          <TitleL3 id="DocumentosSoftwareAnaliseFacialdescricao">Descrição</TitleL3>
          <p>
            O projeto de <strong>Análise Orofacial</strong> da <strong>THOT I.A. CORP.</strong> é uma solução avançada que utiliza inteligência artificial
            para detectar e analisar características faciais.
            Este sistema permite a extração de pontos de referência faciais, avaliação e medidas de características estéticas,
            além de sugestões personalizadas para harmonização facial.
          </p>
          <p>
            À nossa solução inovadora de Análise Facial com I.A. também usa Realidade Virtual (VR) e Realidade Aumentada (AR) configurando uma Realidade Mista (RM),
            foi desenvolvida para fornecer uma avaliação detalhada e sugestões de harmonização baseadas em características e medidas reais.
          </p>
        </section>
        <TitleL3 id="material-visual">Material visual</TitleL3>
        <p>
          Todo o material audiovisual de ponta pronto para você! Nossa empresa preparou conteúdos exclusivos para profissionais que utilizam nossa ferramenta compartilhar diretamente o material com seus clientes facilitando as suas vendas !
        </p>
        <div className='col-md-2'>Story</div>
        <div className='row text-center'>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/p1.png')} alt="Exemplo 3" width={50} height={70} />
            <br /><small>1</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/p2.png')} alt="Exemplo 5" width={50} height={70} />
            <br /><small>2</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/p3.png')} alt="Exemplo 3" width={50} height={70} />
            <br /><small>3</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/p4.png')} alt="Exemplo 3" width={50} height={70} />
            <br /><small>4</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/p5.png')} alt="Exemplo 3" width={50} height={70} />
            <br /><small>5</small>
          </div>
        </div>
        <div className='col-md-2'>Post</div>
        <div className='row text-center'>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/pf1.png')} alt="Exemplo 3" width={50} height={50} />
            <br /><small>1</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/pf2.png')} alt="Exemplo 5" width={50} height={50} />
            <br /><small>2</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/pf3.png')} alt="Exemplo 3" width={50} height={50} />
            <br /><small>3</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/pf4.png')} alt="Exemplo 3" width={50} height={50} />
            <br /><small>4</small>
          </div>
          <div className='col-md-1 border py-1' style={{ padding: '0px' }}>
            <Image src={require('../assets/pf5.png')} alt="Exemplo 3" width={50} height={50} />
            <br /><small>5</small>
          </div>
        </div>
        <section>
          <TitleL3 id="tabela-de-conteudos" >Tabela de Conteúdos</TitleL3>
          <li>Análise Orofacial com IA e RM</li>
          <ul className='text-primary text-justify'>
            <ul className='ml-1 text-primary text-justify'>
              <li className='hover-bold' onClick={(e) => { e.stopPropagation(); scrollToElement("DocumentosSoftwareAnaliseFacialdescricao"); }}>Descrição</li>
              <li className='hover-bold' onClick={(e) => { e.stopPropagation(); scrollToElement("material-visual"); }}>Material Visual</li>
              <li className='hover-bold' onClick={(e) => { e.stopPropagation(); scrollToElement("tabela-de-conteudos"); }}>Tabela de Conteúdos</li>
              <li className='hover-bold' onClick={(e) => { e.stopPropagation(); scrollToElement("DocumentosSoftwareAnaliseFacialRecursos"); }}>Recursos
                <ul className='ml-2'>
                  <ul className='text-dark'>  Face</ul>
                  <li className='hover-bold' onClick={(e) => { e.stopPropagation(); scrollToElement("DocumentosSoftwareAnaliseFacialAnalisesFrontal"); }}>Análise Frontal com Expressão Neutra</li>
                  <li className='hover-bold' onClick={(e) => { e.stopPropagation(); scrollToElement("DocumentosSoftwareAnaliseFacialVirtualReality"); }}>Análise Frontal com Expressão de Realidade Virtual (VR)</li>
                  <li className='hover-bold' onClick={(e) => { e.stopPropagation(); scrollToElement("DocumentosSoftwareAnaliseFacialAnalisesFrontalSurpresa"); }}>Análise Frontal com Expressão de Surpresa</li>
                  <li className='hover-bold' onClick={(e) => { e.stopPropagation(); scrollToElement("DocumentosSoftwareAnaliseFacialAnalisesFrontalBravo"); }}>Análise Frontal com Expressão Brava</li>
                  <li className='hover-bold' onClick={(e) => { e.stopPropagation(); scrollToElement("DocumentosSoftwareAnaliseFacialAnalisesDoSorriso"); }}>Análises do Sorriso</li>
                  <li className='hover-bold' onClick={(e) => { e.stopPropagation(); scrollToElement("DocumentosSoftwareAnaliseFacialAnalisesLaterais"); }}>Análises Laterais</li>
                  <li className='hover-bold' onClick={(e) => { e.stopPropagation(); scrollToElement("DocumentosSoftwareAnaliseFacialRefTeorica"); }}>Ref. Teórica</li>
                  <ul className='text-dark'>  Intraoral</ul>
                </ul>
              </li>
              <li className='hover-bold' onClick={(e) => { e.stopPropagation(); scrollToElement("DocumentosSoftwareAnaliseFacialRefTeorica"); }} href="#contribuicoes">Contribuições</li>
              <li className='hover-bold' onClick={(e) => { e.stopPropagation(); scrollToElement("DocumentosSoftwareAnaliseFacialRefTeorica"); }} href="#licenca">Licença</li>
              <li className='hover-bold' onClick={(e) => { e.stopPropagation(); scrollToElement("contato-suporte-tec"); }} >Suporte Técnico</li>
            </ul>
          </ul>
        </section>
        <section>
          <TitleL3 id="DocumentosSoftwareAnaliseFacialRecursos">Recursos</TitleL3>
          <DocumentosSoftwareAnaliseFacialAnalisesFrontal />
          <DocumentosSoftwareAnaliseFacialVirtualReality />
          <DocumentosSoftwareAnaliseFacialAnalisesFrontalSurpresa />
          <DocumentosSoftwareAnaliseFacialAnalisesFrontalBravo />
          <DocumentosSoftwareAnaliseFacialAnalisesDoSorriso />
          <DocumentosSoftwareAnaliseFacialAnalisesLaterais />
          <DocumentosSoftwareAnaliseIntraOral />
          <DocumentosSoftwareAnaliseFacialRefTeorica />
          <DocumentosSoftwareAnaliseFacialLaudoGerado />
        </section>
        <section>
          <TitleL3 id="como-usar">Como usar</TitleL3>
          <Divider style={{ margin: '0px', borderColor: 'black', borderWidth: '3px', marginBottom: '10px' }} />
          <ol>
            <li>
              <strong>Submeta uma imagem</strong>: Envie uma imagem clara do seu rosto nos formatos JPG ou PNG.
            </li>
            <li>
              <strong>Aguarde a análise</strong>: Visualize os resultados.
            </li>
            <li>
              <strong>Interaja com a Análise</strong>: <a href="http://localhost:8484/analisar">Visualizar Análise</a>
            </li>
          </ol>
        </section>
        <footer>
          <TitleL3 id="contribuicoes">Contribuições</TitleL3>
          <Divider style={{ margin: '0px', borderColor: 'black', borderWidth: '3px', marginBottom: '10px' }} />
          <p>Contribuições são bem-vindas! Sinta-se à vontade para abrir participar do nosso grupo de profissionais e dar sugestões </p>
          <li>
            <a className='text-primary' href="https://chat.whatsapp.com/L5A95MXz8St2oabZj5AesC" target="_blank" rel="noopener noreferrer">
              WhatsApp Grupo de Profissionais
            </a>
          </li>
          <TitleL3 id="licenca">Licença</TitleL3>
          <Divider style={{ margin: '0px', borderColor: 'black', borderWidth: '3px', marginBottom: '10px' }} />
          <div style={{ backgroundColor: '#fff3cd', padding: '10px', borderRadius: '5px', marginBottom: '10px' }}>
            <strong>⚠️ IMPORTANTE:</strong> Leia antes de começar
            <p>Este projeto é privado para usar como API no seu sistema chame a equipe de suporte</p>
          </div>
          <TitleL3 id="contato-suporte-tec">Suporte Técnico</TitleL3>
          <Divider style={{ margin: '0px', borderColor: 'black', borderWidth: '3px', marginBottom: '10px' }} />
          <ul>
            <li>
              <a className='text-primary' href="https://discord.gg/Ugpz8fD45v" target="_blank" rel="noopener noreferrer">
                Discordy Suporte Técnico Cliente
              </a>
            </li>
            <li>
              <a className='text-primary' href="https://api.whatsapp.com/send?phone=5566996675486&text=Estou%20entrando%20em%20contato%20para%20" target="_blank" rel="noopener noreferrer">
                WhatsApp Suporte Técnico Cliente
              </a>
            </li>
          </ul>
        </footer>
      </div >
    </section >
  );
};
export default DocumentosSoftwareAnaliseFacial;
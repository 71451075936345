import React, { useState, useRef } from 'react';
import { Card, Upload, Button, message, Image } from 'antd';
import { MdCamera, MdCloudUpload, MdDelete, MdCheckCircle, MdEdit } from "react-icons/md";
import Webcam from 'react-webcam';
// import FaceVideoRecorder from './FaceVideoRecorder'; // Import the video recorder component
import { apiToken } from '@services';
import { post_analise_facial } from '../services/post_analise_facial';
import { ViewPostFacePhotoCollectorMaisInfo } from './ViewPostFacePhotoCollectorMaisInfo';
export const PostFacePhotoCollector = ({
  currentType,
  setCurrentType,
  setLandmarks,
  setModifiedLandmarks,
  photos,
  setPhotos,
  thumbnail,
  setThumbnail,
  setApiResults,
  setApiImages,
  setOthers,
  piscaMaisInfo,
  highlightStates,
  setLoading,
  serviceControl,
  setServiceControl
}) => {
  const [capturing, setCapturing] = useState(false);
  const webcamRef = useRef(null);
  const [uploadCount, setUploadCount] = useState({});
  const handleCapture = (type) => {
    setCurrentType(type);
    setCapturing(true);
  };
  const capturePhoto = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        setThumbnail((prev) => ({ ...prev, [currentType]: imageSrc }));
        setPhotos((prev) => ({ ...prev, [currentType]: imageSrc }));
        setCapturing(false);
        setCurrentType(null);
      }
    } else {
      message.error('A câmera não está disponível.');
    }
  };
  // const handleVideoBlob = (blob) => {
  //   setVideoBlob(blob);
  // };
  const handleDelete = (type) => {
    setThumbnail((prev) => {
      const newState = { ...prev };
      delete newState[type];
      return newState;
    });
    setPhotos((prev) => {
      const newState = { ...prev };
      delete newState[type];
      return newState;
    });
  };
  const handleTypeClick = (type) => {
    setCurrentType(type);
  };
  const FacePhotoCollectorCard = (title, type) => (
    <Card title={title} id={`card_id_${type}`} style={{
      border: highlightStates[type] ? '2px solid red' : 'none',
      borderRadius: '5px',
      boxShadow: highlightStates[type] ? '0 0 10px rgba(255, 0, 0, 0.5)' : 'none',
      marginBottom: 16
    }} >
      {thumbnail[type] && (
        <div style={{ marginBottom: 16, display: 'flex' }}>
          <Image
            src={thumbnail[type]}
            alt={`${type}`}
            style={{ width: 80, height: 80, objectFit: 'cover' }}
          />
          <Button
            icon={<MdDelete style={{ color: 'red', }} />}
            onClick={() => handleDelete(type)}
            style={{ border: 'solid 1px', margin: '4px' }}
            type="danger"
          >
          </Button>
          <Button
            onClick={() => handleTypeClick(type)}
            icon={<MdEdit />}
            style={{ border: 'solid 1px', margin: '4px' }}
            type="danger"
          >
          </Button>
        </div>
      )}
      <Upload
        customRequest={({ file, onSuccess, onError }) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            setThumbnail((prev) => ({ ...prev, [type]: e.target.result }));
            setPhotos((prev) => ({ ...prev, [type]: file }));
            onSuccess();
          };
          reader.onerror = (error) => {
            onError(error);
            message.error('Erro ao carregar a imagem.');
          };
          reader.readAsDataURL(file);
        }}
        showUploadList={false}
        accept="image/*"
        style={{ width: '100%' }}
      >
        <Button style={{ width: '100%' }} icon={<MdCloudUpload />}>Upload</Button>
      </Upload>
      <Button style={{ width: '100%' }} icon={<MdCamera />} onClick={() => handleCapture(type)}>
        Capturar com Câmera
      </Button>
      <Button
        type="primary"
        onClick={() => post_analise_facial(
          setCurrentType,
          setLandmarks,
          setModifiedLandmarks,
          photos,
          setApiResults,
          setApiImages,
          setOthers,
          setLoading,
          type,
          apiToken,
          setUploadCount,
          serviceControl,
          setServiceControl
        )}
        style={{ marginTop: 8 }}
      >
        Enviar Arquivo
      </Button>
      {uploadCount[type] > 0 && (
        <div style={{ marginTop: 8, color: 'green' }}>
          <MdCheckCircle style={{ marginRight: 4 }} />
          {uploadCount[type]} envio(s) já realizado(s) para {title}.
        </div>
      )}
      {ViewPostFacePhotoCollectorMaisInfo(type, piscaMaisInfo)}
    </Card>
  );
  return (
    <div>
      {capturing && (
        <div>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width="100%"
            height="auto"
          />
          <Button onClick={capturePhoto} style={{ marginTop: 16 }}>
            Capturar Foto
          </Button>
        </div>
      )}
      <div className='row bg-light' style={{ padding: '10px', margin: '10px' }}>
        <p className='col-md-12 text-left'>Capturas da Face</p>
        {/* <div className='col-md-3'><FaceVideoRecorder piscaMaisInfo={piscaMaisInfo} onVideoBlob={handleVideoBlob} /></div> */}
        <div className='col-md-3'>{FacePhotoCollectorCard('Frontal Neutro', 'FaceFrontal')}</div>
        <div className='col-md-3'>{FacePhotoCollectorCard('Frontal Realidade Virtual', 'FaceFrontalAR')} </div>
        <div className='col-md-3'>{FacePhotoCollectorCard('Frontal Surpresa', 'FaceSurpresa')}</div>
        <div className='col-md-3'>{FacePhotoCollectorCard('Frontal Bravo', 'FaceBravo')}</div>
        <div className='col-md-3'>{FacePhotoCollectorCard('Frontal Sorrindo', 'FaceSorrindo')}</div>
        <div className='col-md-3'>{FacePhotoCollectorCard('Lateral Esquerda', 'FaceEsquerda')}</div>
        <div className='col-md-3'>{FacePhotoCollectorCard('Lateral Direita', 'FaceDireita')}</div>
        <p className='col-md-12 text-left'>Capturas da Boca</p>
        <div className='col-md-3'>{FacePhotoCollectorCard('Superior', 'BocaSuperior')}</div>
        <div className='col-md-3'>{FacePhotoCollectorCard('Inferior', 'BocaInferior')}</div>
        <div className='col-md-3'>{FacePhotoCollectorCard('Frontal Fechada', 'BocaFrontalFechada')}</div>
        <div className='col-md-3'>{FacePhotoCollectorCard('Lateral Esquerda', 'BocaFechadaEsquerda')}</div>
        <div className='col-md-3'>{FacePhotoCollectorCard('Lateral Direita', 'BocaFechadaDireita')}</div>
        <p className='col-md-12 text-left'>Raio-X</p>
        <div className='col-md-3'>{FacePhotoCollectorCard('Panorâmica', 'Panoramica')}</div>
        <div className='col-md-3'>{FacePhotoCollectorCard('Cefalométrica', 'Cefalometrica')}</div>
      </div>
    </div>
  );
};
export default PostFacePhotoCollector;
import React, { useState, useEffect, } from 'react';
import { apiToken } from '@services';
import { ButtonAPI, MaisInfoMenosInfo } from '@components';
import { CadastrarPhotoProfissionalInfo } from '../info/CadastrarPhotoProfissionalInfo';
import CarouselFotoFace from '@pages/Private/Usuarios/Cadastro/components/usuario/components/CarouselFotoFace';
import { useProfisional } from 'context';
import { useUser } from '@context/components/UserProvider';
export const CadastrarPhotoProfissional = () => {
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [imageUpdated, setImageUpdated] = useState(false);
  const { user, setUser } = useUser();
  const { profissional } = useProfisional()
  const routeGetSendSingleImage = (`buscarFotoFaceProfissionalAll/${user.id}/photos`);
  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setImageName(file.name);
    };
    reader.readAsDataURL(file);
  };
  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setImageName(file.name);
    };
    reader.readAsDataURL(file);
  };
  const handleTakePictureClick = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      const video = document.createElement('video');
      video.srcObject = stream;
      video.onloadedmetadata = () => {
        video.play();
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        const picture = canvas.toDataURL('image/png');
        setImage(picture);
        stream.getTracks().forEach(track => track.stop()); // Stop the camera stream
      };
    } catch (error) {
      console.error(error);
    }
  };
  const handleSendImageProfissional = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('id', `${user.id}`);
      formData.append('professional_id', `${profissional.id}`);
      const extension = image.split(';')[0].split('/')[1];
      const fileName = `image.${extension}`;
      const blob = dataURItoBlob(image);
      formData.append('file', blob, fileName);
      await apiToken().post(`professionalFace/${user.id}`, formData);
      alert('Imagem enviada com sucesso!');
    } catch (error) {
      console.error(error);
      alert('Erro ao enviar a imagem.');
    } finally {
      setIsLoading(false);
    }
  };
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  };
  const [isLoading, setIsLoading] = useState(false);
  const fetchImage = async ({ user, setImage }) => {
    try {
      const response = await apiToken().get(`buscarFotoFaceProfissional/${user.id}/photo`, {
        responseType: "blob", // Indica que a resposta é um arquivo blob
      });
      const imageUrl = URL.createObjectURL(response.data);
      setImage(imageUrl);
    } catch (error) {
      console.error("Erro ao buscar a imagem do usuário:", error);
    }
  };
  useEffect(() => {
    fetchImage({ user, setImage });
  }, [user.id, imageUpdated]); // Mantém apenas dependências essenciais
  return (<>
    <div className="container">
      <div className="row">
        <div className="d-flex justify-content-center col-md-12 text-center" >
          <div
            className="dropzone py-5 bg-white rounded TridimencionalTotal  col-md-12"
            onDrop={(e) => handleDrop(e)}
            onDragOver={(e) => e.preventDefault()}
          >
            {image && (<>
              <img className="d-block  rounded-circle w-50 h-50 mx-auto "
                src={image}
                alt="Imagem"
                style={{ maxHeight: '50vh', maxWidth: '50vw', boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.2)", }} />
              <p className='collapse'>{imageName}</p>
              <CarouselFotoFace
                tradeKey={'cadastrarFotoFace'}
                routeGetSendSingleImage={routeGetSendSingleImage}
                imageUpdated={imageUpdated}
                onImageUpdated={() => setImageUpdated(null)}
              />
            </>)}
            Solte a imagem aqui ou <br />
            <div className='col-md-12 justify-content-center col-md-12 align-items-center mt-3'
              style={{
                overflow: 'hidden',         /* Esconde o conteúdo que ultrapassa o espaço disponível */
                textOverflow: 'ellipsis',  /* Adiciona "..." quando o texto é cortado */
              }} >
              <input className='mr-2 mx-auto'
                type="file"
                onChange={(e) => handleFileSelect(e)} />
              <button className='btn btn-primary' onClick={handleTakePictureClick}> <i className=' fa fa-camera'></i></button>
            </div>
            <ButtonAPI isLoading={isLoading} onClick={handleSendImageProfissional}> Enviar Foto</ButtonAPI>
          </div>
        </div>
      </div>
    </div>
    <div className='col-md-12 text-center'>
      <MaisInfoMenosInfo text={<CadastrarPhotoProfissionalInfo />}></MaisInfoMenosInfo>
    </div>
  </>)
}
export default CadastrarPhotoProfissional;
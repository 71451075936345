import React from 'react';
import { Form, Input, Button, Divider } from 'antd';
import { TitleL1, TitleL2 } from '@components';
import { ButtonCadastrar } from '@components/button';
const { TextArea } = Input;
const Fornecedores = () => {
    const onFinish = (values) => {
        console.log('Valores do formulário:', values);
        // Aqui você pode enviar os valores do formulário para o backend ou fazer outra ação
    };
    return (<>
        <section style={{ minHeight: '95dvh' }}>
            <div className='row'>
                <div className='col-md-6'  >
                    <TitleL1>Fornecedores Dropshipping</TitleL1>
                    <h5 className='text-justify'>
                        Conectar-se conosco abre um mundo de possibilidades desde seu e-commerce ao nosso serviço de dropshipping que pode trazer uma série de benefícios
                        significativos que impulsionarão o sucesso do seu negócio.
                        Em primeiro lugar, nossa parceria permitirá que você expanda instantaneamente
                        seu carteira de clientes sem a necessidade de investimento adicional
                        <br /><br />
                        Estamos falando de além de vender de forma automática conquistar revendedores também de forma automática. Para começar basta cadastrar seus produtos na plataforma.
                        <br /><br />
                        Caso você tenha um ERP [ sistema de gerenciamento ] e ou site e queira interconectar-se de forma mais fluida responda o formulário que nossa equipe entrará em contato
                    </h5>
                    <ButtonCadastrar />
                </div>
                <div className='col-md-6 text-center d-flex  flex-wrap justify-content-center mx-auto align-items-center'>
                    <div className='text-justify'>
                        <img className="d-block image-fluid mx-auto col-md-12"
                            style={{ maxHeight: '450px', maxWidth: '450px' }}
                            src={require('../assets/Fornecedores.png')} alt='Imagem de lâmpada moderna para representar a ideia e o desenvolvimento' />
                        <small className='mt-5 font-weight-bold'><strong>Programa de parceria recomendado para:</strong></small>
                        <ul                        >
                            <small>
                                <li>✅ Produtores</li>
                                <li>✅ Industrias</li>
                                <li>✅ E-commerces</li>
                            </small>
                        </ul>
                    </div>
                </div>
            </div>
            <Form
                name="Fornecedores-regional-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                layout="vertical"
                style={{ padding:'0px' }}
                className='border bg-light py-2 rounded text-center'
            >
                <TitleL2>Dados de Interconexões</TitleL2>
                <Divider />
                <div className='mr-2 ml-2'>
                    <Form.Item
                        label="ERP e ou Site "
                             name="site"
                        rules={[{ required: true, message: 'Por favor, insira seu site!' }]}
                    >
                        <Input placeholder="Nome do ERP e ou Site "
                       />
                    </Form.Item>
                    <Form.Item label="Mensagem" name="message">
                        <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Enviar
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </section>
    </>);
};
export default Fornecedores;
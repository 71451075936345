import { useLocation } from 'react-router-dom';
import { CardDirecionamento, FaResearchgateFA, MdRoomServiceMD, MdWidgetsMD } from 'components';
export const BtnPulbicCarrinhoVazio = () => {
  const location = useLocation();
  const isCarrinhoPage = location.pathname.startsWith("/carrinho");
  const { search } = location;
  return (<>
    <div className='col-md-12'>
      <div className="row  justify-content-center w-100">
        <CardDirecionamento href={isCarrinhoPage ? `../servicos${search}` : `../usuario/servicos/comprar${search}`} title={'História'} text={'Serviços'} icon={<MdRoomServiceMD size={50} />} />
        <CardDirecionamento href={isCarrinhoPage ? `../produtos${search}` : `../usuario/produtos/comprar${search}`} title={'História'} text={'Produtos'} icon={<MdWidgetsMD size={50} />} />
        <CardDirecionamento href={isCarrinhoPage ? `../artigos${search}` : `../artigos${search}`} title={'História'} text={'Artigos'} icon={<FaResearchgateFA size={50} />} />
      </div>
    </div>
  </>);
};
export default BtnPulbicCarrinhoVazio;
import React, { useCallback, useEffect, useState } from 'react';
import { AutoComplete, Form, Button } from 'antd';
import { MdBarcodeReader } from 'react-icons/md';
import { ImageDragDrop, MaisInfoMenosInfo, InputPadrao, DragDropPdf } from 'components';
import CalculadoraTaxas from './CalculadoraTaxas';
import BarcodeScanner from './BarcodeScanner';
import { api, apiToken } from '@services';
import { useProfisional } from 'context';
import MarkdownEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import MarkdownIt from "markdown-it";
import { useForm } from 'antd/es/form/Form';
import apiTokenProfissional from '@services/apiTokenProfissional';
const mdParser = new MarkdownIt();
export const CadastrarProdutoUnitario = () => {
  const { profissional } = useProfisional();
  const [form] = useForm();
  const [productOptions, setProductOptions] = useState([]);
  const [showBarcodeScanner, setShowBarcodeScanner] = useState(false);
  const [markdownContent, setMarkdownContent] = useState(''); // Para o conteúdo do Markdown
  const [fileList, setFileList] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);
  useEffect(() => {
    api.get('get_products')
      .then(({ data }) => setProductOptions(data.produtos || []))
      .catch((error) => console.error('Erro ao buscar produtos:', error));
  }, []);
  useEffect(() => {
    const { value_base, productValueLucro } = form.getFieldsValue();
    const custo = parseFloat(value_base);
    const lucroPercentual = parseFloat(productValueLucro);
    if (!isNaN(custo) && !isNaN(lucroPercentual)) {
      form.setFieldsValue({
        value: (custo + custo * (lucroPercentual / 100)).toFixed(2),
      });
    }
  }, [form]);
  const handleSubmit = async (values) => {
    const payload = new FormData();
    if (pdfFile) {
      payload.append("pdfs", pdfFile);
    }
    fileList.forEach((file) => {
      if (file.originFileObj) {
        payload.append("images", file.originFileObj);
      }
    });
    Object.keys(values).forEach((key) => {
      if (key === "creation_date") {
        payload.append(key, values[key].toISOString());
      } else {
        payload.append(key, values[key]);
      }
    });
    try {
      await apiTokenProfissional().post(`post_products/${profissional.id}`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Produto criado com sucesso!');
    } catch (error) {
      console.error('Erro ao criar o produto:', error);
    }
  };
  const fetchProductNCM = useCallback(async () => {
    const { productInfo } = form.getFieldsValue();
    if (!profissional.id || !productInfo?.Ncm) return;
    try {
      const { data } = await apiToken().get(`get_products_ncm_info/${profissional.id}/${productInfo.Ncm}`);
      if (data && data.length > 0) {
        form.setFieldsValue({ productType: data.map(item => item.descricao).join(' ') });
      }
    } catch (error) {
      console.error('Erro ao buscar NCM:', error);
    }
  }, [form, form.getFieldsValue, profissional.id]);
  const handleScanSuccess = useCallback(async (result) => {
    const { productInfo } = form.getFieldsValue();
    if (!productInfo) return;
    form.setFieldsValue({
      products_name: productInfo.Nome || '',
      productNcm: productInfo.Ncm || '',
    });
    await fetchProductNCM();
  }, [fetchProductNCM, form]);
  return (
    <div className="col-md-12">
      <Form onFinish={handleSubmit} form={form} className="mt-3">
        <div className="row">
          <div className="text-center col-md-6 d-flex align-items-center">
            <div className="col-md-12">
              <MdBarcodeReader
                size={100}
                color="#007bff"
                onClick={() => setShowBarcodeScanner(!showBarcodeScanner)}
                style={{ cursor: 'pointer' }}
              />
              <p>Leitor de Código de Barras</p>
              <p className="mt-2">
                <MaisInfoMenosInfo text="Use o leitor de código de barras ou o autocomplete para preencher rapidamente os detalhes do produto." />
              </p>
            </div>
            {showBarcodeScanner && (
              <BarcodeScanner
                setScannedQRCode={(value) => form.setFieldsValue({ scannedQRCode: value })}
                productInfo={form.getFieldValue('productInfo')}
                setProductInfo={(info) => form.setFieldsValue({ productInfo: info })}
                onScanSuccess={handleScanSuccess}
              />
            )}
          </div>
          <div className="col-md-6">
            <ImageDragDrop fileList={fileList} setFileList={setFileList} />
            <small className="font-weight-bold"><b>Manual / Bula</b></small>
            <Form.Item label="Arquivo de PDF Informação Serviço" name="informacao_pdf">
              <DragDropPdf onPdfUpload={setPdfFile} />
            </Form.Item>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row">
            {['products_name', 'scannedQRCode', 'productNcm', 'productType'].map((key) => (
              <div className="col-md-3" key={key}>
                <small className="font-weight-bold">
                  <strong>
                    {key === 'scannedQRCode' && 'Código de Barras'}
                    {key === 'productNcm' && 'NCM'}
                    {key === 'productType' && 'Tipo de Produto'}
                    {key === 'products_name' && 'Nome do Produto'}
                  </strong>
                </small><br />
                <Form.Item
                  name={key}
                  rules={[{ required: true, message: 'Por favor, insira o nome do produto.' }]}
                >
                  <AutoComplete
                    className="w-100"
                    placeholder={
                      key === 'scannedQRCode' ? 'Digite ou escaneie o código de barras' :
                        key === 'productNcm' ? 'Digite o NCM' :
                          key === 'productType' ? 'Selecione o tipo de produto' :
                            'Nome do produto'
                    }
                    value={form.getFieldValue(key) || ''}
                    onChange={(value) => form.setFieldsValue({ [key]: value })}
                    options={productOptions.map((p) => ({ value: p[key] }))}
                    required
                  />
                </Form.Item>
              </div>
            ))}
            {['value_base', 'productValueLucro', 'value', 'products_stock'].map((key) => (
              <div className="col-md-3" key={key}>
                <small className="font-weight-bold">
                  <strong>
                    {key === 'value_base' && 'Valor de compra'}
                    {key === 'productValueLucro' && '% de lucro desejado'}
                    {key === 'value' && 'Valor de venda'}
                    {key === 'products_stock' && 'Quantidade estoque'}
                  </strong>
                </small><br />
                <Form.Item
                  name={key}
                  rules={[{ required: true, message: 'Por favor, insira o nome do produto.' }]}
                >
                  <InputPadrao
                    type="number"
                    placeholder={key.replace('product', '')}
                    value={form.getFieldValue(key) || ''}
                    onChange={(e) => form.setFieldsValue({ [key]: e.target.value })}
                    required={key !== 'productValueLucro'}
                  />
                </Form.Item>
              </div>
            ))}
          </div>
        </div>
        <MaisInfoMenosInfo
          text={<CalculadoraTaxas productType={form.getFieldValue('products_type')} productValue={form.getFieldValue('value')} />}
        />
        <Form.Item
          name="products_description"
          rules={[{ required: true, message: 'Por favor, insira a descrição do produto.' }]}
        >
          <MarkdownEditor
            value={markdownContent}
            style={{ height: '400px' }}
            renderHTML={(text) => mdParser.render(text)}
            onChange={({ text }) => {
              setMarkdownContent(text);
              form.setFieldsValue({ products_description: text });
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" size="large">
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default CadastrarProdutoUnitario;
import React from 'react';
import { ButtonCadastrar, CardFragmentIdentifier, TitleL1, TitleL3, } from "components";
import {
    MdCreditScore,
    MdDeveloperMode,
    MdOutlineDiversity2,
    MdContentPasteSearch,
    MdOutlineAddShoppingCart
} from "react-icons/md";
export const ApresentacaoParcerias = () => {
    return (<>
        <section style={{ minHeight: '95dvh' }}>
            <div>
                <div className='row'>
                    <div className='col-md-6'>
                        <TitleL1>Parcerias de Sucesso</TitleL1>
                        <h5 className=" text-dark text-justify ">
                            A THOT I.A CORP. é uma empresa inclusiva.
                            Construímos um futuro compartilhado,
                            fortalecendo parcerias estratégicas e trocando ações por oportunidades reais.
                            Aqui, damos asas ao crescimento, abrindo portas para uma variedade de oportunidades. Junte-se a nós para uma jornada de inclusão e descoberta, onde cada interação é uma chance de prosperar juntos
                            <br /><br />Realizamos desde programas de mentoria e treinamento até acesso exclusivo a recursos de ponta,
                            estamos empenhados em criar um ambiente onde o potencial individual se transforma em sucesso coletivo. No entanto,
                            os benefícios verdadeiros só são realmente percebidos quando você dá o primeiro passo. Por isso, convidamos você a
                            quebrar as barreiras e nos procurar. Estamos aqui para orientar, apoiar e capacitar você em sua jornada rumo ao sucesso.
                            Então - junte-se a nós e descubra o que o futuro reserva para você
                        </h5>
                    </div>
                    <div className='col-md-6 text-center d-flex  flex-wrap justify-content-center mx-auto align-items-center'>
                        <p className='text-center col-md-12'>
                            <img className="d-block image-fluid mx-auto col-md-12"
                                style={{ maxHeight: '280px', maxWidth: '580px' }}
                                src={require('../assets/ApresetacaoParcerias.png')} alt='Imagem de lâmpada moderna para representar a ideia e o desenvolvimento' />
                            <small className='font-weight-bold'><b>Conquiste sua permissividade! Te ajudamos a expandir seus limites! Prospere conosco!</b></small>
                        </p>
                    </div>
                </div>
                <div className='col-md-6' style={{padding:'5px'}}>
                    <TitleL3 className='mt-5'>⏣ Tipos de parcerias</TitleL3>
                </div>
                <div className='mb-5 d-flex flex-wrap justify-content-center '>
                    <CardFragmentIdentifier href={'representacao'} title={'Rep. Comercial'} text={'Construa sua renda passiva eterna'} icon={<MdCreditScore style={{ marginTop: '28px', scale: '4' }} />} />
                    <CardFragmentIdentifier href={'SoftwareHouse'} title={'Software    House'} text={'Desenvolva seus projetos conosco'} icon={<MdDeveloperMode style={{ marginTop: '28px', scale: '4' }} />} />
                    <CardFragmentIdentifier href={'Pesquisadores'} title={'Pesquisadores'} text={'Revise ou publique artigos'} icon={<MdContentPasteSearch style={{ marginTop: '28px', scale: '4' }} />} />
                    <CardFragmentIdentifier href={'fornecedores'} title={'Fornecedores'} text={'E-commerces, distribuidoras e afins'} icon={<MdOutlineAddShoppingCart style={{ marginTop: '28px', scale: '4' }} />} />
                    <CardFragmentIdentifier href={'ColaboreConosco'} title={'Colaboradores'} text={'Veja como atuar aqui'} icon={<MdOutlineDiversity2 style={{ marginTop: '28px', scale: '4' }} />} />
                </div>
                <ButtonCadastrar />
            </div>
        </section>
    </>)
}
export default ApresentacaoParcerias;
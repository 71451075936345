import React from 'react';
import { Avatar, Button } from 'antd';
import moment from 'moment';
import { TitleL3 } from 'components';
import { MdDelete } from 'react-icons/md';
import { getInitialsName } from 'functions';
export const ViewClienteController = ({ title, cliente, clearCliente }) => {
  if (!cliente) return null;
  return (<>
    <div className='bg-info py-1 col-md-6' style={{ borderRadius: '36px' }}>
      <TitleL3>{title}</TitleL3>
      <div className='d-flex align-items-center  justify-content-center'>
        <Avatar
          src={cliente.fotoFace || null}  // Se não houver foto, usa null
          alt="Imagem do Paciente"
          size={50}
          style={{
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.2)",
            zIndex: 1000
          }}
        >
          {cliente.fotoFace ? null : getInitialsName(cliente.name)}
        </Avatar>
        <h5><strong>Nome: </strong>{cliente.name}&nbsp;</h5>
        <p><strong>Data de Nascimento: </strong> {moment(cliente.birthdate).format('DD/MM/YYYY')}&nbsp;</p>
        <Button
          type="text"
          onClick={clearCliente}
          style={{ color: 'red' }}
        >
          <MdDelete size={20} />
        </Button>
      </div>
    </div>
  </>);
};
export default ViewClienteController;
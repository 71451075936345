import React, { useState } from "react";
import api from "@services/api";
import { InputPadrao } from "@components/Input";
import { MdLocalShipping } from "react-icons/md"; // Ícone do correio
import { SelectAnt } from "@components/select";
import { ButtonAPI } from "@components/button";
export const GetFrete = () => {
    const [formData, setFormData] = useState({
        cepOrigem: "",
        cepDestino: "",
        peso: "",
        comprimento: "",
        altura: "",
        largura: "",
        codigoServico: "", // Para armazenar o serviço selecionado
    });
    const [resultado, setResultado] = useState(null);
    const [erro, setErro] = useState("");
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSelectChange = (value) => {
        setFormData({ ...formData, codigoServico: value });
    };
    const calcularFrete = async (e) => {
        e.preventDefault();
        setErro("");
        setResultado(null);
        try {
            const response = await api.post("get_frete", formData);
            setResultado(response.data);
            alert(JSON.stringify(response.data))
        } catch (error) {
            setErro("Erro ao calcular frete. Verifique os dados e tente novamente.");
        }
    };
    // Opções para o select dos Correios
    const pacotesCorreios = [
        { value: "04014", label: "PAC" },
        { value: "04065", label: "SEDEX" },
        { value: "04510", label: "SEDEX 10" },
        { value: "04599", label: "SEDEX Hoje" },
        { value: "40010", label: "PAC 7" },
    ];
    return (
        <div className="mt-1">
            <small style={{ display: "flex", alignItems: "center" }}>
                <MdLocalShipping size={20} style={{ marginRight: 5 }} />
                Calcular Frete Correios
            </small>
            <form onSubmit={calcularFrete}>
                <InputPadrao
                    type="text"
                    title={'CEP Origem'}
                    name="cepOrigem"
                    placeholder="CEP Origem"
                    onChange={handleChange}
                />
                <InputPadrao
                    type="text"
                    title={'CEP Destino'}
                    name="cepDestino"
                    placeholder="CEP Destino"
                    onChange={handleChange}
                />
                <InputPadrao
                    type="number"
                    title={'Peso'}
                    name="peso"
                    placeholder="Peso (kg)"
                    onChange={handleChange}
                />
                <InputPadrao
                    type="number"
                    title={'Comprimento'}
                    name="comprimento"
                    placeholder="Comprimento (cm)"
                    onChange={handleChange}
                />
                <InputPadrao
                    type="number"
                    title={'Altura'}
                    name="altura"
                    placeholder="Altura (cm)"
                    onChange={handleChange}
                />
                <InputPadrao
                    type="number"
                    title={'Largura'}
                    name="largura"
                    placeholder="Largura (cm)"
                    onChange={handleChange}
                />
                <SelectAnt
                    title="Selecione o Pacote"
                    value={formData.codigoServico}
                    onChange={handleSelectChange}
                    options={pacotesCorreios}
                    placeholder="Selecione o Pacote"
                />
                <ButtonAPI type="submit">Calcular</ButtonAPI>
            </form>
            {erro && <p style={{ color: "red" }}>{erro}</p>}
            {resultado ? (
                <div style={{ marginTop: "20px", padding: "15px", borderRadius: "8px", backgroundColor: "#f9f9f9", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
                    <h3 style={{ color: "#2d2d2d", marginBottom: "10px" }}>Resultado do Frete</h3>
                    {/* Acessando as propriedades do objeto resultado */}
                    <div style={{ marginBottom: "8px" }}>
                        <strong style={{ color: "#333" }}>Código do Produto:</strong>
                        <span style={{ color: "#28a745", fontWeight: "bold" }}> {resultado.coProduto}</span>
                    </div>
                    <div style={{ marginBottom: "8px" }}>
                        <strong style={{ color: "#333" }}>Valor Base:</strong>
                        <span style={{ color: "#28a745", fontWeight: "bold" }}> R$ {resultado.pcBase}</span>
                    </div>
                    <div style={{ marginBottom: "8px" }}>
                        <strong style={{ color: "#333" }}>Valor Base Geral:</strong>
                        <span style={{ color: "#28a745", fontWeight: "bold" }}> R$ {resultado.pcBaseGeral}</span>
                    </div>
                    <div style={{ marginBottom: "8px" }}>
                        <strong style={{ color: "#333" }}>Valor do Imposto:</strong>
                        <span style={{ color: "#28a745", fontWeight: "bold" }}> R$ {resultado.vlBaseCalculoImposto}</span>
                    </div>
                    <div style={{ marginBottom: "8px" }}>
                        <strong style={{ color: "#333" }}>Seguro Automático:</strong>
                        <span style={{ color: "#28a745", fontWeight: "bold" }}> R$ {resultado.vlSeguroAutomatico}</span>
                    </div>
                    <div style={{ marginBottom: "8px" }}>
                        <strong style={{ color: "#333" }}>Preço Final:</strong>
                        <span style={{ color: "#007bff", fontWeight: "bold" }}> R$ {resultado.pcFinal}</span>
                    </div>
                    {/* Exibindo o valor total final */}
                    <div>
                        <h4>Total do Frete: <span style={{ color: "#007bff", fontWeight: "bold" }}>R$ {resultado.pcFinal}</span></h4>
                    </div>
                </div>
            ) : (
               null
            )}
        </div>
    );
};
export default GetFrete;
import React, { useState, useEffect } from 'react';
import { CarouselFotoFace } from '@pages/Private/Usuarios/Cadastro/components/usuario/components/CarouselFotoFace';
import { baseURL, api } from '@services';
import { ButtonAPI } from 'components';
import { useUser } from '@context/components/UserProvider';
export const ImageHome = () => {
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const { user } = useUser();
  const routeGetSendSingleImage = (`${baseURL}ImageHome/${user.id}/photos`);
  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setImageName(file.name);
    };
    reader.readAsDataURL(file);
  };
  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setImageName(file.name);
    };
    reader.readAsDataURL(file);
  };
  const handleTakePictureClick = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      const video = document.createElement('video');
      video.srcObject = stream;
      video.play();
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
      const picture = canvas.toDataURL('image/png');
      setImage(picture);
    } catch (error) {
      console.error(error);
    }
  };
  const handleSendImage = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('id', `${user.id}`); // substitua 1 pelo ID do usuário correto
      formData.append('file', dataURItoBlob(image));
      await api.post(`ImageHome`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('Imagem enviada com sucesso!');
      window.location.reload();
    } catch (error) {
      console.error(error);
      alert('Erro ao enviar a imagem.');
    } finally {
      setIsLoading(false);
    }
  };
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  };
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    fetch(`${baseURL}ImageHome/${user.id}/photo`)
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
        throw new Error('Erro ao buscar a imagem do usuário');
      })
      .then((blob) => {
        const imageUrl = URL.createObjectURL(blob);
        setImage(imageUrl);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [user.id]);
  return (
    <>
      <div className="text-center d-flex justify-content-center mt-1 mb-2 col-md-12">
        <div className="container">
          <div className="row">
            <div className="d-flex justify-content-center col-md-12" >
              <div
                className="dropzone py-5 bg-white rounded TridimencionalTotal  col-md-12"
                onDrop={(e) => handleDrop(e)}
                onDragOver={(e) => e.preventDefault()}
              >
                {image && (
                  <>
                    <img className="d-block  rounded-circle w-50 h-50 mx-auto " src={image} alt="Imagem" style={{ maxHeight: '50vh', maxWidth: '50vw', boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.2)", }} />
                    <p className='collapse'>{imageName}</p>
                    <CarouselFotoFace tradeKey={'cadastrarImageHome'} routeGetSendSingleImage={routeGetSendSingleImage} />
                  </>
                )}
                Solte a imagem aqui ou <br />
                <input className='btn btn-primary form-control' type="file" onChange={(e) => handleFileSelect(e)} />
                <button className='btn btn-primary' onClick={handleTakePictureClick}> <i className=' fa fa-camera'></i></button>
                <ButtonAPI isLoading={isLoading} onClick={handleSendImage}> Enviar Imagem</ButtonAPI>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ImageHome;
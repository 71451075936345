import { TitleL2 } from "@components"
import { MdBookMD, MdOutlineEmojiEventsMD } from "@components/icons";
import { Link } from "react-router-dom";
export const ReconhecimentosCard = (props) => {
    return (<>
        <div className="col-md-3  mt-2"
            style={{
                padding: '5px',
            }}>
            <div className="border d-flex rounded bg-white">
                <div className="card text-center ">
                    <div className=" justify-content-center align-items-center d-flex mx-auto rounded" style={{
                        maxHeight: '180px',
                        minHeight: '180px',
                        maxWidth: '180px',
                        minWidth: '180px',
                    }}>
                        <img src={props.imageSource} className="card-img-top rounded py-3" alt={props.name} />
                    </div>
                    <div className="card-body">
                        <TitleL2>{props.name}</TitleL2>
                        <p className="card-text text-justify " style={{
                            overflow: "hidden",
                            marginBottom: "8px",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            width: '100%',
                        }}>{props.title}</p>
                        <div className="row justify-content-center">
                            <a className='text-primary '
                                href={props.href}
                                target={'_blank'} rel={'noopener noreferrer'}
                                style={{ cursor: 'pointer' }}>
                                <h5 className='text-center '><MdOutlineEmojiEventsMD className='mx-auto text-warning' size={50} /></h5>
                                <b> &nbsp;link</b>
                            </a>
                            <Link className=' text-center text-primary '
                                to={props.href_dois}
                                style={{ cursor: 'pointer' }}>
                                <h5 className='text-center '> <MdBookMD size={50} style={{  marginLeft: '30px' }} /></h5>
                                <b className="text-center ml-3"> &nbsp;link</b>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
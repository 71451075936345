import MainCarrinhoPublic from './components/MainCarrinhoPublic';
import CartOrder from './components/ordens/CartOrder';
import { getUser } from 'functions';
export const ItemsList = () => {
  const user  = getUser();
  const items = [
    {
      id: 'NavPublicCarrinhoItens',
      title: 'Itens no carrinho',
      component: <MainCarrinhoPublic />,
      subItems: [],
    },
  ];
  if (user) {
    items.push({
      id: 'NavPublicCarrinhoOrder',
      title: 'Pedidos',
      component: <CartOrder />,
      subItems: [],
    });
  }
  return items;
};
export default ItemsList;
import { Breadcrumb } from "antd";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const formatPageTitle = (title) => {
  title = title.trim().replace(/^\//, '');
  return title.split('/');
};
export function BreadcrumbAnt(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const formattedTitle = formatPageTitle(location.pathname);
  const handlePartClick = (index) => {
    const relativePath = '/' + formattedTitle.slice(0, index + 1).join('/');
    navigate(relativePath, { replace: true });
  };
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  const breadcrumbItems = formattedTitle.map((part, index) => ({
    title: (
      <span
      className="font-weight-bold"
        style={{ textTransform: 'capitalize', color: '#1890ff', cursor: 'pointer' }}
        onClick={() => handlePartClick(index)}
      >
        {part}
      </span>
    ),
  }));
  return (<>
    <Breadcrumb
      className="breadcrumb font-weight-bold"
      style={{ maxHeight: '30px', overflow: "hidden", marginTop: '-12px', paddingLeft: '0px' }}
      items={breadcrumbItems}
    />
    {screenWidth >= 1058 && <hr style={{ marginTop: '-12px', marginBottom: '8px' }} />}
  </>);
}
export default BreadcrumbAnt;
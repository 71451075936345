import React, { useState } from 'react';
import ChartConfiguration from './ChartConfiguration';
import '../../styles/ChartConfiguration.css';
import { ButtonAPI, ButtonRemoverCampo, TitleL3 } from '@components';
const Chart = ({ type, config, onConfigure, onDelete }) => {
  const [isConfiguring, setIsConfiguring] = useState(false);
  const storedData = JSON.parse(localStorage.getItem('profissional'));
  const professional_id = storedData.id;
  const handleConfigureClick = () => {
    setIsConfiguring(true);
  };
  const handleConfigure = (chartConfig) => {
    onConfigure(chartConfig);
    setIsConfiguring(false);
  };
  const handleCancelConfigure = () => {
    setIsConfiguring(false);
  };
  const handleDelete = () => {
    onDelete();
  };
  return (
    <div className="chart-display">
      <TitleL3>{type} Chart</TitleL3>
      {isConfiguring ? (
        <ChartConfiguration 
          config={config}
          onConfigure={handleConfigure}
          onCancel={handleCancelConfigure}
          onDelete={handleDelete}
          selectedChart={{ label: type }}
          professionalId={professional_id} 
          />
      ) : (
        <div className='row'>     
          <ButtonAPI onClick={handleConfigureClick}>Configure</ButtonAPI>
          <ButtonRemoverCampo onClick={onDelete}/>
        </div>
      )}
    </div>
  );
};
export default Chart;
import React, { useState } from "react";
import { Form, Input, Button, Flex, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ImageDragDrop, BreadcrumbAnt, DragDropPdf } from "components";
import { apiTokenProfissional } from "services";
import MarkdownEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import MarkdownIt from "markdown-it";
import { useProfisional } from "context";
const mdParser = new MarkdownIt();
export const Index = () => {
  const [form] = useForm();
  const [fileList, setFileList] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);
  const [markdownContent, setMarkdownContent] = useState(""); 
  const { profissional } = useProfisional();
  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key === "creation_date") {
          formData.append(key, values[key].toISOString()); 
        } else {
          formData.append(key, values[key]);
        }
      });
      formData.append("pdfs", pdfFile);
      fileList.forEach((file) => {
        formData.append("images", file.originFileObj);
      });
      const response = await apiTokenProfissional().post(
        `post_services/${profissional.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // Exibe mensagem de sucesso
      message.success("Serviço cadastrado com sucesso!");
      console.log("Dados enviados com sucesso:", response.data);
    } catch (error) {
      console.error("Erro ao enviar os dados:", error);
    }
  };
  return (
    <>
      <BreadcrumbAnt icon={"suitcase fa"} />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <ImageDragDrop fileList={fileList} setFileList={setFileList} />
        <Form.Item label="Arquivo de PDF Informação Serviço" name="informacao_pdf">
          <DragDropPdf onPdfUpload={setPdfFile} />
        </Form.Item>
        <Form.Item
          label="Nome do Serviço"
          name="services_name"
          rules={[{ required: true, message: "Por favor, insira o nome do serviço." }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Valor do Serviço"
          name="value"
          rules={[{ required: true, message: "Por favor, insira o valor do serviço." }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Estoque do Serviço" name="services_stock">
          <Input />
        </Form.Item>
        <Form.Item
          label="Descrição do Serviço"
          name="services_description"
          rules={[{ required: true, message: "Por favor, insira a descrição do serviço." }]}
        >
          <MarkdownEditor
            value={markdownContent}
            style={{ height: "400px" }}
            renderHTML={(text) => mdParser.render(text)}
            onChange={({ text }) => {
              setMarkdownContent(text);
              form.setFieldsValue({ services_description: text }); // Atualiza o formulário corretamente
            }}
          />
        </Form.Item>
        <Form.Item>
          <Flex vertical gap="small" style={{ width: "10%" }}>
            <Button type="primary" htmlType="submit" size="large">
              Enviar
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </>
  );
};
export default Index;
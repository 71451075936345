import React, { useEffect, useState } from 'react';
import GDPRModules from './GDPRModules';
import LGPDModules from './LGPDModules';
import PoliticaDePrivacidade from './PoliticaDePrivacidade';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Checkbox, Tag } from 'antd';
import TitleL2 from '../../text/TitleL2';
import { MdInfoOutlineMD } from '@components/icons';
export const TermoDeResponsabilidadeCadastro = ({ aceitouTermo, setAceitouTermo, getUserId }) => {
  const [showModules, setShowModules] = useState(false);
  const toggleModules = () => {
    setShowModules(!showModules);
  };
  const [showSubModules, setShowSubModules] = useState(false);
  const toggleSubModules = () => {
    setShowSubModules(!showSubModules);
  };
  const handleAceitarTermo = () => {
    setAceitouTermo((prevAceitouTermo) => !prevAceitouTermo);
  };
  useEffect(() => {
    setAceitouTermo(aceitouTermo);
  }, [getUserId, aceitouTermo, setAceitouTermo]);
  return (<>
    <div className='w-100' id={'TermoResposabilidadeCadastro'} >
      <div className='text-center mr-2 ml-2 mt-3'>
        <label className='font-weight-bold text-center underline text-primary'>
          <Checkbox
            defaultChecked={aceitouTermo}
            onChange={handleAceitarTermo}
          />
          &nbsp;
          <b style={{
            textDecorationLine: 'underline',
            textDecorationSkipInk: 'none',
            cursor: 'pointer',
          }}>Eu aceito os termos de cadastro</b>
        </label>
      </div>
      {aceitouTermo &&
        (
          <div className='mt-3 mb-3 d-flex w-1oo align-items-center justify-content-center' style={{ backgroundColor: '#d4edda' }} >
              <div className='col-md-1'>
                <CheckCircleOutlined style={{ fontSize: '48px', color: 'green' }} />
              </div>
              <div className='col-md-11'>
                <TitleL2 style={{ color: "green", textAlign: "left" }}>Termos de Consentimento Livre e Esclarecido aceito</TitleL2>
                <li className='text-success'> TCLEs - Cadastro Resumo</li>
                <li className='text-dark'> Você assumiu total responsabilidade pelo uso dos sistema e está pronto para desfrustar dos beneficíos desse</li>
                <li className='text-dark'> Você pode escalonar qualquer serviço nosso de cadastro com esse termo único, unificado de cadastro</li>
                <li className='text-dark'> Você assumiu compartilhamento total de dados com a THOT I.A. CORP.</li>
                <li className='text-warning'> Você também pode discordar a qualquer momento dos nossos termos e inativar seu serviços</li>
                <li className='text-dark py-3'><strong>Data aceita: </strong>{ }</li>
            </div>
          </div>)}
      <p className='text-center'><abbr title="Clique para ver mais informações"><small className={`${showModules ? 'font-weight-bold text-center text-danger ' : 'font-weight-bold text-center text-primary '}`} onClick={toggleModules}><MdInfoOutlineMD /> - Termos de Consentimento Livre e Esclarecido [  TCLEs ] de Cadastro</small></abbr></p>
      {
        showModules && <>
          <div className="text-dark text-justify col-md-12">
            <div className='row'>
              <div className='col-md-6'>
                <h5 className='text-center'><abbr title="Clique para ver mais informações"><h5 className={`${!showSubModules ? 'font-weight-bold text-justify text-danger  ' : 'font-weight-bold text-justify text-primary'}`} onClick={toggleSubModules}>Termo de Responsabilidade Cadastro &nbsp;<i className='fa fa-info-circle'></i></h5></abbr></h5>
                {
                  !showSubModules && <>
                    <p className='text-justify '>
                      Bem-vindo(a) ao Termo de Responsabilidade para Cadastro  da THOT I.A. CORP.
                      Este documento estabelece os termos e condições para a utilização dos nossos serviços ao realizar o cadastro  em nossos sistema digitais.
                      Leia atentamente antes de prosseguir com o cadastro.
                    </p>
                    <h5 className='font-weight-bold'>Termos e Condições</h5>
                    <ul className='ml-3'>
                      <hr />
                      <li className='text-justify '>
                        <strong>Introdução e Aceitação</strong>
                        <p className='text-justify  '>
                          Ao prosseguir com o cadastro, você concorda em cumprir todos os termos e condições estabelecidos neste documento. Você reconhece que leu e compreendeu o conteúdo deste termo e concorda em ficar vinculado por ele.
                        </p>
                      </li>
                      <hr />
                      <li className='text-justify '>
                        <strong>Cadastro e Uso dos Sistemas Digitais</strong>
                        <p className='text-justify  '>
                          Ao se cadastrar em nossos sistema digitais, você se compromete a fornecer informações precisas e atualizadas. O acesso e uso dos sistema estão sujeitos aos termos estabelecidos neste documento.
                        </p>
                        <ul className='ml-3 '>
                          <li className='text-justify '>✅ Forneça informações precisas e atualizadas ao se cadastrar.</li>
                          <li className='text-justify '>✅ Utilize os sistema de acordo com as regras e regulamentos.</li>
                          <li className='text-justify '>✅ Acesso e uso dos sistema sujeitos aos termos estabelecidos.</li>
                          <li className='text-justify '>✅ Você é o único responsável por manter suas informações de cadastro atualizadas.</li>
                        </ul>
                      </li>
                      <hr />
                      <li className='text-justify '>
                        <strong>Responsabilidade do Usuário</strong>
                        <p className='text-justify  '>
                          Você é o único responsável por manter a confidencialidade das credenciais de acesso (login e senha). Não compartilhe suas credenciais com terceiros. Você é responsável por todas as atividades realizadas com suas credenciais de acesso.
                        </p>
                        <ul className='ml-3 '>
                          <li className='text-justify '>✅ Mantenha suas credenciais de acesso em segurança.</li>
                          <li className='text-justify '>✅ Não compartilhe suas credenciais com terceiros.</li>
                          <li className='text-justify '>✅ Assuma a responsabilidade por atividades realizadas com suas credenciais.</li>
                          <li className='text-justify '>✅ Proteja suas informações de acesso contra uso não autorizado.</li>
                        </ul>
                      </li>
                      <hr />
                      <li className='text-justify '>
                        <strong>Uso Adequado dos Sistemas</strong>
                        <p className='text-justify  '>
                          O uso dos nossos sistema deve ser adequado e respeitar as leis e regulamentos. Conteúdo ofensivo, ilegal ou prejudicial não será tolerado.
                        </p>
                        <ul className='ml-3 '>
                          <li className='text-justify '>✅ Utilize os sistema de forma ética e legal.</li>
                          <li className='text-justify '>✅ Evite compartilhar conteúdo ofensivo ou difamatório.</li>
                          <li className='text-justify '>✅ Não utilize os sistema para atividades ilegais.</li>
                          <li className='text-justify '>✅ Respeite os direitos de privacidade de outros usuários.</li>
                        </ul>
                      </li>
                      <hr />
                      <li className='text-justify '>
                        <strong>Propriedade Intelectual e Conteúdo</strong>
                        <p className='text-justify  '>
                          Reconheça que o conteúdo presente nos sistema pode estar sujeito a direitos autorais e propriedade intelectual. Não copie, reproduza ou distribua o conteúdo sem autorização.
                        </p>
                        <ul className='ml-3 '>
                          <li className='text-justify '>✅ Respeite os direitos autorais e propriedade intelectual do conteúdo nos sistema.</li>
                          <li className='text-justify '>✅ Obtenha autorização antes de copiar, reproduzir ou distribuir qualquer conteúdo.</li>
                          <li className='text-justify '>✅ Evite violar os direitos dos detentores de propriedade intelectual.</li>
                          <li className='text-justify '>✅ Utilize o conteúdo apenas de acordo com as permissões fornecidas.</li>
                        </ul>
                      </li>
                      <hr />
                      <li className='text-justify '>
                        <strong>Privacidade e Proteção de Dados</strong>
                        <p className='text-justify  '>
                          Entenda como coletamos, armazenamos e processamos seus dados. Consulte nossa política de privacidade para saber como suas informações são utilizadas e protegidas.
                        </p>
                        <ul className='ml-3 '>
                          <li className='text-justify '>✅ Leia e compreenda nossa política de privacidade.</li>
                          <li className='text-justify '>✅ Conheça como suas informações pessoais são coletadas e utilizadas.</li>
                          <li className='text-justify '>✅ Compreenda os direitos que você possui sobre seus dados pessoais.</li>
                          <li className='text-justify '>✅ Garanta que suas informações estejam protegidas de acordo com nossos padrões de segurança.</li>
                        </ul>
                      </li>
                      <hr />
                      <li className='text-justify '>
                        <strong>Limitação de Responsabilidade</strong>
                        <p className='text-justify  '>
                          Não somos responsáveis por danos ou prejuízos decorrentes do uso dos sistema. Você utiliza o sistema por sua própria conta e risco.
                        </p>
                        <ul className='ml-3 '>
                          <li className='text-justify '>✅ Compreenda que você utiliza os sistema por sua própria responsabilidade.</li>
                          <li className='text-justify '>✅ Não nos responsabilizamos por perdas, danos ou prejuízos resultantes do uso do sistema.</li>
                          <li className='text-justify '>✅ Esteja ciente dos riscos associados ao uso de sistemas digitais.</li>
                          <li className='text-justify '>✅ Aceite a responsabilidade por qualquer resultado decorrente da ações das suas credências nos sistema.</li>
                        </ul>
                      </li>
                      <hr />
                      <li className='text-justify '>
                        <strong>Alterações nos Termos</strong>
                        <p className='text-justify  '>
                          Os termos podem ser atualizados periodicamente. Você será notificado sobre alterações significativas nos termos.
                        </p>
                        <ul className='ml-3 '>
                          <li className='text-justify '>✅ Esteja ciente de que os termos podem ser revisados e atualizados.</li>
                          <li className='text-justify '>✅ Comprometa-se a verificar regularmente os termos para estar informado sobre as mudanças.</li>
                          <li className='text-justify '>✅ Será notificado sobre alterações significativas nos termos por meio de comunicação adequada.</li>
                          <li className='text-justify '>✅ Continue a utilizar os sistema somente se concordar com os termos atualizados.</li>
                        </ul>
                      </li>
                      <hr />
                      <li className='text-justify '>
                        <strong>Encerramento de Conta</strong>
                        <p className='text-justify  '>
                          Sua conta pode ser encerrada em caso de violações dos termos ou inatividade prolongada.
                        </p>
                        <ul className='ml-3 '>
                          <li className='text-justify '>✅ Evite violar os termos para manter o acesso à sua conta ativo.</li>
                          <li className='text-justify '>✅ Compreenda as condições sob as quais sua conta pode ser encerrada.</li>
                          <li className='text-justify '>✅ Entenda que a inatividade prolongada pode levar ao encerramento da conta.</li>
                          <li className='text-justify '>✅ Entre em contato conosco se precisar reativar sua conta após encerramento por inatividade ou violação dos termos.</li>
                        </ul>
                      </li>
                      <hr />
                      <li className='text-justify '>
                        <strong>Contato e Suporte</strong>
                        <p className='text-justify  '>
                          Para dúvidas, suporte técnico ou questões relacionadas aos termos, entre em contato conosco.
                        </p>
                        <ul className='ml-3 '>
                          <li className='text-justify '>✅ Encontre informações de contato para buscar suporte e esclarecer dúvidas.</li>
                          <li className='text-justify '>✅ Use os canais de comunicação fornecidos para obter assistência técnica.</li>
                          <li className='text-justify '>✅ Entre em contato conosco se tiver perguntas sobre os termos ou necessitar de orientações.</li>
                          <li className='text-justify '>✅ Estamos aqui para auxiliá-lo(a) e fornecer o suporte necessário.</li>
                        </ul>
                      </li>
                      <hr />
                    </ul>
                  </>}
              </div>
              <PoliticaDePrivacidade />
              <GDPRModules />
              <LGPDModules />
            </div>
          </div>
        </>}
    </div >
  </>);
};
export default TermoDeResponsabilidadeCadastro;
import { useLocation, useNavigate } from 'react-router-dom';
export function LinkHref(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const { search } = location;
    const destinationWithSearch = `${props.to}${search}`;
    const clicarNoId = (event) => {
        event.preventDefault(); // Evita o comportamento padrão do link
        // Navega para a nova URL sem recarregar a página
        navigate(destinationWithSearch, { replace: true });
        // Se você quiser realizar algum outro comportamento, como clicar em um elemento com id específico
        const elementoClicado = document.getElementById(props.href);
        if (elementoClicado) {
            elementoClicado.click();
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };
    return (
        <a
            className={'mx-auto text-center text-secondary '}
            type={props.type}
            href={destinationWithSearch}
            style={{
                textDecorationLine: 'underline',
                textDecorationSkipInk: 'none',
                cursor: 'pointer',
            }}
            onClick={clicarNoId}
        >
            <b>{props.children}</b>
        </a>
    );
}
export default LinkHref;
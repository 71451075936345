import React, { useState, useEffect } from 'react';
import { fetchImages, updateImageDate, updateImageStatus } from '../services';
import { getUser } from 'functions';
import { MdDelete, MdRefresh } from 'react-icons/md';
export function CarouselFotoFace({ routeGetSendSingleImage, tradeKey, imageUpdated, onImageUpdated, setUser }) {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [deletedImages, setDeletedImages] = useState([]);
  const user = getUser();
  useEffect(() => {
    fetchImages(setLoading, setImages, onImageUpdated, setError, routeGetSendSingleImage);
  }, [routeGetSendSingleImage, imageUpdated]);
  useEffect(() => {
    return () => {
      images.forEach((image) => URL.revokeObjectURL(image.contentUrl));
    };
  }, [images]);
  const RemoveFotoFace = (index) => {
    setDeletedImages((prev) => [...prev, images[index]]);
    setImages((prev) => prev.filter((_, i) => i !== index));
  };
  const handleImageClick = (index) => {
    const clickedImage = images[index];
    const updatedImages = [clickedImage, ...images.filter((_, i) => i !== index)];
    setImages(updatedImages);
    const blob = new Blob([new Uint8Array(clickedImage.content.data)]);
    const imageUrl = URL.createObjectURL(blob);
    updateImageDate(user.id, clickedImage.id, imageUrl, setUser);
  };
  const handleUndoDelete = () => {
    setDeletedImages((prev) => {
      if (prev.length === 0) return prev;
      const lastDeletedImage = prev[prev.length - 1];
      setImages((images) => [...images, lastDeletedImage]);
      return prev.slice(0, -1);
    });
  };
  if (loading) return <p>Carregando carousel...</p>;
  if (error) return null;
  return (
    <div className='d-flex flex-wrap col-md-12 justify-content-center align-items-center mt-2'>
      {images.map((image, index) => {
        const blob = new Blob([new Uint8Array(image.content.data)]);
        const url = URL.createObjectURL(blob);
        const imageKey = `${tradeKey}_${index}`;
        return (
          <div style={{ position: "relative", display: "inline-block" }} key={imageKey}>
            <img
              className='ml-1 mt-1'
              src={url}
              alt={`Imagem ${index}`}
              onClick={() => handleImageClick(index)}
              style={{
                maxHeight: '80px',
                minHeight: '80px',
                maxWidth: '80px',
                minWidth: '80px',
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 5px 15px rgba(0, 0, 0, 0.2)",
              }}
            />
            <MdDelete
              className="holder text-danger position-absolute"
              onClick={() => {
                RemoveFotoFace(index);
                updateImageStatus(user.id, image.id);
              }}
              style={{
                zIndex: '1',
                top: '85%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                cursor: 'pointer'
              }}
            />
          </div>
        );
      })}
      {deletedImages.length > 0 && (
        <button className='btn btn-warning ml-1' onClick={handleUndoDelete}>
          <MdRefresh />
        </button>
      )}
    </div>
  );
}
export default CarouselFotoFace;